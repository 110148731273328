import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { API } from "store/reducer/apiSlice";
import { getVendorsNames } from "api/vendor/index";
import { allowNumber } from "util/makeDataForm";

const useBeverageOption = (options, breweryId, toast) => {
  const dispatch = useDispatch();
  const [connectedVendors, setConnectedVendors] = useState([]);

  const breweryVendorOptions = useMemo(() => {
    const newOptions = options.filter(
      (option) =>
        option.sellerType === "brewery" &&
        option.target === "vendor" &&
        option.sellerId === breweryId
    );

    return {
      title: "제조사 -> 도매사 판매 용량/가격",
      sellerId: breweryId,
      sellerType: "brewery",
      target: "vendor",
      options: newOptions,
    };
  }, [options, breweryId]);

  const selectableVenderOptionVolumes = useMemo(() => {
    return breweryVendorOptions.options.reduce(
      (acc, cur) => [...acc, cur.volume],
      []
    );
  }, [breweryVendorOptions.options]);

  const breweryPubOptions = useMemo(() => {
    const newOptions = options.filter(
      (option) =>
        option.sellerType === "brewery" &&
        option.target === "pub" &&
        option.sellerId === breweryId
    );

    return {
      title: "제조사 -> 매장 판매 용량/가격",
      sellerId: breweryId,
      sellerType: "brewery",
      target: "pub",
      options: newOptions,
    };
  }, [options, breweryId]);

  const vendorOptionCategories = useMemo(() => {
    return connectedVendors.reduce((acc, cur) => {
      const newOptions = options
        .filter(
          (option) =>
            option.sellerType === "vendor" && option.sellerId === cur.vendorId
        )
        .map((option) => {
          const opt = { ...option };
          if (!selectableVenderOptionVolumes.includes(option.volume)) {
            opt.volume = "";
          }
          return opt;
        });
      const optionCategory = {
        title: `${cur.vendorName}(도매사) -> 매장 판매 용량/가격`,
        sellerId: cur.vendorId,
        sellerType: "vendor",
        target: "pub",
        options: newOptions,
      };

      return [...acc, optionCategory];
    }, []);
  }, [options, connectedVendors]);

  const getOrganizedOptions = () => {
    const connectedOptions = getConnectedOptions();
    const options = connectedOptions
      .filter((option) => !(option.price === "" && option.volume === ""))
      .map((option) => ({ ...option, price: allowNumber(option.price) }));

    return options;
  };

  const getOptionIdList = (options) => {
    return options.map((option) => option.optionId);
  };

  const getConnectedOptions = () => {
    const vendorOptions = getConnectedVendorOptions(vendorOptionCategories);

    return [
      ...vendorOptions,
      ...breweryVendorOptions.options,
      ...breweryPubOptions.options,
    ];
  };

  const getConnectedVendorOptions = (categories) => {
    return categories
      .map((category) => category.options)
      .reduce((acc, cur) => [...acc, ...cur], []);
  };

  const checkIncompleteOption = (options) => {
    let result = true;
    options.forEach((option) => {
      if (option.volume === "" || option.price === "") {
        optionValidToast(option);
        return (result = false);
      }
    });

    return result;
  };

  const optionValidToast = (option) => {
    const [categoryTitle, subCategoryTitle] = getOptionCategoryTitle(option);

    typeof toast === "function" &&
      toast(`${categoryTitle} [${subCategoryTitle}] 값이 비어있습니다.`);
  };

  const getOptionCategoryTitle = (option) => {
    let categoryTitle = "";
    let subCategoryTitle = "";

    if (option.sellerType === "brewery") {
      if (option.target === "vendor") {
        categoryTitle = breweryVendorOptions.title;
        subCategoryTitle = getSubCategoryTitle(
          breweryVendorOptions.options,
          option.optionId
        );
      } else {
        categoryTitle = breweryPubOptions.title;
        subCategoryTitle = getSubCategoryTitle(
          breweryPubOptions.options,
          option.optionId
        );
      }
    } else {
      const category = vendorOptionCategories.filter(
        (category) => category.sellerId === option.sellerId
      );
      categoryTitle = category[0].title;
      subCategoryTitle = getSubCategoryTitle(
        category[0].options,
        option.optionId
      );
    }

    return [categoryTitle, subCategoryTitle];
  };

  const getSubCategoryTitle = (options, optionId) => {
    return `용량${options.findIndex((v) => v.optionId === optionId) + 1}`;
  };

  const deleteOptIdInNewCreateOpt = (options, optionIdList) =>
    options.map((option) => {
      const newOption = { ...option };
      !optionIdList.includes(newOption.optionId) && delete newOption.optionId;

      return newOption;
    });

  useEffect(() => {
    const initConnectedVendors = async (breweryId) => {
      if (!breweryId) return;

      const {
        payload: {
          data: { vendors },
        },
      } = await dispatch(API(getVendorsNames({ breweryId })));
      setConnectedVendors(vendors);
    };

    initConnectedVendors(breweryId);
  }, [breweryId, dispatch]);

  return {
    breweryVendorOptions,
    selectableVenderOptionVolumes,
    breweryPubOptions,
    vendorOptionCategories,
    getOrganizedOptions,
    getOptionIdList,
    checkIncompleteOption,
    deleteOptIdInNewCreateOpt,
  };
};

export default useBeverageOption;
