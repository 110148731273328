import { clearResults } from "store/reducer/commonSlice";
import Cookies from "js-cookie";

export const errorControl = (response) => {
  const {
    data: { message },
    status,
  } = response;

  const userCookie = Cookies.get("userData");

  if (status === 401 && userCookie) {
    clearResults();
    Cookies.remove("userData");
    window.location.reload();

    return;
  }
  alert(`Error 발생\n\n${message}`);
};
