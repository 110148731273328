import React from "react";
import LinkSearchBar from "container/searchBar/linkSearchBar/index";
import Styled from "./style";

export default function TabUI(props) {
  const {
    pageList,
    tabLabel,
    state,
    setState,
    handleChangeTab,
    handleSearch,
    searchBarData,
    handleChangeSearchInput,
    handleReload,
  } = props;

  return (
    <Styled.UIWrapper>
      <Styled.TabWrapper>
        {tabLabel.map((list, index) => {
          const { firstName, lastName } = list;

          return (
            <Styled.Tab
              key={index}
              index={index}
              state={state}
              onClick={handleChangeTab(index)}
            >
              {`${firstName.label}:${lastName.label}`}
            </Styled.Tab>
          );
        })}
      </Styled.TabWrapper>
      <LinkSearchBar
        tabLabel={tabLabel}
        state={state}
        setState={setState}
        handleSearch={handleSearch}
        searchBarData={searchBarData}
        handleChangeSearchInput={handleChangeSearchInput}
        handleReload={handleReload}
      />
      {pageList[state.menu]}
    </Styled.UIWrapper>
  );
}
