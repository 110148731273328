import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    height: 190px;
    padding: 34px 40px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};

    .header {
      display: flex;
      align-items: center;
      font-size: 17px;
      margin-bottom: 30px;

      & img {
        margin-right: 4px;
      }

      .desc {
        font-size: 14px;
        color: ${color.Colorce4d4e};
      }
    }
  `,
  InputWrapper: styled.div`
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
  `,
};

export default Styled;
