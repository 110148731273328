import styled from "styled-components";
import { color } from "style/globalStyle";

export const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${color.Colord5d8db};
  padding: 0 15px;
  font-size: 14px;
  line-height: 1.29;
  color: ${color.Color323c41};
  background-color: ${color.Colorf6f8f8};
`;
