import { color } from "style/globalStyle";
import styled from "styled-components";

export const StyledAvatar = styled.div`
  display: block;
  width: ${({ size }) => (size === "small" ? "38px" : "110px")};
  height: ${({ size }) => (size === "small" ? "38px" : "110px")};
  overflow: hidden;
  background-color: ${color.Colorc9c9c9};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
