import React from "react";
import styled from "styled-components";

const InqueryButton = ({ children, ...restProps }) => {
  return (
    <Button type="button" {...restProps}>
      {children}
    </Button>
  );
};

export default InqueryButton;

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 3px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background-color: #1b242b;

  &[disabled] {
    background-color: rgba(27, 36, 43, 0.3);
    cursor: not-allowed;
  }
`;
