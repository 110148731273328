import { createGlobalStyle, css } from "styled-components";
import { reset } from "styled-reset";
export const color = {
  Colorffffff: "#ffffff",
  Color000000: "#000000",
  Color23282B: "#23282b",
  Colore3e3e3: "#e3e3e3",
  Colorb1b1b1: "#b1b1b1",
  Color1b242b: "#1b242b",
  Color12171d: "#12171d",
  Colorce4d4e: "#ce4d4e",
  Colore0e5e8: "#e0e5e8",
  Colord5d8db: "#d5d8db",
  Color5ab1f0: "#5ab1f0",
  Colorc9c9c9: "#c9c9c9",
  Color1f6cd5: "#1f6cd5",
  Colore8f0fb: "#e8f0fb",
  Coloredf1f2: "#edf1f2",
  Color707070: "#707070",
  Colorf9fbfb: "#f9fbfb",
  Colorf6f8f8: "#f6f8f8",
  Color777777: "#777777",
  Color121212: "#121212",
  Colorfcfcfd: "#fcfcfd",
  Colorebfdee: "#ebfdee",
  Color0f8623: "#0f8623",
  Color4d4e56: "#4d4e56",
  Colorff0000: "#ff0000",
  Color323c41: "#323c41",
  Color252729: "#252729",
  Color383d40: "#383d40",
  Color65686a: "#65686a",
  Colora5a5a5: "#a5a5a5",
  Colore9e9e9: "#e9e9e9",
  Colorcbcbcb: "#cbcbcb",
  Colorf8f8f8: "#f8f8f8",
  Colord50016: "#d50016",
  Colorc6c6c6: "#c6c6c6",
  Color323538: "#323538",
  Colordbd1c2: "#dbd1c2",
  Color6e6b66: "#6E6B66",
  Color919395: "#919395",
  Colorbdbebf: "#bdbebf",
  Colordedede: "#dedede",
  Colorffefe7: "#ffefe7",
  Colord04500: "#d04500",
};
export const image = {
  loginBack: require("asset/image/background.png").default,
  icLogo: require("asset/image/icLogo.svg").default,
  icMenu: require("asset/image/icMenu.svg").default,
  icPubs: require("asset/image/icPubs.svg").default,
  icPubsGray: require("asset/image/icPubsGray.svg").default,
  icVendor: require("asset/image/icVendor.svg").default,
  icVendorGray: require("asset/image/icVendorGray.svg").default,
  icVendorBlack: require("asset/image/icVendorBlack.svg").default,
  icBrewery: require("asset/image/icBrewery.svg").default,
  icBreweryGray: require("asset/image/icBreweryGray.svg").default,
  icBreweryBlack: require("asset/image/icBreweryBlack.svg").default,
  icBeverage: require("asset/image/icBeverage.svg").default,
  icBeverageGray: require("asset/image/icBeverageGray.svg").default,
  icEducation: require("asset/image/icEducation.svg").default,
  icEducationGray: require("asset/image/icEducationGray.svg").default,
  icLink: require("asset/image/icLink.svg").default,
  icLinkGray: require("asset/image/icLinkGray.svg").default,
  icTransaction: require("asset/image/icTransaction.svg").default,
  icTransactionGray: require("asset/image/icTransactionGray.svg").default,
  icPermission: require("asset/image/icPermission.svg").default,
  icPermissionGray: require("asset/image/icPermissionGray.svg").default,
  icNext: require("asset/image/icNext.svg").default,
  icNotice: require("asset/image/icNotice.svg").default,
  icNoticeGray: require("asset/image/icNoticeGray.svg").default,
  icBannerWhite: require("asset/image/icBannerWhite.svg").default,
  icBannerGray: require("asset/image/icBannerGray.svg").default,
  icAlarm: require("asset/image/icAlarm.svg").default,
  icProfile: require("asset/image/icProfile.svg").default,
  icPlus: require("asset/image/icPlus.svg").default,
  icPin: require("asset/image/icPin.svg").default,
  icReload: require("asset/image/icReload.svg").default,
  icSearch: require("asset/image/icSearch.svg").default,
  icArrowDown: require("asset/image/icArrowDown.svg").default,
  icCheckFill: require("asset/image/icCheckFill.svg").default,
  icCheckCircle: require("asset/image/icCheckCircle.svg").default,
  icCheckBlank: require("asset/image/icCheckBlank.svg").default,
  icCheckFillBlue: require("asset/image/icCheckFillBlue.svg").default,
  icSubmenu: require("asset/image/icSubmenu.svg").default,
  icUpdate: require("asset/image/icUpdate.svg").default,
  icUpdateGray: require("asset/image/icUpdateGray.svg").default,
  icDelete: require("asset/image/icDelete.svg").default,
  icDeleteGray: require("asset/image/icDeleteGray.svg").default,
  icEssential: require("asset/image/icEssential.svg").default,
  icBusiness: require("asset/image/icBusiness.svg").default,
  icRadioBlank: require("asset/image/icRadioBlank.svg").default,
  icRadioSelect: require("asset/image/icRadioSelect.svg").default,
  icDownload: require("asset/image/icDownload.svg").default,
  icDownloadSmall: require("asset/image/icDownloadSmall.svg").default,
  icDownloadBlack: require("asset/image/icDownloadBlack.svg").default,
  icPartner: require("asset/image/icPartner.svg").default,
  icLogin: require("asset/image/icLogin.svg").default,
  icEye: require("asset/image/icEye.svg").default,
  icEyeCheck: require("asset/image/icEyeCheck.svg").default,
  icArrowDownBold: require("asset/image/icArrowDownBold.svg").default,
  icArrowWhite: require("asset/image/icArrowWhite.svg").default,
  icPerson: require("asset/image/icPerson.svg").default,
  icBeverageInfo: require("asset/image/icBeverageInfo.svg").default,
  icNew: require("asset/image/icNew.svg").default,
  icMeasure: require("asset/image/icMeasure.svg").default,
  icPlusLarge: require("asset/image/icPlusLarge.svg").default,
  icPlusLargeWhite: require("asset/image/icPlusLargeWhite.svg").default,
  icClose: require("asset/image/icClose.svg").default,
  icCloseWhite: require("asset/image/icCloseWhite.svg").default,
  icCloseCircle: require("asset/image/icCloseCircle.svg").default,
  icCloseOpacity: require("asset/image/icCloseOpacity.svg").default,
  icPicture: require("asset/image/icPicture.svg").default,
  icWholesaler: require("asset/image/icWholesaler.svg").default,
  icMaker: require("asset/image/icMaker.svg").default,
  dummy: require("asset/image/dummy.png").default,
  dummy2: require("asset/image/dummy2.png").default,
  dummy3: require("asset/image/dummy3.png").default,
  dummy4: require("asset/image/dummy4.jpeg").default,
  spinner: require("asset/image/spinner.gif").default,
  icGallery: require("asset/image/icGallery.svg").default,
  icCalendar: require("asset/image/icCalendar.svg").default,
  icArrowButton: require("asset/image/icArrowButton.svg").default,
  icReadall: require("asset/image/icReadall.svg").default,
  icReadallGray: require("asset/image/icReadallGray.svg").default,
  vimeoUrlGuide: require("asset/image/vimeoUrlGuide.jpg").default,
  icExclamation: require("asset/image/icExclamation.svg").default,
  defaultProfileImg: require("asset/image/defaultProfileImg.png").default,
  icArrow16: require("asset/image/icArrow16.svg").default,
  icDoubleArrow16: require("asset/image/icDoubleArrow16.svg").default,
  icNoData: require("asset/image/ic_nodata.svg").default,
  icColon: require("asset/image/icColon.svg").default,
  icCheckBoxCheck: require("asset/image/icCheckBoxCheck.svg").default,
  icCheckBoxCheckEmpty: require("asset/image/icCheckBoxCheckEmpty.svg").default,
};

export const typo = {
  medium22: css`
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4545;
  `,
  medium18: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4444;
  `,
  medium16: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  `,
  medium15: css`
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4666;
  `,
  medium14: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4285;
  `,
  medium13: css`
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3846;
  `,
  medium12: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3333;
  `,
  regular14: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285;
  `,
  regular13: css`
    font-size: 13px;
    font-weight: 400;
    line-height: 1.3846;
  `,
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Spoqa Han Sans Neo";
      color: ${color.Color121212};
    }
  html, body{
    height: 100%; 
    font-size: 14px; 
    font-family: "Spoqa Han Sans Neo";
    overflow: overlay;
  }
  #root{
    height: inherit;      
  }
  li {
    list-style: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    display: block;
  }
  input{
  // 구글 자동완성 css 해제
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      // 자동완성 후 포커스 해제시 컬러
      -webkit-text-fill-color: #000000 
      // 자동완성 커서 컬러
      /* caret-color: #fff; */
    }
    font-family: "Spoqa Han Sans Neo";
    ::placeholder {
      font-family: "Spoqa Han Sans Neo";
      color: ${color.Colorb1b1b1}
    }
    outline: none;
  }
  textarea, div{
    resize: none;
  }
  textarea{
    font-family: "Spoqa Han Sans Neo";
    ::placeholder {
      font-family: "Spoqa Han Sans Neo";
      color: ${color.Colorb1b1b1}
    }
  }
  table{
    border-spacing: 0;
  }
  button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
  dialog {
    border: none;
  }
  [tabindex]:focus-visible,
  a:focus-visible,
  label:focus-visible,
  button:focus-visible{
    outline: none;
    box-shadow: 0 0 0 2px ${color.Color1f6cd5};
  }

  .a11y {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
`;
