import React from "react";

import { Input } from "./style";
import { numToStringPhoneNum } from "util/makeDataForm";
import InfoInputWrapper from "../index";

export default function PhoneInfoInput(props) {
  const {
    placeholder,
    title,
    name,
    state,
    setState,
    height = "40px",
    isRequired,
    isValidError,
  } = props;
  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft[name] = numToStringPhoneNum(value);
    });
  };
  return (
    <InfoInputWrapper title={title} name={name} isRequired={isRequired}>
      <Input
        className="input-wrapper"
        placeholder={placeholder}
        name={name}
        value={numToStringPhoneNum(state[name])}
        onChange={handleChangeInput}
        height={height}
        validation={isValidError && state[name] === ""}
      />
    </InfoInputWrapper>
  );
}
