import { useState, useCallback } from "react";
import dayjs from "dayjs";

import { getMonthYearDetails, getNewMonthYear } from "util/helper/monthYear";

const useDatepicker = (selectedDate) => {
  const currentMonthYear = getMonthYearDetails(dayjs());

  const [monthYear, setMonthYear] = useState(currentMonthYear);

  const changePrevMonthYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, -1));
  }, []);

  const changeNextMonthYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, 1));
  }, []);

  const resetMonthYear = useCallback(() => {
    const date = selectedDate ? dayjs(selectedDate) : dayjs();
    const currentDate = getMonthYearDetails(date);
    setMonthYear(currentDate);
  }, [selectedDate]);

  return {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    resetMonthYear,
  };
};

export default useDatepicker;
