import React from "react";

import AddButton from "component/button/addButton/index";
import ReloadButton from "component/button/reloadButton/index";
import { pubFilterList } from "asset/data/dummy";
import Styled from "./style";

export default function PubsSearchBar(props) {
  const {
    searchValue,
    handleChangeSearchInput,
    handleSearch,
    handleReload,
    handleFilter,
    filterValue,
  } = props;

  return (
    <Styled.Root>
      <Styled.Front>
        <Styled.SelectBox
          selectList={pubFilterList}
          value={filterValue}
          className="pub-category"
          handleFilter={handleFilter}
          placeholder="모든 매장"
        />
        <Styled.SearchInput
          searchValue={searchValue}
          placeholder="매장명으로 검색"
          handleChangeSearchInput={handleChangeSearchInput}
          handleSearch={handleSearch}
        />
        <Styled.SearchButton onClick={handleSearch} />
      </Styled.Front>
      <Styled.Back>
        <AddButton path="/pub/create" content="매장 추가" />
        <ReloadButton handleReload={handleReload} />
      </Styled.Back>
    </Styled.Root>
  );
}
