import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.article`
  width: 255px;
  height: 300px;
  border-top: 3px solid ${color.Color1b242b};
  background-color: ${color.Colorffffff};

  :nth-of-type(5n - 1),
  :nth-of-type(5n),
  :nth-of-type(5n + 2) {
    border-top: 3px solid ${color.Color777777};
  }
`;
export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 16px 11px 16px 23px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

  & h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: ${color.Color121212};
  }
`;
export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  height: 24px;
`;

export const ContentBottom = styled.div`
  height: calc(100% - 56px);
  padding: 12px 0 20px;

  & > ul {
    width: 100%;
    height: 100%;
    padding: 0 14px;
    overflow-y: auto;
  }
`;
