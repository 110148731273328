export const permissionStoreDummy = [
  {
    storeName: "생활맥주(압구정로데오점)",
    businessRegistrationNumber: "123-45-67890",
    representativeName: "김맥주",
    phoneNumber: "010-3333-4444",
    category1: "생활맥주",
    category2: "직영",
    address: "서울시 강남구 역삼동 234-45",
    storePhoneNumber: "02-333-4444",
    wholesaler1: "춘천세계",
    wholesaler1Region: "서울",
  },
  {
    storeName: "생활맥주(청담점)",
    businessRegistrationNumber: "123-45-67890",
    representativeName: "김맥주",
    phoneNumber: "010-3333-4444",
    category1: "생활맥주",
    category2: "직영",
    address: "서울시 강남구 역삼동 234-45",
    storePhoneNumber: "02-333-4444",
    wholesaler1: "춘천세계",
    wholesaler1Region: "서울",
  },
  {
    storeName: "생활맥주(역삼점)",
    businessRegistrationNumber: "123-45-67890",
    representativeName: "김맥주",
    phoneNumber: "010-3333-4444",
    category1: "생활맥주",
    category2: "직영",
    address: "서울시 강남구 역삼동 234-45",
    storePhoneNumber: "02-333-4444",
    wholesaler1: "춘천세계",
    wholesaler1Region: "서울",
  },
];
export const permissionOtherDummy = [
  {
    productName: "신바람로동맥주",
    amount: "KAG (20L)",
    price: "100,000",
    category1: "라이트비어",
    style: "엠버라거",
    alcoholPercentage: "4.9%",
    description:
      "달콤한 곡물의 맛을 주는 맥아가 주가 된 맛에 상큼한 홉의 맛이 화사하게 나타나는 맥주",
    wholesaler1: "춘천세계",
    wholesaler1PhoneNumber: "02-333-4444",
  },
  {
    productName: "동동맥주",
    amount: "KAG (30L)",
    price: "100,000",
    category1: "라이트비어",
    style: "엠버라거",
    alcoholPercentage: "4.9%",
    description:
      "달콤한 곡물의 맛을 주는 맥아가 주가 된 맛에 상큼한 홉의 맛이 화사하게 나타나는 맥주",
    wholesaler1: "춘천세계",
    wholesaler1PhoneNumber: "02-333-4444",
  },
  {
    productName: "까사미어생맥주",
    amount: "KAG (50L)",
    price: "100,000",
    category1: "라이트비어",
    style: "엠버라거",
    alcoholPercentage: "4.9%",
    description:
      "달콤한 곡물의 맛을 주는 맥아가 주가 된 맛에 상큼한 홉의 맛이 화사하게 나타나는 맥주",
    wholesaler1: "춘천세계",
    wholesaler1PhoneNumber: "02-333-4444",
  },
];

export const permissionStoreTitle = [
  { label: "매장명", key: "storeName", type: "" },
  {
    label: "사업자등록번호(아이디)",
    key: "businessRegistrationNumber",
    type: "download",
  },
  { label: "대표자명", key: "representativeName", type: "" },
  { label: "휴대폰번호", key: "phoneNumber", type: "" },
  { label: "분류1", key: "category1", type: "" },
  { label: "분류2", key: "category2", type: "" },
  { label: "주소", key: "address", type: "" },
  { label: "매장전화번호", key: "storePhoneNumber", type: "" },
];

export const permissionOtherTitle = [
  { label: "상품명", key: "productName", type: "show" },
  { label: "용량", key: "amount", type: "" },
  { label: "가격", key: "price", type: "underline" },
  { label: "분류1", key: "category1", type: "" },
  { label: "스타일", key: "style", type: "" },
  { label: "도수", key: "alcoholPercentage", type: "" },
  { label: "특징", key: "description", type: "" },
  { label: "등록 요청 도매사", key: "wholesaler1", type: "" },
  { label: "요청 도매사 전화번호", key: "wholesaler1PhoneNumber", type: "" },
];
