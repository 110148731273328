export default class Generator {
  constructor(key, initial = "") {
    this.key = key;
    !this.load() && this.save(initial);
  }

  save(data) {
    if (!data) {
      return;
    }

    return localStorage.setItem(this.key, JSON.stringify(data));
  }

  load() {
    return JSON.parse(localStorage.getItem(this.key));
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}

export const userStorage = new Generator("user");
