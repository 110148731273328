import { image } from "style/globalStyle";
export const PATH = {
  root: "/",
  login: "/login",
  pub: "/pub",
  pubCreate: "/pub/create",
  pubUpdate: "/pub/update",
  vendor: "/vendor",
  vendorCreate: "/vendor/create",
  vendorUpdate: "/vendor/update",
  brewery: "/brewery",
  breweryCreate: "/brewery/create",
  breweryUpdate: "/brewery/update",
  beverage: "/beverage",
  beverageCreate: "/beverage/create",
  beverageCategory: "/beverage/category",
  beverageUpdate: "/beverage/update",
  education: "/education",
  educationCreate: "/education/create",
  educationDetail: "/education/detail",
  educationUpdate: "/education/update",
  link: "/link",
  transaction: "/transaction",
  permission: "/permission",
  notice: "/notice",
  noticeCreate: "/notice/create",
  noticeUpdate: "/notice/update",
  settingAccount: "/account",
  settingChangePassword: "/account/changePassword",
  settingBanner: "/banner",
  settingBannerCreate: "/banner/create",
  settingBannerUpdate: "/banner/update",
};

export const authPathList = [
  PATH.pub,
  PATH.pubCreate,
  PATH.pubUpdate,
  PATH.vendor,
  PATH.vendorCreate,
  PATH.vendorUpdate,
  PATH.brewery,
  PATH.breweryCreate,
  PATH.breweryUpdate,
  PATH.beverage,
  PATH.beverageCreate,
  PATH.beverageCategory,
  PATH.beverageUpdate,
  PATH.education,
  PATH.educationCreate,
  PATH.link,
  PATH.transaction,
  PATH.permission,
  PATH.notice,
  PATH.noticeCreate,
  PATH.noticeUpdate,
  PATH.settingAccount,
  PATH.settingChangePassword,
  PATH.settingBanner,
  PATH.settingBannerCreate,
  PATH.settingBannerUpdate,
];

export const publicPathList = [PATH.root, PATH.login];

export const Layout1110pxList = [
  PATH.pubCreate,
  PATH.pubUpdate,
  PATH.vendorCreate,
  PATH.vendorUpdate,
  PATH.breweryCreate,
  PATH.breweryUpdate,
  PATH.beverageCreate,
  PATH.beverageCategory,
  PATH.beverageUpdate,
  PATH.education,
  PATH.permission,
  PATH.notice,
  PATH.settingBanner,
  PATH.settingBannerCreate,
];
export const ZINDEX = {
  modal: 9999,
  header: 2000,
  calendar: 1000,
  navbar: 100,
  selectBox: 10,
  // header: 11,
};

export const headerTitle = {
  pub: { name: "매장", deps: 1 },
  "pub/create": { name: "매장 > 매장 추가" },
  "pub/update": { name: "매장 > 매장 수정" },
  vendor: { name: "도매사", deps: 1 },
  "vendor/create": { name: "도매사 > 도매사 추가" },
  "vendor/update": { name: "도매사 > 도매사 수정" },
  brewery: { name: "제조사", deps: 1 },
  "brewery/create": { name: "제조사 > 제조사 추가" },
  "brewery/update": { name: "제조사 > 제조사 수정" },
  beverage: { name: "맥주상품", deps: 1 },
  "beverage/create": { name: "맥주상품 > 상품 추가" },
  "beverage/category": {
    name: "맥주상품 > 분류/스타일 설정",
  },
  "beverage/update": { name: "맥주상품 > 상품 수정" },
  education: { name: "교육자료", deps: 1 },
  "education/create": { name: "교육자료 > 자료 추가" },
  "education/detail": { name: "교육자료 > 강의" },
  "education/update": { name: "교육자료 > 자료 수정" },
  link: { name: "링크", deps: 1 },
  transaction: { name: "거래내역", deps: 1 },
  permission: { name: "매장/상품 인가", deps: 1 },
  notice: { name: "공지사항", deps: 1 },
  "notice/create": { name: "공지사항 > 공지 추가" },
  "notice/update": { name: "공지사항 > 공지 수정" },
  account: { name: "설정 > 내 정보 관리" },
  "account/changePassword": { name: "설정 > 내 정보 관리 > 비밀번호 변경" },
  banner: { name: "설정 > 배너 이미지 관리" },
  "banner/create": { name: "설정 > 배너 이미지 관리 > 배너 등록" },
  "banner/update": { name: "설정 > 배너 이미지 관리 > 배너 수정" },
};

export const navbarList = [
  {
    label: "매장",
    imgWhite: image.icPubs,
    imgGray: image.icPubsGray,
    path: PATH.pub,
    alt: "pub-icon",
  },
  {
    label: "도매사",
    imgWhite: image.icVendor,
    imgGray: image.icVendorGray,
    path: PATH.vendor,
    alt: "vendor-icon",
  },
  {
    label: "제조사",
    imgWhite: image.icBrewery,
    imgGray: image.icBreweryGray,
    path: PATH.brewery,
    alt: "brewery-icon",
  },
  {
    label: "맥주상품",
    imgWhite: image.icBeverage,
    imgGray: image.icBeverageGray,
    path: PATH.beverage,
    alt: "beverage-icon",
  },
  {
    label: "교육자료",
    imgWhite: image.icEducation,
    imgGray: image.icEducationGray,
    path: PATH.education,
    alt: "education-icon",
  },
  {
    label: "링크",
    imgWhite: image.icLink,
    imgGray: image.icLinkGray,
    path: PATH.link,
    alt: "link-icon",
  },
  {
    label: "거래내역",
    imgWhite: image.icTransaction,
    imgGray: image.icTransactionGray,
    path: PATH.transaction,
    alt: "transaction-icon",
  },
  {
    label: "매장/상품 인가",
    imgWhite: image.icPermission,
    imgGray: image.icPermissionGray,
    path: PATH.permission,
    alt: "permission-icon",
  },
  {
    label: "공지사항",
    imgWhite: image.icNotice,
    imgGray: image.icNoticeGray,
    path: PATH.notice,
    alt: "notice-icon",
  },
  {
    label: "배너관리",
    imgWhite: image.icBannerWhite,
    imgGray: image.icBannerGray,
    path: PATH.settingBanner,
    alt: "banner-icon",
  },
];
