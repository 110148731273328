import styled from "styled-components";
import { color, image } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    height: ${(props) => props.height};
    display: flex;
    column-gap: 24px;
    align-items: center;
  `,
  Wrapper: styled.div`
    :hover {
      cursor: pointer;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label span {
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      background: url(${image.icRadioBlank}) left top no-repeat;
      cursor: pointer;
    }
    input[type="radio"]:checked + label span {
      background: url(${image.icRadioSelect}) left top no-repeat;
    }
    label {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
    input,
    label {
      :hover {
        cursor: pointer;
      }
    }
  `,
};
export default Styled;
