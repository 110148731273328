import styled from "styled-components";

import { color } from "style/globalStyle";
import { ReactComponent as PlusIcon } from "asset/image/icPlus.svg";

const Styled = {
  Root: styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 16px;
    height: 36px;
    background-color: ${color.Color1b242b};
    cursor: pointer;

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.23;
      color: ${color.Colorffffff};
    }
  `,
  PlusIcon: styled(PlusIcon)`
    margin-right: 5px;
    fill: ${color.Colorffffff};
  `,
};

export default Styled;
