import React from "react";

import {
  Root,
  Front,
  Back,
  CategorySettingBtn,
  SettingIcon,
  CustomSelectBox,
  CustomSearchInput,
  CustomSearchButton,
} from "./style";
import AddButton from "component/button/addButton/index";
import ReloadButton from "component/button/reloadButton/index";

export default function BeverageSearchBar(props) {
  const {
    searchValue,
    filterList,
    filterValue,
    handleChangeSearchInput,
    handleFilter,
    handleSearch,
    handleReload,
  } = props;

  return (
    <Root>
      <Front>
        <CustomSelectBox
          selectList={filterList}
          className="beverage-category-2"
          placeholder="전체보기"
          value={filterValue}
          handleFilter={handleFilter}
        />
        <CustomSearchInput
          searchValue={searchValue}
          placeholder="상품명으로 검색"
          handleChangeSearchInput={handleChangeSearchInput}
          handleSearch={handleSearch}
        />
        <CustomSearchButton onClick={handleSearch} />
      </Front>
      <Back>
        <CategorySettingBtn to="/beverage/category">
          <SettingIcon />
          분류/스타일 설정
        </CategorySettingBtn>
        <AddButton path="/beverage/create" content="상품 추가" />
        <ReloadButton handleReload={handleReload} />
      </Back>
    </Root>
  );
}
