import React, { useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import Styled from "./style";
import { color, image } from "style/globalStyle";
import { modalCont } from "asset/data/modalCont";
import { API } from "store/reducer/apiSlice";
import { putLessonsByLessonId, getLessonsByLessonId } from "api/education";
import PortalModal from "container/modal/portalModal/index";
import InfoInput from "component/input/infoInput/index";
import SubmitButton from "component/button/submitButton/index";
import CancelButton from "component/button/cancelButton/index";
import Toast from "component/toast/index";
import CustomPrompt from "component/prompt/index";
import useEducationForm from "hook/useEducationForm";
import useToast from "hook/useToast";
import usePrompt from "hook/usePrompt";

export default function EducationUpdate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const {
    formData,
    setFormData,
    modalOption,
    setModalOption,
    setRequestData,
    handleCancel,
    isValidError,
    setIsValidError,
    isValidEmpty,
    vimeoError,
    setVimeoError,
  } = useEducationForm();
  const { isShow, message, type, toast } = useToast();

  const checkList = useMemo(() => {
    return [formData.title, formData.content, formData.videoUrl];
  }, [formData]);

  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList,
  });

  const handleSubmit = useCallback(async () => {
    try {
      if (!isValidEmpty(formData)) {
        toast("필수 기입 정보를 입력해 주세요", 'warning');
        setIsValidError(true);
        return;
      }
      setIsValueChanged(false);
      const req = await setRequestData();
      await dispatch(API(putLessonsByLessonId(id, req)));
      setModalOption((draft) => {
        draft.modal.modalVisible = true;
        draft.modal.modalKey = "educationUpdated";
      });
      setTimeout(() => {
        setModalOption((draft) => {
          draft.modal.modalVisible = false;
        });
      }, [1000]);
    } catch (err) {
      console.log(err);
      setIsValidError(true);
      setVimeoError(true);
      toast("동영상 URL이 유효하지 않습니다.", 'warning');
    }
  }, [setModalOption, dispatch, setRequestData, id]);

  useEffect(() => {
    async function init() {
      const {
        payload: { data },
      } = await dispatch(API(getLessonsByLessonId(id)));

      setFormData((draft) => {
        draft.title = data.title;
        draft.content = data.content;
        draft.videoUrl = data.videoUrl;
      });
      setInitList([data.title, data.content, data.videoUrl]); //  initList 의 제일 처음 값 입력 checkList 의 값과 비교하기 위함
    }
    init();
  }, []);

  return (
    <>
      <CustomPrompt
        modalState={modalOption}
        setModalState={setModalOption}
        isValueChanged={isValueChanged}
      />
      <Styled.Root>
        {modalOption.modal.modalVisible && (
          <PortalModal setState={setModalOption}>
            {modalCont({
              modalKey: modalOption.modal.modalKey,
              setState: setModalOption,
              nextPath: "/education",
            })}
          </PortalModal>
        )}
        <Styled.Header>교육 자료 내용 입력</Styled.Header>
        <Styled.Wrapper>
          <InfoInput
            title="제목"
            name="title"
            state={formData}
            setState={setFormData}
            inputType="text"
            placeholder="교육자료 제목을 입력하세요"
            maxLength="80"
            isValidError={isValidError}
            isRequired="true"
          />
          <InfoInput
            title="내용"
            name="content"
            state={formData}
            setState={setFormData}
            inputType="textarea"
            height="350px"
            placeholder="교육자료 내용을 입력하세요"
            maxLength="2000"
            isValidError={isValidError}
            isRequired="true"
          />
          <InfoInput
            title="동영상 첨부 링크"
            name="videoUrl"
            state={formData}
            setState={setFormData}
            inputType="text"
            placeholder="ex. https://vimeo.com/512457933"
            isValidError={isValidError}
            validCheck={vimeoError}
            isRequired="true"
          />
          <Styled.GuideWrapper>
            <figcaption>동영상 URL 첨부 방법</figcaption>
            <img src={image.vimeoUrlGuide} alt="동영상 첨부 가이드" />
          </Styled.GuideWrapper>
        </Styled.Wrapper>
        <Styled.ButtonWrapper>
          <CancelButton
            handleClick={handleCancel}
            content="취소"
            height="44px"
          />
          <SubmitButton
            content="수정완료"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
