import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .desc {
      color: ${color.Color777777};
      margin-top: 8px;
      margin-bottom: 32px;
    }
  `,
  Form: styled.form``,
  InputRoot: styled.div`
    width: 220px;
    position: relative;
    input {
      width: 100%;
      width: 220px;
      height: 34px;
      padding-left: 16px;
      border-radius: 5px;
      border: solid 1px
        ${(props) =>
          props.errorToggle ? color.Colorff0000 : color.Colorc9c9c9};
      background-color: ${color.Colorffffff};
      outline: none;
      :focus {
        border: solid 1px ${color.Color5ab1f0};
      }
    }
    .inner-icon {
      position: absolute;
      right: 12px;
      top: 5px;
      :hover {
        cursor: pointer;
      }
    }
    .error-desc {
      visibility: ${(props) => (props.errorToggle ? "unset" : "hidden")};
      font-size: 12px;
      color: ${color.Colorff0000};
      margin-top: 4px;
    }
  `,
  ButtonWrapper: styled.div`
    margin-top: 30px;
  `,
};
export default Styled;
