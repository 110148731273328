import React from "react";
import Styled from "./style";
export default function InfoInputWrapper({
  children,
  name,
  explain,
  imgSrc,
  title,
  isRequired,
}) {
  return (
    <Styled.Root gridName={name} imgSrc={imgSrc}>
      <div className="title">
        {title}
        {isRequired && (
          <Styled.Essential aria-label="필수값">*</Styled.Essential>
        )}
        <Styled.Explain>{explain}</Styled.Explain>
      </div>
      {children}
    </Styled.Root>
  );
}
