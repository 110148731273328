import React, { Fragment, useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { modalCont } from "asset/data/modalCont";
import Styled from "./style";
import { color, image } from "style/globalStyle";
import PortalModal from "container/modal/portalModal/index";
import { setProfileImg } from "store/reducer/commonSlice";
import SubmitButton from "component/button/submitButton/index";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { setModalKey } from "hook/modal";
import { getHeadquarterInfo, putHeadquarterInfo } from "api/account/index";
import { checkAwsApiStaus } from "util/presigend";
import { handleChangeInput } from "hook/handler";
import { makeDateForm } from "util/makeDataForm";

const initSubmitState = {
  img: { name: "", file: "", filePreview: "" },
  name: "",
  id: "",
  position: "",
  memo: "",
  passwordUpdated: "",
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
  },
};

export default function SettingAccount() {
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [modalState, setModalState] = useImmer(initModalState);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async () => {
    const body = {};

    body.name = submitState.name;
    body.position = submitState.position;
    body.memo = submitState.memo;
    body.contentType = submitState.img.file.type;

    const res = await dispatch(API(putHeadquarterInfo(body)));
    const { status } = res.payload;

    if (status === 200) {
      const awsStatus = await checkAwsApiStaus(
        res,
        submitState.img.file,
        dispatch
      );
      if (awsStatus === 204 || awsStatus === 200) {
        dispatch(setProfileImg(submitState.img.filePreview));
        setModalKey(setModalState, "submitComplete");
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) return;
    reader.onload = () => {
      setSubmitState((draft) => {
        draft.img.file = event.target.files[0];
        draft.img.filePreview = reader.result;
      });
    };
    reader.readAsDataURL(file);
  };

  // const handleClickClose = () => {
  //   setState((draft) => {
  //     draft.img.file = image.icProfile;
  //     draft.img.filePreview = image.icProfile;
  //   });
  // };

  const getAccountDetail = async () => {
    try {
      const info = await dispatch(API(getHeadquarterInfo()));

      const { id, name, position, memo, passwordUpdated, signedUrl } =
        info.payload.data;

      const date = makeDateForm(passwordUpdated).split("/");
      setSubmitState((draft) => {
        draft.id = id;
        draft.name = name;
        draft.position = position;
        draft.memo = memo;
        draft.img.filePreview = signedUrl;
        draft.passwordUpdated = `${date[0]}년 ${date[1]}월 ${date[2]}일`;
      });
    } catch (err) {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  useEffect(() => {
    getAccountDetail();
  }, []);

  return (
    <Styled.Root>
      {modalState.modal.modalVisible && (
        <PortalModal setState={setModalState}>
          {modalCont({
            modalKey: modalState.modal.modalKey,
            reload: true,
            completeContent: "변경사항이 저장되었습니다.",
          })}
        </PortalModal>
      )}
      <Styled.ProfileWrapper>
        <div className="profile-img-wrapper">
          <Fragment>
            <img
              className="profile-img"
              src={submitState.img.filePreview}
              alt=""
            />
            {/* x 버튼 부분입니다. 현재는 필요하지 않다 사료되어 주표님, 마리나님과 상의 후 제외하였습니다. 후에 필요하게 되면 주석만 제거하면 됩니다. - 김재경 2021.11.18*/}
            {/* <img
              className="close-icon"
              src={image.icCloseOpacity}
              alt="close-icon"
              onClick={handleClickClose}
            /> */}
          </Fragment>
        </div>
        <label htmlFor="change-profile">
          <img
            className="icon-img"
            src={image.icUpdateGray}
            alt="update-icon"
          />
          <span>사진변경</span>
        </label>
        <input
          id="change-profile"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
      </Styled.ProfileWrapper>
      <Styled.InfoWrapper>
        <div className="desc-wrapper">
          <div className="wrapper">
            <span className="title">이름</span>
            <input
              type="text"
              name="name"
              value={submitState.name}
              onChange={handleChangeInput(setSubmitState, 100)}
            />
          </div>
          <div className="wrapper">
            <span className="title">아이디</span>
            <div className="unchangeable">{submitState.id}</div>
          </div>
          <div className="wrapper">
            <span className="title">직함</span>
            <input
              type="text"
              name="position"
              value={submitState.position}
              onChange={handleChangeInput(setSubmitState, 100)}
            />
          </div>
          <div className="wrapper">
            <span className="title">비밀번호 변경</span>
            <div className="password-wrapper">
              <div className="desc">
                마지막 변경: {submitState.passwordUpdated}
              </div>
              <div
                className="desc change-password-button"
                onClick={() => {
                  history.push("account/changePassword");
                }}
              >
                변경하기
              </div>
            </div>
          </div>
          <div className="wrapper">
            <span className="title">기타</span>
            <textarea
              name="memo"
              value={submitState.memo}
              onChange={handleChangeInput(setSubmitState, 100)}
            />
          </div>
        </div>
        <Styled.ButtonWrapper>
          <SubmitButton
            content="저장"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="80px"
            height="34px"
          />
        </Styled.ButtonWrapper>
      </Styled.InfoWrapper>
    </Styled.Root>
  );
}
