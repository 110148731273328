import React, { forwardRef } from "react";

import { Root } from "./style";

const TextCountInput = forwardRef(
  ({ className, value, maxLength, ...restProps }, ref) => {
    return (
      <Root className={className}>
        <input
          type="text"
          ref={ref}
          {...restProps}
          //   value={inputValue}
          //   placeholder="분류명을 입력해 주세요"
          //   onChange={handleChangeInput}
          //   onKeyDown={handleEnterConfirm}
        />
        <span>{`${value.length}/${maxLength}`}</span>
      </Root>
    );
  }
);

export default TextCountInput;
