import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { image } from "style/globalStyle";
import { navbarList } from "asset/data/common";
import { toggleNavbarSize } from "store/reducer/commonSlice";
import Styled from "./style";
export default function Navbar() {
  const [pathName, setPathName] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const globalStoreValue = useSelector((state) => ({
    navbarToggle: state.common.navbarToggle,
    navarSize: state.common.navbarSize,
  }));

  const handleMenuSize = () => {
    dispatch(toggleNavbarSize());
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  return (
    <Styled.Root width={globalStoreValue.navarSize}>
      <div className="nav-link menu-button" onClick={handleMenuSize}>
        <img className="icon" src={image.icMenu} alt="menu-icon" />
        {globalStoreValue.navbarToggle && (
          <img className="logo" src={image.icLogo} alt="logo" />
        )}
      </div>
      {navbarList.map((item, idx) => {
        const { label, imgWhite, imgGray, path, alt } = item;
        const selected = pathName.includes(path);

        return (
          <Styled.NavLink
            className="nav-link"
            key={idx}
            to={path}
            exact
            selected={selected}
          >
            <img
              className="icon"
              src={selected ? imgWhite : imgGray}
              alt={alt}
            />
            {globalStoreValue.navbarToggle && (
              <div className="label">{label}</div>
            )}
          </Styled.NavLink>
        );
      })}
    </Styled.Root>
  );
}
