import styled from "styled-components";
import { color, typo } from "style/globalStyle";

export const Root = styled.dialog`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 52px;
  border-radius: 5px;
  background-color: ${color.Color12171d};
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 8px rgba(25, 31, 40, 0.2);
  z-index: 10000;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px;

  & > svg {
    margin-right: 8px;
  }

  & h2 {
    ${typo.medium14};
    color: ${color.Colorffffff};
  }
`;
