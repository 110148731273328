import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    cursor: move;
    width: 350px;
    height: 300px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px ${color.Colord5d8db};
    background-color: ${color.Colorffffff};
    background-color: ${(props) => props.backgroundColor};
    padding: 17px 30px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 14px;
    .banner-img {
      height: 145px;
      object-fit: cover;
    }
  `,
  TopWrapper: styled.div`
    display: flex;
    justify-content: space-between;

    .col {
      width: 100%;
      height: 66px;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 13px;
      padding: 13px 0;
      justify-content: space-between;
      .col-title {
        color: ${color.Color777777};
      }
      .badge {
        margin-top: 10px;
      }
    }
    .col + .col:before {
      position: absolute;
      content: "";
      top: 13px;
      left: 0;
      width: 1px;
      height: 40px;
      background-color: ${color.Colorb1b1b1};
    }
  `,
  BottomWrapper: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 10px;
    div {
      color: ${color.Color777777};
    }
    .button {
      :hover {
        cursor: pointer;
      }
    }
  `,
};
export default Styled;
