import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as CheckBoxIcon } from "asset/image/icCheckBoxCheck.svg";
import { ReactComponent as CheckBoxEmptyIcon } from "asset/image/icCheckBoxCheckEmpty.svg";
import { image } from "style/globalStyle";
import Styled from "./style";

export default function CheckboxSelectBox(props) {
  const {
    id,
    className,
    selectList, //  select List
    selectBoxList, // submit button 누르기전 CheckboxSelectBox 내부에서 체크 상태
    value,
    selectTypeName,
    width = "130px",
    innerWidth = "130px",
    height = "36px",
    placeholder = "",
    handleFilter,
    handleResetSelectList,
    handleConfirmCheckSelectBox,
    disabled,
    isValidError,
  } = props;

  const selectListRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const handleSelectInput = (item) => (e) => {
    e.stopPropagation();
    handleFilter && handleFilter(item);
  };

  const handleOpenSelectItems = (e) => {
    e.stopPropagation();

    setVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleCloseWhenOutsideClick = (e) => {
      if (!visible) {
        return;
      }
      let targetElement = e.target;
      do {
        if (targetElement === selectListRef.current) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      setVisible(false);
      handleResetSelectList();
    };

    const handleEscListener = (e) => {
      if (e.keyCode === 27) {
        setVisible(false);
        handleResetSelectList();
      }
    };

    window.addEventListener("click", handleCloseWhenOutsideClick);
    window.addEventListener("keydown", handleEscListener);
    return () => {
      window.removeEventListener("click", handleCloseWhenOutsideClick);
      window.removeEventListener("keydown", handleEscListener);
    };
  }, [visible, selectListRef]);

  return (
    <Styled.Root>
      <Styled.SelectButton
        id={id}
        width={width}
        height={height}
        onClick={handleOpenSelectItems}
        visible={visible}
        isSelect={value.length ? 1 : 0}
        disabled={disabled}
        className={className}
        validation={isValidError && !value}
      >
        <span>
          {value.length !== 0
            ? value[0].name === "전체"
              ? `${selectTypeName} 전체`
              : `${selectTypeName} ${value.length}개`
            : placeholder}
        </span>
        <img
          className={`arrow-icon`}
          src={image.icArrowDown}
          alt="arrow-button"
        />
      </Styled.SelectButton>
      {visible && (
        <Styled.SelectWrapper>
          <Styled.SelectInner innerWidth={innerWidth} ref={selectListRef}>
            {selectList &&
              selectList.map((item, i) => (
                <Styled.SelectItem
                  height={height}
                  key={i}
                  disabled={disabled}
                  onClick={handleSelectInput(item)}
                >
                  {selectBoxList.some(
                    (valueItem) => valueItem.linkId === item.linkId
                  ) ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxEmptyIcon />
                  )}
                  <div>{item.name}</div>
                </Styled.SelectItem>
              ))}
          </Styled.SelectInner>
          <Styled.ButtonWrapper>
            <Styled.SubmitButton
              type="button"
              onClick={handleConfirmCheckSelectBox}
            >
              선택완료
            </Styled.SubmitButton>
          </Styled.ButtonWrapper>
        </Styled.SelectWrapper>
      )}
    </Styled.Root>
  );
}
