import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import SignInput from "component/input/signInput/index";
import { getChoicesOfVolumeList } from "util/helper/selectList";
import {
  Root,
  TitleWrapper,
  DeleteIcon,
  SettingWrapper,
  SettingItem,
  VolumeSelectBox,
} from "./style";
import { setCommaToNumber } from "util/makeDataForm";

const OptionCard = ({
  data,
  index,
  isAlreadySelectedVolume,
  selectableVolume,
  toast,
  setState,
}) => {
  const handleVolumeSelect = useCallback(
    (_, value) => {
      if (!selectableVolume.includes(value)) {
        typeof toast === "function" &&
          toast("해당 옵션은 제조사에서 판매하고 있지 않아 등록이 불가합니다");
        return;
      }
      setState((draft) => {
        const idx = draft.options.findIndex(
          (option) => option.optionId === data.optionId
        );
        draft.options[idx].volume = value;
      });
    },
    [setState, data.optionId, selectableVolume]
  );

  const handleChangePrice = useCallback((e) => {
    const { value } = e.target;
    setState((draft) => {
      const idx = draft.options.findIndex(
        (option) => option.optionId === data.optionId
      );
      draft.options[idx].price = value;
    });
  }, []);

  const handleDeleteCard = () => {
    setState((draft) => {
      draft.options = draft.options.filter(
        (option) => option.optionId !== data.optionId
      );
    });
  };

  const handleResetCard = () => {
    setState((draft) => {
      const index = draft.options.findIndex(
        (opt) => opt.optionId === data.optionId
      );
      draft.options[index].optionId = uuidv4();
      draft.options[index].price = "";
      draft.options[index].volume = "";
    });
  };

  return (
    <Root>
      <TitleWrapper>
        <span>용량{index + 1}</span>
        <button
          type="button"
          aria-label="옵션 삭제"
          onClick={index !== 0 ? handleDeleteCard : handleResetCard}
        >
          <DeleteIcon />
        </button>
      </TitleWrapper>
      <SettingWrapper>
        <SettingItem>
          <dt>
            <label htmlFor="volumeBtn">용량</label>
          </dt>
          <dd>
            <VolumeSelectBox
              id="volumeBtn"
              selectList={getChoicesOfVolumeList(isAlreadySelectedVolume)}
              placeholder="- 용량 선택 -"
              name="volume"
              value={data.volume}
              handleSelect={handleVolumeSelect}
            />
          </dd>
        </SettingItem>
        <SettingItem>
          <dt>
            <label htmlFor="priceInput">가격</label>
          </dt>
          <dd>
            <SignInput
              id="priceInput"
              sign="원"
              placeholder="가격 입력"
              value={setCommaToNumber(data.price)}
              onChange={handleChangePrice}
            />
          </dd>
        </SettingItem>
      </SettingWrapper>
    </Root>
  );
};

export default OptionCard;
