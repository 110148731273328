import Table from "component/table/table/index";
import React from "react";
import Styled from "./style";
import {
  linkPubAndVenderColumn,
  linkPubAndBeverageColumn,
  linkVenderAndBreweryColumn,
} from "asset/data/column";
import Pagination from "component/pagination/index";
import { license, pubFranchise } from "util/makeDataForm";

export default function TabView(props) {
  const {
    upContentHeader,
    downContentHeader,
    pageNumber,
    state,
    setState,
    handlePagination,
    pageInfo,
    tableData,
    handleSort,
    sort,
    handleDelete,
  } = props;

  const columnsChange = (pageNumber) => {
    switch (pageNumber) {
      case 0:
        return linkPubAndVenderColumn;
      case 1:
        return linkPubAndBeverageColumn;
      case 2:
        return linkVenderAndBreweryColumn;
      default:
        return linkPubAndVenderColumn;
    }
  };

  const pubInfo = () => {
    return (
      <div className="flex-box">
        <span className="title">매장명</span>
        <span className="content">{state.selectData.pubName}</span>
        <span className="title">사업자등록번호</span>
        <span className="content">{state.selectData.pubTaxcode}</span>
        <span className="title">대표자명</span>
        <span className="content">{state.selectData.pubManagerName}</span>
        <span className="title">핸드폰번호</span>
        <span className="content">{state.selectData.pubManagerPhone}</span>
        <span className="title">분류1</span>
        <span className="content">{state.selectData.pubFranchiseName}</span>
        <span className="title">분류2</span>
        <span className="content">
          {pubFranchise(state.selectData.pubFranchise)}
        </span>
        <span className="title">매장주소</span>
        <span className="content">{state.selectData.pubAddr}</span>
        <span className="title">매장 전화번호</span>
        <span className="content">{state.selectData.pubPhone}</span>
      </div>
    );
  };

  const breweryInfo = () => {
    return (
      <div className="flex-box">
        <span className="title">제조사명</span>
        <span className="content">{state.selectData.breweryName}</span>
        <span className="title">사업자등록번호</span>
        <span className="content">{state.selectData.breweryTaxcode}</span>
        <span className="title">대표자명</span>
        <span className="content">{state.selectData.breweryManagerName}</span>
        <span className="title">핸드폰번호</span>
        <span className="content">{state.selectData.breweryManagerPhone}</span>
        <span className="title">거래면허구분</span>
        <span className="content">
          {license(
            state.selectData.breweryLicenseForeign,
            state.selectData.breweryLicenseSpecific,
            state.selectData.breweryLicenseTotal
          )}
        </span>
        <span className="title">제조사 주소</span>
        <span className="content">{state.selectData.breweryAddr}</span>
        <span className="title">제조사 전화번호</span>
        <span className="content">{state.selectData.breweryPhone}</span>
        <span className="title">제조사 담당자</span>
        <span className="content">{state.selectData.breweryManagerName}</span>
        <span className="title">담당자 전화번호</span>
        <span className="content">{state.selectData.breweryManagerPhone}</span>
      </div>
    );
  };

  const venderInfo = () => {
    return (
      <div className="flex-box">
        <span className="title">도매사명</span>
        <span className="content">{state.selectData.vendorName}</span>
        <span className="title">사업자등록번호</span>
        <span className="content">{state.selectData.vendorTaxcode}</span>
        <span className="title">대표자명</span>
        <span className="content">{state.selectData.vendorManagerName}</span>
        <span className="title">핸드폰번호</span>
        <span className="content">{state.selectData.vendorManagerPhone}</span>
        <span className="title">도매면허구분</span>
        <span className="content">
          {license(
            state.selectData.vendorLicenseForeign,
            state.selectData.vendorLicenseSpecific,
            state.selectData.vendorLicenseTotal
          )}
        </span>
        <span className="title">납품지역</span>
        <span className="content">{state.selectData.vendorArea}</span>
        <span className="title">도매사 주소</span>
        <span className="content">{state.selectData.vendorAddr}</span>
        <span className="title">도매사 전화번호</span>
        <span className="content">{state.selectData.vendorPhone}</span>
        <span className="title">도매사 담당자</span>
        <span className="content">{state.selectData.vendorManagerName}</span>
        <span className="title">도매사 연락처</span>
        <span className="content">{state.selectData.vendorPhone}</span>
      </div>
    );
  };

  return (
    <Styled.MainWrapper>
      <Styled.LeftMainWrapper>
        <Table
          columns={columnsChange(pageNumber)}
          datas={tableData}
          modalKey="deletePubModal"
          minWidth="730px"
          state={state}
          setState={setState}
          handleSort={handleSort}
          sort={sort}
          handleDelete={handleDelete(pageNumber)}
        />
        <Pagination
          handlePagination={handlePagination}
          state={state}
          setState={setState}
          margin="28px 0 0 0"
          pageInfo={pageInfo}
        />
      </Styled.LeftMainWrapper>
      <Styled.RightMainWrapper>
        <Styled.RightContent>
          <h2>{upContentHeader} 정보</h2>
          <div className="right-content-box">
            {state.selectData.length !== 0
              ? upContentHeader === "매장"
                ? pubInfo()
                : upContentHeader === "제조사"
                ? breweryInfo()
                : venderInfo()
              : null}
          </div>
        </Styled.RightContent>
        <Styled.RightContent>
          <h2>{downContentHeader} 정보</h2>
          <div className="right-content-box">
            {state.selectData.length !== 0
              ? downContentHeader === "매장"
                ? pubInfo()
                : downContentHeader === "제조사"
                ? breweryInfo()
                : venderInfo()
              : null}
          </div>
        </Styled.RightContent>
      </Styled.RightMainWrapper>
    </Styled.MainWrapper>
  );
}
