import styled from "styled-components";
import { Link } from "react-router-dom";

import { color } from "style/globalStyle";
import SelectBox from "component/selectBox/index";
import SearchInput from "component/input/searchInput/index";
import SearchButton from "component/button/searchButton/index";
import { ReactComponent as SettingIconImage } from "asset/image/icSetting.svg";

export const Root = styled.div`
  width: inherit;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const Front = styled.div`
  display: flex;
  height: inherit;
`;
export const Back = styled.div`
  display: flex;
  height: inherit;
  column-gap: 16px;
`;

export const CustomSelectBox = styled(SelectBox)`
  && span {
    color: ${color.Color1b242b};
  }
`;

export const CustomSearchInput = styled(SearchInput)`
  margin-left: 10px;
`;

export const CustomSearchButton = styled(SearchButton)`
  margin-left: 8px;
`;

export const CategorySettingBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  color: ${color.Colorffffff};
  background-color: ${color.Color1b242b};
`;

export const SettingIcon = styled(SettingIconImage)`
  margin-right: 4px;
  fill: ${color.Colorffffff};
`;
