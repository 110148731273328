import axios from "axios";

const httpClient = axios.create({
  baseURL: "https://api.vimeo.com/",
});

httpClient.defaults.headers.common[
  "Authorization"
] = `bearer ${process.env.REACT_APP_VIMEO_API_KEY}`;

export const getVideoInfo = async (videoId) => {
  const {
    data: {
      duration,
      pictures: { sizes },
    },
  } = await httpClient.get(`videos/${videoId}`);

  const thumbnailUrl = sizes[4].link;

  return { duration, thumbnailUrl };
};

export const extractVideoIdFromVideoUrl = (url) => {
  const reg = /https:\/\/vimeo.com\/(.*)/gm;

  return reg.exec(url)[1];
};
