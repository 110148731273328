export const VOLUME_LIST = Object.freeze([
  { label: "케그 (20L*1EA)", value: "keg", disabled: false },
  { label: "캔 (500ml*24EA)", value: "can", disabled: false },
  { label: "병 (360ml*30EA)", value: "bot", disabled: false },
]);

export const ALARM_CODE_LINK = Object.freeze({
  REQUEST_ORDER: "/permission",
  CANCEL_ORDER: "/permission",
  REJECT_ORDER: "/permission",
  ACCEPT_ORDER: "/permission",
  UPDATE_ORDER: "/permission",
  COMPLETE_ORDER: "/permission",
  BREWERY_SUBMIT_OPTION: "/permission",
  VENDOR_SUBMIT_OPTION: "/permission",
  APPROVE_OPTION: "/permission",
  REJECT_OPTION: "/permission",
  UPDATE_OPTION: "/permission",
  REGISTER_PUB: "/permission",
  UPLOAD_LESSON: "/permission",
  UPLOAD_NOTICE: "/permission",
});

export const TRANSACTION_TAB = Object.freeze({
  PUBS: "pub-transaction",
  VENDORS: "vendor-transaction",
});
