import React from "react";
import Styled from "./style";
import { setModalVisible } from "hook/modal";

export default function ModalCancelButton(props) {
  const { content, height, setState } = props;

  const handleClick = () => {
    setModalVisible(setState, false);
  };
  return (
    <Styled.Root onClick={handleClick} height={height}>
      {content}
    </Styled.Root>
  );
}
