import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { API } from "store/reducer/apiSlice";
import { loadFranchiseList } from "api/pub";
import SelectBox from "component/selectBox/index";
import CheckboxSelectBox from "component/checkboxSelectBox/index";
import ReloadButton from "component/button/reloadButton/index";
import Styled from "./style";
import {
  noticeSelectList,
  noticePubSelectList,
  sidoList,
} from "asset/data/selectList";

const typeKor = {
  pub: "매장",
  vendor: "도매사",
  brewery: "제조사",
  all: "전체",
};

export default function NoticeSearchBar(props) {
  const {
    dropdownInfo,
    filterValue,
    selectBoxList,
    handleInit,
    handleFilter,
    handleCheckSelectBox,
    handleResetSelectList,
    handleConfirmCheckSelectBox,
    handleChangeSearchInput,
    handleResetCheckboxSelectBoxList,
    handleSearch,
    handleReload,
  } = props;

  const dispatch = useDispatch();

  const [franchiseNameList, setFranchiseNameList] = useState({});

  useEffect(() => {
    async function loadFranchiseNames() {
      const {
        payload: {
          data: { names },
        },
      } = await dispatch(API(loadFranchiseList()));

      const franchiseList = names.reduce(
        (acc, cur) => [...acc, { label: cur, value: cur }],
        [{ label: "전체", value: "all" }]
      );

      setFranchiseNameList(franchiseList);
    }

    loadFranchiseNames();
  }, []);

  return (
    <Styled.Root>
      <Styled.Filter>
        <Styled.FilterOption>
          <Styled.MainCategory
            placeholder="전체공지"
            className="type"
            width="140px"
            innerWidth="140px"
            value={filterValue.type}
            selectList={noticeSelectList}
            handleFilter={handleFilter("type")}
          />
        </Styled.FilterOption>
        <Styled.FilterOption>
          <SelectBox
            placeholder="분류1"
            className="type"
            width="140px"
            innerWidth="140px"
            disabled={
              filterValue.type === "all" ||
              filterValue.type === "vendor" ||
              filterValue.type === "brewery"
            }
            name="franchiseName"
            value={filterValue.franchiseName}
            selectList={franchiseNameList}
            handleFilter={handleFilter("franchiseName")}
          />
        </Styled.FilterOption>
        <Styled.FilterOption>
          <SelectBox
            placeholder="분류2"
            className="type"
            width="140px"
            innerWidth="140px"
            disabled={
              filterValue.type === "all" ||
              filterValue.type === "vendor" ||
              filterValue.type === "brewery"
            }
            name="franchise"
            value={filterValue.franchise}
            selectList={noticePubSelectList}
            handleFilter={handleFilter("franchise")}
          />
        </Styled.FilterOption>
        <Styled.FilterOption>
          <SelectBox
            placeholder="지역"
            className="type"
            width="140px"
            innerWidth="140px"
            disabled={
              filterValue.type === "all" ||
              filterValue.type === "vendor" ||
              filterValue.type === "brewery"
            }
            name="area"
            value={filterValue.area}
            selectList={sidoList}
            handleFilter={handleFilter("area")}
          />
        </Styled.FilterOption>
        <Styled.FilterOption>
          <CheckboxSelectBox
            placeholder="업체"
            className="type"
            width="140px"
            innerWidth="236px"
            disabled={filterValue.type === "all"}
            name="linkedIds"
            selectTypeName={typeKor[filterValue.type]}
            value={filterValue.linkedIds}
            selectBoxList={selectBoxList}
            selectList={dropdownInfo}
            handleInit={handleInit}
            handleFilter={handleCheckSelectBox}
            handleResetSelectList={handleResetSelectList}
            handleConfirmCheckSelectBox={handleConfirmCheckSelectBox}
            handleResetCheckboxSelectBoxList={handleResetCheckboxSelectBoxList}
          />
        </Styled.FilterOption>
        <Styled.SearchInput
          searchValue={filterValue.title}
          placeholder="제목으로 검색"
          handleChangeSearchInput={handleChangeSearchInput}
          handleSearch={handleSearch}
        />
        <Styled.BtnWrapper>
          <Styled.AddBtn path="/notice/create" content="공지" />
          <ReloadButton handleReload={handleReload} />
        </Styled.BtnWrapper>
      </Styled.Filter>
    </Styled.Root>
  );
}
