import React from "react";
import Styled from "./style";

export default function ModalCompleteButton(props) {
  const { content, completeModalKey = "", setState, activateFunction } = props;

  const handleClick = () => {
    activateFunction();

    setState((draft) => {
      draft.modal.modalKey = completeModalKey;
    });
  };

  return <Styled.Root onClick={handleClick}>{content}</Styled.Root>;
}
