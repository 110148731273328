import React, { useState, useEffect, useRef, useMemo } from "react";

import { image } from "style/globalStyle";
import Styled from "./style";

export default function SelectBox(props) {
  const {
    id,
    className,
    selectList, //  select List
    value,
    name, //  select Box className -> 클릭 event 설정하기 위함
    width = "130px",
    innerWidth = "130px",
    height = "36px",
    placeholder = "",
    handleSelect, // redux 값이 아닌 local select 값 설정하는 함수
    handleFilter,
    disabled,
    isValidError,
  } = props;

  const selectListRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const handleSelectInput =
    ({ _, value }) =>
    (e) => {
      e.stopPropagation();
      handleSelect && handleSelect(name, value); //  state 값 설정
      handleFilter && handleFilter(value);
      setVisible(false);
    };

  const handleOpenSelectItems = (e) => {
    e.stopPropagation();
    setVisible((prev) => !prev);
  };

  const selectedLabel = useMemo(() => {
    if (!selectList.length) return placeholder;
    return (
      selectList.find((item) => item.value === value || item.label === value)
        ?.label || placeholder
    );
  }, [value, selectList, placeholder]);

  useEffect(() => {
    const handleCloseWhenOutsideClick = (e) => {
      if (!visible) {
        return;
      }
      let targetElement = e.target;
      do {
        if (targetElement === selectListRef.current) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      setVisible(false);
    };

    const handleEscListener = (e) => {
      if (e.keyCode === 27) {
        setVisible(false);
      }
    };

    window.addEventListener("click", handleCloseWhenOutsideClick);
    window.addEventListener("keydown", handleEscListener);
    return () => {
      window.removeEventListener("click", handleCloseWhenOutsideClick);
      window.removeEventListener("keydown", handleEscListener);
    };
  }, [visible, selectListRef]);

  return (
    <Styled.Root>
      <Styled.SelectButton
        id={id}
        width={width}
        height={height}
        onClick={handleOpenSelectItems}
        visible={visible}
        isSelect={selectedLabel === placeholder ? 0 : 1}
        disabled={disabled}
        className={className}
        validation={isValidError && !value}
      >
        <span>{selectedLabel}</span>
        <img
          className={`arrow-icon`}
          src={image.icArrowDown}
          alt="arrow-button"
        />
      </Styled.SelectButton>
      {visible && (
        <Styled.SelectInner innerWidth={innerWidth} ref={selectListRef}>
          {selectList &&
            selectList.map((item) => {
              const { label, value, disabled } = item;
              return (
                <Styled.SelectItem
                  height={height}
                  key={value}
                  disabled={disabled}
                  onClick={handleSelectInput(item)}
                >
                  <div>{label}</div>
                </Styled.SelectItem>
              );
            })}
        </Styled.SelectInner>
      )}
    </Styled.Root>
  );
}
