import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import queryString from "query-string";

import Styled from "./style";
import { getBreweries, deleteBreweriesBreweryId } from "api/brewery/index";
import { API } from "store/reducer/apiSlice";
import VendorSearchBar from "container/searchBar/vendorSearchBar/index";
import PaginationComplex from "container/paginationComplex/index";
import Table from "component/table/table/index";
import { breweryColumn } from "asset/data/column";
import useTableData from "hook/useTableData";

export default function Brewery() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(query.name || "");
  const [licenseCheck, setLicenseCheck] = useImmer({
    licenseTotal: query.licenseTotal,
    licenseSpecific: query.licenseForeign,
    licenseForeign: query.licenseForeign,
  });

  const config = {
    licenseTotal: query.licenseTotal || "",
    licenseSpecific: query.licenseSpecific || "",
    licenseForeign: query.licenseForeign || "",
    name: query.name || "",
    sort: query.sort || "",
    page: query.page || "",
    perPage: query.perPage || "",
  };

  const apiHandler = async (config) => {
    const res = await dispatch(API(getBreweries(config)));

    res.payload.data.breweries.forEach((item) => (item.id = item.breweryId));

    return {
      tableData: res.payload.data.breweries,
      pageInfo: res.payload.data.pageInfo,
      status: res.payload.status,
    };
  };

  const { tableData, pageInfo, getTableData, handlePagination } = useTableData(
    apiHandler,
    config
  );

  const resetLicenseCheck = () => {
    setLicenseCheck((draft) => {
      for (let key in draft.licenseCheck) {
        draft.licenseCheck[key] = false;
      }
    });
  };

  const handleReload = useCallback(() => {
    setSearchValue("");
    resetLicenseCheck();
    for (let option in config) {
      config[option] = "";
    }
    getTableData(config);
  }, [getTableData, setSearchValue]);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      getTableData({ name: searchValue });
    },
    [getTableData, searchValue]
  );

  const handleChangeSearchInput = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const handleCheckItem = useCallback(
    (key) => () => {
      const value = !licenseCheck[key] ? true : "";
      setLicenseCheck((draft) => {
        draft[key] = value;
      });

      getTableData({ [key]: value });
    },
    [getTableData, licenseCheck, setLicenseCheck]
  );

  const handleSort = useCallback(() => {
    const sortOption = query.sort === "desc" ? "asc" : "desc";
    getTableData({ sort: sortOption });
  }, [getTableData, query.sort]);

  const handleDeleteBrewery = useCallback(async (breweryId) => {
    dispatch(API(deleteBreweriesBreweryId(breweryId))).then(() =>
      getTableData()
    );
  }, []);

  useEffect(() => {
    getTableData();
    if (location.search === "") {
      setSearchValue("");
      setLicenseCheck((draft) => {
        draft.licenseTotal = false;
        draft.licenseSpecific = false;
        draft.licenseForeign = false;
      });
    }
  }, [location.search]);

  return (
    <Styled.Root>
      <VendorSearchBar
        addBtnContent="제조사 추가"
        addBtnPath="/brewery/create"
        placeholder="제조사명으로 검색"
        searchValue={searchValue}
        licenseCheck={licenseCheck}
        handleChangeSearchInput={handleChangeSearchInput}
        handleCheckItem={handleCheckItem}
        handleSearch={handleSearch}
        handleReload={handleReload}
      />
      <Table
        columns={breweryColumn}
        datas={tableData}
        modalKey="deleteBreweryModal"
        handleDelete={handleDeleteBrewery}
        updateHref="/brewery/update"
        sort={query.sort}
        handleSort={handleSort}
      />
      <PaginationComplex
        pageInfo={pageInfo}
        handlePagination={handlePagination}
      />
    </Styled.Root>
  );
}
