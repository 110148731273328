import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    position: relative;
  `,
  Heading: styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: ${color.Color777777};
  `,
  Input: styled.input`
    width: 100%;
    padding: 10px 12px;
    border: solid 1px ${color.Colorc9c9c9};
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    :focus {
      border: solid 1px ${color.Color5ab1f0};
    }
    ::placeholder {
      font-weight: 400;
      color: ${color.Colorb1b1b1};
    }
  `,

  LengthCheckWrapper: styled.div`
    position: absolute;
    top: 39px;
    right: 12px;

    & span {
      color: ${color.Colorb1b1b1};
    }
  `,
};
export default Styled;
