import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorControl } from "util/errorControl";

export const API = createAsyncThunk(
  "API",
  async (asyncApi, { getState, rejectWithValue, dispatch }) => {
    // getState, rejectWithValue 와 같은 config 참고:
    // https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
    const { api } = getState(); //  getState() : redux에 선언된 state 전부를 호출함
    let response = "";
    if (api.errorOccur === false) {
      //  error 발생하지 않았으면 API
      try {
        return await asyncApi();
      } catch (err) {
        return rejectWithValue(err); // rejectWithValue(): error catch
      }
    }
    dispatch(handleErrorState(false));
    return response;
  }
);

const initialState = {
  errorOccur: false,
};

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    handleErrorState(state, action) {
      state.errorOccur = action.payload;
    },
  },
  extraReducers: {
    //  Promise의 상태를 받아오는 객체
    [API.pending]: (state, action) => {},
    [API.fulfilled]: (state, action) => {},
    [API.rejected]: (state, action) => {
      const { response } = action.payload;
      if (state.errorOccur === false) {
        errorControl(response);
      }
      state.errorOccur = true;
    },
  },
});

export const { handleErrorState } = apiSlice.actions;
export default apiSlice.reducer;
