import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: calc(100% + 100px);
    height: calc(100vh - 56px);
    padding: 34px 50px;
    background-color: ${color.Colorffffff};
  `,
};
export default Styled;
