import styled from "styled-components";

const Styled = {
  MainWrapper: styled.div`
    padding: 34px 50px;
  `,
  LeftMainWrapper: styled.div``,
  RightMainWrapper: styled.div``,
};
export default Styled;
