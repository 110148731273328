import React from "react";
import Styled from "./style";
import { makeStringSum, numToStringPhoneNum } from "util/makeDataForm";
export default function VendorDetail(props) {
  const { data, columns } = props;
  const gridTemplateList = [
    makeStringSum([columns[0].width]),
    makeStringSum([columns[1].width, columns[2].width, columns[3].width]),
    makeStringSum([columns[4].width, columns[5].width]),
  ];
  return (
    <Styled.Root gridTemplateList={gridTemplateList} columns={columns}>
      <div className={`grid ${columns[0].key} two-row`}>
        <div className="one-row">
          <div className="title">사업자등록번호</div>
          <div className="content">{data.taxcode}</div>
        </div>
        <div className="one-row">
          <div className="title">대표자명</div>
          <div className="content">{data.managerName}</div>
        </div>
      </div>
      <div className={`grid ${columns[1].key} two-row`}>
        <div className="one-row">
          <div className="title">핸드폰번호</div>
          <div className="content">
            {numToStringPhoneNum(data.managerPhone)}
          </div>
        </div>
        <div className="one-row">
          <div className="title">이메일주소</div>
          <div className="content">{data.managerEmail}</div>
        </div>
      </div>
      <div className={`grid ${columns[3].key} two-row`}>
        <div className="one-row">
          <div className="title">도매사 주소</div>
          <div className="content">
            {data.addr} {data.addrDetail}
          </div>
        </div>
        <div className="one-row">
          <div className="title">도매사 전화번호</div>
          <div className="content">{numToStringPhoneNum(data.vendorPhone)}</div>
        </div>
      </div>

      <div className={`grid `}></div>
    </Styled.Root>
  );
}
