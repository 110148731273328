import { ax } from "api/index";

export const getBanners = () => async () => {
  return await ax.get("/banners");
};

export const getBannersBannerId = (id) => async () => {
  return await ax.get(`/banners/${id}`);
};

export const postBanners = (body) => async () => {
  return await ax.post("/banners", body);
};

export const putBannersBannerId = (id, body) => async () => {
  return await ax.put(`/banners/${id}`, body);
};

export const patchBanners = (body) => async () => {
  return await ax.patch("/banners", body);
};

export const deleteBanners = (id) => async () => {
  return await ax.delete(`/banners/${id}`);
};
