import { checkOnlyNumber, isValidAbv } from "util/validation";
import { allowNumber } from "util/makeDataForm";

export const handleRadio = (name, value, setState) => {
  setState((draft) => {
    draft[name] = value;
  });
};

export const handleClickPlus = (setState) => () => {
  setState((draft) => {
    draft.enterpriseList.push({
      type: "select",
      value: "",
    });
  });
};

export const handleClickClose = (idx, state, setState) => () => {
  if (
    state.enterpriseList.length === 1 &&
    state.enterpriseList[idx].type === "select"
  ) {
    return;
  }

  if (state.enterpriseList.length > 0) {
    setState((draft) => {
      draft.enterpriseList.splice(idx, 1);
    });
  }
};

export const handleChangeInput = (setState, maxLength) => (event) => {
  let {
    name,
    value,
    dataset: { type },
  } = event.target;

  if (type === "number") {
    value = checkOnlyNumber(value);
  }

  if (maxLength && value.length > maxLength) {
    return;
  }

  setState((draft) => {
    draft[name] = value;
  });
};

export const handleChangeUnitInput = (setState, max) => (event) => {
  const { name, value } = event.target;
  const validValue = Math.min(value, max);

  setState((draft) => {
    draft[name] = validValue;
  });
};

export const handleBusinessNum = (setState) => (event) => {
  const { name, value } = event.target;
  setState((draft) => {
    draft[name] = allowNumber(value);
  });
};
