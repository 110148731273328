import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
    text-align: center;
    height: ${(props) => props.height};
    /* line-height: 40px; */
    border-radius: 5px;
    border: solid 1px ${color.Color777777};
    :hover {
      cursor: pointer;
    }
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,
};

export default Styled;
