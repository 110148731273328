import React, { useState, Fragment } from "react";

import { color, image } from "style/globalStyle";
import { license, numToStringPhoneNum, pubFranchise } from "util/makeDataForm";
import SideMenu from "component/sideMenu/index";
import Badge from "component/badge/index";
import TableDetail from "../detail/index";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Root,
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  MenuColumn,
  ImgWrapper,
  RatingSelectBox,
} from "./style";

export default function Table(props) {
  const {
    columns,
    datas,
    thHeight = "44px",
    height = "46px",
    modalKey,
    handleDelete,
    rootWidth,
    minWidth,
    state,
    updateHref,
    setState,
    sort,
    handleSort,
    changeRating,
  } = props;
  const [visible, setVisible] = useState([]);
  const { pathname, search } = useLocation();
  const query = queryString.parse(search);

  const handleClick = (data, index) => async () => {
    // 클릭 할 때마다 배열을 모두 false로 바꿔주는 로직
    let newArr = [...visible];
    newArr.forEach((item, idx) => {
      if (idx !== index) {
        newArr[idx] = false;
      }
    });
    newArr[index] = !newArr[index];
    setVisible(newArr);

    if (pathname === "/link") {
      setState((draft) => {
        draft.selectData = datas[index];
      });
    }
  };

  const handleClickSort = (idx) => {
    if (pathname === "/link") {
      handleSort(sort, idx);
    } else {
      handleSort(sort);
    }
  };

  const vendorForm = (array) => {
    if (array.length === 0) return "-";

    let temp = "";
    array.forEach((item, index) => {
      if (index === array.length - 1) {
        return (temp = temp + " " + item.vendorName);
      } else {
        return (temp = temp + item.vendorName + ",");
      }
    });

    return temp;
  };

  const breweryForm = (array) => {
    if (array.length === 0) return "-";

    let temp = "";
    array.forEach((item, index) => {
      if (index === array.length - 1) {
        return (temp = temp + " " + item.breweryName);
      } else {
        return (temp = temp + item.breweryName + ",");
      }
    });

    return temp;
  };

  const linkSortRotate180 = (menu, idx) => {
    // 링크페이지 정렬 아이콘 변경 함수
    if (menu === 0) {
      // 텝메뉴 === 0
      if (idx === 0) {
        // 매장명 정렬
        if (query.sortPubs === "desc") {
          return true;
        } else {
          return false;
        }
      } else if (idx === 2) {
        // 도매사명 정렬
        if (query.sortVendors === "desc") {
          return true;
        } else {
          return false;
        }
      }
    } else if (menu === 1) {
      if (idx === 0) {
        // 매장명 정렬
        if (query.sortPubs === "desc") {
          return true;
        } else {
          return false;
        }
      } else if (idx === 2) {
        // 제조사 정렬
        if (query.sortBreweries === "desc") {
          return true;
        } else {
          return false;
        }
      }
    } else if (menu === 2) {
      if (idx === 0) {
        // 도매사 정렬
        if (query.sortVendors === "desc") {
          return true;
        } else {
          return false;
        }
      } else if (idx === 3) {
        // 제조사 정렬
        if (query.sortBreweries === "desc") {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const content = (props) => {
    const { type, content } = props;

    switch (type) {
      case "status":
        return (
          <Badge
            content={content === "open" ? "운영" : "폐업"}
            backgroundColor={
              content === "open" ? color.Colorebfdee : color.Colore0e5e8
            }
            color={content === "open" ? color.Color0f8623 : color.Color777777}
          />
        );
      case "phone":
        const phoneNum = numToStringPhoneNum(content);
        return phoneNum;
      case "addr":
        const temp = content.split(" ");
        return `${temp[0]} ${temp[1]}`;
      case "license":
        return content;
      case "franchise":
        return (
          <Badge
            content={
              content === "direct"
                ? "직영"
                : content === "franch"
                ? "가맹"
                : "기타"
            }
            backgroundColor={
              content === "direct"
                ? color.Colorffefe7
                : content === "franch"
                ? color.Colore8f0fb
                : "기타"
            }
            color={
              content === "direct"
                ? color.Colord04500
                : content === "franch"
                ? color.Color1f6cd5
                : "기타"
            }
          />
        );
      case "vendors":
        return vendorForm(content);
      case "breweries":
        return breweryForm(content);
      default:
        return content ? content : "-";
    }
  };

  return (
    <Root rootWidth={rootWidth} minWidth={minWidth}>
      <Thead>
        <Tr>
          {columns.map((item, idx) => {
            const { label, width, minWidth, type } = item;
            return (
              <Th
                key={idx}
                height={thHeight}
                width={width}
                minWidth={minWidth}
                pointer={type === "sortAble" && "pointer"}
                onClick={
                  type === "sortAble" ? () => handleClickSort(idx) : null
                }
                rotate180={
                  pathname === "/link"
                    ? linkSortRotate180(state.menu, idx)
                    : type === "sortAble" && sort === "desc"
                    ? true
                    : false
                }
              >
                <span>{label}</span>
                {type === "sortAble" && (
                  <img src={image.icArrowDownBold} alt="arrow" />
                )}
              </Th>
            );
          })}
          <MenuColumn />
        </Tr>
      </Thead>
      <Tbody>
        {datas.length > 0 &&
          datas.map((data, index) => {
            // link페이지 도매사:제조사 탭 (도매면허, 거래면허)
            if (state?.menu === 2) {
              data = {
                ...data,
                license: license(
                  data.breweryLicenseForeign,
                  data.breweryLicenseSpecific,
                  data.breweryLicenseTotal
                ),
                franchise: pubFranchise(
                  data.vendorLicenseForeign,
                  data.vendorLicenseSpecific,
                  data.vendorLicenseTotal
                ),
              };
            }

            //vendor페이지, brewery페이지
            if (pathname === "/vendor" || pathname === "/brewery") {
              data = {
                ...data,
                license: license(
                  data.licenseForeign,
                  data.licenseSpecific,
                  data.licenseTotal
                ),
              };
            }

            return (
              <Fragment key={index}>
                <Tr selected={visible[index]}>
                  {columns.map((item, idx) => {
                    const {
                      key,
                      width,
                      minWidth,
                      type,
                      nowrap = false,
                      thumbnail,
                      RatingList,
                    } = item;
                    return (
                      <Td
                        key={idx}
                        height={height}
                        width={width}
                        minWidth={minWidth}
                        onClick={handleClick(data, index)}
                        nowrap={nowrap}
                        thumbnail={thumbnail}
                      >
                        {thumbnail && (
                          <ImgWrapper
                            isNewLabel={thumbnail && data.isNew ? 1 : 0}
                          >
                            <img src={data.fileUrl} alt="주류 이미지" />
                          </ImgWrapper>
                        )}
                        {key === "rating" ? (
                          <RatingSelectBox
                            selectList={RatingList}
                            placeholder="-"
                            name={data.beverageId}
                            value={data.rating}
                            handleSelect={changeRating}
                          />
                        ) : (
                          <span>
                            {data[key] && content({ type, content: data[key] })}
                          </span>
                        )}
                      </Td>
                    );
                  })}
                  <SideMenu
                    className={`sub-menu-${index}`}
                    data={data}
                    modalKey={modalKey}
                    handleDelete={handleDelete}
                    updateHref={`${updateHref}?id=${data.id}`}
                    id={data.id}
                  />
                </Tr>
                {/* table click 했을때 보여지는 부분 */}
                {visible[index] && (
                  <TableDetail data={data} columns={columns} />
                )}
              </Fragment>
            );
          })}
      </Tbody>
    </Root>
  );
}
