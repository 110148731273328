import React, { useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";

import { modalCont } from "asset/data/modalCont";
import { color } from "style/globalStyle";
import Styled from "./style";
import PortalModal from "container/modal/portalModal/index";
import SubmitButton from "component/button/submitButton/index";
import RadioButton from "component/radioButton/index";
import CustomPrompt from "component/prompt/index";
import Toast from "component/toast/index";
import SelectBox from "component/selectBox/index";
import TextInput from "component/input/textInput/TextInput";
import { setModalKey } from "hook/modal";
import usePrompt from "hook/usePrompt";
import useToast from "hook/useToast";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { postBanners } from "api/banner/index";
import { makeCheckObject } from "util/makeCheckList";
import { checkAwsApiStaus } from "util/presigend";
import { bannerSelectList } from "asset/data/selectList";
import ProductListModal from "../productListModal/index";
import { getLessonsTitles } from "api/education/index";
import { getNoticesTitles } from "api/notice/index";

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};
const initSubmitState = {
  file: "",
  filePreview: "",
  activated: "1",
  linkType: "",
  beverageId: "",
  beverageName: "",
  noticeId: "",
  lessonId: "",
  url: "",
};

export default function SettingBannerCreate() {
  const [modalState, setModalState] = useImmer(initModalState);
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [educationList, setEducationList] = useImmer();
  const [noticeList, setNoticeList] = useImmer();
  const dispatch = useDispatch();
  const bannerIdx = useSelector((state) => ({
    activatedLastIdx: state.banner.activatedLastIdx,
    unActivatedLastIdx: state.banner.unActivatedLastIdx,
  }));
  const { isShow, message, type, toast } = useToast();

  const checkList = makeCheckObject({ ...submitState }, ["filePreview"]);
  const { isValueChanged, setIsValueChanged } = usePrompt({
    checkList: checkList,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) return;
    reader.onload = () => {
      setSubmitState((draft) => {
        draft.file = event.target.files[0];
        draft.filePreview = reader.result;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSelectProductId = useCallback((product) => {
    setSubmitState((draft) => {
      draft.beverageId = product.id;
      draft.beverageName = product.name;
    });
  }, []);

  const handleSubmit = async () => {
    if (!submitState.activated || !submitState.file) {
      toast("필수 기입 정보를 입력해 주세요", "warning");
      return;
    }

    const body = {};
    if (submitState.activated === "1" || submitState.activated === true) {
      body.idx = bannerIdx.activatedLastIdx + 1;
    } else {
      body.idx = bannerIdx.unActivatedLastIdx + 1;
    }
    body.activated = submitState.activated;

    if (submitState.linkType) {
      body.linkType = submitState.linkType;
      body.link =
        submitState.linkType === "beverage"
          ? submitState.beverageId
          : submitState.linkType === "lesson"
          ? submitState.lessonId
          : submitState.linkType === "notice"
          ? submitState.noticeId
          : submitState.url;
    }

    const res = await dispatch(API(postBanners(body)));

    if (res.payload.status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);
      if (awsStatus === 204) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/banner",
          "배너가 등록되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const handleSelect = (name, value) => {
    setSubmitState((draft) => {
      draft[name] = value;
    });
  };

  const handleChangeExternalUrl = useCallback((event) => {
    const value = event?.target?.value ?? event;

    setSubmitState((draft) => {
      draft.url = value;
    });
  }, []);

  const getEducationList = async () => {
    const {
      payload: {
        data: { lessons },
      },
    } = await dispatch(API(getLessonsTitles()));

    setEducationList(
      lessons.map((education) => ({
        label: education.title,
        value: education.lessonId,
      }))
    );
  };

  const getNoticeList = async () => {
    const {
      payload: {
        data: { notices },
      },
    } = await dispatch(API(getNoticesTitles()));

    setNoticeList(
      notices.map((notice) => ({
        label: notice.title,
        value: notice.noticeId,
      }))
    );
  };

  useEffect(() => {
    setSubmitState((draft) => {
      draft.beverageId = "";
      draft.beverageName = "";
      draft.noticeId = "";
      draft.lessonId = "";
      draft.url = "";
    });
  }, [submitState.linkType]);

  useEffect(() => {
    if (submitState.linkType === "lesson" && !educationList) {
      getEducationList();
    }

    if (submitState.linkType === "notice" && !noticeList) {
      getNoticeList();
    }
  }, [submitState.linkType]);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>배너등록 &nbsp; | &nbsp; 모바일</Styled.Header>
        <Styled.Body>
          <div className="tr">
            <div className="title">배너 유형</div>
            <div className="content">모바일 배너</div>
          </div>
          <div className="tr">
            <div className="title">
              활성여부<Styled.Essential>*</Styled.Essential>
            </div>
            <div className="content">
              <RadioButton
                height="100%"
                contents={["활성", "비활성"]}
                name="activated"
                state={submitState}
                setState={setSubmitState}
              />
            </div>
          </div>
          <div className="tr">
            <div className="title">배너 크기</div>
            <div className="content">사이즈: 1200px*600px</div>
          </div>
          <div className="tr">
            <div className="title">연결 페이지</div>
            <div className="content">
              <Styled.SelectBoxWrapper>
                <SelectBox
                  placeholder="- 선택 -"
                  width="140px"
                  innerWidth="140px"
                  name="linkType"
                  value={submitState.linkType}
                  selectList={bannerSelectList}
                  handleSelect={handleSelect}
                />
                {submitState.linkType === "beverage" && (
                  <ProductListModal
                    value={submitState.beverageName}
                    selectedId={submitState.beverageId}
                    handleSelectId={handleSelectProductId}
                  />
                )}
                {submitState.linkType === "lesson" && educationList && (
                  <SelectBox
                    placeholder="- 선택 -"
                    className="type"
                    width="300px"
                    innerWidth="300px"
                    name="lessonId"
                    value={submitState.lessonId}
                    selectList={educationList}
                    handleSelect={handleSelect}
                  />
                )}
                {submitState.linkType === "notice" && noticeList && (
                  <SelectBox
                    placeholder="- 선택 -"
                    className="type"
                    width="300px"
                    innerWidth="300px"
                    name="noticeId"
                    value={submitState.noticeId}
                    selectList={noticeList}
                    handleSelect={handleSelect}
                  />
                )}
                {submitState.linkType === "url" && (
                  <Styled.TextWrapper>
                    <label htmlFor="externalUrl">링크URL</label>
                    <TextInput
                      id="externalUrl"
                      name="url"
                      value={submitState.url}
                      placeholder="Url 입력"
                      onChange={handleChangeExternalUrl}
                    />
                  </Styled.TextWrapper>
                )}
              </Styled.SelectBoxWrapper>
            </div>
          </div>
          <div className="tr gallery-tr">
            <div className="title">
              이미지 등록<Styled.Essential>*</Styled.Essential>
            </div>
            <div className="content">
              <Styled.ImgLabel
                checkFile={submitState.filePreview ? true : false}
                htmlFor="banner-upload-img-file"
              >
                {submitState.filePreview && (
                  <img src={submitState.filePreview} alt="preview-img" />
                )}
              </Styled.ImgLabel>
              <input
                type="file"
                id="banner-upload-img-file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </Styled.Body>
        <Styled.ButtonWrapper>
          <SubmitButton
            content="등록"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="120px"
            height="40px"
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
