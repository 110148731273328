import React from "react";
import Pagination from "component/pagination/index";
import Styled from "./style";
import { useLocation } from "react-router-dom";
import { image } from "style/globalStyle";

export default function PaginationComplex(props) {
  const { pageInfo, handlePagination, handleDownloadExcel } = props;
  const { pathname } = useLocation();

  return (
    <Styled.Root>
      <div></div>
      <Pagination pageInfo={pageInfo} handlePagination={handlePagination} />
      {pathname === "/transaction" ? (
        <Styled.Eexcel onClick={handleDownloadExcel}>
          <img src={image.icDownloadBlack} alt="엑셀 다운로드 버튼" />
          엑셀
        </Styled.Eexcel>
      ) : (
        <div></div>
      )}
    </Styled.Root>
  );
}
