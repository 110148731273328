export const pubAndSellerTableColumns = [
  { label: "상품분류", key: "category" },
  { label: "상품명", key: "beverageName" },
  { label: "용량", key: "volume" },
  { label: "수량", key: "amount" },
  { label: "주문금액", key: "price" },
  { label: "부가세", key: "vat" },
  { label: "총매출액", key: "totalPrice" },
  { label: "상태", key: "status" },
];

export const pubAndSellerTwoDpethTableColumns = [
  { label: "수주사", key: "sellerName" },
  { label: "매장수", key: "buyerCount" },
  { label: "수량", key: "amount" },
  { label: "총매출액", key: "totalPrice" },
];

export const vendorAndSellerTwoDpethTableColumns = [
  { label: "제조사", key: "sellerName" },
  { label: "도매사수", key: "buyerCount" },
  { label: "수량", key: "amount" },
  { label: "총매출액", key: "totalPrice" },
];

export const orderHistoryDetailColums = {
  pubs: [
    { label: "", key: "no" },
    { label: "분류1", key: "franchiseName" },
    { label: "분류2", key: "franchise" },
    { label: "지역", key: "area" },
    { label: "매장명", key: "name" },
    { label: "수량", key: "amount" },
    { label: "주문금액", key: "price" },
    { label: "부가세", key: "vat" },
    { label: "총매출액", key: "totalPrice", sortable: true },
  ],

  vendors: [
    { label: "", key: "no" },
    { label: "도매사", key: "name" },
    { label: "수량", key: "amount" },
    { label: "주문금액", key: "price" },
    { label: "부가세", key: "vat" },
    { label: "총 매출액", key: "totalPrice", sortable: true },
  ],
};

export const VOLUM_TABLE = {
  all: "용량전체",
  keg: "케그",
  can: "캔",
  bot: "병",
};

export const FRANCHISES_TABLE = {
  all: "전체",
  direct: "직영",
  franch: "가맹",
  others: "일반",
};

export const AREA_TABLE = {
  all: "전체",
  서울: "서울",
  부산: "부산",
  인천: "인천",
  대구: "대구",
  광주: "광주",
  대전: "대전",
  울산: "울산",
  세종특별자치시: "세종특별자치시",
  경기: "경기",
  강원: "강원",
  경남: "경남",
  경북: "경북",
  전남: "전남",
  전북: "전북",
  충남: "충남",
  충북: "충북",
  제주특별자치도: "제주도제주특별자치도",
};

export const SORT_TABLE = {
  amountAsc: "주문 적은 순",
  amountDesc: "주문 많은 순",
  priceDesc: "금액 많은 순",
  priceAsc: "금액 적은 순",
};
