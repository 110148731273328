import React from "react";
import { Input } from "./style";
import { handleChangeInput } from "hook/handler";
import InfoInputWrapper from "../index";

export default function CommonInfoInput(props) {
  const {
    title,
    explain,
    placeholder,
    name,
    state,
    setState,
    imgSrc,
    setImageClick,
    imageToggle,
    height = "40px",
    inputType,
    isRequired,
    isValidError,
  } = props;
  return (
    <InfoInputWrapper
      title={title}
      name={name}
      explain={explain}
      isRequired={isRequired}
    >
      <div className="input-wrapper">
        <Input
          placeholder={placeholder}
          name={name}
          value={state[name]}
          onChange={handleChangeInput(setState, "")}
          height={height}
          className={name}
          data-type={inputType}
          validation={isValidError && state[name] === ""}
        />
        {imgSrc && (
          <img
            src={imgSrc}
            className="inner-icon"
            alt="inner-icon"
            onClick={() => {
              setImageClick(!imageToggle);
            }}
          />
        )}
      </div>
    </InfoInputWrapper>
  );
}
