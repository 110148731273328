import React from "react";
import Styled from "./style";
export default function Badge(props) {
  const {
    content,
    backgroundColor,
    color,
    width = "50px",
    height = "24px",
  } = props;
  return (
    <Styled.Root
      backgroundColor={backgroundColor}
      color={color}
      width={width}
      height={height}
    >
      <span>{content}</span>
    </Styled.Root>
  );
}
