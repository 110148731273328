import { ax } from "api";

export const postNotices = (body) => async () => {
  return await ax.post(`/notices`, body);
};

export const getNotices = (req) => async () => {
  return await ax.post("/notices/list", req);
};

export const getNoticesDropdownLink = async (req) => {
  const { data } = await ax.get("/notices/link", { params: req });

  return data.link;
};

export const getNoticesTitles = () => async () => {
  return await ax.get(`/notices/titles`);
};

export const getNoticesNoticesId = (id) => async () => {
  return await ax.get(`/notices/${id}`);
};

export const putNoticesNoticesId = (id, body) => async () => {
  return await ax.put(`/notices/${id}`, body);
};

export const deleteNoticesNoticesId = (id) => async () => {
  return await ax.delete(`/notices/${id}`);
};
