import React from "react";
import Styled from "./style";

export default function CancelButton(props) {
  const {
    content = "취소",
    handleClick,
    width = "80px",
    height = "40px",
  } = props;

  return (
    <Styled.Root onClick={handleClick} height={height} width={width}>
      <span>{content}</span>
    </Styled.Root>
  );
}
