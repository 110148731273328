import { ax } from "api/index";

export const getVendorsAndPubs = (req) => async () => {
  return await ax.get(`/links/vendors/pubs`, { params: { ...req } });
};

export const getBreweriesAndPubs = (req) => async () => {
  return await ax.get(`/links/breweries/pubs`, { params: { ...req } });
};

export const getBreweriesAndVendors = (req) => async () => {
  return await ax.get(`/links/breweries/vendors`, { params: { ...req } });
};

export const getVendorsAndPubsNames = (vendorId) => async () => {
  return await ax.get(`/links/vendors/pubs/names?vendorId=${vendorId}`);
};

export const getBrewerysAndPubsNames = (breweryId) => async () => {
  return await ax.get(`/links/vendors/pubs/names?vendorId=${breweryId}`);
};

export const getBrewerysAndVendorsNames = (breweryId) => async () => {
  return await ax.get(`/links/breweries/vendors/names?breweryId=${breweryId}`);
};

export const postLinksVendorsPubs = (body) => async () => {
  return await ax.post(`/links/vendors/pubs`, body);
};

export const postLinksBreweriesPubs = (body) => async () => {
  return await ax.post(`/links/breweries/pubs`, body);
};

export const postLinksBreweriesVendors = (body) => async () => {
  return await ax.post(`/links/breweries/vendors`, body);
};

export const deleteLinksBreweriesbreweryIdPubsPubId =
  (breweryId, pubId) => async () => {
    return await ax.delete(`/links/breweries/${breweryId}/pubs/${pubId}`);
  };

export const deleteLinksBreweriesBreweryIdVendorsVendorId =
  (breweryId, vendorId) => async () => {
    return await ax.delete(`/links/breweries/${breweryId}/vendors/${vendorId}`);
  };

export const deleteLinksVendorsVendorIdPubsPubId =
  (vendorId, pubId) => async () => {
    return await ax.delete(`/links/vendors/${vendorId}/pubs/${pubId}`);
  };
