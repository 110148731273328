import React, { useState } from "react";
import { useImmer } from "use-immer";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Styled from "./style";
import { image } from "style/globalStyle";
import { PATH } from "asset/data/common";
import { makeCryptoFunction } from "util/crypto";
import { patchLogin } from "api/login/index";
import { getHeadquarterInfo } from "api/account/index";
import { setProfile } from "store/reducer/commonSlice";
import { API, handleErrorState } from "store/reducer/apiSlice";
import LoginInput from "component/input/loginInput/index";
import { token } from "util/helper/token";
import { userStorage } from "util/helper/storage";

const initState = {
  id: "",
  password: "",
  keepLogin: false,
};

export default function Login() {
  const [state, setState] = useImmer(initState);
  const [isIdValid, setIsIdValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft[name] = value;
    });
  };

  const handleKeepLogin = () => {
    setState((draft) => {
      draft.keepLogin = !draft.keepLogin;
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      if (isIdValid && isPasswordValid) {
        const body = {};
        const cryptoedPassword = makeCryptoFunction(state.password);

        body.id = state.id;
        body.password = cryptoedPassword;

        const res = await dispatch(API(patchLogin(body)));

        if (res.payload.status === 200) {
          const { accessToken } = res.payload.data;

          token.setAccessToken(accessToken, state.keepLogin);

          const info = await dispatch(API(getHeadquarterInfo()));
          const { data } = info.payload;
          userStorage.save(data);
          dispatch(setProfile(data));
          history.push(PATH.pub);
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const checkValidation = (name) => {
    switch (name) {
      case "id":
        state.id === "" ? setIsIdValid(false) : setIsIdValid(true);
        break;
      case "password":
        state.password === ""
          ? setIsPasswordValid(false)
          : setIsPasswordValid(true);
        break;
      default:
        break;
    }
  };

  const handleKeyup = (event) => {
    checkValidation(event.target.name);
  };

  return (
    <Styled.Root>
      <Styled.Form onSubmit={handleLogin}>
        <img className="logo" src={image.icLogo} alt="login-logo" />
        <Styled.InputWrapper>
          <LoginInput
            type="id"
            name="id"
            value={state.id}
            isValid={isIdValid}
            placeholder="이메일주소"
            handleChange={handleChange}
            handleKeyup={handleKeyup}
          />
          {!isIdValid && (
            <Styled.Small>이메일 주소를 확인해주세요</Styled.Small>
          )}
          <LoginInput
            type="password"
            name="password"
            value={state.password}
            isValid={isPasswordValid}
            placeholder="비밀번호"
            handleChange={handleChange}
            handleKeyup={handleKeyup}
          />
          {!isPasswordValid && (
            <Styled.Small>비밀번호를 확인해주세요</Styled.Small>
          )}
        </Styled.InputWrapper>
        <div className="keep-login-wrapper">
          <Styled.KeepLogin
            keepLogin={state.keepLogin}
            onClick={handleKeepLogin}
            isPasswordValid={isPasswordValid}
          >
            <img
              src={state.keepLogin ? image.icCheckFill : image.icCheckCircle}
              alt="keep-login-icon"
            />
            <div>로그인 상태 유지</div>
          </Styled.KeepLogin>
        </div>
        <Styled.LoginButton
          type="submit"
          disabled={!isIdValid || !isPasswordValid}
        >
          로그인
        </Styled.LoginButton>
      </Styled.Form>
    </Styled.Root>
  );
}
