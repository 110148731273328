import styled from "styled-components";
import { color } from "style/globalStyle";
import PasswordInput from "component/input/passwordInput/index";

const Styled = {
  Root: styled.div`
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .desc-wrapper {
      .desc {
        color: ${color.Color777777};
        margin-top: 8px;
      }
      margin-bottom: 32px;
    }
  `,
  Form: styled.form`
    .title {
      font-size: 16px;
      margin-bottom: 9px;
    }
    .submit-button {
      width: 80px;
      height: 34px;
      border-radius: 4px;
      border: none;
      color: ${color.Colorffffff};
      background-color: ${color.Color5ab1f0};
      margin-top: 30px;
      opacity: ${(props) => (props.disabled ? 0.4 : 1)};
      :hover {
        cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
      }
    }
  `,
  InputRoot: styled(PasswordInput)`
    margin-bottom: 20px;
    .title {
      color: ${color.Color121212};
    }
    .input-wrapper {
      width: 220px;
      position: relative;
      input {
        width: 100%;
        width: 220px;
        height: 34px;
        padding-left: 16px;
        border-radius: 5px;
        border: solid 1px
          ${(props) =>
            props.errorToggle ? color.Colorff0000 : color.Colorc9c9c9};
        background-color: ${color.Colorffffff};
        outline: none;
        :focus {
          border: solid 1px ${color.Color5ab1f0};
        }
      }
      .inner-icon {
        position: absolute;
        right: 12px;
        top: 5px;
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
  ButtonWrapper: styled.div`
    margin-top: 30px;
  `,
};
export default Styled;
