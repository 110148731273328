import React from "react";

import Styled from "./style";
import usePagination from "hook/usePagination";

export default function Pagination2(props) {
  const { pageInfo, handlePagination } = props;
  const { pageList } = usePagination(pageInfo);

  return (
    <Styled.NumberPart>
      {pageList.map((item) => (
        <Styled.Number
          key={item}
          selected={pageInfo.currentPage === item}
          onClick={handlePagination(item)}
        >
          {item}
        </Styled.Number>
      ))}
    </Styled.NumberPart>
  );
}
