import React from "react";

import { Root } from "./style";
import { ReactComponent as CloseCircleIcon } from "asset/image/icCloseCircle.svg";

const BeerStyleList = ({ list, handleDeleteStyleItem }) => {
  return (
    <Root>
      {list.map(({ style }) => (
        <li key={style}>
          <span>{style}</span>
          <button
            type="button"
            aria-label="스타일 삭제"
            onClick={handleDeleteStyleItem(style)}
          >
            <CloseCircleIcon />
          </button>
        </li>
      ))}
    </Root>
  );
};

export default BeerStyleList;
