import React from "react";
import { createPortal } from "react-dom";

import {
  Dim,
  Dialog,
  Header,
  Content,
  ButtonWrapper,
  CancelBtn,
  ConfirmBtn,
} from "./style";
import useDialog from "hook/useDialog";

const ConfirmModal = ({ className, trigger, handleConfirm }) => {
  const { isOpen, modalRef, handleOpen, handleClose, handleDimClose } =
    useDialog();

  const handleClickConfirmBtn = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <>
      {typeof trigger === "function" && trigger({ handleOpen, isOpen })}
      {isOpen &&
        createPortal(
          <Dim hidden={!isOpen} onClick={handleDimClose}>
            <Dialog
              className={className}
              open={isOpen}
              ref={modalRef}
              tabIndex="-1"
              role="dialog"
              aria-modal="true"
              aria-labelledby="confirmModal"
            >
              <Header>
                <dt>
                  <h2>선택한 분류를 삭제하시겠습니까?</h2>
                </dt>
                <dd>
                  분류 카테고리에 속한 모든 스타일이 함께 삭제되며, 다시 복구할
                  수 없습니다.
                </dd>
              </Header>
              <Content>
                <ButtonWrapper>
                  <CancelBtn type="button" onClick={handleClose}>
                    취소
                  </CancelBtn>
                  <ConfirmBtn type="button" onClick={handleClickConfirmBtn}>
                    확인
                  </ConfirmBtn>
                </ButtonWrapper>
              </Content>
            </Dialog>
          </Dim>,
          document.querySelector("#modal")
        )}
    </>
  );
};

export default ConfirmModal;
