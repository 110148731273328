import { useState, useEffect } from "react";
import { useDetectPageMove } from "./useDetectPageMove";
import { isEqual } from "lodash";

const usePrompt = (props) => {
  const { checkList } = props;
  const [initList, setInitList] = useState([]);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const checkIsEqual = (init, detect) => {
    const comparison = isEqual(init, detect);
    // 값이 변경 된 것이 있다. === true
    // 값이 변경 된 것이 없다. === false
    setIsValueChanged(!comparison);
  };
  useEffect(() => {
    setInitList(checkList);
  }, []);

  useEffect(() => {
    const detectList = Array.isArray(checkList)
      ? [...checkList]
      : { ...checkList };
    checkIsEqual(initList, detectList);
  }, [checkList, initList, isEqual(initList, checkList)]);

  useDetectPageMove(isValueChanged); //  browser 에서 제공하는

  return { setInitList, isValueChanged, setIsValueChanged };
};

export default usePrompt;
