import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { getBeverageNames } from "api/beverage/index";
import { API } from "store/reducer/apiSlice";
import Modal from "component/modal/Modal";
import Opener from "component/opener/index";
import Radio from "component/input/radio/Radio";
import useDialog from "hook/useDialog";
import Styled from "./stlye";

const ProductListModal = ({ className, value, selectedId, handleSelectId }) => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  const [beverageList, setBeverageList] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({
    id: selectedId,
    name: value,
  });

  const { modalRef, isOpen, handleOpen, handleClose, handleDimClose } =
    useDialog();

  const handleChangeSearchText = useCallback((e) => {
    const value = e.target.value;
    setSearchText(value);
  }, []);

  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault();

      const {
        payload: {
          data: { beverages },
        },
      } = await dispatch(API(getBeverageNames({ name: searchText.trim() })));
      setBeverageList(beverages);
    },
    [searchText]
  );

  const handleSelectProduct = useCallback(
    (item) => () => {
      setSelectedProduct({ id: item.beverageId, name: item.beverageName });
    },
    []
  );

  const handleConfirm = () => {
    handleSelectId(selectedProduct);
    handleClose();
  };

  const initGetBeverageList = async () => {
    const {
      payload: {
        data: { beverages },
      },
    } = await dispatch(API(getBeverageNames()));
    setBeverageList(beverages);
  };

  useEffect(() => {
    if (!isOpen && isMounted.current) {
      setSearchText("");
    }
    isMounted.current = true;

    if (isOpen) {
      initGetBeverageList();
    }
  }, [isOpen]);

  useEffect(() => {
    initGetBeverageList();
  }, []);

  return (
    <>
      <Opener
        label="상품경로"
        btnLabel="상품 선택"
        placeholder="선택된 경로 없음"
        value={value}
        onClick={handleOpen}
      />
      {isOpen && (
        <Styled.StyleModal
          className={className}
          ref={modalRef}
          isOpen={isOpen}
          handleDimClose={handleDimClose}
        >
          <Modal.Header handleClose={handleClose}>상품목록</Modal.Header>
          <Modal.Content>
            <Styled.ContentHead>
              <h3>상품명</h3>
              <Styled.SearchArea onSubmit={handleSearch}>
                <input
                  type="text"
                  value={searchText}
                  onChange={handleChangeSearchText}
                />
                <button>검색</button>
              </Styled.SearchArea>
            </Styled.ContentHead>
            <Styled.ContentBody>
              <Styled.ListHead>
                <div>상품명</div>
                <div>제조사</div>
              </Styled.ListHead>
              <Styled.List>
                {beverageList.map((item) => {
                  return (
                    <Styled.Item key={item.beverageId}>
                      <Radio
                        id={item.beverageId}
                        name="product"
                        checked={selectedProduct.id === item.beverageId}
                        value={item.beverageId}
                        onChange={handleSelectProduct(item)}
                      />
                      <label htmlFor={item.beverageId}>
                        {item.beverageName}
                      </label>
                      <Styled.BreweryName>
                        {item.breweryName}
                      </Styled.BreweryName>
                    </Styled.Item>
                  );
                })}
              </Styled.List>
              <Styled.Bottom>
                <Styled.ConfirmBtn
                  type="button"
                  disabled={!selectedProduct.id}
                  onClick={handleConfirm}
                >
                  확인
                </Styled.ConfirmBtn>
              </Styled.Bottom>
            </Styled.ContentBody>
          </Modal.Content>
        </Styled.StyleModal>
      )}
    </>
  );
};

export default ProductListModal;
