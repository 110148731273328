import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100px;
    height: ${({height}) => height ?? '34px'};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 34px;
    border: solid 1px ${color.Colorb1b1b1};
    border-radius: 5px;
    :hover {
      cursor: pointer;
    }
  `,
};

export default Styled;
