import React from "react";
import styled from "styled-components";

const TextInput = ({ ...restProps }) => {
  return <Input type="text" autoComplete="off" {...restProps} />;
};

export default TextInput;

const Input = styled.input`
  width: 100%;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #e0e5e8;
  padding: 0 10px;
  font-size: 13px;
  background-color: #fff;

  &::placeholder {
    color: #b1b1b1;
  }
`;
