import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    margin-top: 30px;
    width: 100%;
    height: 32px;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
  `,
  Eexcel: styled.button`
    width: 71px;
    height: 32px;
    border: 1px solid ${color.Color12171d};
    border-radius: 2px;
    background-color: ${color.Colore0e5e8};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 8px;
  `,
};

export default Styled;
