import { postPresigned } from "api/presigend/index";
import { API } from "store/reducer/apiSlice";

export const makePresignedFormData = (res, file) => {
  const {
    presignedPost: { fields, url },
  } = res.payload.data;

  const presignedFormData = new FormData();

  for (const [key, value] of Object.entries(fields)) {
    presignedFormData.append(key, value);
  }
  presignedFormData.append("Content-Type", file.type);
  presignedFormData.append("file", file);
  return { url, presignedFormData };
};

export const checkAwsApiStaus = async (res, file, dispatch) => {
  if (file?.type) {
    const { url, presignedFormData } = makePresignedFormData(res, file);
    const result = await dispatch(API(postPresigned(url, presignedFormData)));
    const { status } = result.payload;
    return status;
  }

  return 200;
};
