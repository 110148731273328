import { useState, useRef, useCallback, useEffect } from "react";

const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpener, setModalOpener] = useState(null);
  const modalRef = useRef(null);

  const handleOpen = useCallback((e) => {
    e.stopPropagation();
    setModalOpener(document.activeElement);
    setIsOpen(true);
    setTimeout(() => modalRef.current?.focus());
  }, []);

  const handleDimClose = (e) => {
    e.stopPropagation();
    e.target === e.currentTarget && handleClose();
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    modalOpener && modalOpener.focus();
  }, [modalOpener]);

  const handleKeyTrap = useCallback((e) => {
    if (!modalRef.current) {
      return;
    }

    const focusableNodeList = modalRef.current.querySelectorAll(
      "[href], [tabIndex], button, input, textarea, select"
    );
    const shiftKey = e.shiftKey;
    const eventTarget = e.target;
    const firstFocusableNdoe = focusableNodeList[0];
    const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1];
    const isFirstFocusableNode = Object.is(eventTarget, firstFocusableNdoe);
    const isLastFocusableNode = Object.is(eventTarget, lastFocusableNode);

    if (shiftKey && isFirstFocusableNode) {
      e.preventDefault();
      lastFocusableNode.focus();
    }
    if (!shiftKey && isLastFocusableNode) {
      e.preventDefault();
      firstFocusableNdoe.focus();
    }
  }, []);

  useEffect(() => {
    const keyListenerMap = new Map([
      [27, handleClose],
      [9, handleKeyTrap],
    ]);

    function handleKeyListener(e) {
      const listener = keyListenerMap.get(e.keyCode);
      typeof listener === "function" && listener(e);
    }

    window.addEventListener("keydown", handleKeyListener);

    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  }, [handleClose, handleKeyTrap]);

  return {
    isOpen,
    setIsOpen,
    modalRef,
    modalOpener,
    handleOpen,
    handleClose,
    handleDimClose,
  };
};

export default useDialog;
