import { useEffect } from "react";
// some() 배열 안의 어떤 요소라도 주어진 판별 함수를 통과하는지 테스트합니다.
// 배열 안의 한 값이라도 판별 함수를 통과한다. true, 통과하지 못한다 false

export const useCheckValueNotExist = (list, setState, key) => {
  // 배열 안의 값중 ""인 값이 하나라도 있으면 true

  useEffect(() => {
    switch (Array.isArray(list)) {
      case true:
        // case: array
        break;
      default:
        //  case: obejct
        list = [...Object.values(list)];
        break;
    }

    const res = list.some((item) => item === "");

    setState((draft) => {
      draft[key] = res;
    });
  }, [list]);
};

export const useCheckValueExist = (props) => {
  // 배열 안의 값중 ""이 아닌 값이 하나라도 있으면 true
  const { list, setState, key } = props;
  useEffect(() => {
    const res = list.some((item) => item !== "");

    setState((draft) => {
      draft[key] = res;
    });
  }, [list]);
};

export const useCheckValueFalse = (props) => {
  // 배열의 모든 값이 0이라면 true
  const { list, setState, key } = props;

  useEffect(() => {
    const res = list.every((item) => item === 0);
    setState((draft) => {
      draft[key] = res;
    });
  }, [list]);
};
