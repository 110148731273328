import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.form`
    position: relative;
    height: inherit;
    img {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 8px;
      left: 13px;
      :hover {
        cursor: pointer;
      }
    }
    input {
      width: 300px;
      height: 100%;
      padding-left: 45px;
      border-radius: 5px;
      border: solid 1px ${color.Colord5d8db};
      :focus {
        border: 1px solid ${color.Color5ab1f0};
      }
    }
  `,
};
export default Styled;
