import { color } from "style/globalStyle";
import styled from "styled-components";
const Styled = {
  Root: styled.div`
    position: relative;
    width: 58px;
    display: flex;
    justify-content: right;
    align-items: center;
    .${(props) => props.className} {
      :hover {
        cursor: pointer;
      }
    }
    > img {
      transform: ${(props) =>
        props.className === "education-detail" ? "rotate(90deg)" : "initial"};
    }
  `,
  Popup: styled.div`
    position: absolute;
    top: 35px;
    right: 10px;
    width: 93px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px ${color.Colorc9c9c9};
    background-color: ${color.Colorffffff};
    z-index: 10;
    .inner {
      display: flex;
      align-items: center;
      line-height: 36px;
      padding: 0 6px;
      column-gap: 2px;
      :hover {
        cursor: pointer;
        span {
          color: ${color.Color1f6cd5};
        }
        background-color: ${color.Colore8f0fb};
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  `,
};
export default Styled;
