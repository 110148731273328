import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Opener: styled.div`
    display: grid;
    grid-template-columns: max-content 300px;
    align-items: center;
    column-gap: 8px;

    & > span {
      font-size: 13px;
      color: ${color.Color323c41};
    }
  `,
  InputWrapper: styled.div`
    display: grid;
    height: 36px;
    grid-template-columns: max-content 1fr;
    align-items: center;
    border-radius: 2px;
    border: 1px solid ${color.Colore0e5e8};
    padding: 3px 4px;
    background-color: ${color.Colorffffff};

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 2px;
      border: 1px solid ${color.Colorc9c9c9};
      padding: 0 8px;
      font-size: 13px;
      line-height: 1.2307;
      color: ${color.Color323c41};
      background-color: ${color.Colore0e5e8};
    }

    & > input {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 12px;
      font-size: 13px;
      text-overflow: ellipsis;

      &::placeholder {
        color: ${color.Colorb1b1b1};
      }
    }
  `,
};

export default Styled;
