import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { color } from "style/globalStyle";
import { ZINDEX } from "asset/data/common";
const Styled = {
  Root: styled.div`
    width: ${(props) => props.width};
    height: inherit;
    background-color: ${color.Color1b242b};
    position: fixed;
    top: 0;
    overflow-y: overlay;
    z-index: ${ZINDEX.navbar};
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .nav-link {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 24px;
      column-gap: 26px;
    }
    .menu-button {
      background-color: ${color.Colorce4d4e};
      :hover {
        cursor: pointer;
      }
    }
    .icon {
      width: 22px;
      height: 22px;
    }
    .logo {
      width: 62px;
      height: 28px;
    }
  `,
  NavLink: styled(NavLink)`
    background-color: ${(props) => (props.selected ? color.Color12171d : null)};

    &:hover {
      background-color: ${color.Color12171d};
      cursor: pointer;
    }

    .labelWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .label {
      color: ${(props) =>
        props.selected ? color.Colorffffff : color.Colorb1b1b1};
      cursor: pointer;
    }
    .arrow-icon {
      transform: ${(props) => !props.settingselected && `rotate(-90deg)`};
      transition: all 250ms ease-in-out;
    }
  `,
};
export default Styled;
