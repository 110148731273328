import { useRef, useEffect } from "react";

const useTimer = (apiHandler) => {
  const initTime = 1000 * 60 * 5; // 5분
  const timerId = useRef(null);

  useEffect(() => {
    timerId.current = setInterval(() => {
      apiHandler();
    }, initTime);
    return () => clearInterval(timerId.current);
  }, []);
};

export default useTimer;

// 참고: https://handhand.tistory.com/32
