import styled from "styled-components";

const Styled = {
  Root: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: solid 1.5px ${(props) => props.borderColor};
    background-color: ${(props) => props.backgroundColor};
  `,
  Icon: styled.img`
    width: 22px;
    height: 22px;
  `,
};

export default Styled;
