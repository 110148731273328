import styled from "styled-components";

import SearchButton from "component/button/searchButton/index";
import SearchInput from "component/input/searchInput/index";

const Styled = {
  Root: styled.div`
    width: inherit;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  `,
  Front: styled.div`
    display: flex;
    height: inherit;
  `,
  Back: styled.div`
    display: flex;
    height: inherit;
    column-gap: 16px;
  `,
  SearchInput: styled(SearchInput)`
    margin-left: 10px;
  `,
  SearchButton: styled(SearchButton)`
    margin-left: 8px;
  `,
};

export default Styled;
