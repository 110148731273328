import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  createContext,
} from "react";

import styled from "styled-components";

import useOnClickOutside from "hook/useOnClickOutside";
import { ReactComponent as ArrowDownIconSvg } from "asset/image/icArrowDown.svg";

const SelectContext = createContext();

const Select = ({
  id,
  className,
  children,
  name = "",
  selectedOption,
  labelTable,
  readOnly,
  placeholder,
  onSelect,
}) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const selectContainerRef = useRef(null);
  const selectButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClose = () => {
    setIsShowDropdown(false);
  };

  useOnClickOutside(selectContainerRef, handleClose);

  const handleClickDropBox = () => {
    handleShowDropdown();
  };

  const handleShowDropdown = () => {
    setIsShowDropdown((prev) => !prev);
  };

  const handleSelectOption = (option) => (event) => {
    const name = event.target?.name;

    onSelect(option, name);
    setIsShowDropdown(false);
  };

  const handleKeyTrap = (event) => {
    if (!dropdownRef.current) {
      return;
    }
    const focusableNodeList = dropdownRef.current.querySelectorAll(
      "[href], [tabIndex], button:not([disabled]), input:not([disabled]), textarea, select"
    );
    const shiftKey = event.shiftKey;
    const eventTarget = event.target;
    const firstFocusableNdoe = focusableNodeList[0];
    const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1];
    const isFirstFocusableNode = Object.is(eventTarget, firstFocusableNdoe);
    const isLastFocusableNode = Object.is(eventTarget, lastFocusableNode);

    if (
      (shiftKey && isFirstFocusableNode) ||
      (!shiftKey && isLastFocusableNode)
    ) {
      setIsShowDropdown(false);
    }
  };

  useEffect(() => {
    const handleKeyListener = (event) => {
      event.keyCode === 9 && handleKeyTrap(event);
      event.keyCode === 27 && handleClose();
    };

    window.addEventListener("keydown", handleKeyListener);
    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  });

  const uuid = `selectbox-${id}`;

  return (
    <SelectContext.Provider
      value={{ selectedOption, handleSelectOption, name }}
    >
      <Root ref={selectContainerRef} className={className}>
        <SelectButton
          type="button"
          ref={selectButtonRef}
          aria-controls={uuid}
          aria-expanded={isShowDropdown}
          isShowDropdown={isShowDropdown ? 1 : 0}
          disabled={readOnly}
          onClick={handleClickDropBox}
        >
          <span>
            {labelTable[selectedOption] || (
              <Placeholder>{placeholder}</Placeholder>
            )}
          </span>
          <ArrowDownIconSvg />
        </SelectButton>

        <Dropdown
          isShowDropdown={isShowDropdown}
          id={uuid}
          ref={dropdownRef}
          aria-hidden={!isShowDropdown}
        >
          {children}
        </Dropdown>
      </Root>
    </SelectContext.Provider>
  );
};

Select.Option = function OptionItem({ children, value, ...restProps }) {
  const context = useContext(SelectContext);

  return (
    <Option>
      <button
        type="button"
        name={context?.name}
        onClick={context?.handleSelectOption(value)}
        {...restProps}
      >
        <span>{children}</span>
      </button>
    </Option>
  );
};

export default Select;

const Root = styled.div`
  position: relative;
`;

const SelectButton = styled.button.attrs(({ isShowDropdown, theme }) => ({
  rotate: isShowDropdown ? "rotate(180deg)" : "rotate(0)",
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #e0e5e8;
  padding: 0 10px;
  font-size: 13px;
  background-color: #fff;

  & > svg {
    transform: ${({ rotate }) => rotate};
  }

  & > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[disabled] {
    & > svg {
      display: none;
    }
  }
`;

const Placeholder = styled.span`
  color: #b1b1b1;
`;

const Dropdown = styled.ul.attrs(({ isShowDropdown }) => ({
  display: isShowDropdown ? "unset" : "none",
}))`
  position: absolute;
  display: ${({ display }) => display};
  width: 100%;
  max-height: 112px;
  border-radius: 3px;
  border: 1px solid #d5d8db;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  transform: translateY(2px);
  z-index: 10;
  overflow: auto;
`;

const Option = styled.li`
  & > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 1.2142;
    background-color: #fff;

    & > span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      color: #1f6cd5;
      background-color: #e8f0fb;
    }
  }
`;
