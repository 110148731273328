import React, { useState } from "react";
import Styled from "./style";
import ChangePassword from "container/changePassword/changePassword/index";
import CheckPassword from "container/changePassword/checkPassword/index";

export default function AccountChangePassword() {
  const [type, setType] = useState("check");
  const content = () => {
    switch (type) {
      case "check":
        return <CheckPassword setType={setType} />;
      default:
        return <ChangePassword />;
    }
  };
  return <Styled.Root>{content()}</Styled.Root>;
}
