import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    width: 540px;
    height: 181px;
    padding: 32px 30px 24px 30px;
    border-radius: 5px;
    background-color: ${color.Colorffffff};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  Title: styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  `,
  Content: styled.div`
    color: ${color.Color777777};
    line-height: 1.43;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  `,
};
export default Styled;
