import React from "react";

import { ReactComponent as CheckBoxListDeleteIcon } from "asset/image/icCheckBoxListDelete.svg";
import { ReactComponent as ResetIcon } from "asset/image/icReload.svg";
import Styled from "./style";

export default function CheckboxSelectBoxList(props) {
  const {
    className,
    selectList,
    handleDeleteSelectItem,
    handleResetCheckboxSelectBoxList,
  } = props;

  return selectList.length ? (
    <Styled.Root className={className}>
      <Styled.FlexWrapper>
        {selectList.map((item, i) => (
          <Styled.Item key={i}>
            {item.name}
            <Styled.DeleteButton
              type="button"
              onClick={handleDeleteSelectItem(item)}
            >
              <CheckBoxListDeleteIcon />
            </Styled.DeleteButton>
          </Styled.Item>
        ))}
      </Styled.FlexWrapper>
      <Styled.ResetButton
        type="button"
        onClick={handleResetCheckboxSelectBoxList}
      >
        <ResetIcon />
        선택 초기화
      </Styled.ResetButton>
    </Styled.Root>
  ) : null;
}
