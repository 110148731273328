import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.ul`
  width: 100%;
  height: 100%;
  padding: 0 14px;
  overflow-y: auto;

  & li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 9px 9px 9px 16px;
    border-radius: 18px;
    border: solid 1px ${color.Colore0e5e8};
    background-color: ${color.Coloredf1f2};

    :not(:last-child) {
      margin-bottom: 10px;
    }
    & span {
      font-size: 13px;
      line-height: 1.38;
      color: ${color.Color1b242b};
    }

    & button {
    }
  }
`;
