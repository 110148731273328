import { createSlice } from "@reduxjs/toolkit";
import { image } from "style/globalStyle";

const initialState = {
  navbarToggle: true,
  navbarSize: "230px",
  userName: "",
  userId: "",
  userPosition: "",
  userMemo: "",
  userProfileImg: "",
  filePath: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearResults(state) {
      state = initialState;
    },
    toggleNavbarSize(state) {
      state.navbarToggle = !state.navbarToggle;
      state.navbarSize = state.navbarToggle ? "230px" : "72px";
    },
    setProfileImg(state, action) {
      state.userProfileImg = action.payload;
    },
    setProfile(state, action) {
      const { name, id, position, memo, fileId } = action.payload;
      state.userName = name;
      state.userId = id;
      state.userPosition = position;
      state.userMemo = memo;
      state.filePath = `profile/${fileId}`;
      if (fileId === null) {
        state.userProfileImg = image.defaultProfileImg;
      }
    },
  },
});

export const { clearResults, toggleNavbarSize, setProfileImg, setProfile } =
  commonSlice.actions;
export default commonSlice.reducer;
