import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Styled from "./style";
import { setModalVisible } from "hook/modal";

export default function PortalModal(props) {
  const { children, setState, type } = props;
  let ref = useRef();

  const modalElement = document.querySelector("#modal");
  const DimEl = document.querySelector("#modal .modal");

  const handleClickOutside = (event) => {
    //  className 이 modal이 아닌 ref 는 전부 return 시켜버림
    //  mui-popover, custom Modal 둘 다 ref 사용하는데, 충돌 나서 로직 추가

    const target = event.target;

    if (target === DimEl) {
      return;
    }
    // if (event?.path[0]?.classList[0] !== "modal") {
    //   return;
    // }
    handleCloseModal(event);
  };

  const escFunction = (event) => {
    if (type === false) return;

    if (event.keyCode === 27) {
      handleCloseModal(event);
    }
  };

  const handleCloseModal = (event) => {
    if (type === false) return;

    const target = event.target;
    if (ref.current && !ref.current.contains(target)) {
      setState && setModalVisible(setState, false);
    }
    if (ref.current === "") {
      setState && setModalVisible(setState, false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", escFunction);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", escFunction);
    };
  }, []);

  const modal = (
    <Styled.ModalStyle>
      <div className="modal">
        <div className="modal-wrap" ref={ref}>
          {children}
        </div>
      </div>
    </Styled.ModalStyle>
  );
  return ReactDOM.createPortal(modal, modalElement);
}
