import React, { useState, useEffect } from "react";
import Styled from "./style";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";

import { API } from "store/reducer/apiSlice";
import { loadFranchiseList } from "api/pub";
import { modalCont } from "asset/data/modalCont";
import {
  noticeSelectList,
  noticePubSelectList,
  sidoList,
} from "asset/data/selectList";
import { color } from "style/globalStyle";
import PortalModal from "container/modal/portalModal/index";
import InfoInput from "component/input/infoInput/index";
import SubmitButton from "component/button/submitButton/index";
import SwitchButton from "component/button/switchButton/index";
import SelectBox from "component/selectBox/index";
import { useNoticeData } from "hook/useNoticeData";
import CustomPrompt from "component/prompt/index";
import usePrompt from "hook/usePrompt";
import { makeCheckObject } from "util/makeCheckList";
import Toast from "component/toast/index";
import CheckboxSelectBox from "component/checkboxSelectBox/index";

const typeKor = {
  pub: "매장",
  vendor: "도매사",
  brewery: "제조사",
  all: "전체",
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};

const initSubmitState = {
  title: "",
  content: "",
  fixed: 0, // 공지사항 최상단 노출 여부
  type: "all",
  franchiseName: "",
  franchise: "",
  area: "",
  linkedIds: [],
};

export default function NoticeCreate() {
  const dispatch = useDispatch();

  const [modalState, setModalState] = useImmer(initModalState);
  const [submitState, setSubmitState] = useImmer(initSubmitState);

  const [selectBoxList, setSelectBoxList] = useState([]); // selectBox 내에서 선택한 리스트 (선택완료 누르기 전)

  const [franchiseNameList, setFranchiseNameList] = useState({});

  const checkList = makeCheckObject({ ...submitState }, []);
  const { isValueChanged, setIsValueChanged } = usePrompt({ checkList });

  const { handleCreate, dropdownInfo, toast, getNoticeInfo, getDropdownList } =
    useNoticeData({
      submitState,
      setModalState,
      setIsValueChanged,
    });

  const handleNoticeSelect = (type) => (value) => {
    setSelectBoxList([]);

    switch (type) {
      case "type":
        getNoticeInfo({ type: value });
        if (value !== "all") {
          getDropdownList({ type: value });
        }

        setSubmitState({
          type: value,
          franchiseName: "",
          franchise: "",
          area: "",
          linkedIds: "",
          title: submitState.title,
          content: submitState.content,
        });

        break;

      case "franchiseName":
        getNoticeInfo({
          ...submitState,
          franchiseName: value,
        });

        if (submitState.type !== "all") {
          getDropdownList({
            ...submitState,
            franchiseName: value,
          });
        }

        setSubmitState({
          type: submitState.type,
          franchiseName: value,
          franchise: "",
          area: "",
          linkedIds: [],
          title: submitState.title,
          content: submitState.content,
        });

        break;

      case "franchise":
        getNoticeInfo({
          ...submitState,
          franchise: value,
        });
        if (submitState.type !== "all") {
          getDropdownList({
            ...submitState,
            franchise: value,
          });
        }

        setSubmitState({
          type: submitState.type,
          franchiseName: submitState.franchiseName,
          franchise: value,
          area: "",
          linkedIds: "",
          title: submitState.title,
          content: submitState.content,
        });
        break;

      case "area":
        getNoticeInfo({
          ...submitState,
          area: value,
        });

        if (submitState.type !== "all") {
          getDropdownList({
            ...submitState,
            area: value,
          });
        }

        setSubmitState({
          type: submitState.type,
          franchiseName: submitState.franchiseName,
          franchise: submitState.franchise,
          area: value,
          linkedIds: "",
          title: submitState.title,
          content: submitState.content,
        });
        break;
    }
  };

  const handleCheckSelectBox = (value) => {
    if (value.name === "전체") {
      // 전체를 눌렀을 때
      setSelectBoxList([{ linkId: "all", name: "전체" }]);
    } else if (selectBoxList.some((select) => select.linkId === value.linkId)) {
      // 전체가 아닌 다른 리스트를 눌렀을 때 (눌렀는 리스트가 체크되어 있는 상황)
      setSelectBoxList(
        selectBoxList
          .filter((item) => item.linkId !== value.linkId)
          .filter((item) => item.linkId !== "all")
      );
    } else {
      // 전체가 아닌 다른 리스트를 눌렀을 때 (눌렀는 리스트가 체크 안되어 있는 상황)
      setSelectBoxList([
        ...selectBoxList.filter((item) => item.linkId !== "all"),
        value,
      ]);
    }
  };

  const handleResetSelectList = () => {
    setSelectBoxList([]);
  };

  const handleConfirmCheckSelectBox = () => {
    setSubmitState({ ...submitState, linkedIds: selectBoxList });
  };

  const handleDeleteSelectItem = (deleteItem) => () => {
    setSubmitState({
      ...submitState,
      linkedIds: submitState.linkedIds.filter(
        (item) => item.linkId !== deleteItem.linkId
      ),
    });
    setSelectBoxList(
      submitState.linkedIds.filter((item) => item.linkId !== deleteItem.linkId)
    );
  };

  const handleResetCheckboxSelectBoxList = () => {
    setSubmitState({ ...submitState, linkedIds: [] });
    setSelectBoxList([]);
  };

  useEffect(() => {
    async function loadFranchiseNames() {
      const {
        payload: {
          data: { names },
        },
      } = await dispatch(API(loadFranchiseList()));

      const franchiseList = names.reduce(
        (acc, cur) => [...acc, { label: cur, value: cur }],
        [{ label: "전체", value: "all" }]
      );

      setFranchiseNameList(franchiseList);
    }

    loadFranchiseNames();
  }, []);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>공지 내용</Styled.Header>
        <Styled.Wrapper>
          <div>
            <Styled.SelectBoxHeading>분류</Styled.SelectBoxHeading>
            <Styled.SelectBoxWrapper
              hasSelectList={!!submitState.linkedIds.length}
            >
              <Styled.MainCategory
                placeholder="전체공지"
                className="type"
                width="140px"
                innerWidth="140px"
                value={submitState.type}
                selectList={noticeSelectList}
                handleFilter={handleNoticeSelect("type")}
              />
              <SelectBox
                placeholder="분류1"
                className="type"
                width="140px"
                innerWidth="140px"
                disabled={
                  submitState.type === "all" ||
                  submitState.type === "vendor" ||
                  submitState.type === "brewery"
                }
                value={submitState.franchiseName}
                selectList={franchiseNameList}
                handleFilter={handleNoticeSelect("franchiseName")}
              />
              <SelectBox
                placeholder="분류2"
                className="type"
                width="140px"
                innerWidth="140px"
                disabled={
                  submitState.type === "all" ||
                  submitState.type === "vendor" ||
                  submitState.type === "brewery"
                }
                name="franchise"
                value={submitState.franchise}
                selectList={noticePubSelectList}
                handleFilter={handleNoticeSelect("franchise")}
              />
              <SelectBox
                placeholder="지역"
                className="type"
                width="140px"
                innerWidth="140px"
                disabled={
                  submitState.type === "all" ||
                  submitState.type === "vendor" ||
                  submitState.type === "brewery"
                }
                name="area"
                value={submitState.area}
                selectList={sidoList}
                handleFilter={handleNoticeSelect("area")}
              />
              <CheckboxSelectBox
                placeholder="업체"
                className="type"
                width="140px"
                innerWidth="236px"
                disabled={submitState.type === "all"}
                name="linkedIds"
                selectTypeName={typeKor[submitState.type]}
                value={submitState.linkedIds}
                selectBoxList={selectBoxList}
                selectList={dropdownInfo}
                handleFilter={handleCheckSelectBox}
                handleResetSelectList={handleResetSelectList}
                handleConfirmCheckSelectBox={handleConfirmCheckSelectBox}
              />
            </Styled.SelectBoxWrapper>
          </div>
          <Styled.CustomCheckboxSelectBoxList
            selectList={submitState.linkedIds}
            handleDeleteSelectItem={handleDeleteSelectItem}
            handleResetCheckboxSelectBoxList={handleResetCheckboxSelectBoxList}
          />
          <Styled.CustomTitleInfoInput
            state={submitState}
            setState={setSubmitState}
            name="title"
            maxLength="80"
          />
          <InfoInput
            title="내용"
            state={submitState}
            setState={setSubmitState}
            inputType="textarea"
            height="350px"
            name="content"
            maxLength="2000"
          />
          <Styled.OptionWrapper>
            <div className="title">공지사항 최상단 노출</div>
            <SwitchButton
              state={submitState}
              setState={setSubmitState}
              name="fixed"
            />
          </Styled.OptionWrapper>
        </Styled.Wrapper>
        <Styled.ButtonWrapper>
          <SubmitButton
            width="120px"
            height="40px"
            content="등록"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleCreate}
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={toast.isShow} message={toast.message} />
    </>
  );
}
