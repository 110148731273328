import { Link } from "react-router-dom";
import { StyledAvatar } from "./style";

const Avatar = ({ username, to, image = "", size = "small" }) => {
  const alt = `${username}`;

  if (to != null) {
    if (!image)
      return <StyledAvatar size={size} as={Link} to={to} aria-label={alt} />;

    return (
      <StyledAvatar size={size} as={Link} to={to}>
        <img src={image} alt={alt} />
      </StyledAvatar>
    );
  }

  if (!image) return <StyledAvatar size={size} aria-label={alt} />;

  return (
    <StyledAvatar size={size}>
      <img src={image} alt={alt} />
    </StyledAvatar>
  );
};

export default Avatar;
