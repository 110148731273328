import React, { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import OptionCard from "../optionCard/index";
import {
  Root,
  OptionCategory,
  OptionsHeader,
  PlusIcon,
  OptionList,
} from "./style";

const VOLUME_LIST = ["keg", "can", "bot"];

const OptionContainer = ({
  sellerType,
  sellerId,
  optionCategories,
  selectableVolume = VOLUME_LIST,
  toast,
  setState,
}) => {
  const handleAddOption = useCallback(
    (category) => () => {
      if (category.options.length >= 3) return;

      setState((draft) => {
        draft.options.push({
          optionId: uuidv4(),
          sellerId: sellerId,
          sellerType: category.sellerType,
          target: category.target,
          volume: "",
          price: "",
        });
      });
    },
    [setState, sellerId]
  );

  const isAlreadySelectedVolume = useCallback((category) => {
    return category.options.reduce(
      (acc, cur) => (cur.volume ? [...acc, cur.volume] : [...acc]),
      []
    );
  }, []);

  useEffect(() => {
    optionCategories.forEach((category) => {
      category.options.length === 0 && handleAddOption(category)();
    });
  }, [handleAddOption]);

  const title =
    sellerType === "brewery" ? "제조사 옵션 목록" : "도매사 옵션 목록";

  return (
    <Root>
      <h3 className="a11y">{title}</h3>
      {optionCategories.map((category, idx) => (
        <OptionCategory key={idx}>
          <OptionsHeader>
            <h3>{category.title}</h3>
            <button
              type="button"
              aria-label="옵션 추가"
              onClick={handleAddOption(category)}
            >
              <PlusIcon />
            </button>
          </OptionsHeader>
          <OptionList>
            {category.options.map((option, idx) => (
              <li key={option.optionId}>
                <OptionCard
                  data={option}
                  index={idx}
                  isAlreadySelectedVolume={isAlreadySelectedVolume(category)}
                  selectableVolume={selectableVolume}
                  toast={toast}
                  setState={setState}
                />
              </li>
            ))}
          </OptionList>
        </OptionCategory>
      ))}
    </Root>
  );
};

export default OptionContainer;
