import { useEffect } from "react";

const useOnClickOutside = (ref, handler, exceptElement) => {
  useEffect(() => {
    const listener = (e) => {
      const el = ref?.current;
      const exceptEl = exceptElement?.current ?? exceptElement;
      const isIncludeEl = !el || el.contains(e?.target);
      const isIncludeExceptel = exceptEl || exceptEl?.contains(e?.target);

      if (isIncludeEl || isIncludeExceptel) {
        return;
      }

      handler();
    };
    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [ref, handler, exceptElement]);
};

export default useOnClickOutside;
