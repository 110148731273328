import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import queryString from "query-string";

import { API } from "store/reducer/apiSlice";
import { getBeverageDetailWithId, putBeverages } from "api/beverage/index";
import { getBreweriesNames } from "api/brewery/index";
import { getBeveragesCategories, getBeveragesStyle } from "api/beverage/index";
import { setModalKey } from "hook/modal";
import { modalCont } from "asset/data/modalCont";
import { beverageInfoInputList } from "asset/data/infoInputList";
import PortalModal from "container/modal/portalModal/index";
import UploadImg from "component/uploadImg";
import InfoInput from "component/input/infoInput/index";
import SwitchButton from "component/button/switchButton/index";
import CancelButton from "component/button/cancelButton/index";
import OptionContainer from "component/option/optionContainer/index";
import CustomPrompt from "component/prompt";
import Toast from "component/toast/index";
import { checkAwsApiStaus } from "util/presigend";
import useToast from "hook/useToast";
import usePrompt from "hook/usePrompt";
import useBeverageOption from "hook/useBeverageOption";
import { image } from "style/globalStyle";
import Styled from "../create/style";

const formValueInitState = {
  file: null, // 사업자등록증
  fileUrl: "",
  name: "", // 상품명
  breweryId: "", // 제조사
  originBreweryId: "",
  category: "", // 분류1
  categoryId: "", // 스타일 id
  abv: "", // 도수
  rating: "",
  feature: "", // 특징
  isNewChecked: false, // 신규 상품 노출 여부
  options: [],
  originOptions: [],
};

const modalOptionInitState = {
  modal: {
    modalVisible: false,
    modalKey: "",
  },
};

export default function BeverageUpdate() {
  const mounted = useRef(false);
  const [formValue, setFormValue] = useImmer(formValueInitState);
  const [modalOption, setModalOption] = useImmer(modalOptionInitState);
  const [inputList, setInputList] = useImmer([...beverageInfoInputList]);
  const [styleSelectDisabled, setStyledDisabled] = useState(false);
  const [isValidError, setIsValidError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const dispatch = useDispatch();
  const { isShow, message, type, toast } = useToast();
  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList: [
      formValue.file,
      formValue.name,
      formValue.breweryId,
      formValue.categoryId,
      formValue.abv,
      formValue.rating,
      formValue.feature,
      formValue.isNewChecked,
      formValue.options.filter((v) => v.price && v.volume),
    ],
  });
  const {
    breweryVendorOptions,
    selectableVenderOptionVolumes,
    breweryPubOptions,
    vendorOptionCategories,
    getOrganizedOptions,
    getOptionIdList,
    checkIncompleteOption,
    deleteOptIdInNewCreateOpt,
  } = useBeverageOption(formValue.options, formValue.breweryId, toast);

  const handleClickCancel = () => {
    history.push("/beverage");
  };
  const handleSelect = (name, value) => {
    name === "category" && loadStyleSelectOptions(value);

    setFormValue((draft) => {
      draft[name] = value;
    });
  };

  const loadStyleSelectOptions = async (value) => {
    // 스타일 선택 초기화
    setInputList((draft) => {
      draft[3].selectList = [];
    });
    const res = await dispatch(API(getBeveragesStyle({ category: value })));
    const stylesSelectList = [];
    res.payload.data.styles.forEach((item) => {
      const { style, categoryId } = item;
      stylesSelectList.push({ label: style, value: categoryId });
    });

    setInputList((draft) => {
      draft[3].selectList = stylesSelectList;
    });
  };

  const handleSubmit = async () => {
    try {
      const originOptionIdList = getOptionIdList(formValue.originOptions);
      const options = getOrganizedOptions();
      if (!checkIncompleteOption(options)) return;
      const newOptions = deleteOptIdInNewCreateOpt(options, originOptionIdList);
      const reqData = {
        breweryId: formValue.breweryId,
        categoryId: formValue.categoryId,
        name: formValue.name,
        abv: formValue.abv,
        feature: formValue.feature,
        isNew: formValue.isNewChecked,
      };

      if (!isValidEmpty(reqData)) {
        toast("필수 기입 정보를 입력해 주세요", 'warning');
        setIsValidError(true);
        return;
      }

      formValue.rating && (reqData["rating"] = formValue.rating);
      newOptions.length && (reqData["options"] = newOptions);
      if (formValue.file) {
        reqData["file"] = formValue.file;
        reqData["contentType"] = formValue.file.type;
      }
      const res = await dispatch(API(putBeverages(id, reqData)));
      await checkAwsApiStaus(res, formValue.file, dispatch);
      setIsValueChanged(false);
      setModalKey(setModalOption, "submitComplete");
      setTimeout(() => {
        setModalOption((draft) => {
          draft.modal.modalVisible = false;
        });
      }, [1000]);
    } catch (err) {
      console.error(err);
    }
  };

  const isValidEmpty = (data) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "string" ? item !== "" : true;
    };

    return Object.values(data).every(hasNotEmptyValue);
  };

  const init = async () => {
    try {
      await setSelectList();
      await getDetailInfo();
    } catch (err) {
      history.replace("/beverage");
    }
  };
  const setSelectList = async () => {
    const res = await Promise.all([
      dispatch(API(getBreweriesNames())),
      dispatch(API(getBeveragesCategories())),
    ]);
    const brewerySelectList = [];
    const categoriesSelectList = [];

    res[0].payload.data.breweries.forEach((item) => {
      const { breweryName, breweryId } = item;
      brewerySelectList.push({ label: breweryName, value: breweryId });
    });
    res[1].payload.data.categories.forEach((item) => {
      const { category } = item;
      categoriesSelectList.push({ label: category, value: category });
    });
    setInputList((draft) => {
      draft[1].selectList = brewerySelectList;
      draft[2].selectList = categoriesSelectList;
    });
  };
  const getDetailInfo = async () => {
    const {
      payload: {
        data: { beverage: data },
      },
    } = await dispatch(API(getBeverageDetailWithId(id)));
    loadStyleSelectOptions(data.category);
    setFormValue((draft) => {
      draft.fileUrl = data.signedUrl;
      draft.isNewChecked = data.isNew;
      draft.name = data.beverageName;
      draft.breweryId = data.breweryId;
      draft.originBreweryId = data.breweryId;
      draft.breweryName = data.breweryName;
      draft.category = data.category;
      draft.categoryId = data.categoryId;
      draft.abv = data.abv;
      draft.rating = data.rating;
      draft.feature = data.feature;
      draft.options = data.options;
      draft.originOptions = data.options;
    });
    setInputList((draft) => {
      draft[1].value = data.breweryId;
      draft[2].value = data.category;
      draft[3].value = data.categoryId;
      draft[6].value = data.rating;
    });

    setInitList([
      null,
      data.beverageName,
      data.breweryId,
      data.categoryId,
      data.abv,
      data.rating,
      data.feature,
      data.isNew,
      data.options,
    ]);
  };

  const moveManagePage = useCallback(() => {
    history.push("/beverage/create/setting");
  }, [history]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const isStyleSelected = formValue.category === "";
    setStyledDisabled(isStyleSelected);
  }, [formValue.category]);

  useEffect(() => {
    if (!mounted.current) {
      setInitList([
        formValue.file,
        formValue.name,
        formValue.breweryId,
        formValue.categoryId,
        formValue.abv,
        formValue.rating,
        formValue.feature,
        formValue.optionChecked,
        formValue.options,
      ]);
      if (formValue.options.length > 0) {
        mounted.current = true;
      }
    }
  }, [formValue.options]);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalOption}
          setModalState={setModalOption}
          isValueChanged={isValueChanged}
        />
        {modalOption.modal.modalVisible && (
          <PortalModal setState={setModalOption}>
            {modalCont({
              modalKey: modalOption.modal.modalKey,
              completeContent: "수정 정보가 저장되었습니다.",
              nextPath: "/beverage",
              setState: setModalOption,
            })}
          </PortalModal>
        )}
        <Styled.Header>신규 맥주 상품 정보를 입력해 주세요</Styled.Header>
        <Styled.Wrapper>
          <Styled.ProductInfoWrapper>
            <div className="header header-style">
              <img src={image.icBeverageInfo} alt="beverage-info-icon" />
              <span>상품 정보</span>
              <Styled.Essential aria-label="필수값">*</Styled.Essential>
            </div>
            <UploadImg
              content="상품 이미지를 가져오세요"
              state={formValue.file}
              setState={setFormValue}
              name="file"
              url={formValue.fileUrl}
              height="250px"
              isValidError={isValidError}
            />
            <div className="new-product">
              <div className="header-style">
                <img src={image.icNew} alt="new-icon" />
                <span>신규 상품 노출</span>
              </div>
              <SwitchButton
                state={formValue}
                setState={setFormValue}
                name="isNewChecked"
              />
            </div>
            <div className="new-product-desc">
              *신규 상품 노출 버튼을 활성화 하면, 각 App에서 본 상품은 신규
              상품으로 표시됩니다.
            </div>
          </Styled.ProductInfoWrapper>
          <Styled.EssentialInfo>
            <Styled.InputWrapper>
              {inputList.map((item, idx) => {
                const {
                  title,
                  placeholder,
                  name,
                  inputType,
                  innerWidth,
                  selectList,
                  value,
                  unit,
                  height,
                  isRequired,
                  max,
                } = item;
                return (
                  <InfoInput
                    key={idx}
                    title={title}
                    state={formValue}
                    setState={setFormValue}
                    placeholder={placeholder}
                    name={name}
                    inputType={inputType}
                    innerWidth={innerWidth}
                    selectList={selectList}
                    value={value}
                    handleSelect={handleSelect}
                    unit={unit}
                    height={height}
                    handleSettingBtn={moveManagePage}
                    isRequired={isRequired}
                    max={max}
                    disabled={styleSelectDisabled && name === "categoryId"}
                    isValidError={isRequired && isValidError}
                  />
                );
              })}
            </Styled.InputWrapper>
          </Styled.EssentialInfo>
        </Styled.Wrapper>
        <OptionContainer
          sellerType="brewery"
          optionCategories={[breweryVendorOptions, breweryPubOptions]}
          sellerId={formValue.breweryId}
          toast={toast}
          setState={setFormValue}
        />
        {vendorOptionCategories.map((optionCategory) => (
          <OptionContainer
            key={optionCategory.sellerId}
            sellerType="vendor"
            optionCategories={[optionCategory]}
            sellerId={optionCategory.sellerId}
            selectableVolume={selectableVenderOptionVolumes}
            toast={toast}
            setState={setFormValue}
          />
        ))}

        <Styled.ButtonWrapper>
          <CancelButton handleClick={handleClickCancel} />
          <Styled.SubmitButton content="수정 완료" handleClick={handleSubmit} />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type}/>
    </>
  );
}
