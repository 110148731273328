import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin: ${(props) => props.margin};
  `,
  NumberPart: styled.div`
    display: flex;
    column-gap: 8px;
  `,
  Number: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    background-color: ${(props) => props.selected && color.Colore0e5e8};
    color: ${(props) =>
      props.selected ? color.Color12171d : color.Color777777};
    transition: 300ms;

    :hover {
      background-color: #d9dee2;
      color: #a2abb0;
      cursor: pointer;
    }
  `,
  NextPart: styled.div`
    display: flex;
    align-items: center;

    .right {
      transform: rotate(180deg);
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      cursor: pointer;

      & > img {
        opacity: 0.5;
        transition: 300ms;
      }

      &:hover {
        & > img {
          opacity: 1;
        }
      }
    }
  `,
  TotalPart: styled.div`
    display: flex;
    margin-left: 30px;
  `,
};
export default Styled;
