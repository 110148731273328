import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.div.attrs(({ validation }) => ({
  border: validation
    ? `1px solid ${color.Colorff0000}`
    : `1px solid ${color.Colorc9c9c9}`,
}))`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: ${({ border }) => border};
  position: relative;
  display: flex;
  padding: 0 10px 0 16px;
  justify-content: space-between;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    color: ${(props) => props.contentColor};
  }
`;
