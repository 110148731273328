import React from "react";
import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "asset/image/icArrowButton.svg";

const DatepickerHeader = ({
  monthYear,
  changePrevMonthYear,
  changeNextMonthYear,
}) => {
  return (
    <Wrapper>
      <Container>
        <PrevButton label="이전 달" onClick={changePrevMonthYear}>
          <ArrowBtnIcon />
        </PrevButton>
        <CurrentDate>
          <Year>{monthYear.year}</Year>
          <Month>{monthYear.monthName}</Month>
        </CurrentDate>
        <NextButton label="다음 달" onClick={changeNextMonthYear}>
          <ArrowBtnIcon />
        </NextButton>
      </Container>
    </Wrapper>
  );
};

export default DatepickerHeader;

const Wrapper = styled.header`
  margin-bottom: 24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrevButton = styled.button`
  & > svg {
    transform: rotate(-180deg);
  }
`;

const NextButton = styled.button``;

const CurrentDate = styled.time`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 134px;
  line-height: 1.5;
`;

const Year = styled.span`
  margin-right: 4px;
`;

const Month = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const ArrowBtnIcon = styled(ArrowSvg)``;
