import React from "react";
import { useHistory } from "react-router-dom";

import { userStorage } from "util/helper/storage";
import { image } from "style/globalStyle";
import Styled from "./style";
export default function ExpiredModal() {
  const history = useHistory();
  const handleClick = () => {
    userStorage.clear();
    history.push("/");
  };
  return (
    <Styled.Root>
      <div>
        <Styled.Header>
          <img src={image.icExclamation} alt="exclamation-icon" />
          <span>세선 허용 시간이 만료 되었습니다.</span>
        </Styled.Header>
        <Styled.Main>
          개인 정보보호를 위해 다시 한번 로그인해 주세요.
        </Styled.Main>
      </div>
      <Styled.ButtonWrapper>
        <button onClick={handleClick}> 확인</button>
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
