import React from "react";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";

import Pagination from "component/pagination";
import { TRANSACTION_TAB } from "util/constant";
import usePubsPanel from "../hooks/usePubsPanel";
import TableOptionArea from "../TableOptionArea";
import DataStatistics from "../DataStatistics";
import FilterSearchBar from "./FilterSearchBar";
import PubsTransactionTable from "./PubsTransactionTable";
import PubsTransactionTwoDepthTable from "./PubsTransactionTwoDepthTable";

const PubsPanel = () => {
  const {
    filterValues,
    isLoading,
    transactions,
    beverages,
    handleClickStatus,
    handleClickDropdown,
    handleSelectDate,
    handleInquery,
    handleReload,
    handlePagination,
    handleExcelDownload,
    handleClickBeverageList,
    handleFilter,
    handleSearchInput,
  } = usePubsPanel();

  return (
    <Wrapper id={TRANSACTION_TAB.PUBS}>
      <FilterSearchBar
        data={filterValues}
        handleClickStatus={handleClickStatus}
        handleClickDropdown={handleClickDropdown}
        handleSelectDate={handleSelectDate}
        handleInquery={handleInquery}
        handleReload={handleReload}
        handleFilter={handleFilter}
        handleSearchInput={handleSearchInput}
      />
      <Content>
        {transactions.orders.length > 0 && (
          <>
            <LeftContent>
              <TableOptionArea
                totalPrice={transactions.totalPrice}
                totalAmount={transactions.totalAmount}
                handleExcelDownload={handleExcelDownload}
              />
              <PubsTransactionTable
                data={transactions.orders}
                handleTransactionRow={handleClickBeverageList}
              />
              <BottomWrapper>
                <StylePagination
                  pageInfo={transactions.pageInfo}
                  handlePagination={handlePagination}
                />
              </BottomWrapper>
            </LeftContent>
            <RightContent>
              <DataStatistics
                totalPrice={beverages.totalPrice}
                totalAmount={beverages.totalAmount}
              />
              <PubsTransactionTwoDepthTable
                data={beverages.sellers}
                isClickRow={beverages.sellers.length !== 0}
              />
            </RightContent>
          </>
        )}
        {isLoading && (
          <Oval
            height={80}
            width={80}
            color="#1b242b"
            wrapperStyle={{}}
            wrapperClass="loading"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1b242b"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default PubsPanel;

const Wrapper = styled.div`
  padding: 24px 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  column-gap: 20px;
  min-height: 792px;
  margin-top: 30px;

  & > .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LeftContent = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  flex: 1;
  row-gap: 10px;
`;

const RightContent = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 10px;
`;

const BottomWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
`;

const StylePagination = styled(Pagination)`
  justify-content: center;
`;
