import React from "react";

import Styled from "./style";
import { image } from "style/globalStyle";
import { pubSelectList, pubValueLabel } from "asset/data/selectList";
import CommonInfoInput from "component/input/infoInputWrapper/commonInput/index";
import PhoneInfoInput from "component/input/infoInputWrapper/phoneInput/index";
import RadioInfoInput from "component/input/infoInputWrapper/radioInput/index";
import AdressInfoInput from "component/input/infoInputWrapper/adressInput/index";
import SelectInfoInput from "component/input/infoInputWrapper/selectInput/index";

export default function EssentialInfo(props) {
  const { state, setState, isValidError } = props;

  return (
    <Styled.Root>
      <div className="header">
        <img src={image.icEssential} alt="essential-icon" />
        <span>필수정보</span>
      </div>
      <Styled.InputWrapper>
        <CommonInfoInput
          title="매장명"
          placeholder="ex.생활맥주(구로디지털점)"
          name="pubName"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <CommonInfoInput
          title="사업자등록번호"
          placeholder="123-45-67890"
          name="taxcode"
          state={state}
          setState={setState}
          inputType="number"
          isRequired
          isValidError={isValidError}
        />
        <CommonInfoInput
          title="대표자명"
          placeholder="사업자로 등록된 대표자명을 입력해 주세요."
          name="managerName"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <PhoneInfoInput
          title="핸드폰번호"
          placeholder="대표자 핸드폰 번호를 입력해 주세요."
          name="managerPhone"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <CommonInfoInput
          title="이메일 주소"
          explain="*비밀번호 분실 시 이메일 주소가 필요합니다."
          placeholder="이메일 주소를 입력해 주세요."
          name="managerEmail"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <CommonInfoInput
          title="분류1"
          placeholder="프랜차이즈명 (ex.생활맥주)."
          name="franchiseName"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <SelectInfoInput
          title="분류2"
          placeholder="(분류선택)"
          name="franchise"
          inputType="select"
          innerWidth="142.5px"
          state={state}
          setState={setState}
          selectList={pubSelectList}
          selectLabel={pubValueLabel}
          isRequired
          isValidError={isValidError}
        />

        <RadioInfoInput
          title="운영상태"
          name="status"
          radioContents={["운영", "폐업"]}
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <AdressInfoInput
          title="매장주소"
          placeholder="주소 검색"
          name="addr"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
        <CommonInfoInput
          title="매장상세주소"
          placeholder="전체 매장 주소를 입력해 주세요."
          name="addrDetail"
          state={state}
          setState={setState}
        />
        <PhoneInfoInput
          title="전화번호"
          placeholder="매장 전화번호를 입력해 주세요."
          name="pubPhone"
          state={state}
          setState={setState}
          isRequired
          isValidError={isValidError}
        />
      </Styled.InputWrapper>
    </Styled.Root>
  );
}
