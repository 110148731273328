import styled from "styled-components";
import { color, typo } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  ContentBoxWrapper: styled.article`
    padding: 24px 30px 24px 30px;
    background-color: #fff;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Header: styled.header`
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 8px;

      .header-leftside {
        display: flex;
        align-items: flex-start;

        .header-title {
          max-width: 850px;
          ${typo.medium18};
          color: ${color.Color121212};
          margin-right: 10px;
        }
        .new-tag-box {
          display: ${({ isNew }) => (isNew === 1 ? "flex" : "none")};
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          border-radius: 3px;
          width: 46px;
          height: 24px;
          background-color: ${color.Color1f6cd5};
          .new-tag {
            height: 15px;
            line-height: 1.5;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
          }
        }
      }
    }

    div.date {
      small {
        font-size: 14px;
        line-height: 1.28571;
        color: #777;
      }
    }
  `,
  Body: styled.div.attrs(({ show }) => ({
    display: show ? "block" : "none",
  }))`
    div {
      ${typo.regular14};
      display: ${({ display }) => display};
      margin-top: 24px;
      line-height: 1.7142;
      color: #323c41;
      white-space: pre-wrap;
    }
  `,
  BtnWrapper: styled.div`
    display: flex;

    button {
      padding: 4px 10px;
      color: #777777;
    }

    & > :first-child {
      position: relative;
      display: flex;
      align-items: center;
    }

    & > :first-child::after {
      content: "";
      position: absolute;
      right: -1px;
      width: 1px;
      height: 12px;
      border-right: 1px solid #c9c9c9c9;
    }
  `,
  PaginationWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
};
export default Styled;
