import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import common from "./reducer/commonSlice";
import api from "./reducer/apiSlice";
import searchBar from "./reducer/searchBarSlice";
import banner from "./reducer/bannerSlice";

const appReducer = combineReducers({
  // slice 목록
  common,
  api,
  searchBar,
  banner,
});

const rootReducer = (state, action) => {
  // logout dispatch 발생시 state 초기화
  if (action.type === "common/clearResults") {
    state = undefined;
  }
  return appReducer(state, action);
};

// redux-persist 설정 redux storage 사용하는 거
const persistConfig = {
  key: "root",
  storage, // local storage에 저장 설정
  whitelist: ["common", "banner"], // storage에 저장할 slice 지정
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export const persistor = persistStore(store);
