import React from "react";
import { useHistory } from "react-router-dom";
import { useImmer } from "use-immer";

import { color } from "style/globalStyle";
import Styled from "./style";
import { modalCont } from "asset/data/modalCont";
import PortalModal from "container/modal/portalModal/index";
import Badge from "component/badge/index";
import { setModalKey } from "hook/modal";

const modalInitState = {
  modal: {
    modalVisible: false,
    modalKey: "",
  },
};

export default function DragAbleBox({ forwardedRef, ...props }) {
  const { item, handleDelete } = props;
  const { bannerId, activated, activatedIndex } = item;
  const [modalState, setModalState] = useImmer(modalInitState);

  const history = useHistory();

  const handleClickDelete = async () => {
    setModalKey(setModalState, "deleteBannerModal");
  };

  const handleClickUpdate = () => {
    history.push({
      pathname: "/banner/update",
      search: `?id=${bannerId}`,
    });
  };

  return (
    <Styled.Root ref={forwardedRef} {...props}>
      {modalState.modal.modalVisible && (
        <PortalModal setState={setModalState}>
          {modalCont({
            modalKey: modalState.modal.modalKey,
            setState: setModalState,
            activateFunction: handleDelete(bannerId),
          })}
        </PortalModal>
      )}
      <Styled.TopWrapper>
        <div className="col">
          <div className="col-title">배너순서</div>
          <div>{item.activated ? `${activatedIndex}번` : "없음"}</div>
        </div>
        <div className="col">
          <div className="col-title">활성여부</div>
          <div className="badge">
            <Badge
              content={activated ? "활성" : "비활성"}
              backgroundColor={
                activated ? color.Colorebfdee : color.Colore0e5e8
              }
              color={activated ? color.Color0f8623 : color.Color777777}
              width="60px"
              height="22px"
            />
          </div>
        </div>
        <div className="col">
          <div className="col-title">연결 페이지</div>
          <div>
            {item.linkType === "beverage"
              ? "맥주상품"
              : item.linkType === "lesson"
              ? "교육자료"
              : item.linkType === "notice"
              ? "공지사항"
              : item.linkType === "url"
              ? "외부링크"
              : "-"}
          </div>
        </div>
      </Styled.TopWrapper>
      <img src={item.fileUrl} className="banner-img" alt="banner-img" />
      <Styled.BottomWrapper>
        <div className="button" onClick={handleClickDelete}>
          삭제
        </div>
        <div>|</div>
        <div className="button" onClick={handleClickUpdate}>
          수정
        </div>
      </Styled.BottomWrapper>
    </Styled.Root>
  );
}
