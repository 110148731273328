import { useState, useCallback } from "react";

const useOneOfThem = () => {
  const [activeTarget, setActiveTarget] = useState(null);

  const handleOneOfThem = useCallback(
    (id) => () => {
      if (id === activeTarget) {
        handleUnselectNotice();
        return;
      }
      activeTarget && handleUnselectNotice();
      handleSelectNotice(id);
    },
    [activeTarget]
  );

  const handleSelectNotice = (id) => {
    setActiveTarget(id);
  };

  const handleUnselectNotice = () => {
    setActiveTarget(null);
  };

  return {
    activeTarget,
    handleOneOfThem,
  };
};

export default useOneOfThem;
