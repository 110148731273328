import { ax } from "api/index";

export const getHeadquarterInfo = () => async () => {
  return await ax.get("/headquarter/info");
};

export const postHeadquarterPassword = (body) => async () => {
  return await ax.post("/headquarter/password", body);
};

export const putHeadquarterInfo = (body) => async () => {
  return await ax.put("/headquarter/info", body);
};

export const putHeadquarterPassword = (body) => async () => {
  return await ax.put("/headquarter/password", body);
};
