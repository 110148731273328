import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: calc(
      100% - 57px
    ); // 맨오른쪽 sideMenu 크기만큼 뺌. 약간의 노가다 필요
    padding-left: 22px;
    border-bottom: 1px solid ${color.Coloredf1f2};
    background-color: ${color.Colorf9fbfb};
    display: grid;
    grid-template-columns: ${(props) =>
      `${props.gridTemplateList[0]} ${props.gridTemplateList[1]} ${props.gridTemplateList[2]} ${props.gridTemplateList[3]} 57px`};
    .grid {
      padding: 20px 0 20px 10px;
      background-color: ${color.Colorf9fbfb};
      font-size: 13px;
      height: 100%;

      .title {
        color: ${color.Color777777};
      }
      .content {
        color: ${color.Color12171d};
      }
    }
    .one-row {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }
    .two-row {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
    .${(props) => props.columns[0].key} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  `,
};
export default Styled;
