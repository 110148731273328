import styled from "styled-components";
import { ZINDEX } from "asset/data/common";
const Styled = {
  ModalStyle: styled.div`
    position: relative;
    .modal {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: ${ZINDEX.modal};
    }
    .modal-wrap {
      display: flex;
      justify-content: center;
      /* min-width: 1024px; */
    }
  `,
};
export default Styled;
