import { calWidthRatio } from "util/widthRatio";
import { beverageRatingList } from "./selectList";

// 매장
const pubsWidthArr = [
  "260px",
  "130px",
  "80px",
  "80px",
  "180px",
  "150px",
  "150px",
];

export const pubsColumn = [
  {
    label: "매장명",
    key: "pubName",
    type: "sortAble",
    minWidth: pubsWidthArr[0],
    width: calWidthRatio(pubsWidthArr[0], pubsWidthArr),
  },
  {
    label: "분류1",
    key: "franchiseName",
    minWidth: pubsWidthArr[1],
    width: calWidthRatio(pubsWidthArr[1], pubsWidthArr),
  },
  {
    label: "분류2",
    key: "franchise",
    type: "franchise",
    minWidth: pubsWidthArr[2],
    width: calWidthRatio(pubsWidthArr[2], pubsWidthArr),
  },
  {
    label: "상태",
    key: "status",
    type: "status",
    minWidth: pubsWidthArr[3],
    width: calWidthRatio(pubsWidthArr[3], pubsWidthArr),
  },
  {
    label: "지역",
    key: "area",
    minWidth: pubsWidthArr[4],
    width: calWidthRatio(pubsWidthArr[4], pubsWidthArr),
  },
  {
    label: "도매사",
    key: "vendors",
    type: "vendors",
    minWidth: pubsWidthArr[5],
    width: calWidthRatio(pubsWidthArr[5], pubsWidthArr),
  },
  {
    label: "제조사",
    key: "breweries",
    type: "breweries",
    minWidth: pubsWidthArr[6],
    width: calWidthRatio(pubsWidthArr[6], pubsWidthArr),
  },
];

// 도매사
const vendorWidthArr = ["220px", "150px", "120px", "200px", "170px", "170px"];
export const vendorColumn = [
  {
    label: "도매사명",
    key: "vendorName",
    type: "sortAble",
    minWidth: vendorWidthArr[0],
    width: calWidthRatio(vendorWidthArr[0], vendorWidthArr),
  },
  {
    label: "도매면허구분",
    key: "license",
    type: "license",
    minWidth: vendorWidthArr[1],
    width: calWidthRatio(vendorWidthArr[1], vendorWidthArr),
  },
  {
    label: "상태",
    key: "status",
    type: "status",
    minWidth: vendorWidthArr[2],
    width: calWidthRatio(vendorWidthArr[2], vendorWidthArr),
  },
  {
    label: "납품지역",
    key: "area",
    minWidth: vendorWidthArr[3],
    width: calWidthRatio(vendorWidthArr[3], vendorWidthArr),
  },
  {
    label: "담당자",
    key: "managerName",
    type: "managerName",
    minWidth: vendorWidthArr[4],
    width: calWidthRatio(vendorWidthArr[4], vendorWidthArr),
  },
  {
    label: "연락처",
    key: "managerPhone",
    type: "phone",
    minWidth: vendorWidthArr[5],
    width: calWidthRatio(vendorWidthArr[5], vendorWidthArr),
  },
];

// 제조사
const breweryWidthArr = ["220px", "130px", "100px", "250px", "160px", "170px"];
export const breweryColumn = [
  {
    label: "제조사명",
    key: "breweryName",
    type: "sortAble",
    minWidth: breweryWidthArr[0],
    width: calWidthRatio(breweryWidthArr[0], breweryWidthArr),
  },
  {
    label: "거래면허구분",
    key: "license",
    minWidth: breweryWidthArr[1],
    width: calWidthRatio(breweryWidthArr[1], breweryWidthArr),
  },
  {
    label: "상태",
    key: "status",
    type: "status",
    minWidth: breweryWidthArr[2],
    width: calWidthRatio(breweryWidthArr[2], breweryWidthArr),
  },
  {
    label: "주소",
    key: "addr",
    minWidth: breweryWidthArr[3],
    width: calWidthRatio(breweryWidthArr[3], breweryWidthArr),
    nowrap: true,
  },
  {
    label: "담당자",
    key: "managerName",
    minWidth: breweryWidthArr[4],
    width: calWidthRatio(breweryWidthArr[4], breweryWidthArr),
  },
  {
    label: "연락처",
    key: "managerPhone",
    type: "phone",
    minWidth: breweryWidthArr[5],
    width: calWidthRatio(breweryWidthArr[5], breweryWidthArr),
  },
];

// 맥주상품
const beverageWidthArr = ["250px", "200px", "160px", "180px", "120px", "140px"];
export const beverageColumn = [
  {
    label: "상품명",
    key: "beverageName",
    type: "sortAble",
    minWidth: beverageWidthArr[0],
    width: calWidthRatio(beverageWidthArr[0], beverageWidthArr),
    thumbnail: true,
  },
  {
    label: "제조사",
    key: "breweryName",
    minWidth: beverageWidthArr[1],
    width: calWidthRatio(beverageWidthArr[1], beverageWidthArr),
  },
  {
    label: "분류",
    key: "category",
    minWidth: beverageWidthArr[2],
    width: calWidthRatio(beverageWidthArr[2], beverageWidthArr),
  },
  {
    label: "스타일",
    key: "style",
    minWidth: beverageWidthArr[3],
    width: calWidthRatio(beverageWidthArr[3], beverageWidthArr),
  },
  {
    label: "도수",
    key: "abv",
    minWidth: beverageWidthArr[4],
    width: calWidthRatio(beverageWidthArr[4], beverageWidthArr),
  },
  {
    label: "레이팅",
    key: "rating",
    minWidth: beverageWidthArr[5],
    width: calWidthRatio(beverageWidthArr[5], beverageWidthArr),
    RatingList: beverageRatingList(),
  },
];
const transactionWidthArr = [
  "120px",
  "100px",
  "100px",
  "150px",
  "150px",
  "270px",
  "100px",
  "100px",
];

export const transactionColumn = [
  {
    label: "주문번호",
    key: "orderNo",
    minWidth: transactionWidthArr[0],
    width: calWidthRatio(transactionWidthArr[0], transactionWidthArr),
  },
  {
    label: "발주일시",
    key: "requestedDate",
    minWidth: transactionWidthArr[1],
    width: calWidthRatio(transactionWidthArr[1], transactionWidthArr),
    type: "date",
  },
  {
    label: "승인일시",
    key: "confirmedDate",
    minWidth: transactionWidthArr[2],
    width: calWidthRatio(transactionWidthArr[2], transactionWidthArr),
    type: "date",
  },
  {
    label: "발주사",
    key: "buyerName",
    minWidth: transactionWidthArr[3],
    width: calWidthRatio(transactionWidthArr[3], transactionWidthArr),
  },
  {
    label: "수주사",
    key: "sellerName",
    minWidth: transactionWidthArr[4],
    width: calWidthRatio(transactionWidthArr[4], transactionWidthArr),
  },
  {
    label: "상품명",
    key: "title",
    minWidth: transactionWidthArr[5],
    width: calWidthRatio(transactionWidthArr[5], transactionWidthArr),
  },
  {
    label: "금액",
    key: "totalPrice",
    minWidth: transactionWidthArr[6],
    width: calWidthRatio(transactionWidthArr[6], transactionWidthArr),
    type: "price",
  },
  {
    label: "진행상태",
    key: "status",
    type: "status",
    minWidth: transactionWidthArr[7],
    width: calWidthRatio(transactionWidthArr[7], transactionWidthArr),
  },
];

const linkWidthArr = ["220px", "90px", "140px", "120px", "110px"];
const linkWidthArr6 = ["120px", "100px", "110px", "140px", "100px", "100px"]; //도매사:제조사
export const linkPubAndVenderColumn = [
  {
    label: "매장명",
    key: "pubName",
    type: "sortAble",
    minWidth: linkWidthArr[0],
    width: calWidthRatio(linkWidthArr[0], linkWidthArr),
  },
  {
    label: "지역",
    key: "pubArea",
    type: "pubArea",
    minWidth: linkWidthArr[1],
    width: calWidthRatio(linkWidthArr[1], linkWidthArr),
  },
  {
    label: "도매사명",
    key: "vendorName",
    type: "sortAble",
    minWidth: linkWidthArr[2],
    width: calWidthRatio(linkWidthArr[2], linkWidthArr),
  },
  {
    label: "납품지역",
    key: "vendorArea",
    minWidth: linkWidthArr[3],
    width: calWidthRatio(linkWidthArr[3], linkWidthArr),
  },
  {
    label: "담당자",
    key: "vendorManagerName",
    minWidth: linkWidthArr[4],
    width: calWidthRatio(linkWidthArr[4], linkWidthArr),
  },
];
export const linkPubAndBeverageColumn = [
  {
    label: "매장명",
    key: "pubName",
    type: "sortAble",
    minWidth: linkWidthArr[0],
    width: calWidthRatio(linkWidthArr[0], linkWidthArr),
  },
  {
    label: "지역",
    key: "pubArea",
    minWidth: linkWidthArr[1],
    width: calWidthRatio(linkWidthArr[1], linkWidthArr),
  },
  {
    label: "제조사명",
    key: "breweryName",
    type: "sortAble",
    minWidth: linkWidthArr[2],
    width: calWidthRatio(linkWidthArr[2], linkWidthArr),
  },
  {
    label: "주소",
    key: "breweryAddr",
    type: "addr",
    minWidth: linkWidthArr[3],
    width: calWidthRatio(linkWidthArr[3], linkWidthArr),
  },
  {
    label: "담당자",
    key: "breweryManagerName",
    minWidth: linkWidthArr[4],
    width: calWidthRatio(linkWidthArr[4], linkWidthArr),
  },
];
export const linkVenderAndBreweryColumn = [
  {
    label: "도매사",
    key: "vendorName",
    type: "sortAble",
    minWidth: linkWidthArr6[0],
    width: calWidthRatio(linkWidthArr6[0], linkWidthArr6),
  },
  {
    label: "도매면허",
    key: "franchise",
    minWidth: linkWidthArr6[1],
    width: calWidthRatio(linkWidthArr6[1], linkWidthArr6),
  },
  {
    label: "납품지역",
    key: "vendorArea",
    minWidth: linkWidthArr6[2],
    width: calWidthRatio(linkWidthArr6[2], linkWidthArr6),
  },
  {
    label: "제조사명",
    key: "breweryName",
    type: "sortAble",
    minWidth: linkWidthArr6[3],
    width: calWidthRatio(linkWidthArr6[3], linkWidthArr6),
  },
  {
    label: "거래면허",
    key: "license",
    minWidth: linkWidthArr6[4],
    width: calWidthRatio(linkWidthArr6[4], linkWidthArr6),
  },
  {
    label: "주소",
    key: "breweryAddr",
    type: "addr",
    minWidth: linkWidthArr6[5],
    width: calWidthRatio(linkWidthArr6[5], linkWidthArr6),
  },
];
