import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Styled from "./style";
import { patchLogout } from "api/login/index";
import { getPresigned } from "api/presigend/index";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { clearResults, setProfileImg } from "store/reducer/commonSlice";
import { token } from "util/helper/token";
import { userStorage } from "util/helper/storage";

export default function UserInfo(props) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  let ref = useRef();
  const dispatch = useDispatch();
  const { userProfileImg, filePath } = useSelector((state) => ({
    userProfileImg: state.common.userProfileImg,
    filePath: state.common.filePath,
  }));
  const getProfileImg = async () => {
    try {
      if (filePath) {
        const res = await dispatch(API(getPresigned(filePath)));
        const {
          data: { signedUrl },
        } = res.payload;
        dispatch(setProfileImg(signedUrl));
      }
    } catch (err) {
      console.error(err);
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const handleMove = () => {
    history.push("/account");
    setVisible(false);
  };
  const handleLogout = async () => {
    const res = await dispatch(API(patchLogout()));
    if (res.payload.status === 200) {
      userStorage.clear();
      token.clear();

      await dispatch(clearResults()); //  로그아웃 하면서 기존에 있던 값 초기화
      history.push("/");
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const handleClickOutside = (event) => {
    const target = event.target;
    if (event.keyCode) {
      // esc 클릭시
      if (event.keyCode === 27) {
        setVisible(false);
      }
    } else if (visible && ref.current && !ref.current.contains(target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleClickOutside);
    };
  }, [visible]);

  useEffect(() => {
    getProfileImg();
  }, []);

  return (
    <Styled.Root>
      <img
        className="profile-icon"
        src={userProfileImg}
        alt="profile-icon"
        onClick={() => {
          setVisible(!visible);
        }}
      />
      {visible && (
        <Styled.ProfileDesc ref={ref}>
          <div className="desc-wrapper">
            <img
              className="profile-img"
              src={userProfileImg}
              alt="profile-img"
            />

            <div className="desc">
              <div className="name">생활맥주 본부</div>
              <div className="email">admin@dailybeer.com</div>
            </div>
          </div>
          <hr />
          <div className="button-wrapper">
            <span className="account" onClick={handleMove}>
              설정
            </span>
            <div onClick={handleLogout}>로그아웃</div>
          </div>
        </Styled.ProfileDesc>
      )}
    </Styled.Root>
  );
}
