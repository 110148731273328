import styled from "styled-components";
import InfoInput from "component/input/infoInput/index";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    padding: 34px 0;
    row-gap: 30px;
    .header {
      display: flex;
      align-items: center;
      font-size: 17px;
      margin-bottom: 24px;

      & img {
        margin-right: 4px;
      }
    }
  `,
  Wrapper: styled.div`
    display: flex;
    column-gap: 30px;
  `,
  Header: styled.div`
    font-size: 22px;
  `,
  EssentialInfo: styled.div`
    width: 750px;
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  `,
  InputWrapper: styled.div`
    display: grid;
    row-gap: 24px;
    column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "breweryName breweryName taxcode taxcode"
      "managerName managerName managerPhone managerPhone"
      "managerEmail managerEmail . ."
      "licenseTotal licenseSpecific licenseForeign status"
      "addr addr addrDetail addrDetail"
      "breweryPhone breweryPhone - -";
  `,
  LicenseWrapper: styled.div`
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    width: 330px;
    height: 472px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  `,

  RegistManagerWrapper: styled.div`
    width: 100%;
    height: 190px;
    padding: 34px 40px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};
    .inputWrapper {
      display: flex;
      column-gap: 30px;
    }
  `,

  ButtonWrapper: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,

  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,

  InfoInput: styled(InfoInput)`
    margin-bottom: 8px;
  `,
};

export default Styled;
