import { useState, useEffect, useCallback } from "react";

const useToast = (timer = 2000) => {
  const [isShow, setIsShow] = useState(false);
  const [type, setType] = useState("success"); // success | warning
  const [message, setMessage] = useState("");
  const [call, setCall] = useState(0);

  const toast = useCallback((message, type = "success") => {
    setMessage(message);
    setIsShow(true);
    setCall((prev) => prev + 1);
    setType(type);
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsShow(false);
    }, timer);

    return () => clearTimeout(timerId);
  }, [call]);

  return {
    isShow,
    type,
    message,
    toast,
  };
};

export default useToast;
