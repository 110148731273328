import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Input: styled.input`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    border-radius: 5px;
    border: ${(props) => (props.isValid ? "none" : `2px solid #ff0000`)};
    outline: none;
    margin-bottom: ${(props) => (props.isValid ? "40px" : "none")};
    &:last-child {
      margin-bottom: 0;
    }
    /* outline 테두리 */
    /* :focus {
      outline: 1px solid ${color.Colordbd1c2};
    } */
    padding: 0 14px;
    font-size: 14px;
    color: ${color.Color000000};
    font-family: "Spoqa Han Sans Neo";
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      // 자동완성 후 포커스 해제시 컬러
      -webkit-text-fill-color: ${color.Color000000};
      // 자동완성 커서 컬러
      /* caret-color: #fff; */
    }
    :focus {
      border: 1.5px solid ${color.Color121212};
      background-color: ${color.Colorffffff};
    }
    ::placeholder {
      color: ${color.Color4d4e56};
    }
  `,
};
export default Styled;
