import styled from "styled-components";
import { color } from "style/globalStyle";
import { ZINDEX } from "asset/data/common";

const Styled = {
  Root: styled.header`
    width: ${(props) => `calc(100% - ${props.width})`};
    z-index: ${ZINDEX.header};
    height: 56px;
    padding: 0 50px;
    position: fixed;
    top: 0;
    background-color: ${color.Colorffffff};
    display: flex;
    justify-content: space-between;
    box-shadow: 0 1px 4px 0 rgba(3, 7, 11, 0.08);
    z-index: ${ZINDEX.header};
    div {
      min-width: max-content; // 가로 스크롤 해도 글자 무너지지 않음
    }
  `,
  HeaderText: styled.div`
    font-size: ${({ main }) => (main ? "16px" : "14px")};
    font-weight: 500;
    line-height: 56px;
  `,
  IconWrapper: styled.div`
    display: flex;
    position: relative;
    align-items: center;
  `,
  ProfileDesc: styled.div``,
};
export default Styled;
