import React, { useCallback, useEffect } from "react";

import { Root, MenuWrapper } from "./style";
import useDialog from "hook/useDialog";

const SubMenuDialog = ({
  className,
  id,
  trigger,
  children,
  openedDialog,
  setOpenedDialog,
}) => {
  const { isOpen, modalRef, handleOpen, handleClose } = useDialog();

  const handleToggleOpen = useCallback(
    (e) => {
      isOpen ? handleCloseAndReset() : handleOpenAndOtherClose(e);
    },
    [isOpen]
  );

  const handleCloseAndReset = useCallback(() => {
    handleClose();
    if (openedDialog === id) {
      setOpenedDialog(null);
    }
  }, [handleClose, id, openedDialog, setOpenedDialog]);

  const handleOpenAndOtherClose = (e) => {
    setOpenedDialog(id);
    handleOpen(e);
  };

  useEffect(() => {
    const handleCloseWhenOutsideClick = (e) => {
      if (!isOpen) {
        return;
      }

      let targetElement = e.target;
      do {
        if (targetElement === modalRef.current) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      handleClose();
    };

    window.addEventListener("click", handleCloseWhenOutsideClick);

    return () => {
      window.removeEventListener("click", handleCloseWhenOutsideClick);
    };
  }, [handleClose, modalRef, isOpen]);

  useEffect(() => {
    if (openedDialog && id !== openedDialog) {
      handleCloseAndReset();
    }
  }, [openedDialog]);

  return (
    <>
      {typeof trigger === "function" && trigger({ handleToggleOpen, isOpen })}
      {isOpen && (
        <Root
          className={className}
          open={isOpen}
          aria-modal="true"
          tabIndex={-1}
          ref={modalRef}
        >
          <MenuWrapper>{children}</MenuWrapper>
        </Root>
      )}
    </>
  );
};

export default SubMenuDialog;
