import React from "react";

import AddButton from "component/button/addButton/index";
import ReloadButton from "component/button/reloadButton/index";
import CheckList from "component/checkList/index";
import Styled from "./style";

export default function VendorSearchBar(props) {
  const {
    searchValue,
    licenseCheck,
    placeholder,
    addBtnPath,
    addBtnContent,
    handleChangeSearchInput,
    handleCheckItem,
    handleSearch,
    handleReload,
  } = props;

  return (
    <Styled.Root>
      <Styled.Front>
        <CheckList checkList={licenseCheck} handleCheckItem={handleCheckItem} />
        <Styled.SearchInput
          searchValue={searchValue}
          placeholder={placeholder}
          handleChangeSearchInput={handleChangeSearchInput}
          handleSearch={handleSearch}
        />
        <Styled.SearchButton onClick={handleSearch} />
      </Styled.Front>
      <Styled.Back>
        <AddButton path={addBtnPath} content={addBtnContent} />
        <ReloadButton handleReload={handleReload} />
      </Styled.Back>
    </Styled.Root>
  );
}
