import ModalCancelButton from "component/button/modalCancelButton/index";
import ModalCompleteButton from "component/button/modalCompleteButton/index";
import React from "react";
import Styled from "./style";
export default function DeleteModal(props) {
  const { title, content, completeModalKey, setState, activateFunction } =
    props;

  return (
    <Styled.Root>
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Content>{content}</Styled.Content>
      </div>
      <Styled.ButtonWrapper>
        <ModalCancelButton content="닫기" setState={setState} />
        <ModalCompleteButton
          content="삭제"
          completeModalKey={completeModalKey}
          setState={setState}
          activateFunction={activateFunction}
        />
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
