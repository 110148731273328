import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";

import { TRANSACTION_TAB } from "util/constant";
import TabList from "./container/TabList";
import PubsPanel from "./container/pubs/PubsPanel";
import VendorsPanel from "./container/vendors/VendorsPanel";
const Transaction = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [currentTab, setCurrentTab] = useState(
    () => qs.tab ?? TRANSACTION_TAB.PUBS
  );

  const handleChangeTab = useCallback(
    (tab) => () => {
      setCurrentTab(tab);
      history.replace({
        search: queryString.stringify({ tab }),
      });
    },
    [history]
  );

  useEffect(() => {
    if (qs.tab) {
      setCurrentTab(qs.tab);
    }
  }, [qs]);

  const currentPanel =
    currentTab === TRANSACTION_TAB.PUBS ? <PubsPanel /> : <VendorsPanel />;

  return (
    <Wrapper>
      <TabList currentTab={currentTab} handleChangeTab={handleChangeTab} />
      {currentPanel}
    </Wrapper>
  );
};

export default Transaction;

const Wrapper = styled.section`
  padding: 17px 50px;
`;
