export const transactionDummy = [
  {
    order_number: 202111223322,
    order_date: "2021-10-21",
    accept_date: "2021-11-22",
    order_company: "생활맥주(대덕테크노밸리점)",
    obtain_order_company: "영일만상사",
    productName: [
      {
        label: "생활맥주-일반라거[500ml]",
        capacity: "KEG(20L)",
        price: "100,000",
        amount: 2,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 1,
        totalPrice: "50,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 2,
        totalPrice: "100,000",
      },
    ],
    price: "900,000",
    status: 1,
  },
  {
    order_number: 202111223322,
    order_date: "2021-11-21",
    accept_date: "2021-12-22",
    order_company: "생활맥주(대덕테크노밸리점)",
    obtain_order_company: "영일만상사",
    productName: [
      {
        label: "생활맥주-일반라거[500ml]",
        capacity: "KEG(20L)",
        price: "100,000",
        amount: 2,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 1,
        totalPrice: "50,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 4,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 4,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 4,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 4,
        totalPrice: "200,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 3,
        totalPrice: "150,000",
      },
      {
        label: "저먼클래식바이젠",
        capacity: "BOX(500ml*24)",
        price: "50,000",
        amount: 4,
        totalPrice: "200,000",
      },
    ],
    price: "1,000,000",
    status: 1,
  },
];

export const transactionOrderingDummy = [
  {
    order: "생활맥주(선유도점)",
    receive: [{ label: "두손", value: 0 }],
  },
  {
    order: "생활맥주(선릉역점)",
    receive: [
      { label: "춘천세계", value: 0 },
      { label: "영일만상사", value: 1 },
    ],
  },
];

export const transactionOrderingStateDummy = [
  { label: "전체보기", value: "" },
  { label: "진행중", value: "requested" },
  { label: "완료", value: "completed" },
  { label: "취소됨", value: "canceled" },
];

export const beverageFilterList = [
  { label: "- 전체 -", value: "" },
  { label: "라이트에일", value: "lightbeer" },
  { label: "밀맥주", value: "wheatbeer" },
  { label: "흑맥주", value: "darkbeer" },
  { label: "페일에일", value: "paleale" },
  { label: "IPA", value: "ipa" },
  { label: "일반맥주", value: "normalbeer" },
  { label: "소주", value: "soju" },
];

export const pubFilterList = [
  { label: "모든 매장", value: "" },
  { label: "직영", value: "direct" },
  { label: "가맹", value: "franch" },
  { label: "일반", value: "others" },
];

export const licenseTable = {
  licenseTotal: "종합",
  licenseSpecific: "특정",
  licenseForeign: "수입",
};
