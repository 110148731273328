import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Styled from "./style";
export default function CompleteMessage(props) {
  const { content, nextPath = false } = props;
  const history = useHistory();
  useEffect(() => {
    return () => {
      if (nextPath) {
        history.push(nextPath);
      }
    };
  }, []);

  return <Styled.Root>{content}</Styled.Root>;
}
