import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    background-color: ${color.Color121212};
    color: ${color.Colorffffff};
    border-radius: 5px;
    :hover {
      cursor: pointer;
    }
  `,
};

export default Styled;
