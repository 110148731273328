import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div.attrs(({ validation }) => ({
    border: validation
      ? `1px dashed ${color.Colorff0000}`
      : `1px dashed ${color.Colorc9c9c9}`,
  }))`
    width: 250px;
    height: ${(props) => props.height};
    border-radius: 5px;
    border: ${({ border }) => border};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    color: ${color.Color777777};
    position: relative;

    .imgwrapper {
      width: 102px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: ${color.Coloredf1f2};
    }
    .img-content {
      color: ${color.Color777777};
    }
    .preview-img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
    input {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0;
      :hover {
        cursor: pointer;
      }
    }
  `,
};

export default Styled;
