import React, { useEffect } from "react";
import Styled from "./style";
import TabUI from "component/tabUI/index";
import { useImmer } from "use-immer";
import TabView from "../tabView/index";
import CreateLinkModal from "container/modal/createLinkModal/index";
import { useDispatch } from "react-redux";
import { API } from "store/reducer/apiSlice";
import {
  deleteLinksBreweriesbreweryIdPubsPubId,
  deleteLinksBreweriesBreweryIdVendorsVendorId,
  deleteLinksVendorsVendorIdPubsPubId,
  getBreweriesAndPubs,
  getBreweriesAndVendors,
  getVendorsAndPubs,
} from "api/link/index";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import useTableData from "hook/useTableData";
import { useCallback } from "react";

const tabLabel = [
  {
    firstName: { label: "매장", value: "pub" },
    lastName: { label: "도매사", value: "vendor" },
  },
  {
    firstName: { label: "매장", value: "pub" },
    lastName: { label: "제조사", value: "brewery" },
  },
  {
    firstName: { label: "도매사", value: "vendor" },
    lastName: { label: "제조사", value: "brewery" },
  },
];

const initState = {
  menu: 0 /* tabUI 메뉴 선택 번호 0 = "매장:도매사" 1 = "매장:제조사" 2 = "도매사:제조사" */,
  datas: [], //테이블 데이터
  selectData: [], //테이블에서 선택한 데이터
  isChoiceList: {
    // 링크 생성 모달에서 + 버튼 클릭시 나오는 리스트 boolean 값
    first: false,
    second: false,
  },
  pagination: {
    datas: [],
    selectedPage: 1, //  선택한 페이지
    maxPageNum: 10, //  pagination에 표시할 개수: 10개로 고정
    pageList: [],
    totalPages: 0,
    totalCounts: 0,
    maxListCount: 10, //  한 목록에 보여줄 최대 개수
    pageInfo: {},
  },
  modal: {
    modalVisible: false,
  },
  searchBarData: {
    searchValue: "",
    reload: false,
    submitToggle: false,
  },
};

export default function Link({ match }) {
  const [state, setState] = useImmer(initState);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const config = {
    name: query.name || "",
    page: query.page || 1,
    perPage: query.perPage || null,
    sortPubs: query.sortPubs || "",
    sortVendors: query.sortVendors || "",
    sortBreweries: query.sortBreweries || "",
    sortPrior: state.sortPrior || "",
  };
  const apiHandler = async (config) => {
    let res = null;
    if (state.menu === 0) {
      res = await dispatch(API(getVendorsAndPubs(config)));
      res.payload.data.links.forEach((v) => {
        v.id = [v.vendorId, v.pubId];
      });
    } else if (state.menu === 1) {
      res = await dispatch(API(getBreweriesAndPubs(config)));
      res.payload.data.links.forEach((v) => {
        v.id = [v.breweryId, v.pubId];
      });
    } else if (state.menu === 2) {
      res = await dispatch(API(getBreweriesAndVendors(config)));
      res.payload.data.links.forEach((v) => {
        v.id = [v.breweryId, v.vendorId];
      });
    }

    return {
      tableData: res.payload.data.links,
      pageInfo: res.payload.data.pageInfo,
      status: res.payload.status,
    };
  };

  const { tableData, pageInfo, getTableData, handlePagination } = useTableData(
    apiHandler,
    config,
    match.pathname
  );

  const handleChangeTab = (index) => () => {
    setState((draft) => {
      draft.menu = index;
      draft.selectData = [];
    });
  };

  const handleModalClose = () => {
    setState((draft) => {
      draft.modal.modalVisible = false;
    });
  };

  const handleChangeSearchInput = useCallback(
    (e) => {
      const { value } = e.target;
      setState((draft) => {
        draft.searchBarData.searchValue = value;
      });
    },
    [setState]
  );

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      getTableData({ name: state.searchBarData.searchValue });
    },
    [getTableData]
  );

  const handleReload = useCallback(() => {
    setState((draft) => {
      draft.searchBarData.searchValue = "";
    });
    for (let option in config) {
      config[option] = "";
    }
    getTableData(config);
  }, [getTableData, setState]);

  const handleSort = useCallback(
    (_, idx) => {
      if (state.menu === 0) {
        // 매장:도매사
        let sortPubs = config.sortPubs === "" ? "" : config.sortPubs;
        let sortVendors = config.sortVendors === "" ? "" : config.sortVendors;
        if (idx === 0) {
          // 정렬기준 매장명
          sortPubs = config.sortPubs === "desc" ? "asc" : "desc";
          getTableData({
            sortPubs,
            sortVendors,
            sortBreweries: "",
            sortPrior: "pubs",
          });
        } else if (idx === 2) {
          // 정렬기준 도매사
          sortVendors = config.sortVendors === "desc" ? "asc" : "desc";
          getTableData({
            sortPubs,
            sortVendors,
            sortBreweries: "",
            sortPrior: "vendors",
          });
        }
      } else if (state.menu === 1) {
        // 매장:제조사
        let sortPubs = config.sortPubs === "" ? "" : config.sortPubs;
        let sortBreweries =
          config.sortBreweries === "" ? "" : config.sortBreweries;
        if (idx === 0) {
          // 정렬기준 매장명
          sortPubs = config.sortPubs === "desc" ? "asc" : "desc";
          getTableData({
            sortPubs,
            sortBreweries,
            sortVendors: "",
            sortPrior: "pubs",
          });
        } else if (idx === 2) {
          // 정렬기준 제조사
          sortBreweries = config.sortBreweries === "desc" ? "asc" : "desc";
          getTableData({
            sortPubs,
            sortBreweries,
            sortVendors: "",
            sortPrior: "breweries",
          });
        }
      } else if (state.menu === 2) {
        // 도매사:제조사
        let sortVendors = config.sortVendors === "" ? "" : config.sortVendors;
        let sortBreweries =
          config.sortBreweries === "" ? "" : config.sortBreweries;
        if (idx === 0) {
          // 정렬기준 도매사
          sortVendors = config.sortVendors === "desc" ? "asc" : "desc";
          getTableData({
            sortVendors,
            sortBreweries,
            sortPubs: "",
            sortPrior: "vendors",
          });
        } else if (idx === 3) {
          // 정렬기준 제조사
          sortBreweries = config.sortBreweries === "desc" ? "asc" : "desc";
          getTableData({
            sortVendors,
            sortBreweries,
            sortPubs: "",
            sortPrior: "breweries",
          });
        }
      }
    },
    [getTableData]
  );

  const handleDelete = useCallback(
    (tabNum) => async (id, otherId) => {
      const deleteHandlerMap = new Map([
        [0, dispatch(API(deleteLinksVendorsVendorIdPubsPubId(id, otherId)))],
        [1, dispatch(API(deleteLinksBreweriesbreweryIdPubsPubId(id, otherId)))],
        [
          2,
          dispatch(
            API(deleteLinksBreweriesBreweryIdVendorsVendorId(id, otherId))
          ),
        ],
      ]);
      setState((draft) => {
        draft.menu = tabNum;
      });

      await deleteHandlerMap.get(tabNum);
      getTableData();
    },
    [dispatch]
  );

  useEffect(() => {
    for (let option in config) {
      config[option] = "";
    }
    getTableData();
    setState((draft) => {
      draft.searchBarData.searchValue = "";
    });
  }, [state.menu]);

  useEffect(() => {
    getTableData();
    if (location.search === "") {
      setState((draft) => {
        draft.searchBarData.searchValue = "";
      });
    }
  }, [location.search]);

  const pageList = {
    0: (
      <TabView
        upContentHeader="매장"
        downContentHeader="도매사"
        pageNumber={0}
        state={state}
        setState={setState}
        handlePagination={handlePagination}
        pageInfo={pageInfo}
        tableData={tableData}
        handleSort={handleSort}
        sort={query.sort}
        handleDelete={handleDelete}
      />
    ),
    1: (
      <TabView
        upContentHeader="매장"
        downContentHeader="제조사"
        pageNumber={1}
        state={state}
        setState={setState}
        handlePagination={handlePagination}
        pageInfo={pageInfo}
        tableData={tableData}
        handleSort={handleSort}
        handleDelete={handleDelete}
      />
    ),
    2: (
      <TabView
        upContentHeader="도매사"
        downContentHeader="제조사"
        pageNumber={2}
        state={state}
        setState={setState}
        handlePagination={handlePagination}
        pageInfo={pageInfo}
        tableData={tableData}
        handleSort={handleSort}
        handleDelete={handleDelete}
      />
    ),
  };

  return (
    <Styled.MainWrapper>
      {state.modal.modalVisible && (
        <CreateLinkModal
          menu={state.menu}
          handleModalClose={handleModalClose}
          modalSetState={setState}
          getTableData={getTableData}
        />
      )}
      <TabUI
        pageList={pageList}
        tabLabel={tabLabel}
        state={state}
        setState={setState}
        handleChangeTab={handleChangeTab}
        handleSearch={handleSearch}
        searchBarData={state.searchBarData}
        handleChangeSearchInput={handleChangeSearchInput}
        handleReload={handleReload}
      />
    </Styled.MainWrapper>
  );
}
