import React from "react";
import DaumPostcode from "react-daum-postcode";
import { color } from "style/globalStyle";
import Styled from "./style";

export default function DaumPostPopup(props) {
  const { addressVisible, setAddressVisible, name, setState } = props;
  const modalStyle = {
    width: "400px",
    height: "360px",
    border: `1px solid ${color.Color707070}`,
  };

  const handleAddress = (name, fullAddress) => {
    setState((draft) => {
      draft[name] = fullAddress;
    });
  };

  const handleLocationInput = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    handleAddress(name, fullAddress);
    setAddressVisible(false);
  };

  return (
    <>
      {addressVisible && (
        <Styled.Root
          onClick={() => {
            setAddressVisible(false);
          }}
        >
          <Styled.Wrapper>
            <DaumPostcode onComplete={handleLocationInput} style={modalStyle} />
            <Styled.CloseButton
              onClick={() => {
                setAddressVisible(false);
              }}
            >
              X
            </Styled.CloseButton>
          </Styled.Wrapper>
        </Styled.Root>
      )}
    </>
  );
}
