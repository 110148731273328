import styled from "styled-components";

import SelectBox from "component/selectBox/index";
import SearchButton from "component/button/searchButton/index";
import SearchInput from "component/input/searchInput/index";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    width: inherit;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  `,
  Front: styled.div`
    display: flex;
    height: inherit;
  `,
  Back: styled.div`
    display: flex;
    height: inherit;
    column-gap: 16px;
  `,
  SelectBox: styled(SelectBox)`
    && span {
      color: ${color.Color1b242b};
    }
  `,
  SearchInput: styled(SearchInput)`
    margin-left: 10px;
  `,
  SearchButton: styled(SearchButton)`
    margin-left: 8px;
  `,
};

export default Styled;
