import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background-color: ${color.Colore0e5e8};
  `,
};

export default Styled;
