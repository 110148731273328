import React, { useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import Styled from "./style";
import { API } from "store/reducer/apiSlice";
import { getLessonsByLessonId, deleteLessonsByLessonId } from "api/education";
import { extractVideoIdFromVideoUrl } from "service/vimeo";
import SideMenu from "component/sideMenu/index";
import { extractYearMonthDay } from "util/extractYearMonthDay";
import { convertFormatToSec } from "util/common";
import { formatDate } from "util/makeDataForm";

export default function EducationDetail() {
  const [detailInfo, setDetailInfo] = useImmer({
    videoUrl: "",
    title: "",
    content: "",
    created: "",
    nextLesson: null,
    nextNextLesson: null,
    prevLesson: null,
    prevPrevLesson: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const handleMoveOtherDetailPage = (id) => () => {
    history.push({
      pathname: "/education/detail",
      search: `?id=${id}`,
    });
  };

  useEffect(() => {
    async function init() {
      const {
        payload: { data },
      } = await dispatch(API(getLessonsByLessonId(id)));

      setDetailInfo((draft) => {
        draft.videoUrl = data.videoUrl;
        draft.title = data.title;
        draft.content = data.content;
        draft.created = data.created;
        draft.nextLesson = data.nextLesson;
        draft.nextNextLesson = data.nextNextLesson;
        draft.prevLesson = data.prevLesson;
        draft.prevPrevLesson = data.prevPrevLesson;
      });
    }
    init();
  }, [id, dispatch, setDetailInfo]);

  const handleDelete = useCallback(
    (id) => {
      dispatch(API(deleteLessonsByLessonId(id)));
    },
    [dispatch]
  );

  const educationCardList = useMemo(() => {
    return [
      detailInfo.prevPrevLesson,
      detailInfo.prevLesson,
      detailInfo.nextLesson,
      detailInfo.nextNextLesson,
    ].filter((item) => item);
  }, [
    detailInfo.nextLesson,
    detailInfo.nextNextLesson,
    detailInfo.prevLesson,
    detailInfo.prevPrevLesson,
  ]);

  const videoId = useMemo(() => {
    if (detailInfo.videoUrl) {
      return extractVideoIdFromVideoUrl(detailInfo.videoUrl);
    }
  }, [detailInfo.videoUrl]);

  return (
    <Styled.Root>
      <Styled.MainWrapper>
        <Styled.Video
          src={`https://player.vimeo.com/video/${videoId}`}
          width="100%"
          height="405px"
          allow="fullscreen"
          allowfullscreen
          title={`video-${detailInfo.title}`}
        />
        <Styled.Description>
          <Styled.TitleWrapper>
            <div className="title">
              <div className="title-content">{detailInfo.title}</div>
              <SideMenu
                className="education-detail"
                modalKey="deleteEduModal"
                updateHref={`/education/update?id=${id}`}
                nextPath="/education"
                handleDelete={handleDelete}
                id={id}
              />
            </div>
            <div className="title-date">
              {detailInfo.created &&
                formatDate(detailInfo.created, "YYYY년 MM월 DD일")}
            </div>
          </Styled.TitleWrapper>
          <div className="content">{detailInfo.content}</div>
        </Styled.Description>
      </Styled.MainWrapper>
      <Styled.SubWrapper>
        <Styled.EducationCardList>
          {educationCardList.map(({ title, created, lessonId, duration }) => {
            const date = created && extractYearMonthDay(created);
            return (
              <Styled.EducationCardWrapper
                key={lessonId}
                onClick={handleMoveOtherDetailPage(lessonId)}
              >
                <Styled.UpperWrapper>
                  <div className="education-card-title">{title}</div>
                  <div className="education-card-time">
                    {convertFormatToSec(duration)}
                  </div>
                </Styled.UpperWrapper>
                <div className="education-card-date">{date}</div>
              </Styled.EducationCardWrapper>
            );
          })}
        </Styled.EducationCardList>
        <Styled.ButtonWrapper>
          <button
            type="button"
            className="move-button"
            disabled={!detailInfo.prevLesson}
            onClick={handleMoveOtherDetailPage(detailInfo.prevLesson?.lessonId)}
          >
            <Styled.Arrow disabled={!detailInfo.prevLesson} />
            이전강의
          </button>
          <button
            type="button"
            className="move-button"
            disabled={!detailInfo.nextLesson}
            onClick={handleMoveOtherDetailPage(detailInfo.nextLesson?.lessonId)}
          >
            다음강의
            <Styled.Arrow next="true" disabled={!detailInfo.nextLesson} />
          </button>
        </Styled.ButtonWrapper>
      </Styled.SubWrapper>
    </Styled.Root>
  );
}
