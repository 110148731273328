import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.div`
  position: relative;
  margin-bottom: 34px;
  padding-bottom: 19px;

  & input {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid ${color.Colorc9c9c9};
    padding: 0 17px;
    font-size: 14px;
    line-height: 1.21;
    color: ${color.Color121212};

    :focus {
      border: 1px solid ${color.Color5ab1f0};
    }
  }

  & span {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    line-height: 1.25;
    color: ${color.Color777777};
  }
`;
