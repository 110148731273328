import React from "react";
import Styled from "./style";
import { handleRadio } from "hook/handler";
export default function RadioButton(props) {
  const { name, height, contents, state, setState } = props;
  const handleRadioInput = (event) => {
    const { name, value } = event.target;
    handleRadio(name, value, setState);
  };

  return (
    <Styled.Root height={height}>
      <Styled.Wrapper>
        <input
          type="radio"
          id={`${name}-${contents[0]}`}
          name={name}
          value={1}
          onChange={handleRadioInput}
          checked={
            state[name] &&
            (state[name] === "1" ||
              state[name] === 1 ||
              state[name] === true) &&
            true
          }
        />
        <label htmlFor={`${name}-${contents[0]}`}>
          <span />
          {contents[0]}
        </label>
      </Styled.Wrapper>
      <Styled.Wrapper>
        <input
          type="radio"
          id={`${name}-${contents[1]}`}
          name={name}
          value={0}
          onChange={handleRadioInput}
          checked={
            state[name] &&
            (state[name] === "0" ||
              state[name] === 0 ||
              state[name] === false) &&
            true
          }
        />
        <label htmlFor={`${name}-${contents[1]}`}>
          <span />
          {contents[1]}
        </label>
      </Styled.Wrapper>
    </Styled.Root>
  );
}
