import React, { createContext, useContext, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

import { ReactComponent as SortIcon } from "asset/image/icArrow.svg";

const TableContext = createContext();

const TABLE_ROW_ID_PREFIX = "table-row";
const TABLE_DETAIL_ROW_ID_PREFIX = "table-detail-row";

const Table = ({
  className,
  children,
  title,
  hasDetail,
  openDetailInfoRowId,
  handleOpenRowDetailInfo,
}) => {
  return (
    <Root className={className}>
      <TableContext.Provider
        value={{
          hasDetail,
          openDetailInfoRowId,
          handleOpenRowDetailInfo,
        }}
      >
        <caption className="a11y">{title}</caption>
        {children}
      </TableContext.Provider>
    </Root>
  );
};

Table.Head = function TableHeader({ children, className }) {
  return (
    <Thead className={className}>
      <Trow>{children}</Trow>
    </Thead>
  );
};

Table.HeadColumn = function TableHeaderColumn({
  column,
  sortOption,
  handleSort,
}) {
  return (
    <Th scope="col">
      {column.sortable ? (
        <SortButton
          type="button"
          name={column.key}
          data-sort={sortOption}
          onClick={handleSort}
        >
          {column.label} <SortIcon aria-label={sortOption} />
        </SortButton>
      ) : (
        column.label
      )}
    </Th>
  );
};

Table.Body = function TableBody({ children, className }) {
  return <Tbody className={className}>{children}</Tbody>;
};

Table.Row = function TableRow({ children, id }) {
  const rowId = `${TABLE_ROW_ID_PREFIX}-${id}`;
  const detailId = `${TABLE_DETAIL_ROW_ID_PREFIX}-${id}`;
  const [domReady, setDomReady] = useState(false);
  const { hasDetail, openDetailInfoRowId, handleOpenRowDetailInfo } =
    useContext(TableContext);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <Trow id={rowId}>{children}</Trow>

      {domReady &&
        hasDetail &&
        createPortal(
          <>
            <DetailOpenButton
              type="button"
              aria-controls={detailId}
              aria-expanded={openDetailInfoRowId === id}
              aria-label="디테일 정보 열기"
              onClick={handleOpenRowDetailInfo(id)}
            />
          </>,
          document.querySelector(`#${rowId} > td:first-child`)
        )}
    </>
  );
};

Table.TransactionRow = function TableRow({
  children,
  id,
  handleTransactionRow,
}) {
  const rowId = `${TABLE_ROW_ID_PREFIX}-${id}`;

  return (
    <TransactionTrow id={rowId} onClick={handleTransactionRow}>
      {children}
    </TransactionTrow>
  );
};

Table.Cell = function TableData({ children, className }) {
  return <Td className={className}>{children}</Td>;
};

export default Table;

const Root = styled.table`
  width: 100%;
  height: max-content;
  border-radius: 4px;
  border: 1px solid #e0e5e8;
`;

const Trow = styled.tr`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0 10px;

  & > * {
    padding: 0 10px;
  }
`;

const TransactionTrow = styled.tr`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 10px;
  border-bottom: 1px solid #e0e5e8;

  & > * {
    padding: 0 10px;
  }

  :hover {
    cursor: pointer;
  }
`;

const Thead = styled.thead`
  & > ${Trow} {
    border-bottom: 1px solid #e0e5e8;
    background-color: #fff;
  }
`;

const Tbody = styled.tbody`
  & > ${Trow} {
    min-height: 50px;
    &:not(:nth-of-type(10)) {
      border-bottom: 1px solid #e0e5e8;
    }
  }
  display: block;
  height: 500px;
  background-color: #fff;
  overflow-y: auto;
`;

const mixinCell = css`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.31;
`;

const Th = styled.th`
  ${mixinCell};
  font-size: 13px;
  color: #777;
`;

const Td = styled.td`
  ${mixinCell};
  min-width: 0;
  font-weight: 400;
  color: #121212;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DetailOpenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }
`;

const SortButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    margin-left: 3px;
    pointer-events: none;
  }

  &[data-sort="asc"] > svg {
    transform: rotate(180deg);
  }
`;
