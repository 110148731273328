import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    position: relative;
  `,
  Image: styled.img`
    width: 420px;
    height: 420px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -13px;
    top: -13px;
    width: 30px;
    height: 30px;
    background-color: ${color.Color121212};
    border-radius: 50%;
  `,
  CloseButton: styled.img`
    cursor: pointer;
    filter: brightness(0) invert(1);
  `,
};

export default Styled;
