import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import { Root, Content } from "./style";

const Toast = ({ className, isShow, message }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    setRender(isShow);
  }, [isShow]);

  return (
    <>
      {render &&
        createPortal(
          <Root
            className={className}
            open={render}
            role="alertdialog"
            aria-live="assertive"
            aria-labelledby="toastTitle"
          >
            <Content>
              <h2 id="toastTitle">{message}</h2>
            </Content>
          </Root>,
          document.querySelector("#modal")
        )}
    </>
  );
};

export default Toast;
