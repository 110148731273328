import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Styled from "./style";

export default function AddButton(props) {
  const { className, path, content, setState } = props;

  const history = useHistory();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (pathname === "/link") {
      setState((draft) => {
        draft.modal.modalVisible = true;
      });
    } else {
      history.push(path);
    }
  };
  return (
    <Styled.Root className={className} onClick={handleClick}>
      <Styled.PlusIcon />
      <span>{content}</span>
    </Styled.Root>
  );
}
