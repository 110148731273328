import React, { useState, useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";

import {
  CategoryWrapper,
  ButtonWrapper,
  AddBtn,
  SaveBtn,
  PlusIcon,
} from "./style";
import { API } from "store/reducer/apiSlice";
import { getBeveragesCategories, putBeveragesCategories } from "api/beverage";
import CategoryCreateModal from "container/modal/categoryCreateModal/index";
import SubLayout from "component/layout/SubLayout/index";
import BeerCategoryCard from "component/card/beerCategoryCard";
import CustomPrompt from "component/prompt/index";
import Toast from "component/toast/index";
import useToast from "hook/useToast";
import usePrompt from "hook/usePrompt";
import { modalCont } from "asset/data/modalCont";
import PortalModal from "container/modal/portalModal/index";

const BeverageCategory = () => {
  const dispatch = useDispatch();
  const [openedDialog, setOpenedDialog] = useState(null);
  const [promptState, setPromptState] = useImmer({
    modal: {
      modalVisible: false,
      modalKey: "",
      nextPath: "",
      completeContent: "",
    },
  });
  const [dbCategories, setDbCategories] = useImmer([]); // 최신화 되기전 db에 있는 스타일분류
  const [categories, setCategories] = useImmer([]);
  const { isShow, message, type, toast } = useToast();

  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList: categories,
  });
  const handleAddCategory = useCallback(
    (category, cb) => {
      if (isDuplicateCategory(category)) {
        toast("이미 존재하는 카테고리입니다.", 'warning');
        return;
      }
      setCategories((draft) => {
        draft.push({
          category: category,
          styles: [],
        });
      });
      toast("카테고리가 생성되었습니다.");
      typeof cb === "function" && cb();
    },
    [setCategories, categories]
  );

  const isDuplicateCategory = useCallback(
    (category) => {
      return categories.find((item) => item.category === category);
    },
    [categories]
  );

  const isBeforeSaveCategory = useCallback(
    (category) => {
      return !dbCategories.some((item) => item.category === category);
    },
    [dbCategories]
  );

  const handleSave = async () => {
    if (hasEmptyStyleCategory(categories)) {
      toast("스타일이 없는 카테고리는 저장할 수 없습니다.", 'warning');
      return;
    }
    const res = await dispatch(API(putBeveragesCategories(categories)));
    setDbCategories(categories);

    if (res.payload.status === 200) {
      toast("변경사항이 저장되었습니다.");
      setIsValueChanged(false);
    }
  };

  const hasEmptyStyleCategory = (categories) => {
    return categories.find((item) => item.styles.length === 0);
  };

  const getCategoryData = async () => {
    const {
      payload: {
        data: { categories },
      },
    } = await dispatch(API(getBeveragesCategories()));
    setInitList(categories);
    setDbCategories(categories);
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  useEffect(() => {
    if (Object.is(categories, dbCategories)) {
      return;
    }

    setCategories(dbCategories);
  }, [dbCategories, setCategories]);
  return (
    <>
      <CustomPrompt
        modalState={promptState}
        setModalState={setPromptState}
        isValueChanged={isValueChanged}
      />
      {promptState.modal.modalVisible && (
        <PortalModal setState={setPromptState}>
          {modalCont({
            modalKey: promptState.modal.modalKey,
            nextPath: promptState.modal.nextPath,
            completeContent: promptState.modal.completeContent,
            setState: setPromptState,
          })}
        </PortalModal>
      )}
      <SubLayout>
        <SubLayout.Header title="분류/스타일 설정">
          <ButtonWrapper>
            <CategoryCreateModal
              trigger={({ handleOpenAndFocuse, isOpen }) => (
                <AddBtn
                  type="button"
                  aria-haspopup="true"
                  aria-pressed={isOpen}
                  onClick={handleOpenAndFocuse}
                >
                  <PlusIcon />
                  분류생성
                </AddBtn>
              )}
              handleConfirm={handleAddCategory}
            />
            <SaveBtn type="button" onClick={handleSave}>
              저장하기
            </SaveBtn>
          </ButtonWrapper>
        </SubLayout.Header>
        <SubLayout.Content>
          <CategoryWrapper>
            {categories.map(({ category, styles }, idx) => (
              <BeerCategoryCard
                key={category}
                category={category}
                styles={styles}
                id={category}
                idx={idx}
                openedDialog={openedDialog}
                setOpenedDialog={setOpenedDialog}
                setCategories={setCategories}
                isDuplicateCategory={isDuplicateCategory}
                isBeforeSaveCategory={isBeforeSaveCategory}
                toast={toast}
              />
            ))}
          </CategoryWrapper>
        </SubLayout.Content>
      </SubLayout>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
};

export default BeverageCategory;
