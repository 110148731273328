import React from "react";

import { VOLUME_LIST } from "util/constant";
import { Root, Column, ColumnItem, OptionItem } from "./style";

export default function BeverageDetail({ data }) {
  const breweryVendorOptions = data.options
    .filter(
      (option) => option.sellerType === "brewery" && option.target === "vendor"
    )
    .reduce(
      (acc, cur) => ({ ...acc, [cur.volume]: cur.price.toLocaleString() }),
      {
        keg: "-",
        can: "-",
        bot: "-",
      }
    );
  const breweryPubOptions = data.options
    .filter(
      (option) => option.sellerType === "brewery" && option.target === "pub"
    )
    .reduce(
      (acc, cur) => ({ ...acc, [cur.volume]: cur.price.toLocaleString() }),
      {
        keg: "-",
        can: "-",
        bot: "-",
      }
    );
  const vendorOptionsList = data.options
    .filter((option) => option.sellerType === "vendor")
    .reduce((acc, cur) => {
      acc[cur.sellerName] = acc[cur.sellerName]
        ? { ...acc[cur.sellerName], [cur.volume]: cur.price.toLocaleString() }
        : {
            keg: "-",
            can: "-",
            bot: "-",
            [cur.volume]: cur.price.toLocaleString(),
          };

      return acc;
    }, {});

  return (
    <Root>
      <Column>
        <ColumnItem>
          <dt>제조사 -&gt; 도매사 판매 용량/가격</dt>
          <dd>
            {VOLUME_LIST.map(({ label, value }) => (
              <OptionItem key={value}>
                <dt>{label}</dt>:<dd>{breweryVendorOptions[value]}</dd>
              </OptionItem>
            ))}
          </dd>
        </ColumnItem>
      </Column>
      <Column>
        <ColumnItem>
          <dt>제조사 -&gt; 매장 판매 용량/가격</dt>
          <dd>
            {VOLUME_LIST.map(({ label, value }) => (
              <OptionItem key={value}>
                <dt>{label}</dt>:<dd>{breweryPubOptions[value]}</dd>
              </OptionItem>
            ))}
          </dd>
        </ColumnItem>
      </Column>
      <Column>
        {Object.entries(vendorOptionsList).map(([sellerName, option], idx) => (
          <ColumnItem key={idx}>
            <dt>도매사({sellerName}) -&gt; 매장 판매 용량/가격</dt>
            <dd>
              {VOLUME_LIST.map(({ label, value }) => (
                <OptionItem key={value}>
                  <dt>{label}</dt>:<dd>{option[value]}</dd>
                </OptionItem>
              ))}
            </dd>
          </ColumnItem>
        ))}
      </Column>
      <Column>
        <ColumnItem>
          <dt>특징</dt>
          <dd>{data.feature}</dd>
        </ColumnItem>
      </Column>
    </Root>
  );
}
