import React, { useState, useCallback, useEffect, useRef } from "react";

import {
  Root,
  ContentTop,
  CloseIcon,
  ContentBottom,
  RegisterBtn,
} from "./style";
import useDialog from "hook/useDialog";

const RegisterDialog = ({
  className,
  id,
  trigger,
  title,
  idx,
  handleRegister,
  openedDialog,
  setOpenedDialog,
}) => {
  const { isOpen, modalRef, handleOpen, handleClose } = useDialog();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleChangeInputValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleOpenAndOtherClose = (e) => {
    setOpenedDialog(id);
    handleOpen(e);
    setTimeout(() => inputRef.current?.focus());
  };

  const handleCloseAndReset = useCallback(() => {
    setInputValue("");
    setTimeout(() => handleClose());

    if (openedDialog === id) {
      setOpenedDialog(null);
    }
  }, [handleClose, id, openedDialog, setOpenedDialog]);

  const handleEnterRegister = (e) => {
    e.keyCode === 13 && handleClickRegisterBtn();
  };

  const handleClickRegisterBtn = () => {
    handleRegister(inputValue, handleCloseAndReset);
  };

  useEffect(() => {
    if (openedDialog && id !== openedDialog) {
      handleCloseAndReset();
    }
  }, [openedDialog, id]);

  const uid = `input__id-${id}`;

  return (
    <>
      {typeof trigger === "function" &&
        trigger({ handleOpenAndOtherClose, isOpen })}
      {isOpen && (
        <Root
          className={className}
          open={isOpen}
          aria-modal="true"
          tabIndex={-1}
          idx={idx}
          ref={modalRef}
        >
          <ContentTop>
            <label htmlFor={uid}>{title}</label>
            <button type="button" onClick={handleCloseAndReset}>
              <CloseIcon />
            </button>
          </ContentTop>
          <ContentBottom>
            <input
              type="text"
              id={uid}
              ref={inputRef}
              value={inputValue}
              onChange={handleChangeInputValue}
              onKeyDown={handleEnterRegister}
            />
            <RegisterBtn
              type="button"
              isValue={inputValue}
              onClick={handleClickRegisterBtn}
            >
              등록하기
            </RegisterBtn>
          </ContentBottom>
        </Root>
      )}
    </>
  );
};

export default RegisterDialog;
