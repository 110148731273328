export const downloadFile = (file, fileName) => {
  const URL = window.URL ?? window.webkitURL;
  const downloadUrl = URL.createObjectURL(
    new Blob([file.data], { type: file.headers["content-type"] })
  );
  download(downloadUrl, fileName);
};

const download = (url, name) => {
  const a = document.createElement("a");

  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};
