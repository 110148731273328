import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import useDatepicker from "hook/useDatepicker";
import useDialog from "hook/useDialog";
import useOnClickOutside from "hook/useOnClickOutside";
import DatepickerHeader from "./DatepickerHeader";
import DatepickerContent from "./DatepickerContent";

const Datepicker = ({
  className,
  type,
  startDate,
  endDate,
  selectedDate,
  handleSelectDate,
  trigger,
}) => {
  const {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    resetMonthYear,
  } = useDatepicker(selectedDate);

  const { isOpen, modalRef, handleOpen, handleClose } = useDialog();

  useOnClickOutside(modalRef, handleClose);

  const handleSelect = useCallback(
    (date) => () => {
      handleSelectDate(date);
      handleClose();
    },
    []
  );

  useEffect(() => {
    if (!isOpen) {
      resetMonthYear();
    }
  }, [isOpen]);

  return (
    <>
      {typeof trigger === "function" && trigger({ handleOpen, isOpen })}
      {isOpen && (
        <Wrapper
          ref={modalRef}
          className={className}
          open={isOpen}
          aria-modal="true"
          tabIndex={-1}
        >
          <h3 className="a11y">calendar</h3>
          <DatepickerHeader
            monthYear={monthYear}
            changePrevMonthYear={changePrevMonthYear}
            changeNextMonthYear={changeNextMonthYear}
          />
          <DatepickerContent
            monthYear={monthYear}
            startDate={startDate}
            type={type}
            endDate={endDate}
            selectedDate={selectedDate}
            handleSelectDate={handleSelect}
          />
        </Wrapper>
      )}
    </>
  );
};

export default Datepicker;

const Wrapper = styled.dialog`
  border-radius: 4px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
`;
