import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import Styled from "../create/style";
import { color, image } from "style/globalStyle";
import { modalCont } from "asset/data/modalCont";
import PortalModal from "container/modal/portalModal/index";
import CompanySeparateBox from "container/pub/companySeparateBox/index";
import EssentialInfo from "container/pub/essentialInfo/index";
import UploadImg from "component/uploadImg/index";
import SubmitButton from "component/button/submitButton/index";
import CustomPrompt from "component/prompt/index";
import CancelButton from "component/button/cancelButton/index";
import { getBreweryList, getVendorList } from "hook/getInfoApi";
import { setModalKey } from "hook/modal";
import { handleClickPlus } from "hook/handler";
import usePrompt from "hook/usePrompt";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { phoneNumToString } from "util/makeDataForm";
import { getPubsPubId, putPubs } from "api/pub/index";
import { makeCheckObject } from "util/makeCheckList";
import { checkAwsApiStaus } from "util/presigend";
import useToast from "hook/useToast";
import Toast from "component/toast/index";

const initSubmitState = {
  taxcode: "", //  사업자등록번호
  pubName: "", //  매장명
  pubPhone: "", //  전화번호
  addr: "", //  매장주소
  addrDetail: "", //  매장상세주소
  franchise: "", //  분류2
  franchiseName: "", //  분류1
  status: "", //  운영상태
  managerName: "", //  대표자명
  managerPhone: "", //  핸드폰번호
  managerEmail: "",
  file: "", //  사업자등록증
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};
const initEnterpriseState = {
  enterpriseList: [{ type: "select", label: "", value: "" }],
};

export default function PubUpdate() {
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [modalState, setModalState] = useImmer(initModalState);
  const [enterpriseState, setEnterpriseState] = useImmer(initEnterpriseState);
  const [vendorList, setVendorList] = useState([]);
  const [breweryList, setBreweryList] = useState([]);
  const [isValidError, setIsValidError] = useState(false);
  const [imgUrl, setImgUrl] = useState(""); //이미 등록되어 있는 사업자등록증 url 주소
  const { isShow, message, type, toast } = useToast();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = queryString.parse(location.search);

  const checkList = makeCheckObject({ ...submitState, ...enterpriseState }, [
    "",
  ]);

  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList,
  });

  const handleClickCancel = () => {
    history.push("/pub");
  };

  const handleSubmit = async () => {
    const requiredValue = Object.entries(submitState)
      .filter(([key, _]) => key !== "addrDetail")
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {});

    if (!isValidEmpty(requiredValue)) {
      toast("필수 기입 정보를 입력해 주세요", 'warning');
      setIsValidError(true);
      return;
    }

    const status = submitState.status === "0" ? "closed" : "open";
    const area = submitState.addr.split(" ")[0];
    const breweryIds = [];
    const vendorIds = [];

    enterpriseState.enterpriseList.forEach((item) => {
      const { type, value } = item;
      switch (type) {
        case "brewery":
          breweryIds.push(value);
          break;
        case "vendor":
          vendorIds.push(value);
          break;
        default:
          break;
      }
    });

    const body = {
      taxcode: submitState.taxcode,
      pubName: submitState.pubName,
      pubPhone: phoneNumToString(submitState.pubPhone),
      addr: submitState.addr,
      addrDetail: submitState.addrDetail,
      area: area,
      status: status,
      managerName: submitState.managerName,
      managerPhone: phoneNumToString(submitState.managerPhone),
      managerEmail: submitState.managerEmail,
      franchise: submitState.franchise,
      franchiseName: submitState.franchiseName,
      breweryIds: breweryIds,
      vendorIds: vendorIds,
      contentType: submitState.file?.type,
    };

    const res = await dispatch(API(putPubs(id, body)));

    if (res.payload.status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);
      if (awsStatus === 204 || awsStatus === 200) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/pub",
          "수정 정보가 저장되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);

        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const isValidEmpty = (obj) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "object" ? item.value !== "" : item !== "";
    };

    return Object.values(obj).every(hasNotEmptyValue);
  };

  const getDetailInfo = async () => {
    const {
      payload: { data },
    } = await dispatch(API(getPubsPubId(id)));

    const totalVendorList = await getVendorList(dispatch, setVendorList);
    const totalBreweryList = await getBreweryList(dispatch, setBreweryList);
    const enterpriseList = setEnterPriseList(
      totalVendorList,
      data.vendors,
      totalBreweryList,
      data.breweries
    );

    setInitList({
      taxcode: data.taxcode,
      pubName: data.pubName,
      pubPhone: data.pubPhone,
      addr: data.addr,
      addrDetail: data.addrDetail,
      franchise: data.franchise,
      franchiseName: data.franchiseName,
      status: data.status === "open" ? "1" : "0",
      managerName: data.managerName,
      managerPhone: data.managerPhone,
      managerEmail: data.managerEmail,
      enterpriseList: enterpriseList.length
        ? enterpriseList
        : [{ type: "select", label: "", value: "" }],
      file: data.fileId,
    });

    setSubmitState((draft) => {
      draft.taxcode = data.taxcode;
      draft.pubName = data.pubName;
      draft.pubPhone = data.pubPhone;
      draft.addr = data.addr;
      draft.addrDetail = data.addrDetail;
      draft.franchise = data.franchise;
      draft.franchiseName = data.franchiseName;
      draft.status = data.status === "open" ? "1" : "0";
      draft.managerName = data.managerName;
      draft.managerPhone = data.managerPhone;
      draft.managerEmail = data.managerEmail;
      draft.file = data.fileId;
    });
    setImgUrl(data.signedUrl);
  };

  const setEnterPriseList = (
    totalVendorList,
    vendors,
    totalBreweryList,
    breweries
  ) => {
    const enterPriseList = [];

    totalVendorList.forEach((item) => {
      const { label, value } = item;
      const filter = vendors.filter((vendor) => vendor.vendorId === value);

      if (filter.length > 0) {
        enterPriseList.push({ type: "vendor", label, value });
      }
    });

    totalBreweryList.forEach((item) => {
      const { label, value } = item;
      const filter = breweries.filter((brewery) => brewery.breweryId === value);
      if (filter.length > 0) {
        enterPriseList.push({ type: "brewery", label, value });
      }
    });

    setEnterpriseState((draft) => {
      draft.enterpriseList = enterPriseList;
    });
    return enterPriseList;
  };

  useEffect(() => {
    if (enterpriseState.enterpriseList.length === 0) {
      setEnterpriseState((draft) => {
        draft.enterpriseList = [{ type: "select", label: "", value: "" }];
      });
    }
  }, [enterpriseState.enterpriseList]);

  useEffect(() => {
    getDetailInfo();
  }, []);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>매장 정보를 입력해 주세요</Styled.Header>
        <Styled.Wrapper>
          <EssentialInfo
            state={submitState}
            setState={setSubmitState}
            isValidError={isValidError}
          />
          <Styled.LicenseWrapper>
            <div className="header">
              <img src={image.icBusiness} alt="business-icon" />
              <span>사업자 등록증</span>
            </div>
            <UploadImg
              content="사업자 등록증 이미지를 가져오세요"
              state={submitState.file}
              setState={setSubmitState}
              name="file"
              url={imgUrl}
              isValidError={isValidError}
            />
          </Styled.LicenseWrapper>
        </Styled.Wrapper>
        <Styled.TradeCompanyWrapper>
          <div className="header-wrapper">
            <div className="header">
              <img src={image.icPartner} alt="partner-icon" />
              <span>거래업체</span>
            </div>
            <img
              className="plus-button"
              src={image.icPlusLarge}
              alt="plus-icon"
              onClick={handleClickPlus(setEnterpriseState)}
            />
          </div>
          <Styled.CompanySeparateWrapper>
            {enterpriseState.enterpriseList.map((item, idx) => {
              return (
                <CompanySeparateBox
                  key={idx}
                  state={enterpriseState}
                  setState={setEnterpriseState}
                  data={item}
                  idx={idx}
                  vendorList={vendorList}
                  breweryList={breweryList}
                />
              );
            })}
          </Styled.CompanySeparateWrapper>
        </Styled.TradeCompanyWrapper>
        <Styled.ButtonWrapper>
          <CancelButton handleClick={handleClickCancel} />
          <SubmitButton
            content="수정 완료"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="120px"
            height="40px"
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type}/>
    </>
  );
}
