import styled, { css } from "styled-components";

import { color } from "style/globalStyle";
import { ReactComponent as PlusIconImage } from "asset/image/icPlus.svg";

export const CategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

const btnMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const AddBtn = styled.button`
  ${btnMixin};
  color: ${color.Colorffffff};
  background-color: ${color.Color1b242b};
`;

export const PlusIcon = styled(PlusIconImage)`
  margin-right: 4px;
  fill: ${color.Colorffffff};
`;

export const SaveBtn = styled.button`
  ${btnMixin};
  border: 1px solid ${color.Color323c41};
  background-color: ${color.Colore0e5e8};
`;
