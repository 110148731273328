import React, { useState } from "react";

import Styled from "./style";
import { color, image } from "style/globalStyle";
import SelectBox from "component/selectBox/index";
import RadioButton from "component/radioButton/index";
import DaumPostPopup from "component/daumPostPopup/index";
import { numToStringPhoneNum, allowDotAndNumber } from "util/makeDataForm";
import { handleChangeInput, handleChangeUnitInput } from "hook/handler";

export default function InfoInput(props) {
  const {
    className,
    title,
    state,
    setState,
    placeholder,
    name,
    inputType,
    type,
    selectList,
    selectLabel,
    value,
    height = "40px",
    radioContents,
    handleSelect,
    imgSrc,
    imageToggle,
    setImageClick,
    innerWidth,
    unit,
    disabled,
    maxLength,
    isRequired,
    max,
    explain,
    isValidError,
    validCheck,
  } = props;
  const [addressVisible, setAddressVisible] = useState(false);

  const content = () => {
    switch (inputType) {
      case "select": //  select box
        return (
          <SelectBox
            width="100%"
            innerWidth={innerWidth}
            height={height}
            selectList={selectList}
            selectLabel={selectLabel}
            value={state[name] ? state[name].label || state[name] : value}
            name={name}
            className={name}
            placeholder={placeholder}
            handleSelect={handleSelect}
            disabled={disabled}
            isValidError={isValidError && state[name] === ""}
          />
        );
      case "radio": //  라디오 버튼
        return (
          <RadioButton
            name={name}
            height={height}
            contents={radioContents}
            state={state}
            setState={setState}
            isValidError={isValidError}
          />
        );
      case "textarea": // textarea
        return (
          <Styled.TextAreaWrapper
            height={height}
            validation={isValidError && state[name] === ""}
          >
            <textarea
              placeholder={placeholder}
              name={name}
              value={state[name]}
              type={type}
              onChange={handleChangeInput(setState, maxLength)}
            />
            {maxLength && (
              <Styled.LengthCheckWrapper>
                <span>{state[name].length}</span>
                <span>{`/${maxLength}`}</span>
              </Styled.LengthCheckWrapper>
            )}
          </Styled.TextAreaWrapper>
        );
      case "address-post": //  주소 선택 했을시
        return (
          <Styled.AddressPostInput
            contentColor={state[name] ? "unset" : color.Colorb1b1b1}
            validation={isValidError && state[name] === ""}
            onClick={() => {
              setAddressVisible(!addressVisible);
            }}
          >
            <span>{state[name] ? state[name] : placeholder}</span>
            <img src={image.icSearch} alt="search-icon" />
            <DaumPostPopup
              addressVisible={addressVisible}
              setAddressVisible={setAddressVisible}
              name={name}
              setState={setState}
            />
          </Styled.AddressPostInput>
        );
      case "phone":
        return (
          <Styled.Input
            className="input-wrapper"
            placeholder={placeholder}
            name={name}
            value={numToStringPhoneNum(state[name])}
            onChange={handleChangeInput(setState)}
            height={height}
            validation={isValidError && state[name] === ""}
          />
        );
      case "unit":
        return (
          <Styled.Unit className="input-wrapper">
            <Styled.Input
              placeholder={placeholder}
              name={name}
              value={state[name] && allowDotAndNumber(state[name])}
              onChange={handleChangeUnitInput(setState, max)}
              type="number"
              height={height}
              validation={isValidError && !state[name]}
            />
            <div className="unit">{unit}</div>
          </Styled.Unit>
        );
      default:
        // type: input
        // type: number
        return (
          <div className="input-wrapper">
            <Styled.Input
              placeholder={placeholder}
              name={name}
              value={state[name]}
              type={type}
              height={height}
              data-type={inputType}
              validation={
                isRequired && isValidError && (state[name] === "" || validCheck)
              }
              onChange={handleChangeInput(setState, maxLength)}
            />
            {maxLength && (
              <Styled.LengthCheckWrapper>
                <span>{state[name].length}</span>
                <span>{`/${maxLength}`}</span>
              </Styled.LengthCheckWrapper>
            )}
            {imgSrc && (
              <img
                src={imgSrc}
                className="inner-icon"
                alt="inner-icon"
                onClick={() => {
                  setImageClick(!imageToggle);
                }}
              />
            )}
          </div>
        );
    }
  };

  return (
    <Styled.Root gridName={name} imgSrc={imgSrc}>
      <Styled.LabelWrapper className={className}>
        <div className="label">
          {title}
          {isRequired && (
            <Styled.Essential aria-label="필수값">*</Styled.Essential>
          )}
          <Styled.Explain>{explain}</Styled.Explain>
        </div>
      </Styled.LabelWrapper>
      {content()}
    </Styled.Root>
  );
}
