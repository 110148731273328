import React from "react";
import styled, { css } from "styled-components";

import DateInput from "../dateInput/DateInput";

const PeriodInput = ({ id, from, to, handleSelectDate, ...restProps }) => {
  const startUid = `${id}-start`;
  const endUid = `${id}-end`;
  return (
    <Wrapper role="group" {...restProps}>
      <label className="a11y" htmlFor={startUid}>
        시작 날짜
      </label>
      <StartDateInput
        id={startUid}
        name="from"
        value={from}
        startDate={from}
        endDate={to}
        placeholder="날짜 선택"
        handleSelectDate={handleSelectDate("from")}
      />
      <label className="a11y" htmlFor={endUid}>
        종료 날짜
      </label>
      <EndDateInput
        id={endUid}
        name="to"
        value={to}
        startDate={from}
        endDate={to}
        placeholder="날짜 선택"
        handleSelectDate={handleSelectDate("to")}
      />
    </Wrapper>
  );
};

export default PeriodInput;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const dateInputMixin = css`
  & > dialog {
    top: 34px;
  }
`;

const StartDateInput = styled(DateInput)`
  ${dateInputMixin};
  margin-right: 16px;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    right: -18px;
    width: 8px;
    height: 1px;
    background-color: #d5d8db;
  }
`;

const EndDateInput = styled(DateInput)`
  ${dateInputMixin};
  width: 100%;

  & > dialog {
    left: 0;
  }
`;
