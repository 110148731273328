import { ax } from "api/index";
export const getHeadquarterAlarms = () => async () => {
  return await ax.get(`/headquarter/alarms`);
};

export const patchHeadquarterAlarmsAll = () => async () => {
  // 전체 알림 read 처리
  return await ax.patch(`/headquarter/alarms`, {});
};

export const patchHeadquarterAlarmsId = (alarmId) => async () => {
  // 알림 한 개 read 처리
  return await ax.patch(`/headquarter/alarms/${alarmId}`, {});
};
