import axios from "axios";
import dotenv from "dotenv";

import { token } from "util/helper/token";

dotenv.config({ path: ".env" });

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const setInterceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      const accessToken = token.accessToken;
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response?.data.code === "INVALID_ACCESS_TOKEN") {
        token.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

const createInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  return setInterceptors(instance);
};

const ax = createInstance();

export { ax };
