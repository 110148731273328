import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.div`
  display: grid;
  grid-template-columns: 230px 230px 230px 1fr;
  grid-gap: 20px;
  width: 100%;
  border-bottom: 1px solid ${color.Coloredf1f2};
  padding: 0 32px;
  background-color: ${color.Colorf9fbfb};
`;
export const Column = styled.div`
  padding: 20px 0;
  &:last-child {
    margin-left: 30px;
    padding-right: 16px;
  }
`;

export const ColumnItem = styled.dl`
  font-size: 13px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & > dt {
    margin-bottom: 6px;
    line-height: 1.23;
    color: ${color.Color777777};
  }

  & > dd {
    line-height: 1.436;
    color: ${color.Color12171d};
  }
`;

export const OptionItem = styled.dl`
  display: flex;

  & dt,
  dd {
    color: ${color.Color12171d};
  }

  & dd {
    text-indent: 3px;
  }
`;
