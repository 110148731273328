import React from "react";
import Styled from "./style";

export default function Layout1110px({ children }) {
  return (
    <Styled.Root>
      <Styled.Wrapper>{children}</Styled.Wrapper>
    </Styled.Root>
  );
}
