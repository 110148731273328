import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Root, ContentTop, ButtonWrapper, ContentBottom } from "./style";
import { API } from "store/reducer/apiSlice";
import {
  deleteBeveragesCategories,
  patchBeveragesCategoriesWithName,
} from "api/beverage";
import BeerStyleList from "./beerStyleList";
import RegisterDialog from "component/dialog/registerDialog";
import SubMenuDialog from "component/dialog/subMenuDialog";
import SubMenuButton from "component/button/subMenuButton";
import PlusButton from "component/button/plusButton";
import ConfirmModal from "container/modal/confirmModal2";
import CategoryUpdateModal from "container/modal/categoryUpdateModal";

const BeerCategoryCard = ({
  id,
  category,
  styles,
  idx,
  openedDialog,
  setOpenedDialog,
  setCategories,
  isDuplicateCategory,
  isBeforeSaveCategory,
  toast,
}) => {
  const dispatch = useDispatch();

  const handleCategoryUpdate = useCallback(
    async (value) => {
      if (isDuplicateCategory(value)) {
        toast("이미 존재하는 카테고리입니다.");
        return;
      }

      if (isBeforeSaveCategory(category)) {
        setCategories((draft) => {
          const idx = draft.findIndex((item) => item.category === category);
          draft[idx].category = value;
        });
        return;
      }

      const res = await dispatch(
        API(
          patchBeveragesCategoriesWithName({
            oldCategory: category,
            newCategory: value,
          })
        )
      );

      if (res.payload.status === 200) {
        toast("카테고리 이름이 변경되었습니다.");
        setCategories((draft) => {
          const idx = draft.findIndex((item) => item.category === category);
          draft[idx].category = value;
        });
      }
    },
    [setCategories, dispatch, category]
  );

  const handleCategoryDelete = useCallback(async () => {
    if (isBeforeSaveCategory(category)) {
      setCategories((draft) => {
        const idx = draft.findIndex((item) => item.category === category);
        draft = draft.splice(idx, 1);
      });

      return;
    }
    const res = await dispatch(API(deleteBeveragesCategories(category)));

    switch (res.payload.status) {
      case 200:
        setCategories((draft) => {
          const idx = draft.findIndex((item) => item.category === category);
          draft = draft.splice(idx, 1);
        });
        toast("카테고리가 삭제되었습니다.");
        return;

      case 419:
        //TODO 처리필요
        toast("해당 분류/스타일을 사용하는 주류가 있어 삭제할 수 없습니다.");
        return;

      default:
        break;
    }

    if (res.payload.status === 200) {
      setCategories((draft) => {
        const idx = draft.findIndex((item) => item.category === category);
        draft = draft.splice(idx, 1);
      });
      toast("카테고리가 삭제되었습니다.");
    }
  }, [category, dispatch, toast, setCategories]);

  const handleAddStyle = useCallback(
    (category) => (style, cb) => {
      if (!style) {
        toast("스타일을 입력해주세요.");
        return;
      }

      if (isDuplicateStyle(style)) {
        toast("이미 존재하는 스타일입니다.");
        return;
      }

      setCategories((draft) => {
        const idx = draft.findIndex((item) => item.category === category);
        draft[idx].styles.push({ style: style });
      });

      typeof cb === "function" && cb();
    },
    [setCategories, styles]
  );

  const isDuplicateStyle = (style) => {
    return [...styles].find((item) => item.style === style);
  };

  const handleDeleteStyle = useCallback(
    (category) => (style) => () => {
      setCategories((draft) => {
        const idx = draft.findIndex((item) => item.category === category);
        draft[idx].styles = draft[idx].styles.filter(
          (item) => item.style !== style
        );
      });
    },
    [setCategories]
  );

  return (
    <>
      <Root>
        <ContentTop>
          <h3>{category}</h3>
          <ButtonWrapper>
            <SubMenuDialog
              id={`submenu__id-${id}`}
              trigger={({ handleToggleOpen, isOpen }) => (
                <SubMenuButton
                  aria-label={isOpen ? "서브메뉴 닫기" : "서브메뉴 열기"}
                  aria-haspopup="true"
                  aria-pressed={isOpen}
                  onClick={handleToggleOpen}
                />
              )}
              openedDialog={openedDialog}
              setOpenedDialog={setOpenedDialog}
            >
              <CategoryUpdateModal
                trigger={({ handleOpenAndFocuse, isOpen }) => (
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-pressed={isOpen}
                    onClick={handleOpenAndFocuse}
                  >
                    이름수정
                  </button>
                )}
                category={category}
                handleConfirm={handleCategoryUpdate}
              />

              <ConfirmModal
                trigger={({ handleOpen, isOpen }) => (
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-pressed={isOpen}
                    onClick={handleOpen}
                  >
                    분류삭제
                  </button>
                )}
                handleConfirm={handleCategoryDelete}
              />
            </SubMenuDialog>
            <RegisterDialog
              id={`register__id-${id}`}
              title="신규 스타일 등록"
              idx={idx + 1}
              trigger={({ handleOpenAndOtherClose, isOpen }) => (
                <PlusButton
                  aria-label="스타일 추가"
                  aria-haspopup="true"
                  aria-pressed={isOpen}
                  onClick={handleOpenAndOtherClose}
                />
              )}
              handleRegister={handleAddStyle(category)}
              openedDialog={openedDialog}
              setOpenedDialog={setOpenedDialog}
            />
          </ButtonWrapper>
        </ContentTop>
        <ContentBottom>
          <BeerStyleList
            list={styles}
            handleDeleteStyleItem={handleDeleteStyle(category)}
          />
        </ContentBottom>
      </Root>
    </>
  );
};

export default BeerCategoryCard;
