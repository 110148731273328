import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import Date from "./Date";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const dayOfWeek = [
  { label: "일" },
  { label: "월" },
  { label: "화" },
  { label: "수" },
  { label: "목" },
  { label: "금" },
  { label: "토" },
];

const MAX_GRID_CELL = 42;

const DatepickerContent = ({
  monthYear,
  type,
  startDate,
  endDate,
  selectedDate,
  handleSelectDate,
}) => {
  const hasStartAndEndDate = !!startDate && !!endDate;
  const isFromCalendar = type === "from";

  return (
    <Wrapper>
      <Week>
        {dayOfWeek.map((day) => (
          <li key={day.label}>{day.label}</li>
        ))}
      </Week>
      <DateList>
        {[...Array(monthYear.firstDOW)].map((_, i) => (
          <Date
            key={i}
            date={monthYear.firstWeekPrevMonthDate.add(i, "d")}
            isHide={true}
            disabled
            handleSelectDate={handleSelectDate}
          />
        ))}
        {[...Array(monthYear.lastDate)].map((_, i) => {
          const date = monthYear.startDate.add(i, "d");

          return (
            <Date
              key={i}
              date={date}
              isSelectedDate={dayjs(
                `${monthYear.year}${monthYear.month}${i + 1}`
              ).isSame(selectedDate, "d")}
              highlight={
                hasStartAndEndDate &&
                date.isSameOrAfter(startDate) &&
                date.isSameOrBefore(endDate)
              }
              disabled={
                (isFromCalendar && !!endDate && date.isAfter(endDate)) ||
                (!isFromCalendar && !!startDate && date.isBefore(startDate))
              }
              isOtherDate={
                (date.isSame(startDate, "day") && type === "to") ||
                (date.isSame(endDate, "day") && type === "from")
              }
              handleSelectDate={handleSelectDate}
            />
          );
        })}
        {[
          ...Array(MAX_GRID_CELL - (monthYear.firstDOW + monthYear.lastDate)),
        ].map((_, i) => (
          <Date
            key={i}
            isHide={true}
            date={monthYear.nextMonthStartDate.add(i, "d")}
            disabled
            handleSelectDate={handleSelectDate}
          />
        ))}
      </DateList>
    </Wrapper>
  );
};

export default DatepickerContent;

const Wrapper = styled.div``;

const Week = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-template-rows: 30px;

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0 2px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.25;
    color: #919395;
    text-align: center;
  }
`;

const DateList = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  row-gap: 4px;
`;
