import styled from "styled-components";
import { color, typo } from "style/globalStyle";
import { ZINDEX } from "asset/data/common";

const Styled = {
  Root: styled.div`
    position: relative;
  `,
  SelectButton: styled.button.attrs(({ validation }) => ({
    border: validation
      ? `1px solid ${color.Colorff0000}`
      : `1px solid ${color.Colord5d8db}`,
  }))`
    ${typo.regular13};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: ${color.Colorffffff};
    border-radius: 2px;
    border: ${({ border }) => border};
    span {
      color: ${(props) =>
        props.isSelect ? color.Color1b242b : color.Colorb1b1b1};
      pointer-events: none;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    .arrow-icon {
      transform: ${(props) => props.visible && `rotate(180deg)`};
      transition: all 250ms ease-in-out;
      pointer-events: none;
    }
    outline: none;

    :disabled {
      background-color: ${color.Coloredf1f2};
      cursor: not-allowed;
    }

    & > span {
      color: ${({ isSelect }) => isSelect && "#5AB1F0"};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  SelectWrapper: styled.div`
    position: absolute;
    height: 306px;
    margin-top: 2px;
    border-radius: 5px;
    border: 1px solid ${color.Colorc9c9c9};
    background-color: ${color.Colorffffff};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    z-index: ${ZINDEX.selectBox};
  `,
  SelectInner: styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.innerWidth};
    height: 100%;
    max-height: 268px;
    overflow: auto;
    font-size: 13px;
    color: ${color.Color1b242b};
  `,
  SelectItem: styled.button.attrs(({ disabled }) => ({
    color: disabled ? color.Color777777 : "",
    cursor: disabled ? "default" : "pointer",
  }))`
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    height: ${(props) => props.height};
    line-height: ${(props) => props.height};
    padding: 0 10px 0 16px;
    text-align: left;
    color: ${({ color }) => color};
    cursor: ${({ cursor }) => cursor};

    :not([disabled]):hover {
      color: ${color.Color1f6cd5};
      background-color: ${color.Colore8f0fb};
    }

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 34px;
    padding-right: 12px;
  `,
  SubmitButton: styled.button`
    color: #5ab1f0;
    text-decoration: underline;
  `,
};

export default Styled;
