import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";

import {
  Dim,
  Dialog,
  Header,
  Content,
  ButtonWrapper,
  CancelBtn,
  ConfirmBtn,
} from "./style";
import TextCountInput from "component/input/textCountInput/index";
import useDialog from "hook/useDialog";

const MAX_VALUE_LENGTH = 20;

const CategoryCreateModal = ({ className, trigger, handleConfirm }) => {
  const inputRef = useRef(null);
  const { isOpen, modalRef, handleOpen, handleClose } = useDialog();
  const [inputValue, setInputValue] = useState("");

  const handleChangeInput = (e) => {
    const {
      target: { value },
    } = e;
    if (value.length > MAX_VALUE_LENGTH) {
      return;
    }
    setInputValue(value);
  };

  const handleOpenAndFocuse = (e) => {
    handleOpen(e);
    setTimeout(() => inputRef.current?.focus());
  };

  const handleDimClose = (e) => {
    e.stopPropagation();
    e.target === e.currentTarget && handleCancelAndResetValue();
  };

  const handleClickConfirmBtn = () => {
    handleConfirm(inputValue, handleCancelAndResetValue);
  };

  const handleCancelAndResetValue = () => {
    setInputValue("");
    setTimeout(() => handleClose());
  };

  const handleEnterConfirm = (e) => {
    e.keyCode === 13 && handleClickConfirmBtn();
  };

  return (
    <>
      {typeof trigger === "function" &&
        trigger({ handleOpenAndFocuse, isOpen })}
      {isOpen &&
        createPortal(
          <Dim hidden={!isOpen} onClick={handleDimClose}>
            <Dialog
              className={className}
              open={isOpen}
              ref={modalRef}
              tabIndex="-1"
              role="dialog"
              aria-modal="true"
              aria-labelledby="newCategory"
            >
              <Header>
                <dt>
                  <h2>신규 분류 생성</h2>
                </dt>
                <dd>
                  * 분류 생성 시 모든 도매사, 가맹점, 제조사 앱에 적용됩니다.
                </dd>
              </Header>
              <Content>
                <TextCountInput
                  ref={inputRef}
                  value={inputValue}
                  maxLength={MAX_VALUE_LENGTH}
                  placeholder="분류명을 입력해 주세요"
                  onChange={handleChangeInput}
                  onKeyDown={handleEnterConfirm}
                />
                <ButtonWrapper>
                  <CancelBtn type="button" onClick={handleCancelAndResetValue}>
                    취소
                  </CancelBtn>
                  <ConfirmBtn type="button" onClick={handleClickConfirmBtn}>
                    생성
                  </ConfirmBtn>
                </ButtonWrapper>
              </Content>
            </Dialog>
          </Dim>,
          document.querySelector("#modal")
        )}
    </>
  );
};

export default CategoryCreateModal;
