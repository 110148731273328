import styled from "styled-components";

import { color } from "style/globalStyle";
import { ReactComponent as CloseIconImage } from "asset/image/icClose.svg";

export const Root = styled.dialog.attrs(({ idx }) => ({
  translateX: idx % 4 === 0 && "translateX(-100%)",
}))`
  position: absolute;
  top: 28px;
  left: 24px;
  width: 280px;
  height: 138px;
  border-radius: 4px;
  padding: 14px;
  background-color: ${color.Colorffffff};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  transform: ${({ translateX }) => translateX};
`;

export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 2px;

  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    color: ${color.Color121212};
  }

  & button {
    width: 20px;
    height: 20px;
    transform: translateX(4px);
  }
`;

export const CloseIcon = styled(CloseIconImage)`
  fill: ${color.Colorb1b1};
`;

export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;

  & input {
    width: 100%;
    height: 34px;
    margin-bottom: 14px;
    border-radius: 4px;
    border: 1px solid ${color.Colorc9c9c9};
    padding: 0 12px;

    :focus {
      border: 1px solid ${color.Color5ab1f0};
    }
  }
`;

export const RegisterBtn = styled.button`
  align-self: flex-end;
  max-width: fit-content;
  padding: 4px;
  font-size: 14px;
  line-height: 1.21;
  color: ${({ isValue }) => (isValue ? color.Color1f6cd5 : color.Colorb1b1b1)};
`;
