import React, { useState } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";

import { modalCont } from "asset/data/modalCont";
import { vendorInfoInputList } from "asset/data/infoInputList";
import Styled from "./style";
import { color, image } from "style/globalStyle";
import PortalModal from "container/modal/portalModal/index";
import LoginInfoRegist from "container/loginInfoRegist/index";
import UploadImg from "component/uploadImg/index";
import InfoInput from "component/input/infoInput/index";
import SubmitButton from "component/button/submitButton/index";
import CustomPrompt from "component/prompt/index";
import Toast from "component/toast/index";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { postVendors } from "api/vendor/index";
import { setModalKey } from "hook/modal";
import usePrompt from "hook/usePrompt";
import { makeCryptoFunction } from "util/crypto";
import { phoneNumToString } from "util/makeDataForm";
import { makeCheckObject } from "util/makeCheckList";

import { checkAwsApiStaus } from "util/presigend";
import useToast from "hook/useToast";

const initSubmitState = {
  taxcode: "", //  사업자 등록번호
  password: { value: "", errorToggle: false }, // 로그인 비밀번호
  passwordCheck: { value: "", errorToggle: false }, // 로그인 비밀번호 확인
  vendorName: "", //  도매사명
  vendorPhone: "", //  도매사 전화번호
  addr: "", //  도매사 주소
  addrDetail: "", //  도매사 상세 주소
  area: "", //  납품지역
  licenseTotal: "1", //  거래구분(종합면허)
  licenseSpecific: "1", // 거래구분(특정면허)
  licenseForeign: "1", //  거래구문(수입면허)
  status: "1", //  영업상태
  managerName: "", //  대표자명
  managerPhone: "", //  대표자 핸드폰번호
  managerEmail: "",
  file: "", //  사업자등록증
  picName: "", //  본사 담당자명
  picPhone: "", //  본사 담당자 핸드폰번호
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};

export default function VendorCreate() {
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [modalState, setModalState] = useImmer(initModalState);
  const dispatch = useDispatch();
  const [isValidError, setIsValidError] = useState(false);
  const { isShow, message, type, toast } = useToast();
  const checkList = makeCheckObject({ ...submitState }, [""]);
  const { isValueChanged, setIsValueChanged } = usePrompt({
    checkList,
  });

  const handleSubmit = async () => {
    const requiredValue = Object.entries(submitState)
      .filter(
        ([key, _]) =>
          key !== "picName" && key !== "picPhone" && key !== "addrDetail"
      )
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {});

    if (!isValidEmpty(requiredValue)) {
      toast("필수 기입 정보를 입력해 주세요", 'warning');
      setIsValidError(true);
      return;
    }

    const licenseTotal = submitState.licenseTotal === "0" ? false : true;
    const licenseSpecific = submitState.licneseSpecific === "0" ? false : true;
    const licenseForeign = submitState.licenseForeign === "0" ? false : true;
    const status = submitState.status === "0" ? "closed" : "open";
    const cryptoedPassword = makeCryptoFunction(submitState.password.value);

    const body = {};
    body.taxcode = submitState.taxcode;
    body.password = cryptoedPassword;
    body.vendorName = submitState.vendorName;
    body.vendorPhone = phoneNumToString(submitState.vendorPhone);
    body.addr = submitState.addr;
    body.addrDetail = submitState.addrDetail;
    body.area = submitState.area;
    body.licenseTotal = licenseTotal;
    body.licenseSpecific = licenseSpecific;
    body.licenseForeign = licenseForeign;
    body.status = status;
    body.managerName = submitState.managerName;
    body.managerPhone = phoneNumToString(submitState.managerPhone);
    body.managerEmail = submitState.managerEmail;
    body.picName = submitState.picName;

    body.picPhone = phoneNumToString(submitState.picPhone);

    const res = await dispatch(API(postVendors(body)));

    if (res.payload.status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);

      if (awsStatus === 204) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/vendor",
          "도매사 정보가 등록되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const isValidEmpty = (obj) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "object" ? item.value !== "" : item !== "";
    };

    return Object.values(obj).every(hasNotEmptyValue);
  };

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>신규 도매사 정보를 입력해 주세요</Styled.Header>
        <Styled.Wrapper>
          <Styled.EssentialInfo>
            <div className="header">
              <img src={image.icEssential} alt="essential-icon" />
              <span>필수정보</span>
            </div>
            <Styled.InputWrapper>
              {vendorInfoInputList.map((item, idx) => {
                const {
                  title,
                  name,
                  inputType,
                  radioContents,
                  placeholder,
                  explain,
                  isRequired,
                } = item;
                return (
                  <Styled.InfoInput
                    key={idx}
                    title={title}
                    state={submitState}
                    setState={setSubmitState}
                    name={name}
                    inputType={inputType}
                    radioContents={radioContents}
                    placeholder={placeholder}
                    isRequired={isRequired}
                    explain={explain}
                    isValidError={isValidError}
                  />
                );
              })}
            </Styled.InputWrapper>
          </Styled.EssentialInfo>
          <Styled.LicenseWrapper>
            <div className="header">
              <img src={image.icBusiness} alt="business-icon" />
              <span>사업자 등록증</span>
              <Styled.Essential aria-label="필수값">*</Styled.Essential>
            </div>
            <UploadImg
              content="사업자 등록증 이미지를 가져오세요"
              state={submitState.file}
              setState={setSubmitState}
              name="file"
              isValidError={isValidError}
            />
          </Styled.LicenseWrapper>
        </Styled.Wrapper>
        <Styled.RegistManagerWrapper>
          <div className="header">
            <img src={image.icPerson} alt="partner-icon" />
            <span>도매사 담당자 등록</span>
          </div>
          <div className="inputWrapper">
            <InfoInput
              title="담당자명"
              state={submitState}
              setState={setSubmitState}
              placeholder="도매사의 대표 담당자를 입력해 주세요."
              name="picName"
              inputType="input"
            />
            <InfoInput
              title="담당자 연락처"
              state={submitState}
              setState={setSubmitState}
              placeholder="담당자의 연락처를 입력해 주세요."
              name="picPhone"
              inputType="phone"
            />
          </div>
        </Styled.RegistManagerWrapper>
        <LoginInfoRegist
          isValidError={isValidError}
          submitState={submitState}
          setSubmitState={setSubmitState}
        />
        <Styled.ButtonWrapper>
          <SubmitButton
            content="도매사 등록"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="120px"
            height="40px"
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type}/>
    </>
  );
}
