export const pubSelectList = [
  { label: "직영", value: "direct" },
  { label: "가맹", value: "franch" },
  { label: "일반", value: "others" },
];

export const pubValueLabel = {
  direct: "직영",
  franch: "가맹",
  others: "일반",
};

export const beverageRatingList = () => {
  const res = [];
  for (let i = 100; i > 0; i--) {
    res.push({ label: i, value: i });
  }
  return res;
};

export const pubSearchBarSelectList = [
  { label: "모든 매장", value: 0 },
  { label: "직영", value: 1 },
  { label: "가맹", value: 2 },
  { label: "일반", value: 3 },
];

export const beverageSearchBarSelectList = [
  { label: "모든 매장", value: 0 },
  { label: "직영", value: 1 },
  { label: "가맹", value: 2 },
  { label: "일반", value: 3 },
];

export const noticeSelectList = [
  { label: "전체공지", value: "all" },
  { label: "매장", value: "pub" },
  { label: "도매사", value: "vendor" },
  { label: "제조사", value: "brewery" },
];

export const noticePubSelectList = [
  { label: "전체", value: "all" },
  { label: "직영", value: "direct" },
  { label: "가맹", value: "franch" },
  { label: "일반", value: "others" },
];

export const bannerSelectList = [
  { label: "전체", value: "all" },
  { label: "맥주상품", value: "beverage" },
  { label: "교육자료", value: "lesson" },
  { label: "공지사항", value: "notice" },
  { label: "외부링크", value: "url" },
];

export const noticeValueLabel = {
  all: { label: "전체공지", value: "all" },
  pub: { label: "매장", value: "pub" },
  vendor: { label: "도매사", value: "vendor" },
  brewery: { label: "제조사", value: "brewery" },
};

export const sidoList = [
  { label: "전체", value: "all" },
  { label: "서울", value: "서울" },
  { label: "부산", value: "부산" },
  { label: "인천", value: "인천" },
  { label: "대구", value: "대구" },
  { label: "대전", value: "대전" },
  { label: "광주", value: "광주" },
  { label: "울산", value: "울산" },
  { label: "세종특별자치시", value: "세종특별자치시" },
  { label: "경기", value: "경기" },
  { label: "강원", value: "강원" },
  { label: "충북", value: "충북" },
  { label: "충남", value: "충남" },
  { label: "전북", value: "전북" },
  { label: "전남", value: "전남" },
  { label: "경북", value: "경북" },
  { label: "경남", value: "경남" },
  { label: "제주특별자치도", value: "제주특별자치도" },
];

export const volumTable = [
  { label: "용량전체", value: "all" },
  { label: "케그", value: "keg" },
  { label: "캔", value: "can" },
  { label: "병", value: "bot" },
];
