import React, { useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import ReloadButton from "component/button/reloadButton/index";
import PermissionCard from "component/card/permissionCard/index";
import Styled from "./style";
import { useDispatch } from "react-redux";
import { API } from "store/reducer/apiSlice";
import {
  getBeveragesOptionsBreweries,
  getBeveragesOptionsVendors,
} from "api/beverage/index";
import {
  dateForm,
  formatDate,
  numToStringPhoneNum,
  pubFranchise,
  setCommaToNumber,
} from "util/makeDataForm";
import { getPubsRequested } from "api/pub/index";
import Toast from "component/toast/index";
import useToast from "hook/useToast";

const initState = {
  searchBar: {
    submitToggle: false,
  },
  main: {
    pubs: [],
    vendors: [],
    breweries: [],
  },
};

export default function Permission() {
  const [state, setState] = useImmer(initState);
  const dispatch = useDispatch();
  const { isShow, message, type, toast } = useToast();

  const initPermissionData = useCallback(async () => {
    const getPubs = async () => {
      const {
        payload: {
          data: { pubs },
        },
      } = await dispatch(API(getPubsRequested()));

      return pubs.map((option) => ({
        storeName: option.pubName,
        businessRegistrationNumber: option.taxcode,
        representativeName: option.managerName,
        phoneNumber: numToStringPhoneNum(option.managerPhone),
        category1: option.franchiseName,
        category2: pubFranchise(option.franchise),
        address: option.addr + option.addrDetail,
        storePhoneNumber: numToStringPhoneNum(option.pubPhone),
        create: dateForm(option.created, "."),
        // create: formatDate(option.created, "YYYY.MM.DD HH:MM"),
        filePath: option.filePath,
        pubId: option.pubId,
        id: option.pubId,
      }));
    };
    const getVendors = async () => {
      const {
        payload: {
          data: { options },
        },
      } = await dispatch(API(getBeveragesOptionsVendors()));

      return options.map((option) => ({
        productName: option.beverageName,
        amount: option.volume,
        price: `${setCommaToNumber(String(option.price))}`,
        category1: option.category,
        style: option.style,
        alcoholPercentage: `${option.abv}%`,
        description: option.feature,
        wholesaler1: option.requestVendorName,
        wholesaler1PhoneNumber: numToStringPhoneNum(option.requestVendorPhone),
        create: formatDate(option.created, "YYYY.MM.DD HH:MM"),
        beverageId: option.beverageId,
        beverageName: option.beverageName,
        optionId: option.optionId,
        filePath: option.filePath,
        sellerId: option.sellerId,
        sellerType: option.sellerType,
        id: option.optionId,
      }));
    };

    const getBreweries = async () => {
      const {
        payload: {
          data: { options },
        },
      } = await dispatch(API(getBeveragesOptionsBreweries()));

      return options.map((option) => ({
        productName: option.beverageName,
        amount: option.volume,
        price: `${setCommaToNumber(String(option.price))}`,
        category1: option.category,
        style: option.style,
        alcoholPercentage: `${option.abv}%`,
        description: option.feature,
        wholesaler1: option.requestBreweryName,
        wholesaler1PhoneNumber: numToStringPhoneNum(option.requestBreweryPhone),
        create: formatDate(option.created, "YYYY.MM.DD HH:MM"),
        beverageId: option.beverageId,
        beverageName: option.beverageName,
        optionId: option.optionId,
        filePath: option.filePath,
        sellerId: option.sellerId,
        sellerType: option.sellerType,
        id: option.optionId,
      }));
    };

    const pubs = await getPubs();
    const vendors = await getVendors();
    const breweries = await getBreweries();

    setState((draft) => {
      draft.main.pubs = pubs;
      draft.main.vendors = vendors;
      draft.main.breweries = breweries;
    });
  }, [dispatch, setState]);

  useEffect(() => {
    initPermissionData();
  }, []);

  return (
    <>
      <Styled.Root>
        <Styled.HeaderWrapper>
          <Styled.Header>승인 대기중 목록</Styled.Header>
          <ReloadButton handleReload={initPermissionData} />
        </Styled.HeaderWrapper>
        <Styled.Wrapper>
          {/* 반복되는 것 컴포넌트 만들기 */}
          <Styled.Column type="pub">
            <div className="header">
              <div className="content">
                <strong>매장</strong>
                <span>{state.main.pubs.length}</span>
              </div>
            </div>
            <div className="body">
              <ul>
                {state.main.pubs.map((item) => (
                  <PermissionCard
                    item={item}
                    key={item.id}
                    type="pubs"
                    imageUrl={item.fileURL}
                    initPermissionData={initPermissionData}
                    toast={toast}
                  />
                ))}
              </ul>
            </div>
          </Styled.Column>
          <Styled.Column type="vendor">
            <div className="header">
              <div className="content">
                <strong>도매사</strong>
                <span>{state.main.vendors.length}</span>
              </div>
            </div>
            <div className="body">
              <ul>
                {state.main.vendors.map((item) => (
                  <PermissionCard
                    item={item}
                    key={item.id}
                    type={item.sellerType}
                    imageUrl={item.fileURL}
                    initPermissionData={initPermissionData}
                    toast={toast}
                  />
                ))}
              </ul>
            </div>
          </Styled.Column>
          <Styled.Column type="brewery">
            <div className="header">
              <div className="content">
                <strong>제조사</strong>
                <span>{state.main.breweries.length}</span>
              </div>
            </div>
            <div className="body">
              <ul>
                {state.main.breweries.map((item) => (
                  <PermissionCard
                    item={item}
                    key={item.id}
                    type={item.sellerType}
                    imageUrl={item.fileURL}
                    initPermissionData={initPermissionData}
                    toast={toast}
                  />
                ))}
              </ul>
            </div>
          </Styled.Column>
        </Styled.Wrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
