import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { API } from "store/reducer/apiSlice";
import { getBeveragesCategories } from "api/beverage";
import PeriodInput from "component/input/periodInput/PeriodInput";
import TextInput from "component/input/textInput/TextInput";
import ReloadButton from "component/button/reloadButton";
import InqueryButton from "component/button/inqueryButton/InqueryButton";
import { ReactComponent as CheckBoxIcon } from "asset/image/icCheckBoxCheck.svg";
import { ReactComponent as CheckBoxEmptyIcon } from "asset/image/icCheckBoxCheckEmpty.svg";
import SelectBox from "component/selectBox/index";
import { volumTable } from "asset/data/selectList";

const FilterSearchBar = ({
  data,
  handleClickStatus,
  handleClickDropdown,
  handleSelectDate,
  handleInquery,
  handleReload,
  handleFilter,
  handleSearchInput,
}) => {
  const dispatch = useDispatch();

  const [beverageCategoryTable, setBeverageCategoryTable] = useState({});

  useEffect(() => {
    const loadBeverageCategory = async () => {
      const {
        payload: {
          data: { categories },
        },
      } = await dispatch(API(getBeveragesCategories()));

      const table = categories.reduce(
        (acc, cur) => [...acc, { label: cur.category, value: cur.category }],
        [{ label: "상품전체", value: "all" }]
      );

      setBeverageCategoryTable(table);
    };

    loadBeverageCategory();
  }, []);

  return (
    <Wrapper>
      <CheckButtonWrapper>
        <CheckButtonLabel>
          완료
          <CheckButton type="button" onClick={handleClickStatus("completed")}>
            {data.status.includes("completed") ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxEmptyIcon />
            )}
          </CheckButton>
        </CheckButtonLabel>
        <CheckButtonLabel>
          진행
          <CheckButton type="button" onClick={handleClickStatus("requested")}>
            {data.status.includes("requested") ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxEmptyIcon />
            )}
          </CheckButton>
        </CheckButtonLabel>
        <CheckButtonLabel>
          취소
          <CheckButton type="button" onClick={handleClickStatus("canceled")}>
            {data.status.includes("canceled") ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxEmptyIcon />
            )}
          </CheckButton>
        </CheckButtonLabel>
      </CheckButtonWrapper>
      <Division />
      <FilterOption>
        <PeriodInput
          id="transaction-period"
          from={data.from}
          to={data.to}
          aria-labelledby="period"
          handleSelectDate={handleSelectDate}
        />
      </FilterOption>
      <Division />
      <OptionContainer>
        <CustomSelectBox
          placeholder="- 선택 -"
          width="120px"
          innerWidth="120px"
          name="category"
          value={data.category}
          selectList={beverageCategoryTable}
          handleFilter={handleFilter("category")}
        />
        <CustomSelectBox
          placeholder="- 선택 -"
          width="120px"
          innerWidth="120px"
          name="volume"
          value={data.volume}
          selectList={volumTable}
          handleFilter={handleFilter("volume")}
        />
        <TextInput
          id="productName"
          name="name"
          value={data.name}
          placeholder="상품명으로 검색"
          onChange={handleSearchInput}
        />
      </OptionContainer>
      <InqueryButtonWrapper>
        <InqueryButton
          disabled={!data.from || !data.to || !data.status.length}
          onClick={handleInquery}
        >
          조회
        </InqueryButton>
        <StyleReloadButton handleReload={handleReload} />
      </InqueryButtonWrapper>
    </Wrapper>
  );
};

export default FilterSearchBar;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  padding: 0 12px;
  background-color: #f6f8f8;
`;

const CheckButtonWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

const CheckButtonLabel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 13px;
`;

const CheckButton = styled.button`
  display: flex;
  align-items: center;
`;

const OptionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 8px;
  margin-right: 16px;

  & > div > button {
    width: 120px;
  }
`;

const CustomSelectBox = styled(SelectBox)`
  height: 32px;
  border: 1px solid #e0e5e8;
`;

const FilterOption = styled.div`
  & > div > div {
    width: 128px;
  }

  & > div > div:first-of-type::after {
    right: -12px;
  }
`;

const Division = styled.div`
  width: 1px;
  height: 32px;
  margin: 0 16px;
  background-color: #d5d8db;
`;

const InqueryButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyleReloadButton = styled(ReloadButton)`
  width: 32px;
  height: 32px;
  margin-left: 10px;
`;
