import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    background-color: ${color.Colorffffff};
    width: 540px;
    height: 181px;
    padding: 33px 28px 24px 32px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 20px;
    font-weight: 600;
  `,
  Main: styled.main`
    color: ${color.Color777777};
    margin-top: 8px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    button {
      width: 100px;
      height: 34px;
      border-radius: 4px;
      background-color: ${color.Color1f6cd5};
      color: ${color.Colorffffff};
    }
  `,
};
export default Styled;
