import { ax } from "api/index";

export const postBeverages = (body) => async () => {
  return await ax.post("/beverages", body);
};

export const putBeverages = (id, body) => async () => {
  return await ax.put(`/beverages/${id}`, body);
};

export const getBeverages = (req) => async () => {
  return await ax.get("/beverages", { params: { ...req } });
};

export const getBeverageNames = (req) => async () => {
  return await ax.get("/beverages/names", { params: { ...req } });
};

export const getBeveragesCategories = () => async () => {
  return await ax.get("/beverages/categories");
};

export const getBeveragesOptionsBreweries = () => async () => {
  return await ax.get("/beverages/options/breweries");
};

export const getBeveragesOptionsVendors = () => async () => {
  return await ax.get("/beverages/options/vendors");
};

export const getBeveragesStyle = (req) => async () => {
  return await ax.get("/beverages/styles", { params: { ...req } });
};

export const getBeverageDetailWithId = (id) => async () => {
  return await ax.get(`/beverages/${id}`);
};

export const patchBeveragesOptionsOptionsIdApprove = (id, body) => async () => {
  return await ax.patch(`/beverages/options/${id}/approve`, body);
};

export const patchBeveragesOptionsOptionsIdReject = (id, body) => async () => {
  return await ax.patch(`/beverages/options/${id}/reject`, body);
};

export const putBeveragesCategories = (body) => async () => {
  return await ax.put(`/beverages/categories`, body);
};

export const deleteBeveragesCategories = (category) => async () => {
  return await ax.delete(`/beverages/categories?category=${category}`);
};

export const patchBeveragesCategoriesWithName = (body) => async () => {
  return await ax.patch(`/beverages/categories/name`, body);
};

export const deleteBeveragesBeverageId = (beverageId) => async () => {
  return await ax.delete(`/beverages/${beverageId}`);
};
