import styled from "styled-components";

const Styled = {
  Root: styled.div`
    display: grid;
    grid-template-columns: 1fr 100px;
    margin-bottom: 30px;
    padding: 16px;
    background-color: #f6f8f8;
  `,
  FlexWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
  `,
  Item: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    padding: 8px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #777777;
  `,
  DeleteButton: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
  `,
  ResetButton: styled.button`
    display: flex;
    align-items: center;
    column-gap: 4px;
    width: fit-content;
    height: fit-content;
    padding: 8px 4px;
    font-size: 13px;
    color: #777777;

    svg {
      width: 16px;
      height: 16px;
      fill: red;
    }
  `,
};

export default Styled;
