export const setModalKey = (setState, modalKey, nextPath, completeContent) => {
  // modal key 설정
  setState((draft) => {
    draft.modal.modalVisible = true;
    draft.modal.modalKey = modalKey;
    draft.modal.nextPath = nextPath && nextPath;
    draft.modal.completeContent = completeContent && completeContent;
  });
};

export const setModalVisible = (setState, visible) => {
  // modal visible 설정
  setState((draft) => {
    draft.modal.modalVisible = visible;
  });
};
