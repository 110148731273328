import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  UIWrapper: styled.div``,
  TabWrapper: styled.ul`
    position: relative;
    display: flex;
    border-bottom: 2px solid #d5d8db;
    margin-bottom: 28px;
    height: 27px;
  `,
  Tab: styled.li`
    position: absolute;
    text-align: center;
    top: 0;
    left: ${(props) => props.index * 100}px;
    width: 90px;
    color: ${(props) =>
      props.index === props.state.menu ? color.Color1f6cd5 : color.Colord5d8db};
    font-weight: 500;
    margin-right: 28px;
    padding: 0px 0px 11px 0px;
    border-bottom: 2px solid
      ${(props) =>
        props.index === props.state.menu
          ? color.Color1f6cd5
          : color.Colord5d8db};

    cursor: pointer;
  `,
  PageOne: styled.div`
    display: flex;
  `,
};

export default Styled;
