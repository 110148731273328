import React, { useEffect } from "react";
import { useImmer } from "use-immer";

import Styled from "./style";
import PortalModal from "container/modal/portalModal/index";
import { modalCont } from "asset/data/modalCont";
import Navbar from "component/navbar/index";

const initModalState = {
  modal: {
    modalVisible: false,
  },
};

export default function Expired() {
  const [modalState, setModalState] = useImmer(initModalState);

  useEffect(() => {
    setModalState((draft) => {
      draft.modal.modalVisible = true;
    });
  }, []);

  return (
    <div>
      {modalState.modal.modalVisible && (
        <PortalModal>
          {modalCont({
            modalKey: "expired",
          })}
        </PortalModal>
      )}
      <Styled.Root className="authlayout-root">
        <Navbar />
      </Styled.Root>
    </div>
  );
}
