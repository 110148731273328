import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.dialog`
  position: absolute;
  top: 27px;
  left: -37px;
  border-radius: 4px;
  border: solid 1px ${color.Colorc9c9c9};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;

export const MenuWrapper = styled.div`
  width: 66px;

    & button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      padding: 0 10px;
      font-size: 12px;
      line-height: 1.25;
      color: ${color.Color12171d};
      background-color: ${color.Colorffffff};

      :hover {
        color: ${color.Color1f6cd5};
        background-color: ${color.Colore8f0fb};
      }
    }
  }
`;
