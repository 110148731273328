import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  Dialog: styled.div`
    width: 690px;
    height: 580px;
    border-radius: 4px;
    background-color: ${color.Colorf6f8f8};
    position: relative;
  `,

  Header: styled.h1`
    display: block;
    background-color: ${color.Color323c41};
    color: ${color.Colorffffff};
    font-size: 14px;
    line-height: 17px;
    border-radius: 4px 4px 0 0;
    padding: 12px 0 12px 40px;
  `,

  CloseButton: styled.button`
    position: absolute;
    top: 9px;
    right: 20px;
    width: 24px;
    height: 24px;
    background-color: ${color.Color323c41};
    border: 0;
    color: #ffffff;
    font-size: 15px;
  `,

  Main: styled.div`
    padding: 22px 40px 28px 40px;

    .content-box-wrapper {
      display: flex;
    }

    .chanin-img {
      margin-top: 11px;
    }
  `,

  SubHeader: styled.h2`
    display: block;
    font-size: 16px;
    line-height: 19px;
    color: ${color.Color323c41};
    margin-bottom: 20px;
  `,

  ContentBox: styled.div`
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 280px;
    height: 100%;
    background-color: ${color.Colorffffff};
    border: 1px solid ${color.Colord5d8db};
    border-top: 3px solid
      ${(props) =>
        props.header === "도매사"
          ? color.Color0f8623
          : props.header === "매장"
          ? color.Color121212
          : color.Color1f6cd5};
    border-radius: 4px;
    line-height: 56px;
    padding: 0 14px 0 10px;
    position: relative;

    .flex-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 14px;
    }

    .button {
      width: 24px;
      height: 24px;
      border: 0;
      background-color: inherit;
    }
  `,
  ClearAllButton: styled.button`
    font-size: 13px;
    color: ${color.Color777777};
    line-height: 16px;
    border: 0;
    background-color: inherit;
  `,
  ChainIMG: styled.img`
    margin: 0 6px 0 8px;
    line-height: 56px;
    width: 36px;
    height: 36px;
  `,
  DeleteIMG: styled.img`
    width: 18px;
    height: 18px;
    pointer-events: none;
  `,
  AddSelectButton: styled.button`
    width: 260px;
    height: 36px;
    border: 1px solid ${color.Colore0e5e8};
    border-radius: 18px;
    background-color: inherit;
    color: ${color.Colorb1b1b1};
  `,
  ChoiceItemList: styled.ul`
    max-height: 280px;
    overflow: auto;
  `,
  ChoiceItem: styled.li`
    /* width: 260px; */
    height: 36px;
    line-height: 36px;
    margin-bottom: 16px;
    padding-left: 20px;
    padding-right: 9px;
    border-radius: 18px;
    border: 1px solid ${color.Colore0e5e8};
    background-color: ${color.Coloredf1f2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    button {
      width: 18px;
      height: 18px;
      background-color: white;
    }
  `,
  ButtonWarpper: styled.div`
    width: 196px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 28px;
    right: 40px;

    .cancel-button {
      width: 80px;
      height: 34px;
      background-color: inherit;
      border: 1px solid ${color.Colorb1b1b1};
      color: ${color.Color777777};
      border-radius: 4px;
    }

    .add-button {
      width: 100px;
      height: 34px;
      background-color: inherit;
      border-radius: 4px;
      color: ${color.Colorffffff};
      background-color: ${(props) =>
        props.state.submit ? color.Color12171d : color.Colorb1b1b1};
      border: 0;
      pointer-events: ${(props) => (props.state.submit ? "unset" : "none")};
    }
  `,
};

export default Styled;
