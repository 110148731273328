import React from "react";

import { Root, Input, Sign } from "./style";

const SignInput = ({ sign, ...restProps }) => {
  return (
    <Root>
      <Input type="text" {...restProps} />
      <Sign>{sign}</Sign>
    </Root>
  );
};

export default SignInput;
