// 숫자 입력에 실시간으로 , 추가
export const checkNumberValidate = (number) => {
  const check = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  // ^: 괄호 안에서 사용하면 부정 문자셋 ex) [^0-9]
  // ^: 괄호 밖에서 사용하면 문자열의 시작을 뜻함
  number = number.replace(check, ""); // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  number = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return number;
};

export const checkDataValidate = (date) => {
  const check = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  date = date.replace(check, ""); // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  date = date.toString().replace(/(\d{4})+(\d{2})+(\d{2})$/, "$1/$2/$3");
  if (date.length > 8) {
  }
  return date;
};

// 숫자 string에서 , 빼는 것
export const checkStringNumber = (string) => {
  const number = Number(string?.replace(/\,/g, ""));
  return number;
};

export const checkPasswordValidate = (password) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 확인하는 정규식
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/;
  const checkLength = /^.{8,16}$/; //  길이 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckThreeOfTwo =
    checkList.filter((element) => true === element).length >= 2;
  // 4개 중 2개가 true 이면 true

  return !(checkLength.test(password) && boolenCheckThreeOfTwo);
};

export const checkOnlyNumber = (string) => {
  const check = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  string = string.replace(check, ""); // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  return string;
};

export const isValidAbv = (value) => {
  return +value <= 100;
};

export const isEmail = (data) => {
  if (!data) {
    return false;
  }
  const reg = RegExp(/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/);

  return reg.test(data);
};
