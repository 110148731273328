import React from "react";
import styled from "styled-components";

import { ReactComponent as DownloadIcon } from "asset/image/icDownloadBlack.svg";

const ExcelDownloadButton = ({ ...restProps }) => {
  return (
    <Wrapper type="button" aria-label="엑셀 파일 다운로드" {...restProps}>
      <DownloadIcon />
      엑셀
    </Wrapper>
  );
};

export default ExcelDownloadButton;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 2px;
  padding: 0 10px;
  font-weight: 500;
  color: #323c41;
  background-color: #e0e5e8;

  & > svg {
    margin-right: 8px;
  }
`;
