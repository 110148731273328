import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import dotenv from "dotenv";

import Router from "./router";
import { store, persistor } from "./store/index";
import { GridProvider } from "context/gridContext";
import { GlobalStyle } from "style/globalStyle";
import "style/fonts.css";

dotenv.config();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GridProvider>
        <GlobalStyle />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </GridProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
