import React from "react";

import usePagination from "hook/usePagination";
import Styled from "./style";
import { image } from "style/globalStyle";

export default function Pagination(props) {
  const { pageInfo, handlePagination, ...restProps } = props;
  const {
    pageList,
    hasPreviousBtn,
    hasNextBtn,
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage,
  } = usePagination(pageInfo);

  return (
    <Styled.Root {...restProps}>
      <Styled.NextPart>
        {hasPreviousBtn && (
          <button type="button" onClick={handleFirstPage(handlePagination)}>
            <img src={image.icDoubleArrow16} alt="previous page" />
          </button>
        )}
      </Styled.NextPart>
      <Styled.NextPart>
        {hasPreviousBtn && (
          <button type="button" onClick={handlePreviousPage(handlePagination)}>
            <img src={image.icArrow16} alt="previous page" />
          </button>
        )}
      </Styled.NextPart>
      <Styled.NumberPart>
        {pageList.map((item) => (
          <Styled.Number
            key={item}
            selected={pageInfo.currentPage === item}
            onClick={handlePagination(item)}
          >
            {item}
          </Styled.Number>
        ))}
      </Styled.NumberPart>
      <Styled.NextPart>
        {hasNextBtn && (
          <button type="button" onClick={handleNextPage(handlePagination)}>
            <img className="right" src={image.icArrow16} alt="next page" />
          </button>
        )}
      </Styled.NextPart>
      <Styled.NextPart>
        {hasNextBtn && (
          <button type="button" onClick={handleLastPage(handlePagination)}>
            <img
              className="right"
              src={image.icDoubleArrow16}
              alt="previous page"
            />
          </button>
        )}
      </Styled.NextPart>
    </Styled.Root>
  );
}
