import { useRef, useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import dayjs from "dayjs";
import queryString from "query-string";

import {
  loadPubsTransactions,
  loadPubsSellers,
  downloadPubsExcel,
} from "api/transaction";
import { downloadFile } from "util/helper/download";

const usePubsPanel = () => {
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const initFilterState = {
    status:
      typeof query.status === "string"
        ? [query.status]
        : query.status || ["completed", "requested"],
    from: query.from || "",
    to: query.to || "",
    category: query.category || "all",
    name: query.name || "",
    volume: query.volume || "all", // 필터에서 사용하는 용량
    beverageIdVolume: query.beverageIdVolume || "", // 2뎁스에서 사용하는 용량
    beverageId: query.beverageId || "", // 2 -> 3뎁스에서 사용하는 상품 id
    page: query.page || 1,
  };

  const initTransactionsState = {
    orders: [],
    pageInfo: {},
    totalPrice: "",
    totalAmount: "",
  };

  const initBeveragesState = {
    sellers: [],
    pageInfo: {},
    totalPrice: "",
    totalAmount: "",
  };

  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useImmer(initFilterState);
  const [transactions, setTransactions] = useState(initTransactionsState);
  const [beverages, setBeverages] = useState(initBeveragesState); // 왼쪽 테이블에서 row 클릭 시 해당 row id로 오른쪽 테이블 데이터 조회에 사용되는 데이터

  const clientQuery = {
    ...filterValues,
    twoDepthStatus: query.twoDepthStatus || "", // depth1에서 선택한 Row의 status값 (depth2 테이블에서 사용)
    tab: "pub-transaction",
    beverageIdVolume: "",
    beverageId: "",
  }; // 클라이언트에서 사용하는 쿼리 값

  const serverQuery = {
    ...clientQuery,
    category: filterValues.category === "all" ? "" : filterValues.category,
    volume: filterValues.volume === "all" ? "" : filterValues.volume,
    perPage: 20,
  }; // 서버 통신 할 때 사용하는 쿼리 값

  const manufactureData = (data) => ({
    ...data,
    orders: data.orders.map((v, idx) => ({
      ...v,
      no: (data.pageInfo.currentPage - 1) * 10 + idx + 1,
      price: (+v.price).toLocaleString(),
      vat: (+v.vat).toLocaleString(),
      totalPrice: (+v.totalPrice).toLocaleString(),
    })),
    totalPrice: (+data.totalPrice).toLocaleString(),
  });

  const getPubsSellers = async (id, volume, status) => {
    history.replace({
      search: queryString.stringify({
        ...serverQuery,
        beverageIdVolume: volume,
        beverageId: id,
        twoDepthStatus: status,
      }),
    });

    const {
      data: { sellers },
    } = await loadPubsSellers({
      ...serverQuery,
      volume,
      beverageId: id,
      status: [status ?? query.twoDepthStatus],
    });

    const totalAmount = sellers.reduce((acc, value) => acc + value.amount, 0);
    const totalPrice = sellers.reduce(
      (acc, value) => acc + value.totalPrice,
      0
    );

    setBeverages({
      totalAmount: (+totalAmount).toLocaleString(),
      totalPrice: (+totalPrice).toLocaleString(),
      sellers: sellers.map((seller) => ({
        ...seller,
        totalPrice: (+seller.totalPrice).toLocaleString(),
      })),
    });
  };

  const handleFilter = (type) => (value) => {
    setFilterValues((draft) => {
      draft[type] = value;
    });
  };

  const handleSearchInput = (e) => {
    setFilterValues((draft) => {
      draft.name = e.target.value;
    });
  };

  const handleClickDropdown = (event, name) => {
    const key = event?.target?.name || name;
    const value = event?.target?.value || event;

    setFilterValues((draft) => {
      draft[key] = value;
    });
  };

  const handleSelectDate = useCallback(
    (key) => (value) => {
      setFilterValues((draft) => {
        draft[key] = value;
      });
    },
    []
  );

  const handleClickStatus = (status) => () => {
    if (filterValues.status.includes(status)) {
      setFilterValues((draft) => {
        draft.status = draft.status.filter((item) => item !== status);
      });
    } else {
      setFilterValues((draft) => {
        draft.status = [...draft.status, status];
      });
    }
  };

  const handleInquery = useCallback(async () => {
    setIsLoading(true);
    setBeverages(initBeveragesState); // 검색 할 때 마다 depth2 데이터 초기화

    history.replace({
      search: queryString.stringify(clientQuery),
    });

    const { data } = await loadPubsTransactions(serverQuery);
    const newData = manufactureData(data);

    setTransactions(newData);
  }, [filterValues]);

  const handleClickBeverageList = (id, volume, status) => () => {
    setFilterValues((draft) => {
      draft.beverageId = id;
      draft.beverageIdVolume = volume;
    });

    getPubsSellers(id, volume, status);
  };

  const handleReload = useCallback(() => {
    history.replace({
      search: queryString.stringify({ tab: "pub-transaction" }),
    });
    setTransactions(initTransactionsState);
    setBeverages(initBeveragesState);
    setFilterValues(initFilterState);
  }, []);

  const handlePagination = useCallback(
    (page) => async () => {
      const reqData = {
        ...(filterValues.status.length && { status: filterValues.status }),
        ...(filterValues.from && { from: filterValues.from }),
        ...(filterValues.to && { to: filterValues.to }),
        ...(filterValues.category && filterValues.category === "all"
          ? ""
          : { category: filterValues.category }),
        ...(filterValues.name && { name: filterValues.name }),
        ...(filterValues.volume && filterValues.volume === "all"
          ? ""
          : { volume: filterValues.volume }),
        ...(filterValues.sellers && {
          sellers: filterValues.sellers.map((v) => v.id),
        }),
        page: page ?? 1,
        perPage: 20,
      };
      setIsLoading(true);
      const { data } = await loadPubsTransactions(reqData);
      const newData = manufactureData(data);

      setTransactions(newData);
    },
    [filterValues]
  ); // TODO: 2월10일 작성, 데이터 추가 후 로직 수정 필요

  const handleExcelDownload = useCallback(async () => {
    const res = await downloadPubsExcel(serverQuery);
    const now = dayjs();
    downloadFile(res, `${now.format("YYYYMMDD_HHmm")}_매장거래내역.xlsx`);
  }, [filterValues]);

  useEffect(() => {
    if (isMounted.current) {
      const loadTransactions = async () => {
        const { data } = await loadPubsTransactions(serverQuery);
        const newData = manufactureData(data);

        setTransactions(newData);
      };
      loadTransactions().then(() => setIsLoading(false));
    }

    isMounted.current = true;

    const queryCondition = { ...query };
    delete queryCondition.tab;
    if (Object.keys(queryCondition).length !== 0) {
      handleInquery();

      if (query.beverageId && query.beverageIdVolume) {
        getPubsSellers(
          query.beverageId,
          query.beverageIdVolume,
          query.twoDepthStatus
        );
      }
    }
  }, []);

  useEffect(() => {
    if (transactions) setIsLoading(false);
  }, [transactions]);

  return {
    filterValues,
    isLoading,
    transactions,
    beverages,
    handleClickStatus,
    handleClickDropdown,
    handleSelectDate,
    handleInquery,
    handleReload,
    handlePagination,
    handleExcelDownload,
    handleClickBeverageList,
    handleFilter,
    handleSearchInput,
  };
};

export default usePubsPanel;
