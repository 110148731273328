import React, { useState } from "react";
import { Root } from "./style";
import { color, image } from "style/globalStyle";
import DaumPostPopup from "component/daumPostPopup/index";
import InfoInputWrapper from "../index";

export default function AdressInfoInput(props) {
  const {
    title,
    name,
    placeholder,
    state,
    setState,
    isRequired,
    isValidError,
  } = props;
  const [addressVisible, setAddressVisible] = useState(false);
  return (
    <InfoInputWrapper title={title} name={name} isRequired={isRequired}>
      <Root
        contentColor={state[name] ? "unset" : color.Colorb1b1b1}
        validation={isValidError && state[name] === ""}
        onClick={() => {
          setAddressVisible(!addressVisible);
        }}
      >
        <span>{state[name] ? state[name] : placeholder}</span>
        <img src={image.icSearch} alt="search-icon" />
        <DaumPostPopup
          addressVisible={addressVisible}
          setAddressVisible={setAddressVisible}
          name={name}
          setState={setState}
        />
      </Root>
    </InfoInputWrapper>
  );
}
