import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    width: 500px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 4px;
    background-color: rgba(18, 18, 18, 0.7);
    color: ${color.Colorffffff};
  `,
};
export default Styled;
