import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  Wrapper: styled.div`
    width: 300px;
    height: 420px;
    background-color: ${color.Colorffffff};
    position: absolute;
    top: -3px;
    left: 284px;
    z-index: 99;
    border-radius: 4px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.3);
  `,
  HeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${color.Colord5d8db};
    padding: 16px 13px 16px 20px;
    margin-bottom: 10px;

    .button {
      width: 18px;
      height: 18px;
      background-color: white;
    }
  `,
  Header: styled.h2`
    font-size: 14px;
    line-height: 17px;
  `,
  SearchInputWrapper: styled.div`
    display: flex;
    width: 276px;
    height: 32px;
    margin: 0 auto;
    margin-bottom: 12px;
    position: relative;

    img {
      position: absolute;
      top: 6px;
      left: 8px;
      width: 20px;
      height: 20px;
    }

    input {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid ${color.Color5ab1f0};
      padding-left: 34px;
    }
  `,
  ChoiceList: styled.ul`
    height: 300px;
    overflow: auto;
  `,
  ChoiceListItem: styled.li`
    width: 276px;
    height: 40px;
    padding-left: 24px;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
    }

    input {
      display: none;
    }

    label {
      pointer-events: none;
    }

    :hover {
      background-color: ${color.Colore8f0fb};
      color: ${color.Color1f6cd5};
    }
  `,
};

export default Styled;
