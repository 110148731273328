import React, { Fragment } from "react";
import Styled from "./style";
import { image } from "style/globalStyle";
import SelectBox from "component/selectBox/index";
import { handleClickClose } from "hook/handler";

export default function CompanySeparateBox(props) {
  const { state, setState, data, idx, vendorList, breweryList } = props;

  const { type, value } = data;
  const handleClickType = (type) => () => {
    setState((draft) => {
      draft.enterpriseList[idx].type = type;
    });
  };

  const handleSelect = (_, value) => {
    setState((draft) => {
      draft.enterpriseList[idx].type = type;
      draft.enterpriseList[idx].value = value;
    });
  };

  const content = () => {
    switch (type) {
      case "vendor": //  도매사
        return (
          <Fragment>
            <Styled.Upper>
              <span>도매사 {idx + 1}</span>
              <img
                className="close-icon"
                src={image.icClose}
                alt="close-icon"
                onClick={handleClickClose(idx, state, setState)}
              />
            </Styled.Upper>

            <SelectBox
              width="100%"
              innerWidth="286px"
              height="46px"
              selectList={vendorList}
              className={`select-${idx}`}
              placeholder="- 도매사 선택 -"
              handleSelect={handleSelect}
              value={value}
            />
          </Fragment>
        );
      case "brewery": //  제조사
        return (
          <Fragment>
            <Styled.Upper>
              <span>제조사 {idx + 1}</span>
              <img
                className="close-icon"
                src={image.icClose}
                alt="close-icon"
                onClick={handleClickClose(idx, state, setState)}
              />
            </Styled.Upper>

            <SelectBox
              width="100%"
              innerWidth="286px"
              height="46px"
              selectList={breweryList}
              className={`select-${idx}`}
              placeholder="- 제조사 선택 -"
              handleSelect={handleSelect}
              value={value}
            />
          </Fragment>
        );
      default:
        //  일반
        return (
          <Fragment>
            <Styled.Upper>
              <div className="desc-wrapper">
                <span>업체 구분</span>
                <span className="desc">
                  *매장과 거래중인 업체 형태를 선택하세요
                </span>
              </div>
              <img
                className="close-icon"
                src={image.icClose}
                alt="close-icon"
                onClick={handleClickClose(idx, state, setState)}
              />
            </Styled.Upper>
            <Styled.Lower>
              <div onClick={handleClickType("vendor")}>
                <img src={image.icVendorBlack} alt="vendor-icon" />
                <span>도매사</span>
              </div>
              <div onClick={handleClickType("brewery")}>
                <img src={image.icBreweryBlack} alt="brewery-icon" />
                <span>제조사</span>
              </div>
            </Styled.Lower>
          </Fragment>
        );
    }
  };

  return (
    <Styled.Root key={idx} type={type}>
      {content()}
    </Styled.Root>
  );
}
