import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 750px;
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    .header {
      display: flex;
      column-gap: 4px;
      align-items: center;
      font-size: 17px;
      margin-bottom: 24px;
    }
  `,
  InputWrapper: styled.div`
    display: grid;
    row-gap: 24px;
    column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "pubName pubName taxcode taxcode"
      "managerName managerName managerPhone managerPhone"
      "managerEmail managerEmail . ."
      "franchiseName franchiseName franchise status"
      "addr addr addrDetail addrDetail"
      "pubPhone pubPhone - -";
    && .title {
      color: ${color.Color777777};
      margin-bottom: 8px;
      font-size: 14px;
    }
  `,
};

export default Styled;
