import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  Root: styled.div`
    position: absolute;
    top: 230px;
    left: 200px;
    z-index: 1;
  `,
  Wrapper: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  CloseButton: styled.button`
    position: absolute;
    top: -20px;
    right: 5px;
    border: 0;
    background: inherit;
    z-index: 1;
    color: ${color.Color000000};
    font-size: 16px;
  `,
};
export default Styled;
