import React, { useState } from "react";
import Styled from "./style";
import { image } from "style/globalStyle";
import { useEffect } from "react";
export default function UploadImg(props) {
  const {
    name,
    content,
    state = "",
    setState,
    height = "350px",
    url,
    isValidError = false,
  } = props;
  const [imgPreview, setImgPreview] = useState("");

  const handleImgUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) return;
    reader.onloadend = () => {
      setState((draft) => {
        draft[name] = file;
      });
      setImgPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (url) {
      setImgPreview(url);
    }
  }, [url]);

  return (
    <Styled.Root height={height} validation={isValidError && !state}>
      <input
        type="file"
        id="img-file"
        onChange={handleImgUpload}
        accept="image/*"
      />

      {imgPreview ? (
        <img className="preview-img" src={imgPreview} alt="preview-img" />
      ) : (
        <>
          <div className="img-wrapper">
            <img src={image.icDownload} alt="download-icon" />
          </div>
          <div className="img-content">{content}</div>
        </>
      )}
    </Styled.Root>
  );
}
