import React from "react";
import styled from "styled-components";

import { TRANSACTION_TAB } from "util/constant";

const TabList = ({ currentTab, handleChangeTab }) => {
  return (
    <TabWrapper role="tablist">
      <TabItem>
        <button
          type="button"
          id={`${TRANSACTION_TAB.PUBS}-anchor`}
          role="tab"
          aria-controls={TRANSACTION_TAB.PUBS}
          aria-selected={currentTab === TRANSACTION_TAB.PUBS}
          onClick={handleChangeTab(TRANSACTION_TAB.PUBS)}
        >
          매장:수주사
        </button>
      </TabItem>
      <TabItem>
        <button
          type="button"
          id={`${TRANSACTION_TAB.VENDORS}-anchor`}
          role="tab"
          aria-controls={TRANSACTION_TAB.VENDORS}
          aria-selected={currentTab === TRANSACTION_TAB.VENDORS}
          onClick={handleChangeTab(TRANSACTION_TAB.VENDORS)}
        >
          도매사:수주사
        </button>
      </TabItem>
    </TabWrapper>
  );
};

export default TabList;

const TabWrapper = styled.ul`
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #d5d8db;
  }
`;

const TabItem = styled.li`
  display: flex;
  justify-content: center;
  width: 90px;

  &:not(:first-of-type) {
    margin-left: 28px;
  }

  & > button {
    position: relative;
    width: 100%;
    padding: 9px 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.27;
    color: #b1b1b1;

    &[aria-selected="true"] {
      color: #1f6cd5;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: #1f6cd5;
        z-index: 10;
      }
    }
  }
`;
