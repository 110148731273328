import React from "react";
import { useHistory } from "react-router-dom";

import Styled from "./style";
import { image } from "style/globalStyle";
import { extractYearMonthDay } from "util/extractYearMonthDay";
import { convertFormatToSec } from "util/common";
import { formatDate } from "util/makeDataForm";

export default function EducationCard({ data }) {
  const history = useHistory();
  const { title, created, thumbnailUrl, duration, lessonId } = data;

  const handleClick = (lessonId) => () => {
    history.push({
      pathname: "/education/detail",
      search: `?id=${lessonId}`,
    });
  };

  return (
    <Styled.Root onClick={handleClick(lessonId)}>
      <Styled.Wrapper>
        <Styled.Image>
          {thumbnailUrl ? (
            <img
              src={thumbnailUrl}
              decoding="async"
              loading="lazy"
              alt="card"
            />
          ) : (
            <img src={image.spinner} alt="loading" />
          )}
          <Styled.Time>{convertFormatToSec(duration)}</Styled.Time>
        </Styled.Image>
        <Styled.Title>
          <h3>{title}</h3>
        </Styled.Title>
        <Styled.Date>
          <span>{formatDate(created, "YYYY년 MM월 DD일")}</span>
        </Styled.Date>
      </Styled.Wrapper>
    </Styled.Root>
  );
}
