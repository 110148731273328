import ModalCancelButton from "component/button/modalCancelButton/index";
import ModalCompleteButton from "component/button/modalCompleteButton/index";
import React from "react";
import Styled from "./style";
export default function ConfirmModal(props) {
  const {
    title,
    content,
    completeModalKey,
    cancelContent = "닫기",
    completeContent = "확인",
    setState,
    activateFunction,
  } = props;

  return (
    <Styled.Root>
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Content>{content}</Styled.Content>
      </div>
      <Styled.ButtonWrapper>
        <ModalCancelButton content={cancelContent} setState={setState} />
        <ModalCompleteButton
          content={completeContent}
          completeModalKey={completeModalKey}
          setState={setState}
          activateFunction={activateFunction}
        />
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
