import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.label`
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    background-color: ${({ checked }) =>
      checked ? color.Color5ab1f0 : color.Colorc9c9c9};
    border-radius: 16px;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input + .switch {
      transform: ${({ checked }) => (checked ? `translate(20px)` : 0)};
    }

    .switch {
      position: absolute;
      cursor: pointer;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      background-color: ${color.Colorffffff};
      border-radius: 50%;
      transition: all 200ms ease-in-out;
    }
  `,
};

export default Styled;
