import { ax } from "api";

export const postLessons = (body) => async () => {
  return await ax.post("/lessons", body);
};

export const getLessons = (req) => async () => {
  return await ax.get("/lessons", { params: { ...req } });
};

export const getLessonsTitles = () => async () => {
  return await ax.get("/lessons/titles");
};

export const getLessonsByLessonId = (id) => async () => {
  return await ax.get(`/lessons/${id}`);
};

export const putLessonsByLessonId = (id, body) => async () => {
  return await ax.put(`/lessons/${id}`, body);
};

export const deleteLessonsByLessonId = (id) => async () => {
  return await ax.delete(`/lessons/${id}`);
};
