import styled from "styled-components";

import { ReactComponent as PlusIconImg } from "asset/image/icPlus.svg";
import { color } from "style/globalStyle";

export const Root = styled.article`
  position: relative;
  border-radius: 4px;
  padding: 0 40px;
  background-color: ${color.Colorffffff};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

  &:nth-of-type(2) {
    margin-top: 40px;

    &::before {
      content: "";
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      border-top: 1px dashed ${color.Colorc9c9c9};
    }
  }

  &:not(:first-of-type) {
    & li > div {
      border: 1px dashed ${color.Colore0e5e8};
    }
  }
`;

export const OptionCategory = styled.div`
  padding: 30px 0;

  &:not(:first-of-type) {
    border-top: 1px dashed ${color.Colore0e5e8};
  }
`;

export const OptionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & h3 {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.17;
    color: ${color.Color12171d};
  }

  & button {
    width: 16px;
    height: 16px;
  }
`;

export const PlusIcon = styled(PlusIconImg)`
  width: 100%;
  height: 100%;
  fill: ${color.Color5ab1f0};
  transform: scale(1.5418);
`;

export const OptionList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 320px);
  grid-gap: 35px;
`;
