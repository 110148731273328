export const deleteConsecutiveEqualValue = (array, key) => {
  let watch = "";
  return array.reduce((acc, cur) => {
    const value = cur[key];

    if (watch === value) {
      return [...acc, { ...cur, [key]: "" }];
    }

    if (!watch || watch !== value) {
      watch = value;
    }

    return [...acc, cur];
  }, []);
};
