import styled from "styled-components";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 34px 0;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    font-size: 22px;
  }
`;

export const Content = styled.div``;
