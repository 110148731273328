import styled from "styled-components";
import { color } from "style/globalStyle";
import { ReactComponent as ArrowIcon } from "asset/image/icNext.svg";

const Styled = {
  Root: styled.div`
    margin-top: 14px;
    display: flex;
    column-gap: 20px;
    cursor: pointer;
  `,
  MainWrapper: styled.div`
    width: 730px;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};
  `,
  Loading: styled.img``,
  Video: styled.iframe``,
  Description: styled.div`
    width: 100%;
    min-height: 302px;
    .content {
      width: 670px;
      /* height: 115px; */
      padding: 24px 30px;
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      word-wrap: break-word;
      color: ${color.Color121212};
    }
  `,
  TitleWrapper: styled.div`
    width: 100%;
    padding: 34px 30px 28px;
    border-bottom: 1px solid ${color.Colord5d8db};
    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 14px;
    }
    .title-content {
      width: 584px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      text-align: left;
      color: ${color.Color121212};
    }
    .title-date {
      height: 17px;
      font-size: 14px;
      line-height: 1.57;
      text-align: left;
      color: ${color.Color777777};
    }
  `,
  Content: styled.div``,
  SubWrapper: styled.div`
    width: 350px;
  `,
  EducationCardList: styled.div`
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  `,
  EducationCardWrapper: styled.div`
    width: 350px;
    height: 107px;
    padding: 20px 24px;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};
    cursor: pointer;
    & * {
      cursor: inherit;
    }
    .education-card-date {
      height: 16px;
      font-size: 13px;
      line-height: 1.69;
      text-align: left;
      color: ${color.Color777777};
    }
  `,

  UpperWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 39px;
    margin-bottom: 12px;
    .education-card-title {
      display: -webkit-box;
      width: 240px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
      color: ${color.Color121212};
    }
    .education-card-time {
      width: 34px;
      height: 16px;
      font-size: 13px;
      line-height: 1.69;
      color: ${color.Colorb1b1b1};
    }
  `,

  EducationCardDate: styled.div``,

  ButtonWrapper: styled.div`
    height: 24px;
    display: flex;
    justify-content: space-between;
    .move-button {
      display: flex;
      align-items: center;
      border: 0;
      font-weight: 500;

      :disabled {
        color: ${color.Colorb1b1b1};
        cursor: default;
      }
    }
  `,
  Arrow: styled(ArrowIcon)`
    margin: ${({ next }) => (next ? "0 15px 0 6px" : "0 6px 0 15px")};
    fill: ${({ disabled }) =>
      disabled ? color.Colorb1b1b1 : color.Color12171d};
    transform: ${({ next }) => (next ? "rotate(180deg)" : "initial")};
  `,
};

export default Styled;
