// 매장

import { beverageRatingList, pubSelectList, pubValueLabel } from "./selectList";

export const pubInfoInputList = [
  {
    title: "매장명",
    placeholder: "ex.생활맥주(구로디지털점)",
    name: "pubName",
    inputType: "input",
  },
  {
    title: "사업자등록번호",
    placeholder: "123-45-67890",
    name: "taxcode",
    inputType: "input",
  },
  {
    title: "대표자명",
    placeholder: "사업자로 등록된 대표자명을 입력해 주세요.",
    name: "managerName",
    inputType: "input",
  },
  {
    title: "핸드폰번호",
    placeholder: "대표자 핸드폰 번호를 입력해 주세요.",
    name: "managerPhone",
    inputType: "phone",
  },
  {
    title: "분류1",
    placeholder: "프랜차이즈명 (ex.생활맥주).",
    name: "franchiseName",
    inputType: "input",
  },
  {
    title: "분류2",
    placeholder: "(분류선택)",
    name: "franchise",
    inputType: "select",
    innerWidth: "142.5px",
    selectList: pubSelectList,
    selectLabel: pubValueLabel,
  },
  {
    title: "운영상태",
    placeholder: "",
    name: "status",
    inputType: "radio",
    radioContents: ["운영", "폐업"],
  },
  {
    title: "매장주소",
    placeholder: "주소 검색",
    name: "addr",
    inputType: "address-post",
  },
  {
    title: "매장상세주소",
    placeholder: "전체 매장 주소를 입력해 주세요.",
    name: "addrDetail",
    inputType: "input",
  },
  {
    title: "전화번호",
    placeholder: "매장 전화번호를 입력해 주세요.",
    name: "pubPhone",
    inputType: "phone",
  },
];

// 도매사
export const vendorInfoInputList = [
  {
    title: "도매사명",
    placeholder: "ex.춘천세계",
    name: "vendorName",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "사업자 등록번호",
    placeholder: "123-45-67890",
    name: "taxcode",
    inputType: "number",
    isRequired: true,
  },
  {
    title: "대표자명",
    placeholder: "사업자로 등록된 대표자명을 입력해 주세요.",
    name: "managerName",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "핸드폰번호",
    placeholder: "대표자 핸드폰 번호를 입력해 주세요.",
    name: "managerPhone",
    inputType: "phone",
    isRequired: true,
  },
  {
    title: "이메일 주소",
    placeholder: "이메일 주소를 입력해 주세요.",
    name: "managerEmail",
    inputType: "input",
    explain: "*비밀번호 분실 시 이메일 주소가 필요합니다.",
    isRequired: true,
  },
  {
    title: "면허구분-종합",
    placeholder: "",
    name: "licenseTotal",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "면허구분-특정",
    placeholder: "",
    name: "licenseSpecific",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "면허구분-수입",
    placeholder: "",
    name: "licenseForeign",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "운영상태",
    placeholder: "",
    name: "status",
    inputType: "radio",
    radioContents: ["운영", "폐업"],
    isRequired: true,
  },
  {
    title: "납품지역",
    placeholder: "주요 납품 지역을 입력해 주세요.",
    name: "area",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "도매사 전화번호",
    placeholder: "도매사 전화번호를 입력해 주세요.",
    name: "vendorPhone",
    inputType: "phone",
    isRequired: true,
  },
  {
    title: "도매사 주소",
    placeholder: "도매사 주소를 입력해 주세요.",
    name: "addr",
    inputType: "address-post",
    isRequired: true,
  },
  {
    title: "도매사 상세주소",
    placeholder: "전체 도매사 주소를 입력해 주세요.",
    name: "addrDetail",
    inputType: "input",
    isRequired: false,
  },
];

// 제조사
export const breweryInfoInputList = [
  {
    title: "제조사명",
    placeholder: "ex.고릴라브루잉",
    name: "breweryName",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "사업자 등록번호",
    placeholder: "123-45-67890",
    name: "taxcode",
    inputType: "number",
    isRequired: true,
  },
  {
    title: "대표자명",
    placeholder: "사업자로 등록된 대표자명을 입력해 주세요.",
    name: "managerName",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "핸드폰번호",
    placeholder: "대표자 핸드폰 번호를 입력해 주세요.",
    name: "managerPhone",
    inputType: "phone",
    isRequired: true,
  },
  {
    title: "이메일 주소",
    placeholder: "이메일 주소를 입력해 주세요.",
    name: "managerEmail",
    inputType: "input",
    explain: "*비밀번호 분실 시 이메일 주소가 필요합니다.",
    isRequired: true,
  },
  {
    title: "거래구분-종합",
    placeholder: "",
    name: "licenseTotal",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "거래구분-특정",
    placeholder: "",
    name: "licenseSpecific",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "거래구분-수입",
    placeholder: "",
    name: "licenseForeign",
    inputType: "radio",
    radioContents: ["O", "X"],
    isRequired: true,
  },
  {
    title: "운영상태",
    placeholder: "",
    name: "status",
    inputType: "radio",
    radioContents: ["운영", "폐업"],
    isRequired: true,
  },
  {
    title: "제조사 주소",
    placeholder: "제조사 주소를 입력해 주세요.",
    name: "addr",
    inputType: "address-post",
    isRequired: true,
  },
  {
    title: "제조사 상세주소",
    placeholder: "전체 제조사 주소를 입력해 주세요.",
    name: "addrDetail",
    inputType: "input",
    isRequired: false,
  },
  {
    title: "제조사 전화번호",
    placeholder: "제조사 전화번호를 입력해 주세요.",
    name: "breweryPhone",
    inputType: "phone",
    isRequired: true,
  },
];

export const beverageInfoInputList = [
  {
    title: "상품명",
    placeholder: "상품명을 입력해 주세요.",
    name: "name",
    inputType: "input",
    isRequired: true,
  },
  {
    title: "제조사",
    placeholder: "제조사를 입력해 주세요.",
    name: "breweryId",
    inputType: "select",
    selectList: [],
    value: "",
    innerWidth: "315px",
    isRequired: true,
  },
  {
    title: "분류",
    placeholder: "- 분류 선택 -",
    name: "category",
    inputType: "select",
    selectList: [],
    value: "",
    innerWidth: "315px",
    isRequired: true,
  },
  {
    title: "스타일",
    placeholder: "- 스타일 선택 -",
    name: "categoryId",
    inputType: "select",
    selectList: [],
    value: "",
    innerWidth: "315px",
    isRequired: true,
  },
  {
    title: "도수",
    placeholder: "도수 입력",
    name: "abv",
    inputType: "unit",
    unit: "%",
    isRequired: true,
    max: 100,
  },
  {
    title: "특징",
    placeholder: "",
    name: "feature",
    inputType: "textarea",
    height: "92px",
    isRequired: true,
  },
  {
    title: "레이팅",
    placeholder: "- 레이팅 선택 -",
    name: "rating",
    inputType: "select",
    selectList: beverageRatingList(),
    value: "",
    innerWidth: "315px",
    isRequired: false,
  },
];
