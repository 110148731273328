import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    margin-left: 16px;
    .profile-icon {
      max-width: 36px;
      max-height: 36px;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  `,
  ProfileDesc: styled.div`
    position: absolute;
    top: 56px;
    right: 0;
    width: 340px;
    height: 170px;
    padding: 22px 24px 15px 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px ${color.Colord5d8db};
    background-color: ${color.Colorffffff};
    .desc-wrapper {
      display: grid;
      column-gap: 24px;
      grid-template-columns: 56px 1fr;
      height: 56px;
      align-items: center;
      .profile-img {
        max-width: 56px;
        max-height: 56px;
        width: 100%;
      }
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 5px;

        .name {
          font-size: 15px;
          font-weight: 500;
        }
        .email {
          color: ${color.Color4d4e56};
        }
      }
    }
    hr {
      margin-top: 30px;
      margin-bottom: 14px;
      border-top: 1px solid #d5d8db;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .account {
        font-size: 13px;
        font-weight: 500;
        color: ${color.Color1f6cd5};

        cursor: pointer;
      }
      div {
        width: 70px;
        text-align: center;
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        border-radius: 2px;
        border: solid 1px #d5d8db;
        background-color: ${color.Colore0e5e8};
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
};
export default Styled;
