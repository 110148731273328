import React from "react";
import styled from "styled-components";

import { ReactComponent as CalendarSvg } from "asset/image/ic_calendar.svg";
import Datepicker from "component/datepicker/Datepicker";

const DateInput = ({
  className,
  value,
  name,
  startDate,
  endDate,
  handleSelectDate,
  ...restProps
}) => {
  return (
    <Wrapper className={className}>
      <Input type="text" readOnly value={value} name={name} {...restProps} />
      <Datepicker
        type={name}
        startDate={startDate}
        endDate={endDate}
        selectedDate={value}
        handleSelectDate={handleSelectDate}
        trigger={({ handleOpen, isOpen }) => (
          <CalendarButton
            type="button"
            aria-label="캘린더 토글"
            aria-haspopup="true"
            aria-pressed={isOpen}
            onClick={handleOpen}
          >
            <CalendarIcon />
          </CalendarButton>
        )}
      />
    </Wrapper>
  );
};

export default DateInput;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #e0e5e8;
  padding: 0 10px;
  font-size: 13px;
  line-height: 1.31;
  color: #12171d;

  &::placeholder {
    color: #b1b1b1;
  }
`;

const CalendarButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CalendarIcon = styled(CalendarSvg)`
  position: absolute;
  right: 6px;
  pointer-events: none;
`;
