import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    grid-area: ${(props) => props.gridName};
    .title {
      color: ${color.Color777777};
      margin-bottom: 10px;
      font-size: 14px;
    }
    .input-wrapper {
      position: relative;
      .inner-icon {
        position: absolute;
        right: 16px;
        top: 8px;
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
  Input: styled.input`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "40px")};
    padding: 0 16px;
    border-radius: 5px;
    border: solid 1px ${color.Colorc9c9c9};
    outline: none;
    :focus {
      border: solid 1px ${color.Color5ab1f0};
    }
    ::placeholder {
      color: ${color.Colorb1b1b1};
    }
  `,
  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,
  Explain: styled.span`
    margin-left: 6px;
    font-size: 12px;
    line-height: 1.25;
    color: ${color.Color5ab1f0};
  `,
};
export default Styled;
