import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    padding: 34px 0;
    row-gap: 30px;
    .header {
      display: flex;
      align-items: center;
      font-size: 17px;
      margin-bottom: 24px;

      & img {
        margin-right: 4px;
      }
    }
  `,
  Wrapper: styled.div`
    display: flex;
    column-gap: 30px;
  `,
  Header: styled.div`
    font-size: 22px;
  `,
  EssentialInfo: styled.div`
    width: 750px;
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  `,
  InputWrapper: styled.div`
    display: grid;
    row-gap: 24px;
    column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "pubName pubName taxcode taxcode"
      "managerName managerName managerPhone managerPhone"
      "franchiseName franchiseName franchise status"
      "addr addr addrDetail addrDetail"
      "pubPhone pubPhone - -";
  `,
  LicenseWrapper: styled.div`
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    width: 330px;
    height: 472px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  `,

  TradeCompanyWrapper: styled.div`
    width: 100%;
    min-height: 260px;
    padding: 34px 40px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      .plus-button {
        width: 24px;
        height: 24px;
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
  CompanySeparateWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 15px;
  `,
  CompanySeparateBox: styled.div`
    width: 320px;
    height: 140px;
    padding: 20px 16px;
    border-radius: 4px;
    border: dashed 1px ${color.Colorc9c9c9};
    background-color: ${color.Colorf6f8f8};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .upper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .desc-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        .desc {
          color: ${color.Color777777};
          font-size: 12px;
        }
      }
      .close-icon {
        :hover {
          cursor: pointer;
        }
      }
    }
    .lower {
      display: flex;
      margin-top: 12px;
      justify-content: space-between;
      div {
        width: 136px;
        height: 46px;
        border-radius: 5px;
        border: solid 0.5px ${color.Colord5d8db};
        background-color: ${color.Colorfcfcfd};
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 14px;
        :hover {
          cursor: pointer;
        }
      }
    }
  `,

  ButtonWrapper: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,

  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,
};
export default Styled;
