import React from "react";

import { image } from "style/globalStyle";
import Styled from "./style";

export default function ReloadButton({ className, handleReload }) {
  return (
    <Styled.Root
      type="button"
      className={className}
      onClick={handleReload}
      aria-label="새로고침"
    >
      <img src={image.icReload} alt="" />
    </Styled.Root>
  );
}
