import ModalCancelButton from "component/button/modalCancelButton/index";
import ModalCompleteButton from "component/button/modalCompleteButton/index";
import { useState } from "react";
import Styled from "./style";

export default function RefuseConfirmModal(props) {
  const {
    title,
    content,
    setState,
    cancelContent = "취소",
    completeContent = "확인",
    completeModalKey,
    activateFunction,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const handleChangeInputValue = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Styled.Root>
      <Styled.MainWrapper>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <input
          className="input-area"
          placeholder="거절 사유를 입력해 주세요"
          onChange={handleChangeInputValue}
        />
      </Styled.MainWrapper>
      <Styled.ButtonWrapper>
        <ModalCancelButton content={cancelContent} setState={setState} />
        <ModalCompleteButton
          content={completeContent}
          completeModalKey={completeModalKey}
          setState={setState}
          activateFunction={() => activateFunction(inputValue)}
        />
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
