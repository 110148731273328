import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import { API } from "store/reducer/apiSlice";
import styled from "styled-components";

import Select from "component/input/select/Select";
import Select2 from "component/input/select/Select2";
import { loadFranchiseList } from "api/pub";
import Modal from "component/modal/Modal";
import Table from "component/newTable/Table";

import { orderHistoryDetailColums } from "asset/data/transaction";
import { FRANCHISES_TABLE, AREA_TABLE } from "asset/data/transaction";
import { typo } from "style/globalStyle";
import { loadPubsBuyers, loadVendorsBuyers } from "api/transaction/index";
import { debounce } from "lodash";

import { ReactComponent as SearchIcon } from "asset/image/icSearch.svg";

const OrderHistoryModal = ({
  className,
  type,
  query,
  openDetailInfoRowId,
  modalRef,
  handleClose,
  handleDimClose,
}) => {
  const dispatch = useDispatch();

  const [orderHistories, setOrderHistories] = useState([]);
  const [filterOrderHistories, setFilterOrderHistories] = useState([]);
  const [sortOption, setSortOption] = useState("desc");
  const [franchiseNameTable, setFranchiseNameTable] = useState({});
  const [filterValues, setFilterValues] = useImmer({
    franchiseName: "",
    franchise: "",
    area: "",
  });

  const handleSort = useCallback(() => {
    const order = sortOption === "desc" ? "asc" : "desc";
    setSortOption(order);
  }, [sortOption]);

  const totalAmount = filterOrderHistories
    .reduce((acc, cur) => acc + cur.amount, 0)
    .toLocaleString();
  const totalPrice = filterOrderHistories
    .reduce((acc, cur) => acc + +cur.price.split(",").join(""), 0)
    .toLocaleString();
  const totalVat = filterOrderHistories
    .reduce((acc, cur) => acc + +cur.vat.split(",").join(""), 0)
    .toLocaleString();
  const totalSales = filterOrderHistories
    .reduce((acc, cur) => acc + +cur.totalPrice.split(",").join(""), 0)
    .toLocaleString();

  const colums =
    type === "pubs"
      ? orderHistoryDetailColums.pubs
      : orderHistoryDetailColums.vendors;

  const handleChangeFilter = (option, name) => {
    setFilterValues((draft) => {
      draft[name] = option;
    });
  };

  const handleChangeName = (event) => {
    if (event.target.value.trim() === "") {
      let tempFilterOrderHistories = [...orderHistories];

      Object.entries(filterValues).forEach(([key, value]) => {
        if (value === "all" || value === "") {
          tempFilterOrderHistories = tempFilterOrderHistories.filter(
            (item) => item
          );
        } else {
          tempFilterOrderHistories = tempFilterOrderHistories.filter(
            (item) => item[key] === value
          );
        }
      });

      setFilterOrderHistories(tempFilterOrderHistories);
    } else {
      const temp = [...filterOrderHistories];

      setFilterOrderHistories(
        temp.filter((item) => item.name.includes(event.target.value))
      );
    }
  };

  useEffect(() => {
    const loadFranchiseNames = async () => {
      const {
        payload: {
          data: { names },
        },
      } = await dispatch(API(loadFranchiseList()));

      const table = names.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {
        all: "전체",
      });

      setFranchiseNameTable(table);
    };

    type === "pubs" && loadFranchiseNames();

    const loadOrderHistories = async () => {
      const newQuery = {
        ...query,
        sellerId: openDetailInfoRowId,
        volume: query.beverageIdVolume,
        status: [query.twoDepthStatus],
      };

      delete newQuery.beverageIdVolume;

      if (type === "pubs") {
        const {
          data: { buyers },
        } = await loadPubsBuyers(newQuery);

        const newBuyers = buyers.map((buyer, i) => ({
          ...buyer,
          no: i + 1,
          price: buyer.price.toLocaleString(),
          totalPrice: buyer.totalPrice.toLocaleString(),
          vat: buyer.vat.toLocaleString(),
        }));

        setOrderHistories(newBuyers);
        setFilterOrderHistories(newBuyers);
      } else {
        const {
          data: { buyers },
        } = await loadVendorsBuyers(newQuery);

        const newBuyers = buyers.map((buyer, i) => ({
          ...buyer,
          no: i + 1,
          price: buyer.price.toLocaleString(),
          totalPrice: buyer.totalPrice.toLocaleString(),
          vat: buyer.vat.toLocaleString(),
        }));

        setOrderHistories(newBuyers);
        setFilterOrderHistories(newBuyers);
      }
    };

    loadOrderHistories();
  }, []);

  useEffect(() => {
    let tempFilterOrderHistories = [...orderHistories];

    Object.entries(filterValues).forEach(([key, value]) => {
      if (key === "status") return;

      if (value === "all" || value === "") {
        tempFilterOrderHistories = tempFilterOrderHistories.filter(
          (item) => item
        );
      } else {
        tempFilterOrderHistories = tempFilterOrderHistories.filter(
          (item) => item[key] === value
        );
      }
    });

    setFilterOrderHistories(tempFilterOrderHistories);
  }, [filterValues]);

  console.log("orderHistories", orderHistories);

  useEffect(() => {
    const result = [...filterOrderHistories].sort((a, b) => {
      const baseSortingValueA = Number(a.totalPrice.replace(/,/g, ""));
      const baseSortingValueB = Number(b.totalPrice.replace(/,/g, ""));

      if (baseSortingValueA > baseSortingValueB) {
        return sortOption === "asc" ? 1 : -1;
      }
      if (baseSortingValueA < baseSortingValueB) {
        return sortOption === "asc" ? -1 : 1;
      }

      return 0;
    });

    setFilterOrderHistories(result);
  }, [sortOption]);

  return (
    <>
      <StyleModal
        className={className}
        ref={modalRef}
        handleDimClose={handleDimClose}
        isOpen={true}
      >
        <Modal.Header handleClose={handleClose}>주문내역</Modal.Header>
        <Modal.Content>
          {type === "pubs" ? (
            <PubFilterWrapper>
              <PubFilterOption>
                <OptionWrapper>
                  {Object.keys(franchiseNameTable).length !== 0 && (
                    <Category1
                      name="franchiseName"
                      labelTable={franchiseNameTable}
                      placeholder="분류1"
                      selectedOption={filterValues.franchiseName}
                      onSelect={handleChangeFilter}
                    >
                      {Object.entries(franchiseNameTable).map(
                        ([value, label]) => (
                          <Select.Option key={uuidv4()} value={value}>
                            {label}
                          </Select.Option>
                        )
                      )}
                    </Category1>
                  )}
                </OptionWrapper>
                <OptionWrapper>
                  <CategorySelector
                    name="franchise"
                    labelTable={FRANCHISES_TABLE}
                    placeholder="분류2"
                    selectedOption={filterValues.franchise}
                    onSelect={handleChangeFilter}
                  >
                    {Object.entries(FRANCHISES_TABLE).map(([value, label]) => (
                      <Select.Option key={uuidv4()} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </CategorySelector>
                </OptionWrapper>
                <OptionWrapper>
                  <AreaSelector
                    name="area"
                    labelTable={AREA_TABLE}
                    placeholder="지역"
                    selectedOption={filterValues.area}
                    onSelect={handleChangeFilter}
                  >
                    {Object.entries(AREA_TABLE).map(([value, label], i) => (
                      <Select.Option key={uuidv4()} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </AreaSelector>
                </OptionWrapper>
                <OptionWrapper>
                  <InputWrapper>
                    <CustomSearchIcon />
                    <NameSearchInput
                      name="name"
                      placeholder="매장명으로 검색"
                      onChange={debounce((e) => handleChangeName(e), 500)}
                    />
                  </InputWrapper>
                </OptionWrapper>
              </PubFilterOption>
            </PubFilterWrapper>
          ) : (
            <VendorFilterWrapper>
              <VendorFilterOption>
                <OptionWrapper>
                  <InputWrapper>
                    <CustomSearchIcon />
                    <NameSearchInput
                      name="name"
                      placeholder="도매사명으로 검색"
                      onChange={debounce((e) => handleChangeName(e), 500)}
                    />
                  </InputWrapper>
                </OptionWrapper>
              </VendorFilterOption>
            </VendorFilterWrapper>
          )}
          <TableWrapper>
            {type === "pubs" ? (
              <PubOrderHistoryTable title="상세 거래내역">
                <Thead>
                  {colums.map((column, i) => (
                    <Table.HeadColumn
                      key={uuidv4()}
                      column={column}
                      sortOption={sortOption}
                      handleSort={handleSort}
                    />
                  ))}
                </Thead>
                <Tbody>
                  {filterOrderHistories.map((rowData, i) => (
                    <Table.Row key={uuidv4()} id={rowData.id}>
                      {colums.map((columnData, i) => {
                        if (columnData.key === "franchise") {
                          return (
                            <Table.Cell key={uuidv4()}>
                              <span>
                                {FRANCHISES_TABLE[rowData[columnData.key]]}
                              </span>
                            </Table.Cell>
                          );
                        } else {
                          return (
                            <Table.Cell key={uuidv4()}>
                              <span>{rowData[columnData.key]}</span>
                            </Table.Cell>
                          );
                        }
                      })}
                    </Table.Row>
                  ))}
                </Tbody>
              </PubOrderHistoryTable>
            ) : (
              <VendorOrderHistoryTable title="상세 거래내역">
                <Thead>
                  {colums.map((column, i) => (
                    <Table.HeadColumn
                      key={uuidv4()}
                      column={column}
                      sortOption={sortOption}
                      handleSort={handleSort}
                    />
                  ))}
                </Thead>
                <Tbody>
                  {filterOrderHistories.map((rowData, i) => (
                    <Table.Row key={uuidv4()} id={rowData.id}>
                      {colums.map((columnData, i) => {
                        if (columnData.key === "franchise") {
                          return (
                            <Table.Cell key={uuidv4()}>
                              <span>
                                {FRANCHISES_TABLE[rowData[columnData.key]]}
                              </span>
                            </Table.Cell>
                          );
                        } else {
                          return (
                            <Table.Cell key={uuidv4()}>
                              <span>{rowData[columnData.key]}</span>
                            </Table.Cell>
                          );
                        }
                      })}
                    </Table.Row>
                  ))}
                </Tbody>
              </VendorOrderHistoryTable>
            )}
          </TableWrapper>
          <Total>
            <div>합계</div>
            <div>
              <span className="a11y">총 수량 합계</span>
              <span>{totalAmount}</span>
            </div>
            <div>
              <span className="a11y">총 주문금액 합계</span>
              <span>{totalPrice}</span>
            </div>
            <div>
              <span className="a11y">총 부가세 합계</span>
              <span>{totalVat}</span>
            </div>
            <div>
              <span className="a11y">총 매출액 합계</span>
              <span>{totalSales}</span>
            </div>
          </Total>
        </Modal.Content>
      </StyleModal>
    </>
  );
};

export default OrderHistoryModal;

const StyleModal = styled(Modal)`
  width: 920px;
`;

const TableWrapper = styled.div`
  min-height: 496px;
`;

const PubOrderHistoryTable = styled(Table)`
  width: 920px;
  border: 0;

  tr,
  tbody {
    width: 100%;
    padding: 0;
  }

  && thead > tr > th {
    color: #4d4e56;
  }

  & tr {
    grid-template-columns: 37px 118px 57px 83px 201px 61px 121px 111px 120px;
    border-bottom: 1px solid #d5d8db;

    & > * {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        width: 1px;
        height: 16px;
        background-color: #e0e5e8;
      }

      &:last-of-type {
        &::after {
          content: none;
        }
      }
    }
  }
`;

const VendorOrderHistoryTable = styled(Table)`
  border: 0;

  && thead > tr > th {
    color: #4d4e56;
  }

  & tr {
    grid-template-columns: 24px 202px 61px 111px 120px 1fr;
    border-bottom: 1px solid #d5d8db;

    & > :first-of-type {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    & > * {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        width: 1px;
        height: 16px;
        background-color: #e0e5e8;
      }

      &:last-of-type {
        &::after {
          content: none;
        }
      }
    }
  }
`;

const Thead = styled(Table.Head)`
  && > tr {
    height: 36px;
    background-color: #f6f8f8;
  }
`;

const Tbody = styled(Table.Body)`
  height: 460px;
  && > tr {
    min-height: 40px;
  }
`;

const Total = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 110px 110px 150px;
  height: 40px;
  margin-bottom: 18px;
  border-top: 1px solid #e0e5e8;
  border-bottom: 1px solid #e0e5e8;

  & > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4e56;
    background-color: #e0e5e8;
  }

  & > div:not(:first-of-type) {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 13px;
    background-color: #f6f8f8;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1px;
      height: 16px;
      background-color: #e0e5e8;
    }
  }

  & > div:last-of-type {
    font-weight: 700;
  }
`;

const PubFilterWrapper = styled.div`
  padding: 12px 0;
  background-color: #f6f8f8;
  border-bottom: 1px solid #e0e5e8;
`;

const PubFilterOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 36px;

  &:first-of-type::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #d5d8db;
  }
`;

const VendorFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 12px 0;
  background-color: #f6f8f8;
  border-bottom: 1px solid #e0e5e8;
`;

const VendorFilterOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 36px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category1 = styled(Select2)`
  width: 130px;
  margin-right: 8px;

  & > ul {
    max-height: 162px;
  }
`;

const CategorySelector = styled(Select)`
  width: 140px;
  margin-right: 8px;

  & > ul {
    max-height: 162px;
  }
`;

const AreaSelector = styled(Select)`
  width: 140px;
  margin-right: 8px;

  & > ul {
    max-height: 226px;
  }
`;

const NameSearchInput = styled.input`
  ${typo.regular13};
  width: 396px;
  height: 32px;
  margin-right: 8px;
  border: 1px solid #e0e5e8;
  border-radius: 2px;
  padding: 0 12px 0 36px;
  background-color: #fff;

  &::placeholder {
    ${typo.regular13};
    color: #b1b1b1;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const CustomSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 6px;
  left: 8px;
`;
