import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";

import Styled from "./style";
import { color, image } from "style/globalStyle";
import { modalCont } from "asset/data/modalCont";
import LoginInfoRegist from "container/loginInfoRegist/index";
import PortalModal from "container/modal/portalModal/index";
import CompanySeparateBox from "container/pub/companySeparateBox/index";
import EssentialInfo from "container/pub/essentialInfo/index";
import UploadImg from "component/uploadImg/index";
import SubmitButton from "component/button/submitButton/index";
import CustomPrompt from "component/prompt/index";
import { getBreweryList, getVendorList } from "hook/getInfoApi";
import { setModalKey } from "hook/modal";
import { handleClickPlus } from "hook/handler";
import usePrompt from "hook/usePrompt";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { makeCryptoFunction } from "util/crypto";
import { phoneNumToString } from "util/makeDataForm";
import { postPubs } from "api/pub/index";
import { makeCheckObject } from "util/makeCheckList";
import { checkAwsApiStaus } from "util/presigend";
import Toast from "component/toast/index";
import useToast from "hook/useToast";

const initSubmitState = {
  taxcode: "",
  password: { value: "", errorToggle: false },
  passwordCheck: { value: "", errorToggle: false },
  pubName: "",
  pubPhone: "",
  addr: "",
  addrDetail: "",
  status: "1",
  managerName: "",
  managerPhone: "",
  managerEmail: "",
  franchise: "",
  franchiseName: "",
  file: "",
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};

const initEnterpriseState = {
  enterpriseList: [{ type: "select", label: "", value: "" }],
};

export default function PubCreate() {
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [modalState, setModalState] = useImmer(initModalState);
  const [enterpriseState, setEnterpriseState] = useImmer(initEnterpriseState);
  const [vendorList, setVendorList] = useState([]);
  const [breweryList, setBreweryList] = useState([]);
  const [isValidError, setIsValidError] = useState(false);
  const { isShow, message, type, toast } = useToast();
  const dispatch = useDispatch();
  const checkList = makeCheckObject({ ...submitState, ...enterpriseState }, [
    "",
  ]);
  const { isValueChanged, setIsValueChanged } = usePrompt({
    checkList,
  });

  const handleSubmit = async () => {
    const requiredValue = Object.entries(submitState)
      .filter(([key, _]) => key !== "addrDetail")
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {});

    if (!isValidEmpty(requiredValue)) {
      toast("필수 기입 정보를 입력해 주세요", "warning");
      setIsValidError(true);
      return;
    }

    const status = submitState.status === "0" ? "closed" : "open";
    const area = submitState.addr.split(" ")[0];
    const cryptoedPassword = makeCryptoFunction(submitState.password.value);
    const breweryIds = enterpriseState.enterpriseList
      .filter((v) => v.type === "brewery")
      .map((v) => v.value);
    const vendorIds = enterpriseState.enterpriseList
      .filter((v) => v.type === "vendor")
      .map((v) => v.value);

    const body = {
      taxcode: submitState.taxcode,
      password: cryptoedPassword,
      pubName: submitState.pubName,
      pubPhone: phoneNumToString(submitState.pubPhone),
      addr: submitState.addr,
      addrDetail: submitState.addrDetail,
      area: area,
      status: status,
      managerName: submitState.managerName,
      managerPhone: phoneNumToString(submitState.managerPhone),
      managerEmail: submitState.managerEmail,
      franchise: submitState.franchise,
      franchiseName: submitState.franchiseName,
      breweryIds: breweryIds,
      vendorIds: vendorIds,
    };

    const res = await dispatch(API(postPubs(body)));
    if (res.payload.status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);

      if (awsStatus === 204) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/pub",
          "매장 정보가 등록되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const isValidEmpty = (obj) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "object" ? item.value !== "" : item !== "";
    };

    return Object.values(obj).every(hasNotEmptyValue);
  };

  useEffect(() => {
    getVendorList(dispatch, setVendorList);
    getBreweryList(dispatch, setBreweryList);
  }, []);

  useEffect(() => {
    if (enterpriseState.enterpriseList.length === 0) {
      setEnterpriseState((draft) => {
        draft.enterpriseList = [{ type: "select", label: "", value: "" }];
      });
    }
  }, [enterpriseState.enterpriseList]);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>신규 매장 정보를 입력해 주세요</Styled.Header>
        <Styled.Wrapper>
          <EssentialInfo
            state={submitState}
            setState={setSubmitState}
            isValidError={isValidError}
          />
          <Styled.LicenseWrapper>
            <div className="header">
              <img src={image.icBusiness} alt="business-icon" />
              <span>사업자 등록증</span>
              <Styled.Essential aria-label="필수값">*</Styled.Essential>
            </div>
            <UploadImg
              content="사업자 등록증 이미지를 가져오세요"
              state={submitState.file}
              setState={setSubmitState}
              name="file"
              isValidError={isValidError}
            />
          </Styled.LicenseWrapper>
        </Styled.Wrapper>
        <Styled.TradeCompanyWrapper>
          <div className="header-wrapper">
            <div className="header">
              <img src={image.icPartner} alt="partner-icon" />
              <span>거래업체</span>
            </div>
            <img
              className="plus-button"
              src={image.icPlusLarge}
              alt="plus-icon"
              onClick={handleClickPlus(setEnterpriseState)}
            />
          </div>
          <Styled.CompanySeparateWrapper>
            {enterpriseState.enterpriseList.map((item, idx) => {
              return (
                <CompanySeparateBox
                  key={idx}
                  state={enterpriseState}
                  setState={setEnterpriseState}
                  data={item}
                  idx={idx}
                  vendorList={vendorList}
                  breweryList={breweryList}
                />
              );
            })}
          </Styled.CompanySeparateWrapper>
        </Styled.TradeCompanyWrapper>
        <LoginInfoRegist
          isValidError={isValidError}
          submitState={submitState}
          setSubmitState={setSubmitState}
        />
        <Styled.ButtonWrapper>
          <SubmitButton
            content="매장 등록"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="120px"
            height="40px"
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
