import React from "react";
import styled from "styled-components";

const DataStatistics = ({ className, totalAmount, totalPrice }) => {
  return (
    <TotoalInfoWrapper className={className}>
      <div>
        <dt>총 수량:</dt>
        <dd>{totalAmount}</dd>
      </div>
      <div>
        <dt>총 매출액:</dt>
        <dd>{totalPrice}</dd>
      </div>
    </TotoalInfoWrapper>
  );
};

export default DataStatistics;

const TotoalInfoWrapper = styled.dl`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 32px;
  border-radius: 3px;
  padding: 0 12px;
  background-color: #f9fbfb;

  & > div {
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-right: 50px;
    }

    & > dt,
    & > dd {
      font-size: 13px;
      color: #323c41;
    }

    & > dt {
      margin-right: 4px;
      font-weight: 500;
    }
  }
`;
