import React, { useState } from "react";
import { useImmer } from "use-immer";
import { useHistory } from "react-router";

import Styled from "./style";
import { image } from "style/globalStyle";
import NoticeSearchBar from "container/searchBar/noticeSearchBar/index";
import PortalModal from "container/modal/portalModal/index";
import CheckboxSelectBoxList from "component/checkboxSelectBoxList/index";
import { modalCont } from "asset/data/modalCont";
import { useNoticeData } from "hook/useNoticeData";
import useOneOfThem from "hook/useOneOfThem";
import { formatDate } from "util/makeDataForm";
import { setModalKey } from "hook/modal";
import Pagination2 from "component/pagination2/index";

const typeKor = {
  pub: "매장",
  vendor: "도매사",
  brewery: "제조사",
  all: "전체",
};

const franchiseKor = {
  all: "전체",
  direct: "직영",
  franch: "가맹",
  others: "일반",
};

const initState = {
  modal: {
    modalVisible: false,
    modalKey: "",
  },
};

const initQueryState = {
  title: "",
  type: "all",
  franchise: "",
  franchiseName: "",
  area: "",
  linkedIds: [],
  page: 1,
};

export default function Notice() {
  const history = useHistory();

  const [state, setState] = useImmer(initState);
  const [noticeId, setNoticeId] = useState();
  const [queryState, setQueryState] = useState(initQueryState);

  const [selectBoxList, setSelectBoxList] = useState([]); // selectBox 내에서 선택한 리스트 (선택완료 누르기 전)

  const { activeTarget, handleOneOfThem } = useOneOfThem();

  const {
    noticesInfo,
    pageInfo,
    dropdownInfo,
    getNoticeInfo,
    getDropdownList,
    handleDelete,
  } = useNoticeData({
    config: queryState,
    getInfo: true,
  });

  const handleSearch = (event) => {
    event.preventDefault();
    getNoticeInfo({
      ...queryState,
      linkedIds: [...queryState.linkedIds.map((item) => item.linkId)],
    });
  };

  const handleReload = () => {
    setQueryState(initQueryState);
    setSelectBoxList([]);
    getNoticeInfo({});
  };

  const handleEdit = (updateHref) => () => {
    history.push(`/notice/update?id=${updateHref}`);
  };

  const handleChangeSearchInput = (event) => {
    const { value } = event.target;
    setQueryState({ ...queryState, title: value });
  };

  const activateFunction = (id) => () => {
    handleDelete(id);
    setTimeout(() => {
      setState((draft) => {
        draft.modal.modalVisible = false;
      });
    }, [1000]);
  };

  const handleClickDeleteButton = (modalKey, id) => (e) => {
    e.stopPropagation();

    setNoticeId(id);
    setModalKey(setState, modalKey);
  };

  const handleNoticeSelect = (type) => (value) => {
    setSelectBoxList([]);

    switch (type) {
      case "type":
        getNoticeInfo({ type: value });
        if (value !== "all") {
          getDropdownList({ type: value });
        }

        setQueryState({
          type: value,
          franchiseName: "",
          franchise: "",
          area: "",
          linkedIds: [],
          title: "",
          page: 1,
        });

        break;

      case "franchiseName":
        getNoticeInfo({
          ...queryState,
          franchiseName: value,
        });

        if (queryState.type !== "all") {
          getDropdownList({
            ...queryState,
            franchiseName: value,
          });
        }

        setQueryState({
          type: queryState.type,
          franchiseName: value,
          franchise: "",
          area: "",
          linkedIds: [],
          title: "",
          page: 1,
        });
        break;

      case "franchise":
        getNoticeInfo({
          ...queryState,
          franchise: value,
        });
        if (queryState.type !== "all") {
          getDropdownList({
            ...queryState,
            franchise: value,
          });
        }

        setQueryState({
          type: queryState.type,
          franchiseName: queryState.franchiseName,
          franchise: value,
          area: "",
          linkedIds: [],
          title: "",
          page: 1,
        });
        break;

      case "area":
        getNoticeInfo({
          ...queryState,
          area: value,
        });

        if (queryState.type !== "all") {
          getDropdownList({
            ...queryState,
            area: value,
          });
        }

        setQueryState({
          type: queryState.type,
          franchiseName: queryState.franchiseName,
          franchise: queryState.franchise,
          area: value,
          linkedIds: [],
          title: "",
          page: 1,
        });
        break;
    }
  };

  const handleCheckSelectBox = (value) => {
    if (value.name === "전체") {
      // 전체를 눌렀을 때

      setSelectBoxList([{ linkId: "all", name: "전체" }]);
    } else if (selectBoxList.some((select) => select.linkId === value.linkId)) {
      // 전체가 아닌 다른 리스트를 눌렀을 때 (눌렀는 리스트가 체크되어 있는 상황)
      setSelectBoxList(
        selectBoxList
          .filter((item) => item.linkId !== value.linkId)
          .filter((item) => item.linkId !== "all")
      );
    } else {
      // 전체가 아닌 다른 리스트를 눌렀을 때 (눌렀는 리스트가 체크 안되어 있는 상황)
      setSelectBoxList([
        ...selectBoxList.filter((item) => item.linkId !== "all"),
        value,
      ]);
    }
  };

  const handleResetSelectList = () => {
    setSelectBoxList([]);
  };

  const handleConfirmCheckSelectBox = () => {
    setQueryState({ ...queryState, linkedIds: selectBoxList });
    getNoticeInfo({
      ...queryState,
      linkedIds: [...selectBoxList.map((item) => item.linkId)],
    });
  };

  const handleDeleteSelectItem = (deleteItem) => () => {
    setQueryState({
      ...queryState,
      linkedIds: queryState.linkedIds.filter(
        (item) => item.linkId !== deleteItem.linkId
      ),
    });
    setSelectBoxList(
      queryState.linkedIds.filter((item) => item.linkId !== deleteItem.linkId)
    );

    getNoticeInfo({
      ...queryState,
      linkedIds: queryState.linkedIds
        .filter((item) => item.linkId !== deleteItem.linkId)
        .map((item) => item.linkId),
    });
  };

  const handleResetCheckboxSelectBoxList = () => {
    setQueryState({ ...queryState, linkedIds: [] });
    setSelectBoxList([]);

    getNoticeInfo({ ...queryState, linkedIds: [] });
  };

  const handlePagination = (pageNumber) => () => {
    getNoticeInfo({
      ...queryState,
      page: pageNumber,
    });
  };

  const handleInit = () => {
    setSelectBoxList([...queryState.linkedIds]);
  };

  return (
    <Styled.Root>
      {state.modal.modalVisible && (
        <PortalModal setState={setState}>
          {modalCont({
            modalKey: state.modal.modalKey,
            setState,
            activateFunction: activateFunction(noticeId),
          })}
        </PortalModal>
      )}
      <NoticeSearchBar
        dropdownInfo={dropdownInfo}
        filterValue={queryState}
        selectBoxList={selectBoxList}
        handleInit={handleInit}
        handleFilter={handleNoticeSelect}
        handleCheckSelectBox={handleCheckSelectBox}
        handleResetSelectList={handleResetSelectList}
        handleConfirmCheckSelectBox={handleConfirmCheckSelectBox}
        handleChangeSearchInput={handleChangeSearchInput}
        handleSearch={handleSearch}
        handleReload={handleReload}
      />
      <CheckboxSelectBoxList
        selectList={queryState.linkedIds}
        handleDeleteSelectItem={handleDeleteSelectItem}
        handleResetCheckboxSelectBoxList={handleResetCheckboxSelectBoxList}
      />
      {noticesInfo.map((item, index) => {
        const {
          content,
          created,
          fixed,
          isNew,
          title,
          type,
          franchise,
          franchiseName,
          area,
          linkCnt,
          noticeId,
        } = item;

        return (
          <Styled.ContentBoxWrapper
            key={index}
            onClick={handleOneOfThem(noticeId)}
          >
            <Styled.Header isNew={isNew}>
              <div className="header">
                <div className="header-leftside">
                  <h3 className="header-title">{title}</h3>
                  {/* 태그 */}
                  <div className="new-tag-box">
                    <span className="new-tag">NEW</span>
                  </div>
                  {fixed ? <img src={image.icPin} alt="pic" /> : null}
                </div>
                <Styled.BtnWrapper>
                  <button type="button" onClick={handleEdit(noticeId)}>
                    수정
                  </button>
                  <button
                    type="button"
                    onClick={handleClickDeleteButton(
                      "deleteNoticeModal",
                      noticeId
                    )}
                  >
                    삭제
                  </button>
                </Styled.BtnWrapper>
              </div>
              <div className="date">
                <small>
                  {typeKor[type]} ·{" "}
                  {`${franchiseName ? `${franchiseName} · ` : ""}`}
                  {franchise && `${franchiseKor[franchise]} · `}
                  {`${area ? `${area}·` : ""} `}
                  {`${typeKor[type] === "전체" ? "" : typeKor[type]} ${
                    linkCnt !== 0 ? `${linkCnt}건` : "전체"
                  } · `}
                  {formatDate(created, "YYYY.MM.DD")}
                </small>
              </div>
            </Styled.Header>
            <Styled.Body show={activeTarget === noticeId}>
              <div>{content}</div>
            </Styled.Body>
          </Styled.ContentBoxWrapper>
        );
      })}
      <Styled.PaginationWrapper>
        <Pagination2 pageInfo={pageInfo} handlePagination={handlePagination} />
      </Styled.PaginationWrapper>
    </Styled.Root>
  );
}
