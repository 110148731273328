import styled from "styled-components";

import { color } from "style/globalStyle";
import ParentStyle from "../style";

export const Input = styled(ParentStyle.Input).attrs(({ validation }) => ({
  border: validation && `1px solid ${color.Colorff0000}`,
}))`
  border: ${({ border }) => border};
`;

export const Essential = styled.strong`
  color: ${color.Colorce4d4e};
`;
