import React from "react";
import { image } from "style/globalStyle";
import Styled from "./style";

export default function SearchInput(props) {
  const {
    className,
    searchValue,
    placeholder,
    handleChangeSearchInput,
    handleSearch,
  } = props;

  return (
    <Styled.Root className={className} onSubmit={handleSearch}>
      <img src={image.icSearch} alt="search-icon" />
      <input
        className="search-input"
        id="search-input"
        type="text"
        onChange={handleChangeSearchInput}
        value={searchValue}
        placeholder={placeholder}
      />
    </Styled.Root>
  );
}
