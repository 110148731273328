import React, { useState } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";
import Styled from "./style";
import { color } from "style/globalStyle";
import { modalCont } from "asset/data/modalCont";
import PortalModal from "container/modal/portalModal/index";
import SubmitButton from "component/button/submitButton/index";
import Toast from "component/toast/index";
import { makeCryptoFunction } from "util/crypto";
import { setModalKey } from "hook/modal";
import { putHeadquarterPassword } from "api/account/index";
import useToast from "hook/useToast";
import { API } from "store/reducer/apiSlice";

const initState = {
  password: { value: "", errorToggle: false }, // 로그인 비밀번호
  passwordCheck: { value: "", errorToggle: false }, // 로그인 비밀번호 확인

  modal: {
    modalVisible: false,
    modalKey: "",
  },
};
export default function ChangePassword() {
  const [state, setState] = useImmer(initState);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);
  const { isShow, message, type, toast } = useToast();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.password.value) {
      toast("비밀번호를 입력해주세요.", "warning");
      return;
    }
    if (state.password.errorToggle) {
      toast("비밀번호 형식에 맞게 입력해 주세요", "warning");
      return;
    }
    setModalKey(setState, "changePasswordConfirmModal");
  };

  const activateFunction = async () => {
    const cryptoedPassword = makeCryptoFunction(state.password.value);
    await dispatch(API(putHeadquarterPassword({ password: cryptoedPassword })));
  };

  return (
    <>
      <Styled.Root>
        {state.modal.modalVisible && (
          <PortalModal setState={setState}>
            {modalCont({
              modalKey: state.modal.modalKey,
              setState,
              activateFunction,
              nextPath: "/account",
              completeContent: "비밀번호 변경이 완료되었습니다.",
            })}
          </PortalModal>
        )}
        <div className="title">비밀번호 변경</div>
        <div className="desc-wrapper">
          <div className="desc">
            · 8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류를 조합하여
            설정하세요.
          </div>
          <div className="desc">
            · 이전에 사용한 비밀번호를 재사용하면 도용의 우려가 있으니 가급적
            새로운 비밀번호를 사용해 주십시오.
          </div>
          <div className="desc">
            · 비밀번호 변경 시 계정보안을 위해 로그인되어 있는 모든 브라우저 및
            기기에서 로그아웃됩니다.
          </div>
        </div>

        <Styled.Form>
          <Styled.InputRoot
            title="새 비밀번호"
            state={state}
            setState={setState}
            name="password"
            type={passwordVisible}
            setImageClick={setPasswordVisible}
            errorToggle={state.password.errorToggle}
            errorContent="8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류를 조합하여 설정하세요."
          />
          <Styled.InputRoot
            title="비밀번호 확인"
            state={state}
            setState={setState}
            name="passwordCheck"
            type={passwordCheckVisible}
            setImageClick={setPasswordCheckVisible}
            errorToggle={state.passwordCheck.errorToggle}
            errorContent="비밀번호와 비밀번호 확인정보가 일치하지 않습니다."
          />
          <Styled.ButtonWrapper>
            <SubmitButton
              content="확인"
              backgroundColor={color.Color5ab1f0}
              width="80px"
              height="34px"
              handleClick={handleSubmit}
            />
          </Styled.ButtonWrapper>
        </Styled.Form>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
