import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  MainWrapper: styled.div`
    display: flex;
  `,
  LeftMainWrapper: styled.div`
    min-width: 730px;
    flex-grow: 2;
    display: flex;
    flex-flow: column;
    align-items: center;
  `,
  RightMainWrapper: styled.div`
    flex-grow: 1;
    margin-left: 30px;
    height: 533px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    h2 {
      display: block;
      padding: 13px 0 11px 24px;
      border-bottom: 1px solid ${color.Colord5d8db};
      font-size: 13px;
      line-height: 16px;
    }
  `,
  RightContent: styled.div`
    min-width: 350px;
    height: 260px;
    border: 1px solid ${color.Colord5d8db};
    background-color: ${color.Colorffffff};
    border-radius: 4px;

    .right-content-box {
      overflow: auto;
      height: 219px;
    }

    .flex-box {
      display: flex;
      flex-flow: column;
      padding: 14px 0 0 24px;
    }

    .title {
      font-size: 12px;
      line-height: 15px;
      color: ${color.Color777777};
      margin-bottom: 4px;
    }

    .content {
      font-size: 13px;
      line-height: 16px;
      color: ${color.Color121212};
      margin-bottom: 16px;
    }
  `,
};

export default Styled;
