import styled from "styled-components";
const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  CardWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 32px;
  `,
};
export default Styled;
