import React, { useState } from "react";
import { useImmer } from "use-immer";
import Styled from "./style";
import { color, image } from "style/globalStyle";
import { makeCryptoFunction } from "util/crypto";
import SubmitButton from "component/button/submitButton/index";
import { postHeadquarterPassword } from "api/account/index";
import { useDispatch } from "react-redux";
import { API, handleErrorState } from "store/reducer/apiSlice";

const initState = {
  password: "",
  imgToggle: false,
  errorToggle: false,
};
export default function CheckPassword(props) {
  const { setType } = props;
  const [state, setState] = useImmer(initState);
  const [imgToggle, setImgToggle] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cryptoedPassword = makeCryptoFunction(state.password);

    const res = await dispatch(
      API(postHeadquarterPassword({ password: cryptoedPassword }))
    );

    if (res.payload.status === 200) {
      setType("change");
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
      setState((draft) => {
        draft.errorToggle = true;
      });
    }
  };

  const handleInputChage = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft[name] = value;
      draft.errorToggle = false;
    });
  };

  return (
    <Styled.Root>
      <div className="title">비밀번호 재확인</div>
      <div className="desc">
        계정 정보 보호를 위해 비밀번호를 다시 입력해주세요.
      </div>
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.InputRoot errorToggle={state.errorToggle}>
          <input
            type={imgToggle ? "text" : "password"}
            onChange={handleInputChage}
            name="password"
            value={state.password}
          />
          <img
            src={imgToggle ? image.icEyeCheck : image.icEye}
            className="inner-icon"
            alt="inner-icon"
            onClick={() => {
              setImgToggle(!imgToggle);
            }}
          />
          <div className="error-desc">비밀번호가 올바르지 않습니다.</div>
        </Styled.InputRoot>
        <Styled.ButtonWrapper>
          <SubmitButton
            content="확인"
            backgroundColor={color.Color5ab1f0}
            width="80px"
            height="34px"
          />
        </Styled.ButtonWrapper>
      </Styled.Form>
    </Styled.Root>
  );
}
