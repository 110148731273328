import Styled from "./style";
import { image } from "style/globalStyle";
import { setModalVisible } from "hook/modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ImageModal(props) {
  // imgUrl을 동적으로 받아올 방법을 구상해야함
  let { imgUrl, setState, state } = props;
  const handleClick = () => {
    setModalVisible(setState, false);
  };

  return (
    <Styled.Root>
      {/* 이미지 임시로 저장 -> 추후 redux로 이미지 설정하면 될듯 */}
      {state.imageUrl === "" ? (
        <Skeleton width="420px" height="420px" />
      ) : (
        <>
          <Styled.Image src={imgUrl || image.dummy4} alt="product" />
          <Styled.ButtonWrapper>
            <Styled.CloseButton
              onClick={handleClick}
              src={image.icClose}
              alt="clost-button"
            />
          </Styled.ButtonWrapper>
        </>
      )}
    </Styled.Root>
  );
}
