import { getBreweriesNames } from "api/brewery/index";
import { getVendorsNames } from "api/vendor/index";
import { API } from "store/reducer/apiSlice";

export const getVendorList = async (dispatch, setState) => {
  const res = await dispatch(API(getVendorsNames("")));
  const vendorList = [];
  res.payload.data.vendors.forEach((item) => {
    const object = {
      label: item.vendorName,
      value: item.vendorId,
    };
    vendorList.push(object);
  });
  setState(vendorList);
  return vendorList;
};

export const getBreweryList = async (dispatch, setState) => {
  const res = await dispatch(API(getBreweriesNames("")));
  const breweryList = [];
  res.payload.data.breweries.forEach((item) => {
    const object = {
      label: item.breweryName,
      value: item.breweryId,
    };
    breweryList.push(object);
  });
  setState(breweryList);

  return breweryList;
};
