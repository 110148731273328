import styled from "styled-components";
import { color, typo } from "style/globalStyle";

import Modal from "component/modal/Modal";

const Styled = {
  StyleModal: styled(Modal)`
    width: 600px;
  `,
  ContentHead: styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 50px;
    padding: 7px 44px 7px 30px;
    background-color: ${color.Coloredf1f2};

    & > h3 {
      ${typo.medium14};
      color: ${color.Color323c41};
    }
  `,

  SearchArea: styled.form`
    display: flex;
    column-gap: 8px;
    height: 100%;

    & > input {
      width: 400px;
      height: 100%;
      border-radius: 2px;
      border: 1px solid ${color.Colorc9c9c9};
      padding: 0 16px;
      background-color: ${color.Colorffffff};
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 100%;
      border-radius: 2px;
      border: 1px solid ${color.Colorc9c9c9};
      font-size: 13px;
      line-height: 1.2307;
      color: ${color.Color323c41};
      background-color: ${color.Colore0e5e8};
    }
  `,

  ContentBody: styled.div`
    background-color: ${color.Colorffffff};
  `,

  ListHead: styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
    height: 40px;
    background-color: ${color.Colorf9fbfb};

    & > div {
      ${typo.medium13};
      display: flex;
      align-items: center;
      height: 100%;
      color: ${color.Color4d4e56};
    }

    & > div:first-of-type {
      position: relative;
      padding-left: 72px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        align-items: center;
        width: 1px;
        height: 16px;
        background-color: ${color.Colore0e5e8};
        transform: translateY(-50%);
      }
    }

    & > div:last-of-type {
      padding: 0 12px;
    }
  `,

  List: styled.ul`
    height: 360px;
    max-height: 360px;
    background-color: ${color.Colorffffff};
    overflow: overlay;
  `,

  Item: styled.li`
    display: grid;
    grid-template-columns: 40px 1fr 209px;
    height: 40px;
    padding: 0 31px;

    & > label {
      ${typo.regular13};
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      color: ${color.Color1b242b};
      cursor: pointer;

      &:first-of-type::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        align-items: center;
        width: 1px;
        height: 16px;
        background-color: ${color.Colore0e5e8};
        transform: translateY(-50%);
      }

      &:last-of-type {
        padding: 0 12px;
      }
    }
  `,
  BreweryName: styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;
  `,
  Bottom: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    border-top: 1px solid ${color.Colore0e5e8};
    background-color: ${color.Colorffffff};
  `,
  ConfirmBtn: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 2px;
    padding: 0 20px;
    font-size: 13px;
    font-wegiht: 500;
    color: ${color.Colorffffff};
    background-color: ${color.Color5ab1f0};

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowd;
    }
  `,
};

export default Styled;
