import { ax } from "api/index";

export const postPubs = (body) => async () => {
  return await ax.post(`/pubs`, body);
};

export const getPubs = (req) => async () => {
  return await ax.get("/pubs", { params: { ...req } });
};

export const getPubsNames = (req) => async () => {
  return await ax.get("/pubs/names", { params: { ...req } });
};

export const getPubsPubId = (pubId) => async () => {
  return await ax.get(`/pubs/${pubId}`);
};

export const getPubsRequested = () => async () => {
  return await ax.get("/pubs/requested");
};

export const putPubs = (id, body) => async () => {
  return await ax.put(`/pubs/${id}`, body);
};

export const patchPubsPubIdApprove = (req) => async () => {
  return await ax.patch(`/pubs/${req.pubId}/approve`, { params: { ...req } });
};

export const patchPubsPubIdReject = (req) => async () => {
  return await ax.patch(`/pubs/${req.pubId}/reject`, { params: { ...req } });
};

export const deletePubsPubId = (pubId) => async () => {
  return await ax.delete(`/pubs/${pubId}`);
};

export const loadFranchiseList = () => async () => {
  return await ax.get("/pubs/franchises");
};
