import styled from "styled-components";

import { ReactComponent as CloseIconImg } from "asset/image/icClose.svg";
import SelectBox from "component/selectBox/index";
import { color } from "style/globalStyle";

export const Root = styled.div`
  border: 1px solid ${color.Colore0e5e8};
  padding: 16px;
  background-color: ${color.Colorf9fbfb};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 17px;
  margin-bottom: 12px;

  & span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    color: ${color.Color121212};
  }

  & button {
    height: 100%;
  }
`;

export const DeleteIcon = styled(CloseIconImg)``;

export const SettingWrapper = styled.div``;

export const SettingItem = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  & dt {
    margin-right: 24px;

    & label {
      font-size: 14px;
      line-height: 1.21;
      color: ${color.Color777777};
      white-space: nowrap;
    }
  }

  & dd {
    width: 100%;
  }
`;

export const VolumeSelectBox = styled(SelectBox)`
  width: 100%;

  & + div {
    width: 100%;
  }
`;
