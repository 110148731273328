import React from "react";
import { useCallback } from "react";
import Styled from "./style";
export default function SwitchButton(props) {
  const { state, setState, name } = props;

  // const [checked, setChecked] = useState(document.querySelector(`input[type='checkbox']`).checked);

  const handleChange = useCallback(() => {
    setState((draft) => {
      draft[name] = !draft[name];
    });
  }, []);

  return (
    <Styled.Root checked={state[name]}>
      <input type="checkbox" checked onChange={handleChange} />
      <span className="switch"></span>
    </Styled.Root>
  );
}
