import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
    text-align: center;
    height: ${(props) => props.height};
    border-radius: 5px;
    background-color: ${(props) => props.backgroundColor};
    color: ${color.Colorffffff};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    pointer-events: ${(props) => props.disabled && "none"};
    outline: none;
    border: none;
    cursor: pointer;

    span {
      color: ${color.Colorffffff};
    }
  `,
};

export default Styled;
