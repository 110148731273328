import styled from "styled-components";
import { color, image } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    height: 100%;
    background: url("${image.loginBack}") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Form: styled.form`
    width: 477px;
    height: 592px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.3);
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .keep-login-wrapper {
      width: 100%;
    }
    .logo {
      width: 193px;
      height: 96px;
      padding: 9.6px 12.2px;
    }
  `,
  InputWrapper: styled.div`
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // row-gap: 40px;
  `,
  KeepLogin: styled.div`
    margin-top: ${(props) => (props.isPasswordValid ? "80px" : "40px")};
    display: flex;
    column-gap: 10px;
    align-items: center;
    width: 150px;
    border: none;
    :hover,
    div {
      color: ${color.Colorffffff};
      cursor: pointer;
    }
  `,
  LoginButton: styled.button`
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background-color: ${color.Color000000};
    color: ${color.Colorffffff};
    font-size: 16px;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: ${(props) =>
      props.disabled ? "not-allowed !important" : "pointer"};
    :disabled {
      background-color: rgba(18, 18, 18, 0.4);
    }
  `,
  Small: styled.small`
    color: #ff0000;
    font-size: 12px;
    line-height: 17px;
    margin: 4px 0 19px;
  `,
};
export default Styled;
