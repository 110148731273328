import React from "react";
import { image } from "style/globalStyle";
import { checkPasswordValidate } from "util/validation";
import Styled from "./style";
export default function PasswordInput(props) {
  const {
    title,
    state,
    setState,
    name,
    type,
    setImageClick,
    errorToggle,
    errorContent,
    height = "40px",
    className,
    isValidError,
  } = props;

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    let check = "";

    setState((draft) => {
      switch (name) {
        case "password":
          check = checkPasswordValidate(value);
          break;
        default:
          //  passwordCheck
          check = !(state.password.value === value);
          break;
      }
      draft[name].value = value;
      draft[name].errorToggle = check;
    });
  };

  return (
    <Styled.Root
      gridName={name}
      imgSrc={type}
      errorToggle={errorToggle}
      className={className}
    >
      <div className="title">
        {title}
        <Styled.Essential aria-label="필수값">*</Styled.Essential>
      </div>
      <div className="input-wrapper">
        <Styled.Input
          type={type ? "text" : "password"}
          onChange={handleChangeInput}
          name={name}
          value={state[name].value}
          maxLength={16}
          height={height}
          validation={isValidError && state[name].value === ""}
        />

        <img
          src={type ? image.icEyeCheck : image.icEye}
          className="inner-icon"
          alt="inner-icon"
          onClick={() => {
            setImageClick(!type);
          }}
        />
      </div>
      <div className="error-desc">{errorContent}</div>
    </Styled.Root>
  );
}
