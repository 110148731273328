import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  Root: styled.div``,
  Wrapper: styled.div`
    display: inline-block;
    width: 255px;
    height: 296px;
    background-color: ${color.Colorffffff};
    cursor: pointer;
  `,
  Image: styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 10:7의 비율 //TODO: 추후 수정
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  Time: styled.div`
    width: 47px;
    height: 19px;
    opacity: 0.8;
    text-align: center;
    border-radius: 5px;
    background-color: ${color.Color121212};
    color: ${color.Colorffffff};
    font-size: 12px;
    line-height: 1.7;
    bottom: 10px;
    right: 10px;
    position: absolute;
  `,
  Title: styled.div`
    margin-bottom: 12px;
    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: ${color.Color121212};
      cursor: pointer;
    }
  `,
  Date: styled.div`
    margin-bottom: 12px;
    cursor: pointer;
    span {
      line-height: 22px;
      font-size: 14px;
      color: ${color.Color777777};
    }
  `,
};

export default Styled;
