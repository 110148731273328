import styled from "styled-components";

import { color } from "style/globalStyle";

export const Root = styled.input`
  border-radius: 4px;
  border: 1px solid ${color.Colorc9c9c9};
  padding: 0 16px;
  font-size: 14px;
  line-height: 1.71;
  color: ${color.Color121212};

  &::placeholder {
    color: ${color.Colorb1b1b1};
  }

  &:focus {
    border: 1px solid ${color.Color5ab1f0};
  }
`;
