import React, { Fragment } from "react";
import { useImmer } from "use-immer";
import Styled from "./style";
import Avatar from "component/avatar/index";
import IconAvatar from "component/iconAvatar/index";
import { image, color } from "style/globalStyle";
import {
  permissionStoreTitle,
  permissionOtherTitle,
} from "asset/data/permissionDummy";
import SubmitButton from "component/button/submitButton/index";
import CancelButton from "component/button/cancelButton/index";
import { modalCont } from "asset/data/modalCont";
import PortalModal from "container/modal/portalModal/index";
import { setModalKey } from "hook/modal";
import {
  patchBeveragesOptionsOptionsIdApprove,
  patchBeveragesOptionsOptionsIdReject,
} from "api/beverage/index";
import { useDispatch } from "react-redux";
import { API } from "store/reducer/apiSlice";
import { patchPubsPubIdApprove, patchPubsPubIdReject } from "api/pub/index";
import { getPresigned } from "api/presigend/index";

const initState = {
  modal: {
    modalVisible: false,
    modalKey: "",
  },
  arrow: {
    isClicked: false,
  },
  imageUrl: "",
};

export default function PermissionCard({
  item,
  type,
  imageUrl,
  initPermissionData,
  toast,
}) {
  const [state, setState] = useImmer(initState);
  const dispatch = useDispatch();
  const { storeName, productName, category2, amount } = item;
  // 매장과 (도매사, 제조사)에 따라서 다른 포맷과 데이터 사용 - start
  const titleList =
    type === "pubs" ? permissionStoreTitle : permissionOtherTitle;
  const headerName = type === "pubs" ? storeName : productName;
  const headerContent = type === "pubs" ? category2 : amount;
  // 매장과 (도매사, 제조사)에 따라서 다른 포맷과 데이터 사용 - end

  // 도매사, 제조사내 이미지 클릭 이벤트 발생시 이벤트 핸들러
  const handleImageShow = async () => {
    const imageUrl = await dispatch(API(getPresigned(item.filePath)));
    setState((draft) => {
      draft.imageUrl = imageUrl.payload.data.signedUrl;
    });
    setModalKey(setState, "imageShow");
  };

  // 승인 버튼 클릭 이벤트 발생시 이벤트 핸들러
  const handlePermit = async () => {
    switch (type) {
      case "pubs":
        const pubRes = await dispatch(
          API(
            patchPubsPubIdApprove({
              pubId: item.pubId,
            })
          )
        );

        if (pubRes.payload.status === 200) {
          initPermissionData();
          toast("신규 매장을 등록하였습니다.");
        }

        break;
      default:
        const body = {
          sellerId: item.sellerId,
          sellerType: item.sellerType,
          beverageId: item.beverageId,
          beverageName: item.beverageName,
        };

        const res = await dispatch(
          API(patchBeveragesOptionsOptionsIdApprove(item.optionId, body))
        );

        if (res.payload.status === 200) {
          initPermissionData();
          toast("신규 상품을 등록하였습니다.");
        }
        break;
    }
  };
  // 거절 버튼 클릭 이벤트 발생시 이벤트 핸들러
  const handleRefuse = async () => {
    switch (type) {
      case "pubs":
        setModalKey(setState, "refuseStoreConfirmModal");
        break;
      default:
        setModalKey(setState, "refuseProductConfirmModal");
        break;
    }
  };

  const handleArrow = async () => {
    setState((draft) => {
      draft.arrow.isClicked = !state.arrow.isClicked;
    });
  };

  // 매장, 도매사, 제조사 아이콘
  const avatar = () => {
    switch (type) {
      case "pubs":
        return <Avatar username={storeName} image={image.icProfile} />;
      case "vendor":
        return (
          <IconAvatar
            image={image.icWholesaler}
            backgroundColor={color.Colorebfdee}
            borderColor={color.Color0f8623}
            alt={type}
          />
        );
      default:
        return (
          <IconAvatar
            image={image.icMaker}
            backgroundColor={color.Colore8f0fb}
            borderColor={color.Color1f6cd5}
            alt={type}
          />
        );
    }
  };

  const activateFunction = async (inputValue) => {
    if (type === "pubs") {
      const req = {
        pubId: item.pubId,
      };
      const body = {
        exuse: inputValue,
      };
      const res = await dispatch(API(patchPubsPubIdReject(req, body)));
      if (res.payload.status === 200) {
        initPermissionData();
        toast("매장 등록을 거절하였습니다.");
      }
    } else {
      const body = {
        sellerId: item.sellerId,
        sellerType: item.sellerType,
        beverageId: item.beverageId,
        beverageName: item.beverageName,
        excuse: inputValue,
      };

      const res = await dispatch(
        API(patchBeveragesOptionsOptionsIdReject(item.optionId, body))
      );
      if (res.payload.status === 200) {
        initPermissionData();
        toast("상품 등록을 거절하였습니다.");
      }
    }
  };

  const handleDownloadImage = async () => {
    const imageUrl = await dispatch(API(getPresigned(item.filePath)));

    fetch(imageUrl.payload.data.signedUrl, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "사업자등록증.jpg";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 6000);
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Styled.Root>
      {state.modal.modalVisible && (
        <PortalModal setState={setState}>
          {modalCont({
            modalKey: state.modal.modalKey,
            setState,
            reload: true,
            activateFunction,
            imageUrl: state.imageUrl,
            state: state,
          })}
        </PortalModal>
      )}
      <Styled.HeaderWrapper onClick={handleArrow}>
        <Styled.UpperHeader>
          <span className="date">요청일시: {item.create}</span>
        </Styled.UpperHeader>
        <Styled.LowerHeader>
          {avatar()}
          <div className="store-header">
            <h1 className="header-title">{headerName}</h1>
            <span>{headerContent}</span>
          </div>
          <Styled.ArrowIcon
            className="arrow-icon"
            src={image.icArrowDown}
            rotate180={state.arrow.isClicked}
            aria-label="펼치기"
          />
        </Styled.LowerHeader>
      </Styled.HeaderWrapper>
      {state.arrow.isClicked && (
        <Styled.Main>
          {titleList &&
            titleList.map((titleItem, idx) => {
              const { label, key, type } = titleItem;
              return (
                <Fragment key={idx}>
                  <div className="main-title">{label}</div>
                  <div className="main-content" type={type}>
                    {item[key]}
                    {type === "download" && (
                      <img
                        onClick={handleDownloadImage}
                        url={imageUrl}
                        className="main-icon"
                        src={image.icDownloadSmall}
                        alt="downlod-icon"
                      />
                    )}
                    {type === "show" && (
                      <img
                        className="main-icon"
                        src={image.icPicture}
                        alt="show-icon"
                        onClick={handleImageShow}
                      />
                    )}
                  </div>
                  {type === "underline" && (
                    <div className="main-empty-line"></div>
                  )}
                </Fragment>
              );
            })}
          <div className="main-buttons">
            <CancelButton
              handleClick={handleRefuse}
              content="거절"
              height="34px"
              width="80px"
            />
            <SubmitButton
              content="승인"
              width="80px"
              height="34px"
              handleClick={handlePermit}
              backgroundColor={color.Color5ab1f0}
            />
          </div>
        </Styled.Main>
      )}
    </Styled.Root>
  );
}
