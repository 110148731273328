import React from "react";
import SearchInput from "component/input/searchInput/index";
import AddButton from "component/button/addButton/index";
import ReloadButton from "component/button/reloadButton/index";
import Styled from "./style";
import SearchButton from "component/button/searchButton/index";

export default function LinkSearchBar(props) {
  const {
    state,
    setState,
    tabLabel,
    handleSearch,
    handleChangeSearchInput,
    handleReload,
  } = props;

  return (
    <Styled.Root>
      <Styled.Front>
        <Styled.SearchInput
          state={state}
          setState={setState}
          searchValue={state.searchBarData.searchValue}
          placeholder={`${tabLabel[state.menu].firstName.label}, ${
            tabLabel[state.menu].lastName.label
          }명으로 검색`}
          handleSearch={handleSearch}
          handleChangeSearchInput={handleChangeSearchInput}
        />
        <SearchButton onClick={handleSearch} />
      </Styled.Front>
      <Styled.Back>
        <AddButton setState={setState} content="링크 생성" />
        <ReloadButton handleReload={handleReload} />
      </Styled.Back>
    </Styled.Root>
  );
}
