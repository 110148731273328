import styled from "styled-components";
import { color } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 30px;
    width: 540px;
    height: 258px;
    border-radius: 5px;
    background-color: ${color.Colorffffff};
  `,
  MainWrapper: styled.div`
    .title {
      font-size: 20px;
      font-weight: bold;
      color: ${color.Color121212};
    }
    .content {
      font-size: 14px;
      color: ${color.Color777777};
      margin: 8px 0 28px 0;
    }
    .input-area {
      width: 480px;
      height: 38px;
      border-radius: 4px;
      padding: 10px;
      border: solid 1px ${color.Colorc9c9c9};
    }
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    margin-top: 58px;
  `,
};

export default Styled;
