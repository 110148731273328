import React from "react";

import { PlusIcon } from "./style";

const PlusButton = ({ ...restProps }) => {
  return (
    <button type="button" {...restProps}>
      <PlusIcon />
    </button>
  );
};

export default PlusButton;
