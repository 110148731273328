import React from "react";
import styled from "styled-components";

const Radio = ({ className, label, id, name, checked, value, onChange }) => {
  return (
    <Wrapper className={className}>
      <input
        type="radio"
        id={id}
        name={name}
        tabIndex={-1}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} tabIndex={0}>
        {label}
      </label>
    </Wrapper>
  );
};

export default Radio;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    padding: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  input[type="radio"] + label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #c9c9c9;
      text-align: center;
      transition: 0.3s;
    }

    &::after {
      content: "";
      position: absolute;
      left: 4px;
      display: flex;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  input[type="radio"]:checked + label {
    &::after {
      background-color: #000000;
    }
  }
`;
