import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    grid-area: ${(props) => props.gridName};

    .input-wrapper {
      position: relative;
      .inner-icon {
        position: absolute;
        right: 16px;
        top: 8px;
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
  LabelWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .label {
      font-size: 14px;
      font-weight: 500;
      color: ${color.Color777777};
    }
  `,

  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,

  Input: styled.input.attrs(({ validation }) => ({
    border: validation
      ? `1px solid ${color.Colorff0000}`
      : `1px solid ${color.Colorc9c9c9}`,
  }))`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "40px")};
    padding: 0 16px;
    border-radius: 5px;
    border: ${({ border }) => border};
    outline: none;
    :focus {
      border: solid 1px ${color.Color5ab1f0};
    }
    ::placeholder {
      color: ${color.Colorb1b1b1};
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      appearance: none;
    }
  `,

  TextAreaWrapper: styled.div.attrs(({ validation }) => ({
    border: validation
      ? `1px solid ${color.Colorff0000}`
      : `1px solid ${color.Colorc9c9c9}`,
  }))`
    position: relative;

    && textarea {
      width: 100%;
      height: ${(props) => (props.height ? props.height : "40px")};
      margin-bottom: 0;
      padding: 16px 16px;
      /* padding: 23px 16px; */
      border-radius: 4px;
      font-size: 14px;
      border: ${({ border }) => border};
      outline: none;
      :focus {
        border: solid 1px ${color.Color5ab1f0};
      }
      ::placeholder {
        color: ${color.Colorb1b1b1};
      }
    }

    & div {
      top: unset;
      bottom: 20px;
    }
  `,

  AddressPostInput: styled.div.attrs(({ validation }) => ({
    border: validation
      ? `1px solid ${color.Colorff0000}`
      : `1px solid ${color.Colorc9c9c9}`,
  }))`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: ${({ border }) => border};
    position: relative;
    display: flex;
    padding: 0 10px 0 16px;
    justify-content: space-between;
    align-items: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      color: ${(props) => props.contentColor};
    }
    :hover {
      cursor: pointer;
    }
  `,
  Unit: styled.div`
    display: grid;
    grid-template-columns: 1fr 68px;
    column-gap: 10px;
    .unit {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: solid 1px ${color.Colorc9c9c9};
      background-color: ${color.Colore0e5e8};
    }
  `,
  ErrorMessage: styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    color: ${color.Colorce4d4e};
  `,

  LengthCheckWrapper: styled.div`
    position: absolute;
    top: 12px;
    right: 12px;

    & span {
      color: ${color.Colorb1b1b1};
    }
  `,
  Explain: styled.span`
    margin-left: 6px;
    font-size: 12px;
    line-height: 1.25;
    color: ${color.Color5ab1f0};
  `,
};
export default Styled;
