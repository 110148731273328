import { ax } from "api/index";

export const loadPubs = (req) => {
  const params = {
    ...(req?.franchiseName && { franchiseName: req.franchiseName }),
    ...(req?.franchise && { franchise: req.franchise }),
    ...(req?.area && { area: req.area }),
  };

  return ax.get("/headquarter/buyers/pubs", { params });
};

export const loadBreweries = () => {
  return ax.get("/headquarter/sellers");
};

export const loadPubsTransactions = (req) => {
  return ax.get("/headquarter/orders/pubs", { params: { ...req } });
};

export const loadPubsSellers = (req) => {
  return ax.get("/headquarter/orders/pubs/sellers", { params: { ...req } });
};

export const loadVendorsSellers = (req) => {
  return ax.get("/headquarter/orders/vendors/sellers", { params: { ...req } });
};

export const loadPubsBuyers = (req) => {
  return ax.get("/headquarter/orders/pubs/buyers", { params: { ...req } });
};

export const loadVendorsBuyers = (req) => {
  return ax.get("/headquarter/orders/vendors/buyers", { params: { ...req } });
};

export const loadPubOrderHistory = (optionId, body) => {
  return ax.post(`/headquarter/orders/pubs/${optionId}`, body);
};

export const downloadPubsExcel = (req) => {
  return ax.get("/headquarter/orders/excel/pubs", {
    params: req,
    responseType: "blob",
  });
};

export const loadVendors = () => {
  return ax.get("/headquarter/buyers/vendors");
};

export const loadVendorsTransactions = (req) => {
  return ax.get("/headquarter/orders/vendors", { params: { ...req } });
};

export const loadVendorOrderHistory = (optionId, body) => {
  return ax.post(`/headquarter/orders/vendors/${optionId}`, body);
};

export const downloadVendorsExcel = (req) => {
  return ax.get("/headquarter/orders/excel/vendors", {
    params: req,
    responseType: "blob",
  });
};
