import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Styled from "./style";
import UserInfo from "component/userInfo/index";
import Alert from "component/alert/index";
import { headerTitle } from "asset/data/common";

export default function Header() {
  const location = useLocation();

  const globalStoreValue = useSelector((state) => ({
    navbarSize: state.common.navbarSize,
  }));

  return (
    <Styled.Root width={globalStoreValue.navbarSize}>
      <Styled.HeaderText main={headerTitle[location.pathname.substr(1)].deps}>
        {headerTitle[location.pathname.substr(1)].name}
      </Styled.HeaderText>
      <Styled.IconWrapper>
        <Alert />
        <UserInfo />
      </Styled.IconWrapper>
    </Styled.Root>
  );
}
