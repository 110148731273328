import styled from "styled-components";

import { color } from "style/globalStyle";
import SelectBox from "component/selectBox/index";

export const Root = styled.div`
  width: ${(props) => (props.rootWidth ? props.rootWidth : "100%")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "1100px")};
  background-color: ${color.Colorffffff};
  border-radius: 5px;
  border-collapse: separate;
  border: 1px solid ${color.Colord5d8db};
  /* div {
      min-width: max-content; // 가로 스크롤 해도 글자 무너지지 않음
    } */
`;
export const Thead = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.Colord5d8db};
`;

export const Th = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  text-align: left;
  height: inherit;
  line-height: ${(props) => props.height};
  padding-left: 10px;
  display: flex;
  :hover {
    cursor: ${(props) => props.pointer};
  }
  img {
    transform: ${(props) => props.rotate180 && `rotate(180deg)`};
  }
`;

export const Tbody = styled.div``;

export const Td = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  & > span {
    display: block;
    padding-top: 2px;
    white-space: ${(props) => (props.nowrap ? "nowrap" : "pre-line")};
    line-height: ${(props) => (props.nowrap ? "unset" : "1.3")};
    overflow: ${(props) => (props.nowrap ? "hidden" : "unset")};
    text-overflow: ${(props) => (props.nowrap ? "ellipsis" : "unset")};
  }
`;

export const Tr = styled.div`
  display: flex;
  background-color: ${(props) => props.selected && color.Colorf6f8f8};
  padding-left: 22px;
  border-bottom: 1px solid ${color.Coloredf1f2};
`;

export const MenuColumn = styled.div`
  width: 58px;
`;

export const ImgWrapper = styled.figure`
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border-radius: 4px;

  &::before {
    content: ${({ isNewLabel }) => (isNewLabel ? "'NEW'" : false)};
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 16px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.18;
    color: ${color.Colorffffff};
    border-radius: 2px;
    background-color: ${color.Color5ab1f0};
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RatingSelectBox = styled(SelectBox)`
  width: 88px;
  height: 34px;
`;
