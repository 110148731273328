import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { modalCont } from "asset/data/modalCont";
import { vendorInfoInputList } from "asset/data/infoInputList";
import Styled from "../create/style";
import { color, image } from "style/globalStyle";
import PortalModal from "container/modal/portalModal/index";
import UploadImg from "component/uploadImg/index";
import InfoInput from "component/input/infoInput/index";
import SubmitButton from "component/button/submitButton/index";
import CancelButton from "component/button/cancelButton/index";
import CustomPrompt from "component/prompt/index";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { getVendorsVendorId, putVendorsVendorId } from "api/vendor/index";
import { setModalKey } from "hook/modal";
import usePrompt from "hook/usePrompt";
import { phoneNumToString } from "util/makeDataForm";
import { makeCheckObject } from "util/makeCheckList";

import { checkAwsApiStaus } from "util/presigend";
import useToast from "hook/useToast";
import Toast from "component/toast/index";

const initSubmitState = {
  vendorName: "", //  도매사명
  vendorPhone: "", //  도매사 전화번호
  taxcode: "", //  사업자 등록번호
  licenseTotal: "", //  거래구분(종합면허)
  licenseSpecific: "", // 거래구분(특정면허)
  licenseForeign: "", //  거래구문(수입면허)
  status: "", //  영업상태
  addr: "", //  도매사 주소
  addrDetail: "", //  도매사 상세 주소
  area: "", //  납품지역
  managerName: "", //  대표자명
  managerPhone: "", //  대표자 핸드폰번호
  managerEmail: "",
  picName: "", //  본사 담당자명
  picPhone: "", //  본사 담당자 핸드폰번호
  file: "", //  사업자등록증
};

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};

export default function VendorUpdate() {
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [modalState, setModalState] = useImmer(initModalState);
  const [imgUrl, setImgUrl] = useState(""); //이미 등록되어 있는 사업자등록증 url 주소
  const [isValidError, setIsValidError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isShow, message, type, toast } = useToast();
  const { id } = queryString.parse(location.search);

  const checkList = makeCheckObject({ ...submitState }, [""]);
  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList,
  });
  const handleClickCancel = () => {
    history.push("/vendor");
  };

  const handleSubmit = async () => {
    const requiredValue = Object.entries(submitState)
      .filter(
        ([key, _]) =>
          key !== "picName" && key !== "picPhone" && key !== "addrDetail"
      )
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {});

    if (!isValidEmpty(requiredValue)) {
      toast("필수 기입 정보를 입력해 주세요", "warning");
      setIsValidError(true);
      return;
    }

    const licenseTotal = submitState.licenseTotal === "0" ? false : true;
    const licenseSpecific = submitState.licenseSpecific === "0" ? false : true;
    const licenseForeign = submitState.licenseForeign === "0" ? false : true;
    const status = submitState.status === "0" ? "closed" : "open";

    const body = {
      taxcode: submitState.taxcode,
      vendorName: submitState.vendorName,
      vendorPhone: phoneNumToString(submitState.vendorPhone),
      addr: submitState.addr,
      addrDetail: submitState.addrDetail,
      area: submitState.area,
      licenseTotal: licenseTotal,
      licenseSpecific: licenseSpecific,
      licenseForeign: licenseForeign,
      status: status,
      managerName: submitState.managerName,
      managerPhone: phoneNumToString(submitState.managerPhone),
      managerEmail: submitState.managerEmail,
      picName: submitState.picName,
      picPhone: phoneNumToString(submitState.picPhone),
      contentType: submitState.file?.type,
    };

    const res = await dispatch(API(putVendorsVendorId(id, body)));

    if (res.payload.status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);

      if (awsStatus === 204 || awsStatus === 200) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/vendor",
          "수정 정보가 저장되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const isValidEmpty = (obj) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "object" ? item.value !== "" : item !== "";
    };

    return Object.values(obj).every(hasNotEmptyValue);
  };

  const getDetailInfo = async () => {
    const {
      payload: { data },
    } = await dispatch(API(getVendorsVendorId(id)));

    setInitList({
      vendorName: data.vendorName === null ? "" : data.vendorName,
      vendorPhone: data.vendorPhone === null ? "" : data.vendorPhone,
      taxcode: data.taxcode === null ? "" : data.taxcode,
      licenseTotal: data.licenseTotal === 1 ? "1" : "0",
      licenseSpecific: data.licenseSpecific === 1 ? "1" : "0",
      licenseForeign: data.licenseForeign === 1 ? "1" : "0",
      status: data.status === "open" ? "1" : "0",
      addr: data.addr === null ? "" : data.addr,
      addrDetail: data.addrDetail === null ? "" : data.addrDetail,
      area: data.area === null ? "" : data.area,
      managerName: data.managerName === null ? "" : data.managerName,
      managerPhone: data.managerPhone === null ? "" : data.managerPhone,
      managerEmail: data.managerEmail === null ? "" : data.managerEmail,
      picName: data.picName === null ? "" : data.picName,
      picPhone: data.picPhone === null ? "" : data.picPhone,
      file: data.fileId,
    });

    setSubmitState((draft) => {
      draft.vendorName = data.vendorName === null ? "" : data.vendorName;
      draft.vendorPhone = data.vendorPhone === null ? "" : data.vendorPhone;
      draft.taxcode = data.taxcode === null ? "" : data.taxcode;
      draft.licenseTotal = data.licenseTotal === 1 ? "1" : "0";
      draft.licenseSpecific = data.licenseSpecific === 1 ? "1" : "0";
      draft.licenseForeign = data.licenseForeign === 1 ? "1" : "0";
      draft.status = data.status === "open" ? "1" : "0";
      draft.addr = data.addr === null ? "" : data.addr;
      draft.addrDetail = data.addrDetail === null ? "" : data.addrDetail;
      draft.area = data.area === null ? "" : data.area;
      draft.managerName = data.managerName === null ? "" : data.managerName;
      draft.managerPhone = data.managerPhone === null ? "" : data.managerPhone;
      draft.managerEmail = data.managerEmail;
      draft.picName = data.picName === null ? "" : data.picName;
      draft.picPhone = data.picPhone === null ? "" : data.picPhone;
      draft.file = data.fileId;
    });
    setImgUrl(data.signedUrl);
  };

  useEffect(() => {
    getDetailInfo();
  }, []);

  return (
    <>
      <Styled.Root>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Header>도매사 정보를 입력해 주세요</Styled.Header>
        <Styled.Wrapper>
          <Styled.EssentialInfo>
            <div className="header">
              <img src={image.icEssential} alt="essential-icon" />
              <span>필수정보</span>
            </div>
            <Styled.InputWrapper>
              {vendorInfoInputList.map((item, idx) => {
                const {
                  title,
                  name,
                  inputType,
                  radioContents,
                  explain,
                  isRequired,
                } = item;

                return (
                  <Styled.InfoInput
                    key={idx}
                    title={title}
                    state={submitState}
                    setState={setSubmitState}
                    name={name}
                    inputType={inputType}
                    radioContents={radioContents}
                    explain={explain}
                    isRequired={isRequired}
                    isValidError={isValidError}
                  />
                );
              })}
            </Styled.InputWrapper>
          </Styled.EssentialInfo>
          <Styled.LicenseWrapper>
            <div className="header">
              <img src={image.icBusiness} alt="business-icon" />
              <span>사업자 등록증</span>
              <Styled.Essential aria-label="필수값">*</Styled.Essential>
            </div>
            <UploadImg
              content="사업자 등록증 이미지를 가져오세요"
              state={submitState.file}
              setState={setSubmitState}
              name="file"
              url={imgUrl}
              isValidError={isValidError}
            />
          </Styled.LicenseWrapper>
        </Styled.Wrapper>
        <Styled.RegistManagerWrapper>
          <div className="header">
            <img src={image.icPerson} alt="partner-icon" />
            <span>도매사 담당자 등록</span>
          </div>
          <div className="inputWrapper">
            <InfoInput
              title="담당자명"
              state={submitState}
              setState={setSubmitState}
              placeholder="도매사의 대표 담당자를 입력해 주세요."
              name="picName"
              inputType="input"
            />
            <InfoInput
              title="담당자 연락처"
              state={submitState}
              setState={setSubmitState}
              placeholder="담당자의 연락처를 입력해 주세요."
              name="picPhone"
              inputType="phone"
            />
          </div>
        </Styled.RegistManagerWrapper>
        <Styled.ButtonWrapper>
          <CancelButton handleClick={handleClickCancel} />
          <SubmitButton
            content="수정 완료"
            backgroundColor={color.Color5ab1f0}
            handleClick={handleSubmit}
            width="120px"
            height="40px"
          />
        </Styled.ButtonWrapper>
      </Styled.Root>
      <Toast isShow={isShow} message={message} type={type} />
    </>
  );
}
