import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    .alarm-icon {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  `,
  AlertDesc: styled.div`
    position: absolute;
    top: 56px;
    right: 52px;
    width: 340px;
    height: 472px;
    padding: 23px 8px 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d5d8db;
    background-color: #fff;
  `,
  Header: styled.div`
    position: relative;
    padding: 0 12px;

    &::after {
      position: absolute;
      bottom: -15px;
      content: "";
      width: calc(100% - 24px);
      border-top: 1px solid ${color.Colorc9c9c9};
    }
  `,
  HeaderContent: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      font-size: 16px;
      font-weight: 500;
    }
    .read-icon {
      width: 24px;
      height: 24px;
      cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
    }
  `,

  DetailWrapper: styled.ul`
    height: calc(400px);
    padding: 0 7px 0 12px;
    overflow: auto;
  `,
  Detail: styled.li`
    padding: 12px 0;
    opacity: ${(props) => (props.unread === 0 ? 0.5 : 1)};

    button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 9px 0;
      cursor: pointer;

      img,
      div {
        pointer-events: none;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 16px;
      }
      .alert-content {
        font-size: 13px;
        margin-bottom: 5px;
      }
      .alert-time {
        font-size: 12px;
        color: ${color.Color777777};
      }
    }
  `,
};
export default Styled;
