import React, { useState } from "react";
import { image } from "style/globalStyle";
import Styled from "./style";
import PasswordInput from "component/input/passwordInput/index";
import BlockAutoComplete from "component/blockAutoComplete/index";

export default function LoginInfoRegist(props) {
  const { submitState, setSubmitState, isValidError } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);

  return (
    <Styled.Root>
      <div className="header">
        <img src={image.icLogin} alt="login-icon" />
        <span>정보 등록</span>
        <span className="desc">*아이디는 사업자 등록번호와 동일합니다.</span>
      </div>
      <Styled.InputWrapper>
        <BlockAutoComplete />
        <PasswordInput
          title="초기 비밀번호 설정"
          autoComplete="off"
          state={submitState}
          setState={setSubmitState}
          name="password"
          type={passwordVisible}
          setImageClick={setPasswordVisible}
          errorToggle={submitState.password.errorToggle}
          errorContent="8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류를 조합하여 설정하세요."
          isValidError={isValidError}
        />
        <PasswordInput
          title="비밀번호 확인"
          state={submitState}
          setState={setSubmitState}
          name="passwordCheck"
          type={passwordCheckVisible}
          setImageClick={setPasswordCheckVisible}
          errorToggle={submitState.passwordCheck.errorToggle}
          errorContent="비밀번호와 비밀번호 확인정보가 일치하지 않습니다."
          isValidError={isValidError}
        />
      </Styled.InputWrapper>
    </Styled.Root>
  );
}
