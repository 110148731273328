import { createSlice } from "@reduxjs/toolkit";
// 이곳에서 관리하는 것은 list에 해당하는 것이 아닌, state 값만 관리
const initialState = {
  addButtonContent: "",
  addButtonPath: "",
  inputPlaceholder: "",
  searchInput: "",
  selectValue: {},
  checkList: [],
  reload: false,
  submitToggle: false,
};

const searchBarSlice = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    setSearchBarReload(state) {
      // reload 버튼 클릭
      state.reload = !state.reload;
      state.searchInput = ""; //  검색 값
      state.selectValue = {}; //  select box 값
      state.checkList.forEach((item) => {
        item.selected = false;
      });
    },

    setSearchBarInit(state, action) {
      // Mount 할때 초기 값 세팅
      const {
        inputPlaceholder = "",
        addButtonContent = "",
        addButtonPath = "",
        checkList = [],
      } = action.payload;
      state.addButtonContent = addButtonContent;
      state.addButtonPath = addButtonPath;
      state.inputPlaceholder = inputPlaceholder;
      state.checkList = checkList;
    },

    setSearchBarReset(state) {
      // unMount 할때 초기화
      state.addButtonContent = "";
      state.addButtonPath = "";
      state.inputPlaceholder = "";
      state.searchInput = "";
      state.selectValue = {};
      state.checkList = [];
      state.reload = false;
      state.submitToggle = false;
    },

    setSearchInput(state, action) {
      state.searchInput = action.payload;
    },

    setCheckListValue(state, action) {
      const { idx } = action.payload;
      state.checkList[idx].selected = !state.checkList[idx].selected;
    },

    setSelectListValue(state, action) {
      const { name, value } = action.payload;
      state.selectValue[name] = value;
    },

    toggleSearchSubmit(state) {
      state.submitToggle = !state.submitToggle;
    },
  },
});

export const {
  setSearchBarReload,
  setSearchBarInit,
  setSearchBarReset,
  setSearchInput,
  setCheckListValue,
  setSelectListValue,
  toggleSearchSubmit,
} = searchBarSlice.actions;
export default searchBarSlice.reducer;
