import React, { useEffect, useContext, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { modalCont } from "asset/data/modalCont";
import Styled from "./style";
import { image } from "style/globalStyle";
import { GridContext } from "context/gridContext";
import DragItem from "hook/dragItem";
import { setModalKey } from "hook/modal";
import PortalModal from "container/modal/portalModal/index";
import DragAbleBox from "component/dragAbleBox/index";
import Toast from "component/toast/index";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { setBannerListCount } from "store/reducer/bannerSlice";
import { getBanners, patchBanners } from "api/banner/index";
import usePrompt from "hook/usePrompt";
import useToast from "hook/useToast";
import CustomPrompt from "component/prompt/index";
import { deleteBanners } from "api/banner/index";

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};
let activatedIndex = 0;
let unActivatedIndex = 0;

export default function SettingBanner() {
  const { items, moveItem, setItems } = useContext(GridContext);
  const [modalState, setModalState] = useImmer(initModalState);
  const history = useHistory();
  const { isShow, message, toast } = useToast();
  const dispatch = useDispatch();
  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList: { ...items },
  });

  const handleClickAdd = () => {
    history.push("/banner/create");
  };

  const handleClickSave = async () => {
    const body = [];
    items.forEach((item) => {
      const { activatedIndex, unActivatedIndex, bannerId } = item;
      const obj = {
        bannerId,
        idx: activatedIndex ? activatedIndex : unActivatedIndex,
      };
      body.push(obj);
    });

    const res = await dispatch(API(patchBanners(body)));
    const { status } = res.payload;

    if (status === 200) {
      setIsValueChanged(false);
      setModalKey(
        setModalState,
        "submitComplete",
        "/banner",
        "변경 사항이 저장되었습니다."
      );
      setBanners();
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const setBannersActivateList = useCallback(
    (banners) => {
      let activatedLastIdx = 0;
      let unActivatedLastIdx = 0;
      banners.forEach((item) => {
        const { activated, idx } = item;
        if (activated) {
          if (activatedLastIdx < idx) {
            activatedLastIdx = idx;
          }
        } else {
          if (unActivatedLastIdx < idx) {
            unActivatedLastIdx = idx;
          }
        }
      });
      dispatch(setBannerListCount({ activatedLastIdx, unActivatedLastIdx }));
    },
    [dispatch]
  );

  const setBanners = useCallback(async () => {
    const res = await dispatch(API(getBanners()));
    const { data, status } = res.payload;
    const { banners } = data;

    setBannersActivateList(banners);
    if (status === 200) {
      banners.forEach((banner, idx) => {
        const { signedUrl } = banner;
        banners[idx].fileUrl = signedUrl;
      });
      setItems(banners);
      setInitList({ ...banners }); //  initList 의 제일 처음 값 입력 checkList 의 값과 비교하기 위함
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  }, [dispatch, setBannersActivateList, setInitList, setItems]);

  const handleDelete = useCallback(
    (bannerId) => async () => {
      try {
        await dispatch(API(deleteBanners(bannerId)));
        toast("배너가 삭제되었습니다.");
        await setBanners();
      } catch (err) {
        dispatch(handleErrorState(false)); //  errorOccur state 초기화
      }
    },
    [dispatch, setBanners, toast]
  );

  useEffect(() => {
    setBanners();
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <CustomPrompt
          modalState={modalState}
          setModalState={setModalState}
          isValueChanged={isValueChanged}
        />
        {modalState.modal.modalVisible && (
          <PortalModal setState={setModalState}>
            {modalCont({
              modalKey: modalState.modal.modalKey,
              nextPath: modalState.modal.nextPath,
              completeContent: modalState.modal.completeContent,
              setState: setModalState,
            })}
          </PortalModal>
        )}
        <Styled.Root>
          <Styled.Header>
            <div className="save-button hide"> </div>
            <div>
              <div className="content">
                모바일 배너는 등록 순서대로 노출됩니다.
              </div>
              <div className="content">
                클릭해서 드래그 하면 배너 순서를 변경할 수 있습니다.
              </div>
            </div>
            <button className="save-button" onClick={handleClickSave}>
              저장하기
            </button>
          </Styled.Header>
          <Styled.Wrapper>
            <Styled.AddBannerWrapper>
              <div className="addbutton-circle" onClick={handleClickAdd}>
                <img
                  src={image.icPlusLargeWhite}
                  alt="add-button"
                  loading="lazy"
                />
              </div>
              <div className="title">배너 추가하기</div>
              <div className="content-wrapper">
                <div>추가된 배너는 매장앱에서</div>
                <div>순서대로 반영됩니다.</div>
              </div>
            </Styled.AddBannerWrapper>
            {items.map((item, idx) => {
              const { bannerId, activated } = item;
              // index 계산을 위한 로직
              if (idx === 0) {
                activatedIndex = idx;
                unActivatedIndex = idx;
              }

              if (activated) {
                activatedIndex += 1;
                item.activatedIndex = activatedIndex;
              } else {
                unActivatedIndex += 1;
                item.unActivatedIndex = unActivatedIndex;
              }

              return (
                <DragItem key={bannerId} id={bannerId} onMoveItem={moveItem}>
                  <DragAbleBox
                    idx={bannerId}
                    item={item}
                    handleDelete={handleDelete}
                  />
                </DragItem>
              );
            })}
          </Styled.Wrapper>
        </Styled.Root>
      </DndProvider>
      <Toast isShow={isShow} message={message} />
    </>
  );
}
