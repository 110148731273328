import { useState, useMemo } from "react";
import { useHistory } from "react-router";
import { useImmer } from "use-immer";

import { getVideoInfo, extractVideoIdFromVideoUrl } from "service/vimeo";

const useEducationForm = () => {
  const history = useHistory();
  const [formData, setFormData] = useImmer({
    title: "",
    content: "",
    videoUrl: "",
  });
  const [modalOption, setModalOption] = useImmer({
    // 모달이라는 객체로 감싼이유는 이미 작성된 다른 코드에 맞추기 위함
    modal: {
      modalVisible: false,
      modalKey: "",
    },
  });
  const [isValidError, setIsValidError] = useState(false);
  const [vimeoError, setVimeoError] = useState(false);

  const setRequestData = async () => {
    const videoUrl = formData.videoUrl.trim();
    const reqData = { ...formData, videoUrl };
    const videoId = extractVideoIdFromVideoUrl(videoUrl);
    const { duration, thumbnailUrl } = await getVideoInfo(videoId);

    return {
      ...reqData,
      thumbnailUrl,
      duration,
    };
  };

  const isDisabled = useMemo(
    () => Object.values(formData).some((item) => item === ""),
    [formData]
  );

  const handleCancel = () => {
    history.go(-1);
  };

  const isValidEmpty = (obj) => {
    const hasNotEmptyValue = (item) => {
      return typeof item === "object" ? item.value !== "" : item !== "";
    };

    return Object.values(obj).every(hasNotEmptyValue);
  };

  return {
    isValidError,
    setIsValidError,
    formData,
    setFormData,
    modalOption,
    setModalOption,
    setRequestData,
    handleCancel,
    isDisabled,
    isValidEmpty,
    vimeoError,
    setVimeoError,
  };
};

export default useEducationForm;
