import { getBreweriesNames } from "api/brewery/index";
import { getPubsNames } from "api/pub/index";
import { getVendors, getVendorsNames } from "api/vendor/index";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { API } from "store/reducer/apiSlice";
import { image } from "style/globalStyle";
import Styled from "./style";

export default function LinkChoiceList(props) {
  const { header, handleIsOpenChoiceList, position, state, setState } = props;

  const dispatch = useDispatch();

  const handleSearch = async (e) => {
    if (window.event.keyCode === 13) {
      const req = { name: e.target.value };

      if (position === "left" && header === "도매사") {
        setState((draft) => {
          draft.leftState.view = "modalSearch";
        });

        let res = await dispatch(API(getVendors(req)));
        res = res.payload.data.vendors.map((item) => {
          return { ...item, label: item.vendorName };
        });
        setState((draft) => {
          draft.leftState.modalSearch = res;
        });
      }

      if (position === "left" && header === "제조사") {
        setState((draft) => {
          draft.leftState.view = "modalSearch";
        });

        let res = await dispatch(API(getBreweriesNames(req)));
        res = res.payload.data.breweries.map((item) => {
          return { ...item, label: item.breweryName };
        });
        setState((draft) => {
          draft.leftState.modalSearch = res;
        });
      }

      if (position === "right" && header === "매장") {
        const temp = [];

        let res = await dispatch(API(getPubsNames(req)));

        res.payload.data.pubs.forEach((item) => {
          state.rightState.original.forEach((originalItem) => {
            if (item.pubName === originalItem.label) {
              if (originalItem.checked === true) {
                temp.push({ ...item, checked: true, label: item.pubName });
              } else {
                temp.push({ ...item, checked: false, label: item.pubName });
              }
            }
          });
        });

        setState((draft) => {
          draft.rightState.modalSearch = temp;
          draft.rightState.view = "modalSearch";
        });
      }

      if (position === "right" && header === "도매사") {
        const temp = [];

        let res = await dispatch(API(getVendorsNames(req)));

        res.payload.data.vendors.forEach((item) => {
          state.rightState.original.forEach((originalItem) => {
            if (item.vendorName === originalItem.label) {
              if (originalItem.checked === true) {
                temp.push({ ...item, checked: true, label: item.vendorName });
              } else {
                temp.push({ ...item, checked: false, label: item.vendorName });
              }
            }
          });
        });

        setState((draft) => {
          draft.rightState.modalSearch = temp;
          draft.rightState.view = "modalSearch";
        });
      }
    }
  };

  const handleChangeSearchValue = (e) => {
    setState((draft) => {
      draft.searchValue = e.target.value;
    });
  };

  const handleAddSelect = (e) => {
    // li요소가 아닌 ul요소 클릭 시 리턴
    if (e.target.nodeName === "UL") return;

    if (position === "left") {
      // leftChoiceList 일 때 (1개만 선택가능)
      if (state.leftState.view === "original") {
        // 검색하지 않고 original에서 클릭해서 add 할 때
        setState((draft) => {
          draft[`${position}State`].select = [
            state[`${position}State`].original[e.target.id],
          ];
        });
      } else if (state.leftState.view === "modalSearch") {
        // 검색 후 modalSearch에서 클랙해서 add 할 때
        setState((draft) => {
          draft[`${position}State`].select = [
            state[`${position}State`].modalSearch[e.target.id],
          ];
        });
      }
    }

    if (position === "right") {
      // rightChoiceList 일 때 (여러개 선택가능)
      if (state.rightState.view === "original") {
        // original 배열 보여줄 때 (검색 x)
        if (state.rightState.original[e.target.id].checked === true) {
          // checked === true 일 때 삭제하는 로직
          setState((draft) => {
            draft.rightState.original = state.rightState.original.map(
              (item, index) => {
                if (+e.target.id === index) {
                  return { ...item, checked: false };
                } else {
                  return item;
                }
              }
            );
            draft.rightState.select = state.rightState.select.filter((item) => {
              return (
                item.label !==
                state.rightState[`${state.rightState.view}`][e.target.id].label
              );
            });
          });
        } else {
          // checked === false 일 때 추가하는 로직
          setState((draft) => {
            draft.rightState.original[e.target.id].checked = true; // original 배열에 해당 index check === true로 변경
            draft.rightState.select = [
              ...state.rightState.select,
              { ...state.rightState.original[e.target.id], checked: true },
            ];
          });
        }
      }

      if (state.rightState.view === "modalSearch") {
        // modalSearch 배열 보여줄 때 (검색 o)
        const click = state.rightState.modalSearch[e.target.id].label; //choiceList에서 선택한 것
        const temp = [];
        const selectTemp = [];

        if (state.rightState.modalSearch[e.target.id].checked === true) {
          // checked === true 일 때 삭제하는 로직
          state.rightState.original.forEach((item) => {
            if (item.label === click) {
              temp.push({ ...item, checked: false });
            } else {
              temp.push(item);
            }
          });

          const modalSearch = state.rightState.modalSearch.map((item) => {
            if (click === item.label) {
              return { ...item, checked: false };
            } else {
              return item;
            }
          });

          state.rightState.select.forEach((item) => {
            if (item.label !== click) {
              selectTemp.push(item);
            }
          });

          setState((draft) => {
            draft.rightState.original = temp;
            draft.rightState.modalSearch = modalSearch;
            draft.rightState.select = selectTemp;
          });
        } else {
          // checked === false 일 때 추가하는 로직
          state.rightState.original.forEach((item) => {
            if (item.label === click) {
              temp.push({ ...item, checked: true });
            } else {
              temp.push(item);
            }
          });

          const modalSearch = state.rightState.modalSearch.map((item) => {
            if (click === item.label) {
              return { ...item, checked: true };
            } else {
              return item;
            }
          });

          state.rightState.modalSearch.forEach((item) => {
            if (item.label === click) {
              selectTemp.push(...state.rightState.select, item);
            }
          });

          setState((draft) => {
            draft.rightState.original = temp;
            draft.rightState.modalSearch = modalSearch;
            draft.rightState.select = selectTemp;
          });
        }
      }
    }

    // choiceList 클릭 시 자동 닫힘 (left일 때만)
    if (position === "left") {
      setState((draft) => {
        draft.modal[`${position}ChoiceList`] = false;
      });
    }
  };

  useEffect(() => {
    // 검색어의 길이가 0이 되면 original 배열을 목록으로 보여준다.
    if (state.searchValue.length === 0) {
      setState((draft) => {
        draft[`${position}State`].view = "original";
      });
    }
  }, [state.searchValue]);

  return (
    <Styled.Wrapper>
      <Styled.HeaderWrapper>
        <Styled.Header>{header} 선택하기</Styled.Header>
        <button
          className="button"
          onClick={() => handleIsOpenChoiceList(position)}
        >
          <img src={image.icCloseWhite} alt="닫기버튼" />
        </button>
      </Styled.HeaderWrapper>
      <Styled.SearchInputWrapper>
        <img src={image.icSearch} alt={`${header}명으로 검색`} />
        <input
          placeholder={`${header}명으로 검색`}
          onKeyUp={handleSearch}
          value={state.searchValue}
          onChange={handleChangeSearchValue}
        />
      </Styled.SearchInputWrapper>
      <Styled.ChoiceList onClick={handleAddSelect}>
        {position === "left" &&
          // position이 left 이며 검색하지 않았을 때 original 배열을 이용해서 렌더링
          state[`${position}State`][`${state.leftState.view}`].map(
            (item, index) => {
              return (
                <Styled.ChoiceListItem key={index} id={index} name="original">
                  {item.label}
                </Styled.ChoiceListItem>
              );
            }
          )}

        {position === "right" &&
          state[`${position}State`][`${state.rightState.view}`].map(
            (item, index) => {
              return (
                <Styled.ChoiceListItem key={index} id={index}>
                  <>
                    <label htmlFor="checkBox">
                      {item.checked === false ? (
                        <img src={image.icCheckBlank} alt="체크박스" />
                      ) : (
                        <img src={image.icCheckFillBlue} alt="체크박스" />
                      )}
                    </label>
                    <input type="checkbox" id="checkBox" />
                  </>
                  {item.label}
                </Styled.ChoiceListItem>
              );
            }
          )}
      </Styled.ChoiceList>
    </Styled.Wrapper>
  );
}
