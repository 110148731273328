import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { deleteNullObjectValue } from "util/makeDataForm";

export default function useTableData(apiHandler, config) {
  const [tableData, setTableData] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const history = useHistory();

  const getTableData = useCallback(
    async (option = {}) => {
      const copyConfig = { ...config };
      for (let key in option) {
        copyConfig[key] = option[key];
      }
      const newConfig = deleteNullObjectValue(copyConfig);

      const { tableData, pageInfo, status } =
        apiHandler && (await apiHandler(newConfig));

      if (status !== 200) {
        return;
      }
      setPageInfo(pageInfo);
      setTableData(tableData);
      history.replace({
        search: queryString.stringify(newConfig),
      });
    },
    [apiHandler, config, history]
  );

  const handlePagination = useCallback(
    (page) => () => {
      getTableData({ page });
    },
    [getTableData]
  );

  useEffect(() => {
    getTableData();
  }, []);

  return {
    tableData,
    pageInfo,
    getTableData,
    handlePagination,
  };
}
