import React from "react";

import Styled from "./style";

export default function SubmitButton(props) {
  const {
    className,
    backgroundColor,
    content,
    handleClick,
    width = "150px",
    height = "44px",
  } = props;
  return (
    <Styled.Root
      className={className}
      backgroundColor={backgroundColor}
      onClick={handleClick}
      width={width}
      height={height}
    >
      {content}
    </Styled.Root>
  );
}
