import React from "react";
import Styled from "./style";
export default function LoginInput(props) {
  const { placeholder, name, value, handleChange, handleKeyup, type, isValid } = props;
  return (
    <Styled.Input
      placeholder={placeholder}
      name={name}
      value={value}
      type={type}
      isValid={isValid}
      onChange={handleChange}
      onKeyUp={handleKeyup}
    />
  );
}
