import styled from "styled-components";

const Styled = {
  BlockAutoComplete: styled.input`
    // chrome은 자동 완성 기능을 막아두었습니다. (input 태그에서 사용하는 autoComplete)
    // 따로 input을 만들어둔 다음에 안 보이게 했습니다.
    opacity: 0;
    position: fixed;
    display: inline;
    width: 0px;
    height: 0px;
  `,
};
export default Styled;
