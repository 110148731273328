import styled from "styled-components";
import { color, image, typo } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  Header: styled.div`
    ${typo.medium22};
  `,
  Body: styled.div`
    margin-top: 30px;
    border-top: solid 1px ${color.Colord5d8db};

    .tr {
      display: grid;
      grid-template-columns: 180px 1fr;
      min-height: 50px;
      .title {
        ${typo.medium14};
        border-left: solid 1px ${color.Colord5d8db};
        border-bottom: solid 1px ${color.Colord5d8db};
        background-color: ${color.Colorf9fbfb};
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${color.Color777777};
      }
      .content {
        ${typo.regular14};
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-right: solid 1px ${color.Colord5d8db};
        border-bottom: solid 1px ${color.Colord5d8db};
        background-color: ${color.Colorffffff};
      }
    }
    .gallery-tr {
      .content {
        padding: 15px 20px;
      }

      #banner-upload-img-file {
        visibility: hidden;
      }
    }
  `,
  ImgLabel: styled.label`
    width: 425px;
    height: 212px;

    border-radius: 5px;
    background: ${(props) =>
      props.checkFile ? null : `url(${image.icGallery}) no-repeat center`};
    background-color: ${(props) =>
      props.checkFile ? null : color.Colorf6f8f8};
    :hover {
      cursor: pointer;
    }
    img {
      max-width: 425px;
      height: 100%;
      object-fit: cover;
    }
  `,

  ButtonWrapper: styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,

  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,

  SelectBoxWrapper: styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    height: 100%;
    padding: 16px 0;
  `,
  TextWrapper: styled.div`
    height: 100%;
    margin-left: 4px;

    & > label {
      margin-right: 8px;
      font-size: 13px;
      line-height: 1.2307;
    }

    & > input {
      height: 100%;
      width: 400px;
    }
  `,
};

export default Styled;
