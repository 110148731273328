import React from "react";

import { Root } from "./style";

const SearchButton = ({ ...restProps }) => {
  return (
    <Root type="button" {...restProps}>
      검색
    </Root>
  );
};

export default SearchButton;
