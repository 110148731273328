import styled from "styled-components";

import SubmitButton from "component/button/submitButton/index";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 34px 0;

    .header-style {
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 500;

      & img {
        margin-right: 4px;
      }
    }
  `,
  Wrapper: styled.div`
    display: flex;
    column-gap: 30px;
  `,
  Header: styled.div`
    font-size: 22px;
  `,
  EssentialInfo: styled.div`
    width: 750px;
    height: 460px;
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  `,
  InputWrapper: styled.div`
    display: grid;
    row-gap: 24px;
    column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "name breweryId"
      "category  categoryId"
      "abv rating"
      "feature feature"
      "feature feature";
    height: 100%;
  `,
  ProductInfoWrapper: styled.div`
    padding: 34px 40px;
    background-color: ${color.Colorffffff};
    width: 330px;
    height: 460px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

    .header {
      margin-bottom: 24px;
    }
    .new-product {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }
    .new-product-desc {
      margin-top: 20px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.03em;
      color: ${color.Color777777};
    }
  `,

  OptionsContainer: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    height: 290px;
    padding: 34px 40px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: ${color.Colorffffff};
    .header {
      display: flex;
      justify-content: space-between;
      .plus-button {
        :hover {
          cursor: pointer;
        }
      }
    }
  `,
  OptionsContent: styled.div``,

  OptionsTitleWrapper: styled.div`
    & h3 {
    }
  `,
  OptionsWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `,
  OptionItem: styled.div`
    padding: 20px 16px;
    width: 320px;
    height: 167px;
    border-radius: 5px;
    border: dashed 1px ${color.Colorc9c9c9};
    background-color: ${color.Colorffffff};

    & span {
      font-weight: 500;
      margin-bottom: 25px;
    }
  `,
  OptionItemHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .desc-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .desc {
        color: ${color.Color777777};
        font-size: 12px;
      }
    }
  `,
  OptionDeleteBtn: styled.button``,

  OptionItemContent: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    .vnp-grid {
      height: 36px;
      display: grid;
      grid-template-columns: 26px 1fr;
      column-gap: 24px;
      align-items: center;
      .price-grid {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 70px;
        column-gap: 12px;
        input {
          width: 100%;
          padding: 0 16px;
          background-color: rgba(237, 241, 242, 0.5);
          border-radius: 5px;
          border: solid 1px ${color.Colorc9c9c9};
          outline: none;
          :focus {
            border: solid 1px ${color.Color5ab1f0};
          }
          &[name="price"] {
            border: 0;
          }
        }
        .unit {
          border-radius: 5px;
          border: solid 1px ${color.Colorc9c9c9};
          background-color: ${color.Colore0e5e8};
          line-height: 36px;
          text-align: center;
        }
      }
    }
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,

  Essential: styled.strong`
    color: ${color.Colorce4d4e};
  `,
  SubmitButton: styled(SubmitButton)`
    width: 120px;
    height: 40px;
    background-color: ${color.Color5ab1f0};
  `,
};
export default Styled;
