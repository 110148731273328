import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    span {
      background-color: ${(props) => props.backgroundColor};
      color: ${(props) => props.color};
      width: ${(props) => props.width};
      text-align: center;
      font-size: 12px;
      height: ${(props) => props.height};
      line-height: ${(props) => props.height};
      border-radius: 5px;
    }
    cursor: unset;
  `,
};
export default Styled;
