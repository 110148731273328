import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    /* width: ${(props) => props.width}; */
    height: 36px;
    line-height: 36px;
    padding: 0 24px;
    border-radius: 4px;
    border: solid 1px ${color.Colord5d8db};
    background-color: ${color.Colorffffff};
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    div {
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: 0.064em;
      cursor: pointer;
    }
  `,
};
export default Styled;
