import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    background-color: ${color.Colorffffff};
    width: calc(100% + 100px);
    height: calc(100vh - 56px);
    padding: 34px 50px;
    display: flex;
    column-gap: 58px;
  `,
  ProfileWrapper: styled.div`
    width: 110px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 24px;
    .close-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      :hover {
        cursor: pointer;
      }
    }
    .profile-img-wrapper {
      width: 110px;
      height: 110px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-img {
        width: 100%;
      }
    }
    input {
      display: none;
    }
    label {
      display: flex;
      align-items: center;
      padding-left: 4px;
      width: 90px;
      height: 30px;
      border-radius: 5px;
      background-color: #edf1f2;
      font-size: 13px;
      color: #1b242b;
      :hover {
        cursor: pointer;
      }
      .icon-img {
        width: 28px;
        height: 28px;
      }
    }
  `,
  InfoWrapper: styled.div`
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .desc-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 300px;
        .title {
          display: flex;
          align-items: center;
        }
        .password-wrapper {
          display: flex;
          justify-content: space-between;

          .desc {
            display: flex;
            align-items: center;
          }
          .change-password-button {
            color: ${color.Color1f6cd5};
            :hover {
              cursor: pointer;
            }
          }
        }
        div {
          height: 40px;
        }
        input {
          border-radius: 5px;
          height: 40px;
          border: solid 1px ${color.Colorc9c9c9};
          background-color: ${color.Colorffffff};
          outline: none;
          padding: 0 12px;
        }
        textarea {
          height: 120px;
          border-radius: 5px;
          border: solid 1px ${color.Colorc9c9c9};
          background-color: ${color.Colorffffff};
          outline: none;
          padding: 16px 12px;
        }
        .unchangeable {
          border-radius: 4px;
          line-height: 40px;
          padding: 0 12px;
          background-color: ${color.Coloredf1f2};
        }
      }
    }
  `,
  ButtonWrapper: styled.div`
    margin-top: 50px;
  `,
};
export default Styled;
