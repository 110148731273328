import { isEqual } from "lodash";
import dayjs from "dayjs";

export const addKeyInObject = (datas, key, value) => {
  // list object 구조에([{},{} ... ]) 특정 key, value 추가
  const res = datas.map((item) => {
    return {
      ...item,
      [key]: value,
    };
  });
  return res;
};

export const stringToNumber = (number) => {
  // , 포함되어 있는 숫자 string을 숫자 number 로 변경
  const res = Number(number.replace(/,/gi, ""));
  return res;
};

export const phoneNumToString = (phone) => {
  // - 포함되어 있는 핸드폰 번호 string을 숫자 string 으로 변경
  const res = phone.replace(/-/gi, "");
  return res;
};

export const deleteNullObjectValue = (object) => {
  // key 값은 있고, value 값이 없으면, key 값을 제거해주는 함수
  const res = {};
  for (const [key, value] of Object.entries(object)) {
    if (value !== "" && value !== null) {
      res[key] = value;
    }
  }
  return res;
};

export const makeDashFunction = (tableDatas, columns) => {
  const isLengthZero = {};

  columns.forEach((item) => {
    isLengthZero[item.key] = "-";
  });

  while (tableDatas.length < 10) {
    tableDatas.push(isLengthZero);
  }

  return tableDatas;
};

export const addZerotoCalendar = (num) => {
  // 달력에서 자리수가 1이면 앞에 0 추가
  if (num.toString().length === 1) {
    num = `0${num}`;
  }
  return num;
};

export const numToStringPhoneNum = (phoneNumber) => {
  // 입력 받는 값을 숫자만 받고 이외의 값은 빈문자열로 바꿈
  // 앞에 글자가 국번
  // 국번 종류에 따라 - 가 다르게 붙음

  const isNumber = /[^0-9]/g;
  phoneNumber = phoneNumber?.replace(isNumber, "");
  phoneNumber = phoneNumber
    ?.replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3"
    )
    ?.replace("--", "-");

  return phoneNumber;
};

export const numToStringBusinessNum = (num) => {
  const result = num
    ?.replace(/(\d{3})(\d{2})(\d*)/, "$1-$2-$3")
    ?.replace("--", "-");
  return result;
};

export const allowNumber = (number) => {
  const check = /[^0-9]/g;
  const result =
    typeof number === "string" ? number.replace(check, "") : number;
  return result;
};

export const allowDotAndNumber = (number) => {
  // dot, number 만 허용
  const check = /[^0-9.]/g;

  return number.toString().replace(check, "");
};

export const setCommaToNumber = (number) => {
  // number에 1000 자리마다 , 추가
  const check = /[^0-9]/g;
  number = number.toString().replace(check, "");
  const string = number.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return string;
};

export const convertCommaNumToNum = (number) => {
  return +number.toString().replaceAll(",", "");
};

export const makeStringSum = (numList) => {
  const check = /[^0-9.]/g;
  let total = 0;
  numList.forEach((num) => {
    num = Number(num.replace(check, ""));
    total = total + num;
  });
  return `${total}%`;
};

export const dateToSlash = (date) => {
  // / 포함되어 있는 달력 string에서 /를 -로 변경
  const res = date.replace(/\//gi, "-");
  return res;
};

export const makeDateForm = (date) => {
  const formDate = date?.slice(0, 10).replace(/-/gi, "/");
  return formDate ? formDate : "";
};

export const dateToObj = (date) => {
  // date 객체를 object로 변경
  const formDate = date.split("/");
  const res = {
    formYear: Number(formDate[0]),
    formMonth: Number(formDate[1]),
    formDate: Number(formDate[2]),
  };
  return res;
};

export const splitStringToDateAndTime = (req) => {
  const dateSplitByDash = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return { year, month, day };
  };

  const stringList = req.split(/[T|.]/);

  const date = stringList[0];
  const timeList = stringList[1].split(":");
  const time = `${timeList[0]}:${timeList[1]}`;

  const res = isEqual(
    dateSplitByDash(new Date(date)),
    dateSplitByDash(new Date())
  ); // 두 객체가 같은지 확인하는 함수
  if (res) {
    return time;
  } else {
    return `${date} ${time}`;
  }
};

export const getDashFormatDate = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

export const pubFranchise = (franchise) => {
  switch (franchise) {
    case "direct":
      return "직영";
    case "franch":
      return "가맹";
    case "others":
      return "일반";
    default:
      return "일반";
  }
};

export const license = (foreign, specific, total) => {
  const temp = [];

  if (foreign === 1) {
    temp.push("수입");
  }
  if (specific === 1) {
    temp.push("특정");
  }
  if (total === 1) {
    temp.push("종합");
  }

  let license = "";
  temp.forEach((item, index) => {
    if (index !== temp.length - 1) {
      license = license + `${item},`;
    } else {
      license = license + `${item}`;
    }
  });
  return license;
};

export const dateForm = (date, character, format) => {
  // 서버시간받아서 날짜 형식 변환하기 ex) 2021-10-28T00:32:27.000Z -> 2021.11.11 18:24
  // date = 서버시간
  // character = 날짜에 사용할 특수문자

  if (date === null || date === undefined) return "-";

  let yymmss = date.substring(0, 10);
  let hhmm = date.substring(11, 16);

  yymmss = yymmss.replace(/-/gi, character);
  switch (format) {
    case "onlyDate":
      return yymmss;
    default:
      return yymmss + " " + hhmm;
  }
};

export const formatDate = (date, template = "DD/MM/YY, HH:mm") =>
  dayjs(date).format(template);
