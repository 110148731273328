import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import Styled from "./style";
import { API } from "store/reducer/apiSlice";
import { getLessons } from "api/education";
import EducationSearchBar from "container/searchBar/educationSearchBar";
import PaginationComplex from "container/paginationComplex";
import EducationCard from "component/card/educationCard";
import useTableData from "hook/useTableData";

export default function Education() {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(query.title || "");
  const config = {
    title: query.title || "",
    page: query.page || "",
    perPage: query.perPage || "",
  };

  const apiHandler = async (config) => {
    const res = await dispatch(API(getLessons(config)));

    return {
      tableData: res.payload.data.lessons,
      pageInfo: res.payload.data.pageInfo,
      status: res.payload.status,
    };
  };

  const { tableData, pageInfo, getTableData, handlePagination } = useTableData(
    apiHandler,
    config
  );

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      getTableData({ title: searchValue });
    },
    [getTableData]
  );

  const handleReload = useCallback(() => {
    setSearchValue("");
    for (let option in config) {
      config[option] = "";
    }

    getTableData(config);
  }, [getTableData, setSearchValue]);

  const handleChangeSearchInput = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [setSearchValue]
  );

  useEffect(() => {
    getTableData();
    if (location.search === "") {
      setSearchValue("");
    }
  }, [location.search]);

  return (
    <Styled.Root>
      <EducationSearchBar
        searchValue={searchValue}
        handleSearch={handleSearch}
        handleChangeSearchInput={handleChangeSearchInput}
        handleReload={handleReload}
      />
      <Styled.CardWrapper>
        {tableData.map((data) => (
          <EducationCard key={data.lessonId} data={data} />
        ))}
      </Styled.CardWrapper>
      <PaginationComplex
        pageInfo={pageInfo}
        handlePagination={handlePagination}
      />
    </Styled.Root>
  );
}
