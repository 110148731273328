import React from "react";

import Styled from "./style.js";

const Opener = ({ className, label, btnLabel, placeholder, value, onClick }) => {
  return (
    <Styled.Opener className={className}>
      <span>{label}</span>
      <Styled.InputWrapper>
        <button type="button" onClick={onClick}>
          {btnLabel}
        </button>
        <input type="text" placeholder={placeholder} value={value} readOnly />
      </Styled.InputWrapper>
    </Styled.Opener>
  );
};

export default Opener;
