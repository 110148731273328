import React from "react";
import Styled from "./style";
import { makeStringSum, numToStringPhoneNum } from "util/makeDataForm";
export default function PubDetail(props) {
  const { data, columns } = props;

  const gridTemplateList = [
    makeStringSum([columns[0].width, columns[1].width]),
    makeStringSum([columns[2].width, columns[3].width, columns[4].width]),
    makeStringSum([columns[5].width]),
    makeStringSum([columns[6].width]),
  ];

  return (
    <Styled.Root gridTemplateList={gridTemplateList} columns={columns}>
      <div className={`grid ${columns[0].key}`}>
        <div className="two-row">
          <div className="one-row">
            <div className="title">사업자등록번호</div>
            <div className="content">{data.taxcode}</div>
          </div>
          <div className="one-row">
            <div className="title">대표자명</div>
            <div className="content">{data.managerName}</div>
          </div>
        </div>
        <div className="two-row">
          <div className="one-row">
            <div className="title">핸드폰번호</div>
            <div className="content">
              {numToStringPhoneNum(data.managerPhone)}
            </div>
          </div>
          <div className="one-row">
            <div className="title">이메일주소</div>
            <div className="content">{data.managerEmail}</div>
          </div>
        </div>
      </div>
      <div className={`grid ${columns[2].key} two-row`}>
        <div className="one-row">
          <div className="title">매장주소</div>
          <div className="content">
            {data.addr} {data.addrDetail}
          </div>
        </div>
        <div className="one-row">
          <div className="title">매장번호</div>
          <div className="content">{numToStringPhoneNum(data.pubPhone)}</div>
        </div>
      </div>
      <div className={`grid ${columns[5].key}`}>
        <div className="two-row">
          {data.vendors.map((item, index) => {
            return (
              <div key={index} className="one-row">
                <div className="title" key={`title-${index}`}>
                  도매사 {index + 1}
                </div>
                <div className="content" key={`content-${index}`}>
                  {item.vendorName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`grid ${columns[6].key}`}>
        <div className="two-row">
          {data.breweries.map((item, index) => {
            return (
              <div key={index} className="one-row">
                <div className="title" key={`title-${index}`}>
                  제조사 {index + 1}
                </div>
                <div className="content" key={`content-${index}`}>
                  {item.breweryName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`grid `}></div>
    </Styled.Root>
  );
}
