import React from "react";
import { Prompt } from "react-router-dom";

import { setModalKey } from "hook/modal";
export default function CustomPrompt(props) {
  // router 변경이 있을때 동작
  const { modalState, setModalState, isValueChanged } = props;

  const handleLeave = (event) => {
    const { pathname } = event;
    setModalKey(setModalState, "leavePage", pathname);
    // 커스텀 모달

    return false;
  };

  const setWhen = () => {
    // when 체크하는 함수
    if (modalState.modal.modalKey === "submitComplete") {
      return false;
    }

    if (isValueChanged && modalState.modal.modalVisible === false) {
      return true; //  prompt 띄운다.
    }
    return false;
  };

  return <Prompt when={setWhen()} message={handleLeave} />;
}
