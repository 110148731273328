import { color } from "style/globalStyle";
import styled from "styled-components";

const Styled = {
  Root: styled.section`
    position: relative;
    width: 100%;
    background-color: ${color.Colorffffff};
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid ${color.Colord5d8db};
    border-radius: 5px;
    .content {
      display: flex;
      flex-direction: column;
    }
    cursor: pointer;
  `,
  HeaderWrapper: styled.div`
    height: 123px;
    padding: 5px 24px;
    cursor: pointer;
  `,
  UpperHeader: styled.div`
    display: flex;
    align-items: center;
    height: 37px;
    border-bottom: dashed 0.5px ${color.Colorc9c9c9};
    .date {
      font-size: 13px;
      color: ${color.Color4d4e56};
    }
    cursor: pointer;
  `,
  LowerHeader: styled.div`
    display: flex;
    height: 86px;
    align-items: center;
    cursor: pointer;

    .store-header {
      margin-left: 16px;
      .header-title {
        margin-bottom: 4px;
        height: 19px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
      span {
        height: 17px;
        font-size: 14px;
        text-align: left;
        color: ${color.Color777777};
      }
    }
  `,
  ArrowIcon: styled.img`
    cursor: pointer;
    position: absolute;
    right: 20px;
    transform: ${(props) => props.rotate180 && "rotate(180deg)"};
    justify-self: flex-end;
    transition: all 250ms ease-in-out;
  `,
  Main: styled.article`
    /* max-height: ${(props) => (props.visible ? "1000px" : "0")};
    overflow-y: hidden;
    transition: max-height 1s linear; */
    cursor: pointer;
    border-top: 1px solid ${color.Colord5d8db};
    padding: 0 24px 0 24px;
    width: 100%;
    .main-title {
      height: 15px;
      font-size: 12px;
      margin-top: 18px;
      color: ${color.Color777777};
    }
    .main-content {
      margin-top: 4px;
      color: ${color.Color121212};
      position: relative;
    }
    .main-icon {
      padding: 8px 12px;
      border-radius: 2px;
      background-color: ${color.Colore8f0fb};
      width: 44px;
      height: 36px;
      justify-self: flex-end;
      position: absolute;
      top: -21px;
      right: 0;
    }
    .main-empty-line {
      width: 100%;
      border-bottom: dashed 0.5px ${color.Colorc9c9c9};
      margin-top: 18px;
    }
    .main-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      border-top: dashed 0.5px ${color.Colorc9c9c9};
      padding-top: 20px;
      padding-bottom: 20px;
      column-gap: 16px;
    }
    img {
      cursor: pointer;
    }
  `,
};

export default Styled;
