import { ax } from "api/index";

export const postBreweries = (body) => async () => {
  // 제조사 등록
  return await ax.post("/breweries", body);
};

export const getBreweries = (req) => async () => {
  // 제조사 목록 조회
  return await ax.get("/breweries", { params: { ...req } });
};

export const getBreweriesNames = (req) => async () => {
  // 제조사 전체 목록 조회(페이지네이션 X)
  return await ax.get("/breweries/names", { params: { ...req } });
};

export const getBreweriesBreweryId = (breweryId) => async () => {
  // 제조사 상세 정보 조회
  return await ax.get(`/breweries/${breweryId}`);
};

export const putBreweriesBreweryId = (breweryId, body) => async () => {
  // 제조사 정보 수정
  return await ax.put(`/breweries/${breweryId}`, body);
};

export const deleteBreweriesBreweryId = (breweryId) => async () => {
  return await ax.delete(`/breweries/${breweryId}`);
};
