import Styled from "./style";

export default function IconAvatar({
  image,
  borderColor,
  backgroundColor,
  alt,
}) {
  return (
    <Styled.Root borderColor={borderColor} backgroundColor={backgroundColor}>
      <Styled.Icon src={image} alt={alt}></Styled.Icon>
    </Styled.Root>
  );
}
