import { useState, useEffect, useMemo, useCallback } from "react";

export default function usePagination(pageInfo) {
  const { totalPages, dataPerPage, currentPage } = pageInfo;
  const [pageList, setPageList] = useState([]);

  const hasPreviousBtn = useMemo(() => {
    return currentPage - dataPerPage > 0;
  }, [currentPage, dataPerPage]);

  const hasNextBtn = useMemo(() => {
    return (
      Math.floor((currentPage + dataPerPage - 1) / dataPerPage) <=
      Math.floor(totalPages / dataPerPage)
    );
  }, [currentPage, dataPerPage, totalPages]);

  const handleFirstPage = useCallback(
    (callback) => () => {
      if (currentPage === 1) return;
      callback?.(1)();
    },
    [currentPage]
  );

  const handlePreviousPage = useCallback(
    (callback) => () => {
      if (currentPage === 1) return;
      const index =
        Math.floor((currentPage - dataPerPage - 1) / dataPerPage) *
          dataPerPage +
        1;
      // typeof callback === "function" && callback(index)();
      callback?.(index)();
    },
    [currentPage, dataPerPage]
  );

  const handleNextPage = useCallback(
    (callback) => () => {
      const index =
        Math.floor((currentPage + dataPerPage - 1) / dataPerPage) *
          dataPerPage +
        1;
      if (index > totalPages) return;

      // typeof callback === "function" && callback(index)();
      callback?.(index)();
    },
    [currentPage, totalPages, dataPerPage]
  );

  const handleLastPage = useCallback(
    (callback) => () => {
      if (currentPage === totalPages) return;
      callback?.(totalPages)();
    },
    [currentPage, totalPages]
  );

  useEffect(() => {
    const getPageList = () => {
      const result = [];
      const firstIndex =
        Math.floor((currentPage - 1) / dataPerPage) * dataPerPage + 1;
      const lastIndex = Math.min(firstIndex + dataPerPage - 1, totalPages);

      for (let i = firstIndex; i <= lastIndex; i++) {
        result.push(i);
      }
      return result;
    };

    setPageList(getPageList());
  }, [currentPage, dataPerPage, totalPages]);

  return {
    pageList,
    hasPreviousBtn,
    hasNextBtn,
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage,
  };
}
