import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activatedLastIdx: 0,
  unActivatedLastIdx: 0,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBannerListCount(state, action) {
      const { activatedLastIdx, unActivatedLastIdx } = action.payload;
      state.activatedLastIdx = activatedLastIdx;
      state.unActivatedLastIdx = unActivatedLastIdx;
    },
  },
});

export const { setBannerListCount } = bannerSlice.actions;
export default bannerSlice.reducer;
