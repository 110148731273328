import { useEffect } from "react";

let existValue = false;

export const useDetectPageMove = (isValueChanged) => {
  const listener = (event) => {
    if (existValue) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", listener); //  새로고침 detect
    window.addEventListener("unload", listener); //  url 이동 detect

    return () => {
      window.removeEventListener("beforeunload", listener);
      window.removeEventListener("unload", listener);
    };
  }, []);

  useEffect(() => {
    // 배열 안의 값중 ""이 아닌 값이 하나라도 있으면 true
    existValue = isValueChanged;
  }, [isValueChanged]);
};
