import Cookies from "js-cookie";

const TOKEN_EXPIRED_DATE = 14; // TODO 정확한 만료날짜 물어보기

class TokenClass {
  constructor() {
    this.accessTokenKey = "accessToken";
    this._accessToken = Cookies.get(this.accessTokenKey);
  }

  get accessToken() {
    return this._accessToken;
  }

  get isLoggedIn() {
    return !!this._accessToken;
  }

  setAccessToken(newToken, isKeepLogin) {
    const expires = isKeepLogin ? TOKEN_EXPIRED_DATE : null;
    this._accessToken = newToken;
    Cookies.set(this.accessTokenKey, newToken, { expires });
  }

  removeAccessToken() {
    this._accessToken = undefined;
    Cookies.remove(this.accessTokenKey);
  }

  clear() {
    this.removeAccessToken();
  }
}

export const token = new TokenClass();
