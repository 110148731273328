import React from "react";
import Styled from "./style";

import ModalCancelButton from "component/button/modalCancelButton/index";
import ModalCompleteButton from "component/button/modalCompleteButton/index";
import { useHistory } from "react-router-dom";
export default function LeaveModal(props) {
  const { title, content, setState, nextPath } = props;

  const history = useHistory();

  const movePage = () => {
    history.push(nextPath);
    setState((draft) => {
      draft.modal.modalVisible = false;
    });
  };

  return (
    <Styled.Root>
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Content>{content}</Styled.Content>
      </div>
      <Styled.ButtonWrapper>
        <ModalCancelButton content="취소" setState={setState} />
        <ModalCompleteButton
          content="나가기"
          setState={setState}
          activateFunction={movePage}
        />
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
