import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  Header: styled.div`
    width: 100%;
    height: 84px;
    background-color: ${color.Colorffffff};
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    row-gap: 8px;
    .content {
      color: ${color.Color323c41};
      line-height: 1.5;
      text-align: center;
    }
    .save-button {
      width: 120px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      color: ${color.Color777777};
      border: solid 1px ${color.Colorb1b1b1};
      margin-left: 200px;
      background-color: ${color.Colorffffff};
    }
    .hide {
      visibility: hidden;
    }
  `,
  Wrapper: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 30px;
  `,
  AddBannerWrapper: styled.div`
    width: 350px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px ${color.Colord5d8db};
    background-color: ${color.Colorffffff};
    .addbutton-circle {
      width: 60px;
      height: 60px;
      background-color: ${color.Colore0e5e8};
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        cursor: pointer;
      }
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      margin: 40px 0 30px 0;
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
      div {
        color: ${color.Color777777};
      }
    }
  `,
};

export default Styled;
