import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import SearchInput from "component/input/searchInput/index";
import AddButton from "component/button/addButton/index";
import ReloadButton from "component/button/reloadButton/index";
import Styled from "./style";
import { setSearchBarReset } from "store/reducer/searchBarSlice";
import SearchButton from "component/button/searchButton/index";

export default function EducationSearchBar(props) {
  const { searchValue, handleSearch, handleChangeSearchInput, handleReload } =
    props;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setSearchBarReset());
    };
  }, []);
  return (
    <Styled.Root>
      <Styled.Front>
        <Styled.SearchInput
          searchValue={searchValue}
          placeholder="교육자료명으로 검색"
          handleChangeSearchInput={handleChangeSearchInput}
          handleSearch={handleSearch}
        />
        <SearchButton onClick={handleSearch} />
      </Styled.Front>
      <Styled.Back>
        <AddButton path="/education/create" content="자료 추가" />
        <ReloadButton handleReload={handleReload} />
      </Styled.Back>
    </Styled.Root>
  );
}
