import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { API, handleErrorState } from "store/reducer/apiSlice";
import {
  deleteNoticesNoticesId,
  getNotices,
  getNoticesDropdownLink,
  getNoticesNoticesId,
  postNotices,
  putNoticesNoticesId,
} from "api/notice/index";
import { setModalKey } from "./modal";
import useToast from "./useToast";

import {
  makeNoticeDetailDropdownQuery,
  makeNoticeDropdownQuery,
  makeNoticeQuery,
} from "util/query";

const initPageState = {
  currentPage: 1,
  dataPerPage: 10,
  startRow: 0,
  totalPages: 1,
};

export const useNoticeData = (props) => {
  const {
    getInfo = false,
    id,
    submitState,
    setModalState,
    setIsValueChanged,
    setSubmitState,
    setInitList,
  } = props;

  const [noticesInfo, setNoticesInfo] = useState([]);
  const [pageInfo, setPageInfo] = useState(initPageState);
  const [dropdownInfo, setDropdownInfo] = useState([]);
  const { isShow, message, toast } = useToast();

  const dispatch = useDispatch();

  const getNoticeInfo = async (options) => {
    const res = await dispatch(API(getNotices(makeNoticeQuery(options))));

    const {
      data: { notices, pageInfo },
      status,
    } = res.payload;

    if (status === 200) {
      setNoticesInfo(notices);
      setPageInfo(pageInfo);
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const getDropdownList = async (options) => {
    const dropdownList = await getNoticesDropdownLink(
      makeNoticeDropdownQuery(options)
    );

    setDropdownInfo([{ linkId: "all", name: "전체" }, ...dropdownList]);
  };

  const getNoticeDetail = async (id) => {
    const {
      payload: { data, status },
    } = await dispatch(API(getNoticesNoticesId(id)));

    if (status === 200) {
      setSubmitState((draft) => {
        draft.title = data.title;
        draft.type = data.type;
        draft.franchiseName = data.franchiseName ?? "";
        draft.franchise = data.franchise === "all" ? "" : data.franchise;
        draft.area = data.area === "all" ? "" : data.area;
        draft.content = data.content;
        draft.fixed = data.fixed;
      });
      setInitList(data); //  initList 의 제일 처음 값 입력 checkList 의 값과 비교하기 위함
    }

    const dropdownList = await getNoticesDropdownLink({
      ...makeNoticeDetailDropdownQuery(data),
      noticeId: id,
    });

    setDropdownInfo([{ linkId: "all", name: "전체" }, ...dropdownList]);
  };

  const handleCreate = async () => {
    setIsValueChanged(false);
    const {
      title,
      content,
      type,
      franchiseName,
      franchise,
      area,
      linkedIds,
      fixed,
    } = submitState;

    if (type === "") {
      toast("공지사항 타입을 지정해주세요.");
      return;
    } else if (title === "") {
      toast("제목을 입력해주세요.");
      return;
    } else if (content === "") {
      toast("내용을 입력해주세요.");
      return;
    }

    const reqData = {
      title,
      content,
      type,
      ...(franchiseName && franchiseName !== "all" && { franchiseName }),
      ...(franchise && franchise !== "all" && { franchise }),
      ...(linkedIds.length && {
        linkedIds: linkedIds
          .map((item) => item.linkId)
          .filter((item) => item !== "all"),
      }),
      ...(area && { area }),
      fixed: fixed ? 1 : 0,
    };

    const {
      payload: { status },
    } = await dispatch(API(postNotices(reqData)));

    if (status === 200) {
      setModalKey(
        setModalState,
        "submitComplete",
        "/notice",
        "공지사항이 등록되었습니다."
      );
      setTimeout(() => {
        setModalState((draft) => {
          draft.modal.modalVisible = false;
        });
      }, [1000]);
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const handleDelete = async (id) => {
    const res = await dispatch(API(deleteNoticesNoticesId(id)));
    const { status } = res.payload;

    if (status === 200) {
      getNoticeInfo({});
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const handleUpdate = async () => {
    setIsValueChanged(false);
    const {
      title,
      content,
      type,
      franchiseName,
      franchise,
      fixed,
      linkedIds,
      area,
    } = submitState;
    if (type === "") {
      toast("공지사항 타입을 지정해주세요.");
      return;
    } else if (title === "") {
      toast("제목을 입력해주세요.");
      return;
    } else if (content === "") {
      toast("내용을 입력해주세요.");
      return;
    }

    const reqData = {
      title,
      content,
      type,
      franchiseName: franchiseName ? franchiseName : null,
      franchise: franchise ? franchise : "all",
      linkedIds: linkedIds.length
        ? linkedIds.map((item) => item.linkId).filter((item) => item !== "all")
        : [],
      area: area ? area : null,
      fixed: fixed ? 1 : 0,
    };

    const res = await dispatch(API(putNoticesNoticesId(id, reqData)));
    const { status } = res.payload;
    if (status === 200) {
      setModalKey(
        setModalState,
        "submitComplete",
        "/notice",
        "공지사항이 수정되었습니다."
      );
      setTimeout(() => {
        setModalState((draft) => {
          draft.modal.modalVisible = false;
        });
      }, [1000]);
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const handlePagination = (page) => () => {
    getNoticeInfo({ page });
  };

  useEffect(() => {
    getInfo && getNoticeInfo({});
    id && getNoticeDetail(id);
  }, []);

  return {
    noticesInfo,
    pageInfo,
    dropdownInfo,
    getNoticeInfo,
    getDropdownList,
    handleCreate,
    handleUpdate,
    handleDelete,
    handlePagination,
    toast: {
      isShow,
      message,
    },
  };
};
