export const makeNoticeQuery = (query) => {
  let newQuery = {
    ...(query.type && { type: query.type }),
    ...(query.franchise && { franchise: query.franchise }),
    ...(query.franchiseName === "all"
      ? { franchiseName: null }
      : query.franchiseName && { franchiseName: query.franchiseName }),
    ...(query.area === "all"
      ? { area: null }
      : query.area && { area: query.area }),
    ...(query?.linkedIds?.length && {
      linkedIds: query.linkedIds.filter((item) => item !== "all"),
    }),
    ...(query.title && { title: query.title }),
    ...(query.page && { page: query.page }),
  };

  return newQuery;
};

export const makeNoticeDropdownQuery = (query) => {
  let newQuery = {
    ...(query.type && { type: query.type }),
    ...(query.franchise && { franchise: query.franchise }),
    ...(query.franchiseName === "all"
      ? { franchiseName: null }
      : query.franchiseName && { franchiseName: query.franchiseName }),
    ...(query.area === "all"
      ? { area: null }
      : query.area && { area: query.area }),
    ...(query?.linkedIds?.length && {
      linkedIds: query.linkedIds.filter((item) => item !== "all"),
    }),
    ...(query.title && { title: query.title }),
  };

  return newQuery;
};

export const makeNoticeDetailDropdownQuery = (query) => {
  const newQuery = {
    ...(query.type && { type: query.type }),
    ...(query.type === "pub" &&
      query.franchise && { franchise: query.franchise }),
    ...(query.type === "pub" &&
      query.franchiseName && {
        franchiseName:
          query.franchiseName === "all" ? null : query.franchiseName,
      }),
    ...(query.type === "pub" &&
      query.area && { area: query.area === "all" ? null : query.area }),
  };

  return newQuery;
};
