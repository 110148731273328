import styled from "styled-components";

import SelectBox from "component/selectBox/index";
import SearchInput from "component/input/searchInput/index";
import SearchButton from "component/button/searchButton/index";
import AddButton from "component/button/addButton/index";
import { color, typo } from "style/globalStyle";

const Styled = {
  Root: styled.div`
    width: 100%;
    margin-bottom: 20px;
  `,
  Filter: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 12px;
    background-color: ${color.Colorf6f8f8};
  `,
  FilterOption: styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
  `,
  MainCategory: styled(SelectBox)`
    && > span {
      color: ${color.Color1b242b};
    }
  `,
  SearchInput: styled(SearchInput)`
    width: 100%;
    height: 36px;
    margin-right: 30px;

    & > input {
      width: inherit;
      border-radius: 2px;
    }
  `,
  SearchButton: styled(SearchButton)`
    ${typo.regular13};
    margin-left: 8px;
    border-radius: 2px;
    border-color: ${color.Colorc9c9c9};
    background-color: ${color.Colorf9fbfb};
  `,
  BtnWrapper: styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
  `,
  AddBtn: styled(AddButton)`
    width: 76px;

    & > svg {
      width: 14px;
      height: 14px;
    }
  `,
};

export default Styled;
