import styled from "styled-components";
import { color } from "style/globalStyle";
import SelectBox from "component/selectBox/index";
import CheckboxSelectBoxList from "component/checkboxSelectBoxList/index";
import TitleInfoInput from "component/input/titleInput/index";

const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  Header: styled.h3`
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    color: ${color.Color121212};
    margin-bottom: 30px;
  `,
  Wrapper: styled.div`
    margin-bottom: 40px;
    padding: 30px 40px;
    background-color: ${color.Colorffffff};
  `,

  OptionWrapper: styled.div`
    display: flex;
    margin-top: 18px;

    .title {
      color: ${color.Color777777};
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 1.28571;
    }
    div {
      margin-right: 18px;
    }
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 20px;
  `,
  SelectBoxWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    column-gap: 10px;
    margin-bottom: ${({ hasSelectList }) => (hasSelectList ? "8px" : "24px")};
  `,
  SelectBoxHeading: styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: ${color.Color777777};
  `,
  MainCategory: styled(SelectBox)`
    && > span {
      color: ${color.Color1b242b};
    }
  `,
  CustomCheckboxSelectBoxList: styled(CheckboxSelectBoxList)`
    margin-bottom: ${({ selectList }) => (!!selectList ? "24px" : "0")};
  `,
  CustomTitleInfoInput: styled(TitleInfoInput)`
    margin-bottom: 24px;
  `,
};
export default Styled;
