import React from "react";

import { image } from "style/globalStyle";
import Styled from "./style";
import { licenseTable } from "asset/data/dummy";

export default function CheckList(props) {
  const { width = "218px", checkList, handleCheckItem } = props;

  return (
    <Styled.Root width={width}>
      {Object.entries(checkList).map(([key, value], idx) => {
        return (
          <Styled.Content
            key={idx}
            className="inner"
            onClick={handleCheckItem(key)}
          >
            <div>{licenseTable[key]}</div>
            <img
              src={value ? image.icCheckFillBlue : image.icCheckBlank}
              alt="check=icon"
            />
          </Styled.Content>
        );
      })}
    </Styled.Root>
  );
}
