import axios from "axios";

import { ax } from "api/index";

export const postPresigned = (url, body) => async () => {
  return await axios.post(url, body);
};

export const getPresigned = (url) => async () => {
  return await ax.get(`/presigned/${url}`);
};
