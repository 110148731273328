import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { getHeadquarterInfo } from "api/account/index";
import { API } from "store/reducer/apiSlice";
import { setProfile } from "store/reducer/commonSlice";

import { token } from "util/helper/token";
import { userStorage } from "util/helper/storage";
import {
  publicPathList,
  authPathList,
  Layout1110pxList,
  PATH,
} from "asset/data/common";
import Login from "page/login/index";
import Expired from "page/expired/index";
import Pub from "page/pub/main/index";
import PubCreate from "page/pub/create/index";
import PubUpdate from "page/pub/update/index";
import Vendor from "page/vendor/main/index";
import VendorCreate from "page/vendor/create/index";
import VendorUpdate from "page/vendor/update/index";
import Brewery from "page/brewery/main/index";
import BreweryCreate from "page/brewery/create/index";
import BreweryUpdate from "page/brewery/update/index";
import Beverage from "page/beverage/main/index";
import BeverageCreate from "page/beverage/create/index";
import BeverageCategory from "page/beverage/category/index";
import BeverageUpdate from "page/beverage/update/index";
import Education from "page/education/main/index";
import EducationCreate from "page/education/create/index";
import EducationDetail from "page/education/detail/index";
import EducationUpdate from "page/education/update/index";
import Link from "page/link/main/index";
import Transaction from "page/transaction/index";
import Permission from "page/permission/index";
import Notice from "page/notice/main/index";
import NoticeCreate from "page/notice/create/index";
import SettingAccount from "page/account/main/index";
import AccountChangePassword from "page/account/changePassword/index";
import SettingBanner from "page/banner/main/index";
import SettingBannerCreate from "page/banner/create/index";
import SettingBannerUpdate from "page/banner/update/index";
import AuthLayout from "component/layout/authLayout/index";
import Layout1110px from "component/layout/layout1110px/index";
import PublicLayout from "component/layout/publicLayout/index";
import NoticeUpdate from "page/notice/update/index";

const AuthRouter = ({ path, children, history, ...props }) => {
  const isLoggedIn = token.isLoggedIn;

  if (!userStorage.load()) {
    // userData 가 삭제된 경우

    return <Redirect to="/" />;
  }

  if (!isLoggedIn) {
    // jwt가 만료된 경우
    return <Expired />;
  }

  return <Route path={path} render={() => children} {...props} />;
};

const PublicRouter = ({ path, children, history, ...props }) => {
  const render = () => {
    const isAuth = userStorage.load();
    return isAuth ? <Redirect to="/pub" /> : children;
  };
  return <Route path={path} render={render} {...props} />;
};

export default function CustomRouter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userCookie = Cookies.get("userData");

  const getUserInfo = async () => {
    try {
      const {
        payload: { data },
      } = await dispatch(API(getHeadquarterInfo()));
      dispatch(setProfile(data));
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (userCookie) {
      getUserInfo();
    }
  }, []);

  return (
    <Switch>
      <AuthRouter path={authPathList} history={history}>
        <AuthLayout>
          <Switch>
            <Route exact path={PATH.root} component={Pub} />
            <Route exact path={PATH.pub} component={Pub} />
            <Route exact path={PATH.vendor} component={Vendor} />
            <Route exact path={PATH.brewery} component={Brewery} />
            <Route exact path={PATH.beverage} component={Beverage} />
            <Route exact path={PATH.link} component={Link} />
            <Route exact path={PATH.transaction} component={Transaction} />
            <Route
              exact
              path={PATH.settingAccount}
              component={SettingAccount}
            />
            <Route
              exact
              path={PATH.settingChangePassword}
              component={AccountChangePassword}
            />
            <Route path={Layout1110pxList}>
              <Layout1110px>
                {/* layout이 1100px 로 고정인 것들 */}
                <Route exact path={PATH.pubCreate} component={PubCreate} />
                <Route exact path={PATH.pubUpdate} component={PubUpdate} />
                <Route
                  exact
                  path={PATH.vendorCreate}
                  component={VendorCreate}
                />
                <Route
                  exact
                  path={PATH.vendorUpdate}
                  component={VendorUpdate}
                />
                <Route
                  exact
                  path={PATH.breweryCreate}
                  component={BreweryCreate}
                />
                <Route
                  exact
                  path={PATH.breweryUpdate}
                  component={BreweryUpdate}
                />
                <Route
                  exact
                  path={PATH.beverageCreate}
                  component={BeverageCreate}
                />
                <Route
                  exact
                  path={PATH.beverageCategory}
                  component={BeverageCategory}
                />
                <Route
                  exact
                  path={PATH.beverageUpdate}
                  component={BeverageUpdate}
                />

                <Route exact path={PATH.education} component={Education} />
                <Route
                  exact
                  path={PATH.educationCreate}
                  component={EducationCreate}
                />
                <Route
                  exact
                  path={PATH.educationDetail}
                  component={EducationDetail}
                />
                <Route
                  exact
                  path={PATH.educationUpdate}
                  component={EducationUpdate}
                />
                <Route exact path={PATH.permission} component={Permission} />
                <Route exact path={PATH.notice} component={Notice} />
                <Route
                  exact
                  path={PATH.noticeCreate}
                  component={NoticeCreate}
                />
                <Route
                  exact
                  path={PATH.noticeUpdate}
                  component={NoticeUpdate}
                />
                <Route
                  exact
                  path={PATH.settingBanner}
                  component={SettingBanner}
                />
                <Route
                  exact
                  path={PATH.settingBannerCreate}
                  component={SettingBannerCreate}
                />
                <Route
                  exact
                  path={PATH.settingBannerUpdate}
                  component={SettingBannerUpdate}
                />
              </Layout1110px>
            </Route>
          </Switch>
        </AuthLayout>
      </AuthRouter>
      <PublicRouter exact path={publicPathList} history={history}>
        <PublicLayout>
          <Switch>
            <Route exact path={[PATH.root, PATH.login]} component={Login} />
          </Switch>
        </PublicLayout>
      </PublicRouter>
    </Switch>
  );
}
