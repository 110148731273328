export const calWidthRatio = (width, widthArr) => {
  let totalWidth = 0;
  width = parseInt(width.slice(0, -2));
  widthArr.forEach((width) => {
    totalWidth = totalWidth + parseInt(width.slice(0, -2));
  });
  const ratio = `${(width / totalWidth) * 100}%`;

  return ratio;
};
