import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  HeaderWrapper: styled.div`
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  `,
  Header: styled.h3`
    font-size: 22px;
    font-weight: 500;
    color: ${color.Color121212};
  `,
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  `,
  Column: styled.div`
    .header {
      background-color: ${color.Colorffffff};
      display: block;
      width: 350px;
      height: 56px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
      border: 1px solid ${color.Colorc9c9c9};
      border-top: ${(props) =>
        (props.type === "pub" && `3px solid ${color.Color777777}`) ||
        (props.type === "vendor" && `3px solid ${color.Color0f8623}`) ||
        (props.type === "brewery" && `3px solid ${color.Color1f6cd5}`)};
      margin-bottom: 20px;
      padding: 20px 24px;
    }

    .content {
      display: flex;
      justify-content: space-between;
    }
  `,
};

export default Styled;
