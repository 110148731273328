import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";

import Styled from "./style";
import { image } from "style/globalStyle";
import { API } from "store/reducer/apiSlice";
import {
  getHeadquarterAlarms,
  patchHeadquarterAlarmsAll,
  patchHeadquarterAlarmsId,
} from "api/alert/index";
import { splitStringToDateAndTime } from "util/makeDataForm";
import { ALARM_CODE_LINK } from "util/constant";
import { useCheckValueFalse } from "hook/useIsDisable";
import useTimer from "hook/useTimer";

const initState = {
  alarmList: [],
  readClick: false,
  disabled: false,
};

export default function Alert() {
  const history = useHistory();
  const [state, setState] = useImmer(initState);
  const [visible, setVisible] = useState(false);
  const [isAlramExist, setIsAlramExist] = useState(false);
  let ref = useRef();
  const dispatch = useDispatch();

  const getDetailData = async () => {
    const res = await dispatch(API(getHeadquarterAlarms()));
    const unReadList = [];
    if (res.payload.status === 200) {
      const { data } = res.payload;
      data.alarms.forEach((item) => {
        // 객체에 created 변경
        const { unread } = item;
        const time = splitStringToDateAndTime(item.created);
        item.created = time;
        item.link = ALARM_CODE_LINK[item.alarmCode];
        unReadList.push(unread);
      });
      setState((draft) => {
        draft.alarmList = data.alarms;
      });
    }

    const alramExist = unReadList.some((item) => item === true);
    setIsAlramExist(alramExist);
  };

  const handleClickOutside = (event) => {
    const target = event.target;
    if (event.keyCode) {
      // esc 클릭시
      if (event.keyCode === 27) {
        setVisible(false);
      }
    } else if (visible && ref.current && !ref.current.contains(target)) {
      setVisible(false);
    }
  };

  const handleClickAll = async () => {
    // 전체 알림 read 처리
    const res = await dispatch(API(patchHeadquarterAlarmsAll()));
    const { status } = res.payload;
    if (status === 200) {
      state.alarmList.forEach((item, idx) => {
        setState((draft) => {
          draft.alarmList[idx].unread = 0;
        });
        // api 값을 다시 받지 않고, state 값을 변경함으로써 쓸데없는 api 요청을 줄임
      });
      setState((draft) => {
        draft.readClick = !draft.readClick;
      });
    }
  };

  const handleClickAlarmItem =
    ({ unread, alarmId, link }, idx) =>
    () => {
      unread && handleReadAlarm(alarmId, idx);
      setVisible(false);
      history.push(link);
    };

  const handleReadAlarm = async (alarmId, idx) => {
    // 알림 한 개 read 처리
    const res = await dispatch(API(patchHeadquarterAlarmsId(alarmId)));
    const { status } = res.payload;
    if (status === 200) {
      setState((draft) => {
        draft.readClick = !draft.readClick;
        draft.alarmList[idx].unread = 0;
        // api 값을 다시 받지 않고, state 값을 변경함으로써 쓸데없는 api 요청을 줄임
      });
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleClickOutside);
    };
  }, [visible]);

  useEffect(() => {
    getDetailData();
  }, [visible]);

  const checkList = useCallback(() => {
    const arr = [];
    state.alarmList.forEach((item) => {
      arr.push(item.unread);
    });
    return arr;
  }, [state.alarmList]);

  useCheckValueFalse({ list: checkList(), setState, key: "disabled" });
  useTimer(getDetailData);

  return (
    <Styled.Root>
      <img
        src={isAlramExist ? image.icNew : image.icAlarm}
        className="alarm-icon"
        alt="alarm-icon"
        onClick={() => {
          setVisible(!visible);
        }}
      />
      {visible && (
        <Styled.AlertDesc ref={ref}>
          <Styled.Header disabled={state.disabled}>
            <Styled.HeaderContent>
              <h3>알림</h3>
              <button
                type="button"
                onClick={state.disabled ? null : handleClickAll}
              >
                전체읽기
              </button>
            </Styled.HeaderContent>
          </Styled.Header>
          <Styled.DetailWrapper>
            {state.alarmList.map((item, idx) => {
              const { img, content, created, unread } = item;
              // unread - 0: 읽음, 1: 안 읽음
              return (
                <Styled.Detail key={idx} unread={unread}>
                  <button
                    type="button"
                    onClick={handleClickAlarmItem(item, idx)}
                  >
                    <img src={img ? img : image.icProfile} alt="avatar" />
                    <div>
                      <span className="alert-content">{content}</span>
                      <span className="alert-time">{created}</span>
                    </div>
                  </button>
                </Styled.Detail>
              );
            })}
          </Styled.DetailWrapper>
        </Styled.AlertDesc>
      )}
    </Styled.Root>
  );
}
