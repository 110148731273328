import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

const Date = ({
  className,
  date,
  isSelectedDate = false,
  isHide = false,
  isOtherDate,
  highlight,
  disabled,
  handleSelectDate,
}) => {
  const isToday = dayjs().isSame(date, "d");

  return (
    <Wrapper className={className} highlight={highlight}>
      {!isHide && (
        <DateButton
          type="button"
          aria-current={isToday ? "date" : "false"}
          aria-selected={isSelectedDate}
          isOtherDate={isOtherDate}
          disabled={disabled}
          onClick={handleSelectDate(date.format("YYYY-MM-DD"))}
        >
          {<span>{date.format("D")}</span>}
        </DateButton>
      )}
    </Wrapper>
  );
};

export default Date;

const Wrapper = styled.li.attrs(({ highlight }) => ({
  bg: highlight ? "#f8f8f8" : "transparent",
}))`
  background-color: ${({ bg }) => bg};
`;

const DateButton = styled.button.attrs(({ isOtherDate }) => ({
  bg: isOtherDate ? "#ccc" : "transparent",
  color: isOtherDate ? "#fff" : "#121212",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: ${({ bg }) => bg};
  & > span {
    color: ${({ color }) => color};
  }

  &[aria-selected="true"] {
    background-color: #23282b;

    & > span {
      color: #fff;
    }
  }

  &[disabled] {
    cursor: not-allowed;

    & > span {
      color: #c9c9c9;
    }
  }
`;
