import React from "react";

import SelectBox from "component/selectBox/index";
import InfoInputWrapper from "../index";

export default function SelectInfoInput(props) {
  const {
    title,
    name,
    innerWidth,
    height = "40px",
    selectList,
    selectLabel,
    value,
    placeholder,
    disabled,
    state,
    setState,
    isRequired,
    isValidError,
  } = props;

  const handleSelect = (name, value) => {
    setState((draft) => {
      draft[name] = value;
    });
  };

  return (
    <InfoInputWrapper title={title} name={name} isRequired={isRequired}>
      <SelectBox
        width="100%"
        innerWidth={innerWidth}
        height={height}
        selectList={selectList}
        selectLabel={selectLabel}
        value={value ? value : state[name]}
        name={name}
        placeholder={placeholder}
        handleSelect={handleSelect}
        disabled={disabled}
        isValidError={isValidError}
      />
    </InfoInputWrapper>
  );
}
