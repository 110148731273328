import React from "react";
import Styled from "./style";

import { handleChangeInput } from "hook/handler";

export default function TitleInfoInput(props) {
  const { className, state, setState, name, maxLength } = props;

  return (
    <Styled.Root className={className}>
      <Styled.Heading>제목</Styled.Heading>
      <Styled.Input
        name={name}
        value={state[name]}
        onChange={handleChangeInput(setState, maxLength)}
      />
      <Styled.LengthCheckWrapper>
        <span>{state[name].length}</span>
        <span>{`/${maxLength}`}</span>
      </Styled.LengthCheckWrapper>
    </Styled.Root>
  );
}
