import React, { Fragment } from "react";
import Styled from "./style";

export default function BlockAutoComplete() {
  return (
    <Fragment>
      <Styled.BlockAutoComplete />
      <Styled.BlockAutoComplete type="password" />
    </Fragment>
  );
}
