import RadioButton from "component/radioButton/index";
import React from "react";
import InfoInputWrapper from "../index";

export default function RadioInfoInput(props) {
  const {
    title,
    name,
    height = "40px",
    radioContents,
    state,
    setState,
    isRequired,
  } = props;
  return (
    <InfoInputWrapper title={title} name={name} isRequired={isRequired}>
      <RadioButton
        name={name}
        height={height}
        contents={radioContents}
        state={state}
        setState={setState}
      />
    </InfoInputWrapper>
  );
}
