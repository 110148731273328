import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import queryString from "query-string";

import Styled from "./style";
import { API } from "store/reducer/apiSlice";
import {
  deleteBeveragesBeverageId,
  getBeverages,
  getBeveragesCategories,
  putBeverages,
} from "api/beverage/index";
import { getPresigned } from "api/presigend";
import BeverageSearchBar from "container/searchBar/beverageSearchBar/index";
import PaginationComplex from "container/paginationComplex/index";
import Table from "component/table/table/index";
import Toast from "component/toast/index";
import { beverageColumn } from "asset/data/column";
import useTableData from "hook/useTableData";
import useToast from "hook/useToast";

export default function Beverage({ match }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(query.name || "");
  const [filterList, setFilterList] = useImmer([]);
  const { isShow, message, toast } = useToast();

  const config = {
    category: query.category || "",
    name: query.name || "",
    sort: query.sort || "",
    page: query.page || "",
    perPage: query.perPage || "",
  };

  const apiHandler = async (config) => {
    const {
      payload: {
        status,
        data: { beverages, pageInfo },
      },
    } = await dispatch(API(getBeverages(config)));
    const promises = [];

    beverages.forEach((item) => {
      promises.push(
        dispatch(API(getPresigned(`beverage/${item.fileId}`))).then(
          ({ payload: { data } }) => {
            item.fileUrl =
              data.signedUrlw250 || data.signedUrlw500 || data.signedUrl;
          }
        )
      );
      item.id = item.beverageId;
    });
    await Promise.all(promises);

    return {
      tableData: beverages,
      pageInfo: pageInfo,
      status: status,
    };
  };

  const { tableData, pageInfo, getTableData, handlePagination } = useTableData(
    apiHandler,
    config
  );

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      getTableData({ name: searchValue, page: 1 });
    },
    [getTableData, searchValue]
  );

  const handleFilter = useCallback(
    (category) => {
      getTableData({ category });
    },
    [getTableData]
  );

  const handleReload = useCallback(() => {
    setSearchValue("");
    for (let option in config) {
      config[option] = "";
    }
    getTableData(config);
  }, [getTableData, setSearchValue]);

  const handleSort = useCallback(() => {
    const sortOption = query.sort === "desc" ? "asc" : "desc";
    getTableData({ sort: sortOption });
  }, [getTableData, query.sort]);

  const handleChangeSearchInput = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const handleDeleteBeverage = useCallback(
    async (beverageId) => {
      dispatch(API(deleteBeveragesBeverageId(beverageId))).then(() =>
        getTableData()
      );
    },
    [dispatch, getTableData]
  );

  const getFilterList = useCallback(async () => {
    const {
      payload: {
        data: { categories },
      },
    } = await dispatch(API(getBeveragesCategories()));
    const filterList = categories.map(({ category }) => ({
      label: category,
      value: category,
    }));
    const newFilterList = [{ label: "전체보기", value: "" }, ...filterList];
    setFilterList(newFilterList);

    return categories;
  }, [dispatch, setFilterList]);

  const changeRating = useCallback(
    async (name, value) => {
      try {
        await dispatch(API(putBeverages(name, { rating: value })));
        toast("레이팅 정보가 수정되었습니다.");
        getTableData();
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, toast, getTableData]
  );

  useEffect(() => {
    getFilterList();
    getTableData();
    if (location.search === "") {
      setSearchValue("");
    }
  }, [location.search]);

  return (
    <>
      <Styled.Root>
        <BeverageSearchBar
          filterList={filterList}
          filterValue={config.category}
          searchValue={searchValue}
          handleChangeSearchInput={handleChangeSearchInput}
          handleFilter={handleFilter}
          handleSearch={handleSearch}
          handleReload={handleReload}
        />
        <Table
          columns={beverageColumn}
          datas={tableData}
          thHeight="44px"
          height="72px"
          modalKey="deleteBeverageModal"
          handleDelete={handleDeleteBeverage}
          updateHref="/beverage/update"
          handleSort={handleSort}
          sort={query.sort}
          changeRating={changeRating}
        />
        <PaginationComplex
          pageInfo={pageInfo}
          handlePagination={handlePagination}
        />
      </Styled.Root>
      <Toast isShow={isShow} message={message} />
    </>
  );
}
