import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    padding: 34px 0;
  `,
  Header: styled.h3`
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    color: ${color.Color121212};
    margin-bottom: 30px;
  `,
  Wrapper: styled.div`
    margin-bottom: 40px;
    padding: 30px 40px;
    background-color: #fff;

    input:first-child {
      margin-bottom: 24px;
    }

    textarea {
      margin-bottom: 28px;
    }

    .title {
      color: ${color.Color777777};
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  `,
  GuideWrapper: styled.figure`
    & figcaption {
      margin-bottom: 10px;
      font-size: 14px;
      color: ${color.Color777777};
    }

    & img {
      width: 300px;
    }
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
};
export default Styled;
