import styled from "styled-components";

const Styled = {
  Root: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: inherit;
    position: relative;
  `,
  Wrapper: styled.div`
    max-width: 1110px;
    width: 100%;
  `,
};
export default Styled;
