import React from "react";

import { SubMenuIcon } from "./style";

const SubMenuButton = ({ ...restProps }) => {
  return (
    <button type="button" {...restProps}>
      <SubMenuIcon />
    </button>
  );
};

export default SubMenuButton;
