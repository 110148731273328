import styled, { css } from "styled-components";

import { color } from "style/globalStyle";

export const Dim = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  &[hidden] {
    display: none;
  }
`;

export const Dialog = styled.dialog`
  position: relative;
  width: 540px;
  border-radius: 4px;
  padding: 32px 30px 24px;
  background-color: ${color.Colorffffff};
`;

export const Header = styled.dl`
  margin-bottom: 42px;

  & dt {
    margin-bottom: 8px;

    & h2 {
      font-size: 20px;
      line-height: 1.2;
      font-weight: bold;
      color: ${color.Color121212};
    }

    & dd {
      font-size: 14px;
      line-height: 1.21;
      color: ${color.Color777777};
    }
  }
`;

export const Content = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const btnMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.21;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const CancelBtn = styled.button`
  ${btnMixin};
  border: 1px solid ${color.Colorb1b1b1};
  color: ${color.Color777777};
`;

export const ConfirmBtn = styled.button`
  ${btnMixin};
  border: 1px solid ${color.Colorb1b1b1};
  color: ${color.Colorffffff};
  background-color: ${color.Color12171d};
`;
