import CompleteMessage from "container/modal/completeMessage/index";
import ConfirmModal from "container/modal/confirmModal/index";
import LeaveModal from "container/modal/leaveModal/index";
import RefuseConfirmModal from "container/modal/refuseConfirmModal/index";
import DeleteModal from "container/modal/deleteModal/index";
import ImageModal from "container/modal/imageModal/index";
import ExpiredModal from "container/modal/expiredModal/index";

export const modalCont = (props) => {
  const {
    modalKey, //  modal key
    setState, //  부모 요소에서의 setState
    activateFunction, //  확인 버튼 클릭 후 동작하는 함수
    nextPath, //  modal 닫히면서 이동할 경로
    reload = false, //  modal 닫히면서 reload 될지 말지 결정
    completeContent, //
    imageUrl,
    state,
  } = props;
  switch (modalKey) {
    case "deletePubModal":
      return (
        <DeleteModal
          title="선택한 매장을 삭제하시겠습니까?"
          content="매장을 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteEduModal":
      return (
        <DeleteModal
          title="선택한 교육자료를 삭제하시겠습니까?"
          content="교육자료를 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );

    case "deleteVendorModal":
      return (
        <DeleteModal
          title="선택한 도매사를 삭제하시겠습니까?"
          content="도매사를 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteBreweryModal":
      return (
        <DeleteModal
          title="선택한 제조사를 삭제하시겠습니까?"
          content="제조사를 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteBeverageModal":
      return (
        <DeleteModal
          title="선택한 맥주를 삭제하시겠습니까?"
          content="맥주를 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteTransactionModal":
      return (
        <DeleteModal
          title="선택한 거래내역을 삭제하시겠습니까?"
          content="거래내역을 삭제하면 목록에서 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteNoticeModal":
      return (
        <DeleteModal
          title="공지사항을 삭제하시겠습니까?"
          content="공지사항을 삭제하면 매장App에서도 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteBannerModal":
      return (
        <DeleteModal
          title="선택한 배너를 삭제하시겠습니까?"
          content="배너를 삭제하면 매장App에서도 사라지며, 다시 복구할 수 없습니다."
          completeModalKey="deleteComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );

    case "leavePage":
      return (
        <LeaveModal
          title="사이트에서 나가시겠습니까?"
          content="변경사항이 저장되지 않을 수 있습니다."
          setState={setState}
          nextPath={nextPath}
        />
      );
    case "changePasswordConfirmModal":
      return (
        <ConfirmModal
          title="비밀번호를 변경하시겠습니까?"
          content="비밀번호 변경 시 계정보안을 위해 중복 로그인된 브라우저 및 기기에서 로그아웃 될 수 있습니다. 비밀번호를 변경하시겠습니까?"
          cancelContent="취소"
          completeModalKey="submitComplete"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "refuseStoreConfirmModal":
      return (
        <RefuseConfirmModal
          title="매장 등록을 거절하시겠습니까?"
          content="등록 거절을 하시면 앱 내 주문을 이용할 수 없습니다."
          cancelContent="취소"
          completeModalKey="storeRefused"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "refuseProductConfirmModal":
      return (
        <RefuseConfirmModal
          title="상품 등록을 거절하시겠습니까?"
          content="상품 등록 거절 시, 앱 내에서 주문이 불가합니다"
          cancelContent="취소"
          completeModalKey="productRefused"
          setState={setState}
          activateFunction={activateFunction}
        />
      );
    case "deleteComplete":
      return (
        <CompleteMessage
          content="삭제가 완료되었습니다."
          nextPath={nextPath}
          reload={reload}
        />
      );
    case "noticeRegistered":
      return (
        <CompleteMessage
          content="공지사항이 등록되었습니다"
          nextPath={nextPath}
        />
      );
    case "noticeUpdated":
      return (
        <CompleteMessage
          content="수정 정보가 저장되었습니다."
          nextPath={nextPath}
          reload={reload}
        />
      );
    case "educationRegistered":
      return (
        <CompleteMessage
          content="교육 자료가 등록되었습니다."
          nextPath={nextPath}
        />
      );
    case "educationUpdated":
      return (
        <CompleteMessage
          content="수정 정보가 저장되었습니다."
          nextPath={nextPath}
          reload={reload}
        />
      );
    case "imageShow":
      return <ImageModal imgUrl={imageUrl} setState={setState} state={state} />;
    case "submitComplete":
      return <CompleteMessage content={completeContent} nextPath={nextPath} />;
    case "expired":
      return <ExpiredModal />;
    default:
      return;
  }
};
