import React, { useEffect, useState, useRef } from "react";
import { useImmer } from "use-immer";
import Styled from "./style";
import { image } from "style/globalStyle";
import PortalModal from "container/modal/portalModal/index";
import { modalCont } from "asset/data/modalCont";
import { useHistory } from "react-router";
import { setModalKey } from "hook/modal";

import { useLocation } from "react-router-dom";
const initState = {
  updateImg: image.icUpdateGray,
  deleteImg: image.icDeleteGray,
  modal: {
    modalVisible: false,
    modalKey: "",
  },
};
export default function SideMenu(props) {
  const {
    className,
    modalKey,
    updateHref,
    nextPath,
    handleDelete,
    id = "",
  } = props;

  const [visible, setVisible] = useState(false);
  const [state, setState] = useImmer(initState);

  const ref = useRef();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClickOutside = (event) => {
    setState((draft) => {
      //  이미지 초기화 부분
      draft.updateImg = image.icUpdateGray;
      draft.deleteImg = image.icDeleteGray;
    });
    if (event.keyCode) {
      // esc 클릭시
      if (event.keyCode === 27) {
        setVisible(false);
      }
    } else if (
      typeof event.target?.className === "string" &&
      !event.target.className.includes(className)
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleClickOutside);
    };
  }, []);

  const handleMouseHover = (stateName, img) => () => {
    setState((draft) => {
      draft[stateName] = image[img];
    });
  };

  const handleClickDeleteButton = () => {
    setModalKey(setState, modalKey);
  };

  const activateFunction = () => {
    typeof id === "object" ? handleDelete(...id) : handleDelete(id);
    setTimeout(() => {
      setState((draft) => {
        draft.modal.modalVisible = false;
      });
    }, [1000]);
  };

  const handleOpenDialog = (e) => {
    e.stopPropagation();
    setVisible((prev) => !prev);
  };

  return (
    <Styled.Root className={className}>
      {state.modal.modalVisible && (
        <PortalModal setState={setState}>
          {modalCont({
            modalKey: state.modal.modalKey,
            setState,
            activateFunction,
            nextPath,
          })}
        </PortalModal>
      )}
      <img
        className={className}
        ref={ref}
        src={image.icColon}
        alt="submenu-icon"
        onClick={handleOpenDialog}
      />
      {visible && (
        <Styled.Popup>
          {pathname !== "/link" && (
            <div
              className="inner"
              onMouseOver={handleMouseHover("updateImg", "icUpdate")}
              onMouseLeave={handleMouseHover("updateImg", "icUpdateGray")}
              onClick={() => history.push(`${updateHref}`)}
            >
              <img src={state.updateImg} alt="update-icon" />
              <span>수정</span>
            </div>
          )}
          <div
            className="inner"
            onMouseOver={handleMouseHover("deleteImg", "icDelete")}
            onMouseLeave={handleMouseHover("deleteImg", "icDeleteGray")}
            onClick={handleClickDeleteButton}
          >
            <img src={state.deleteImg} alt="delete-icon" />
            <span>삭제</span>
          </div>
        </Styled.Popup>
      )}
    </Styled.Root>
  );
}
