import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 320px;
    height: 138px;
    padding: 20px 16px;
    border-radius: 4px;
    border: dashed 1px ${color.Colorc9c9c9};
    background-color: ${(props) =>
      props.type === "select" ? color.Colorf6f8f8 : null};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Upper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .desc-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .desc {
        color: ${color.Color777777};
        font-size: 12px;
      }
    }
    .close-icon {
      :hover {
        cursor: pointer;
      }
    }
  `,
  Lower: styled.div`
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    div {
      width: 136px;
      height: 46px;
      border-radius: 5px;
      border: solid 0.5px ${color.Colord5d8db};
      background-color: ${color.Colorfcfcfd};
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 14px;
      :hover {
        cursor: pointer;
      }
    }
  `,
};

export default Styled;
