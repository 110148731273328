import { ax } from "api/index";

export const postVendors = (body) => async () => {
  return await ax.post(`/vendors`, body);
};

export const getVendors = (req) => async () => {
  return await ax.get(`/vendors`, { params: { ...req } });
};

export const getVendorsNames = (req) => async () => {
  return await ax.get(`/vendors/names`, { params: { ...req } });
};

export const getVendorsVendorId = (vendorId) => async () => {
  return await ax.get(`/vendors/${vendorId}`);
};

export const putVendorsVendorId = (vendorId, body) => async () => {
  return await ax.put(`/vendors/${vendorId}`, body);
};

export const deleteVendorsVendorId = (vendorId) => async () => {
  return await ax.delete(`/vendors/${vendorId}`);
};
