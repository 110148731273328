import React from "react";
import { useLocation } from "react-router-dom";
import { PATH } from "asset/data/common";
import PubDetail from "./pub/index";
import BeverageDetail from "./beverage/index";
import BreweryDetail from "./brewery/index";
import VendorDetail from "./vendor/index";
export default function TableDetail(props) {
  const location = useLocation();
  const { data, columns } = props;

  const content = () => {
    switch (location.pathname) {
      case PATH.pub:
        return <PubDetail data={data} columns={columns} />;
      case PATH.vendor:
        return <VendorDetail data={data} columns={columns} />;
      case PATH.brewery:
        return <BreweryDetail data={data} columns={columns} />;
      case PATH.beverage:
        return <BeverageDetail data={data} columns={columns} />;

      default:
        break;
    }
  };
  return <>{content()}</>;
}
