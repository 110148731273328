import React from "react";

import { Root, Header, Content } from "./style";

const SubLayout = ({ children }) => {
  return <Root>{children}</Root>;
};

export default SubLayout;

SubLayout.Header = function BeverageHeader({ title, children }) {
  return (
    <Header>
      <h2>{title}</h2>
      {children}
    </Header>
  );
};

SubLayout.Content = function BeverageContent({ children }) {
  return <Content>{children}</Content>;
};
