import React from "react";
import styled from "styled-components";

import ExcelDownloadButton from "component/button/excelDownloadButton/ExcelDownloadButton";
import DataStatistics from "./DataStatistics";

const TableOptionArea = ({ totalAmount, totalPrice, handleExcelDownload }) => {
  return (
    <OptionContainer>
      <StyledDataStatics totalAmount={totalAmount} totalPrice={totalPrice} />
      <ExcelDownloadButton onClick={handleExcelDownload} />
    </OptionContainer>
  );
};

export default TableOptionArea;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledDataStatics = styled(DataStatistics)`
  && {
    width: 400px;
  }
`;
