import styled from "styled-components";
import { color } from "style/globalStyle";
const Styled = {
  Root: styled.div`
    width: 100%;
    min-width: 1210px;
    height: inherit;
    display: flex;
    position: relative;
  `,
  Wrapper: styled.div`
    width: ${(props) => `calc(100% - ${props.marginLeft})`};
    min-width: 1210px;
    margin-left: ${(props) => props.marginLeft};
    position: relative;
    /* 교육자료 페이지 bg color 조정 */
    background-color: ${(props) =>
      props.path === "/education"
        ? `${color.Colorffffff}`
        : `${color.Coloredf1f2}`};
  `,
  Body: styled.main`
    /* 교육자료 페이지 bg color 조정 */
    background-color: ${(props) =>
      props.path === "/education"
        ? `${color.Colorffffff}`
        : `${color.Coloredf1f2}`};
    min-width: 1210px;
    width: 100%;
    padding-top: 56px;
  `,
};
export default Styled;
