import React, { forwardRef, createContext, useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { typo } from "style/globalStyle";
import { ReactComponent as CloseIconSvg } from "asset/image/icCloseWhite.svg";

const ModalContext = createContext();

const Modal = forwardRef(
  ({ children, id, className, isOpen, handleDimClose }, ref) => {
    const uid = `modal-id-${id}`;

    return createPortal(
      <ModalContext.Provider value={{ uid }}>
        <Dim onClick={handleDimClose}>
          <Dialog
            ref={ref}
            open={isOpen}
            className={className}
            aria-modal="true"
            aria-labelledby={uid}
            tabIndex={-1}
          >
            {children}
          </Dialog>
        </Dim>
      </ModalContext.Provider>,
      document.querySelector("#modal")
    );
  }
);

Modal.Header = function ModalHeader({ children, className, handleClose }) {
  const { uid } = useContext(ModalContext);
  return (
    <Header className={className}>
      <h2 id={uid}>{children}</h2>
      <CloseButton type="button" aria-label="닫기" onClick={handleClose}>
        <CloseIconSvg />
      </CloseButton>
    </Header>
  );
};

Modal.Content = function ModalContent({ children, className }) {
  return (
    <Content className={className} role="group">
      {children}
    </Content>
  );
};

Modal.displayName = "Modal";

export default Modal;

const Dim = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;

const Dialog = styled.dialog`
  position: relative;
  width: 927px;
  border-radius: 3px;
  background-color: #fff;
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  background-color: #323c41;

  & > h2 {
    ${typo.regular14};
    line-height: 1.25;
    color: #ffffff;
  }
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  transform: translateX(14px) scale(1.2);

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  background-color: #fff;
`;
