import styled from "styled-components";

import BasicInput from "../basicInput/index";
import { color } from "style/globalStyle";

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Input = styled(BasicInput)`
  width: calc(100% - 82px);
`;

export const Sign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${color.Colorc9c9c9};
  font-size: 14px;
  line-height: 1.71;
  color: ${color.Color121212};
  background-color: ${color.Colore0e5e8};
`;
