import React from "react";
import styled from "styled-components";

import Table from "component/newTable/Table";
import { pubAndSellerTableColumns } from "asset/data/transaction";
import { deleteConsecutiveEqualValue } from "util/helper/transaction";

const VendorsTransactionTable = ({ data, handleTransactionRow }) => {
  // TODO: API 수정되기 전에 임시로 정렬
  const sortedData = data.sort((a, b) => {
    if (a.category < b.category) return 1;
    if (a.category > b.category) return -1;
    if (a.category === b.category) return 0;
  });
  const parsedData = deleteConsecutiveEqualValue(sortedData, "category");

  const statusKor = {
    requested: "진행",
    completed: "완료",
    canceled: "취소",
  };

  return (
    <>
      <StyleTable title="도매사 수주사 거래내역 목록" hasDetail={true}>
        <Table.Head>
          {pubAndSellerTableColumns.map((column) => (
            <Table.HeadColumn key={column.key} column={column} />
          ))}
        </Table.Head>
        <Table.Body>
          {parsedData.map((rowData, i) => (
            <Table.TransactionRow
              key={rowData.optionId + i}
              id={rowData.optionId}
              handleTransactionRow={handleTransactionRow(
                rowData.beverageId,
                rowData.volume,
                rowData.status
              )}
            >
              {pubAndSellerTableColumns.map((columnData) => (
                <Table.Cell key={columnData.key}>
                  {rowData.status === "canceled" ? (
                    <CancelRow>
                      {columnData.key === "status"
                        ? statusKor[rowData[columnData.key]]
                        : rowData[columnData.key]}
                    </CancelRow>
                  ) : (
                    <span>
                      {columnData.key === "status"
                        ? statusKor[rowData[columnData.key]]
                        : rowData[columnData.key]}
                    </span>
                  )}
                </Table.Cell>
              ))}
            </Table.TransactionRow>
          ))}
        </Table.Body>
      </StyleTable>
    </>
  );
};

export default VendorsTransactionTable;

const StyleTable = styled(Table)`
  height: 860px;
  margin-bottom: 22px;

  & > tbody {
    min-height: 100%;
  }

  && tr {
    grid-template-columns:
      minmax(90px, 12.7659%) minmax(130px, 18.4397%) minmax(50px, 7.0921%) minmax(
        54px,
        7.6595%
      )
      minmax(110px, 15.6028%) minmax(100px, 14.1843%) minmax(110px, 15.6028%) minmax(50px, 7.0921%);
    min-height: 41px;
  }

  && > thead > tr {
    height: 41px;
    font-size: 13px;
    color: #777;
  }
`;

const CancelRow = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #777777;
`;
