import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";

import Table from "component/newTable/Table";
import { vendorAndSellerTwoDpethTableColumns } from "asset/data/transaction";
import { image, typo } from "style/globalStyle";
import OrderHistoryModal from "../OrderHistoryModal";
import useDialog from "hook/useDialog";

const VendorsTransactionTwoDepthTable = ({ data, isClickRow }) => {
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [openDetailInfoRowId, setOpenDetailInfoRowId] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [initSearchData, setInitSearchData] = useState([]);

  const { modalRef, isOpen, setIsOpen, handleClose, handleDimClose } =
    useDialog();

  const handleSearchVendor = async (e) => {
    e.preventDefault();
    if (!searchValue.trim()) {
      setSearchData(initSearchData);
    } else {
      setSearchData(
        searchData.filter((data) => data.sellerName.includes(searchValue))
      );
    }
  };

  const handleOpenRowDetailInfo = useCallback(
    (id) => () => {
      setOpenDetailInfoRowId(id);
      setIsOpen(true);
    },
    []
  );

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setSearchData(data);
    setInitSearchData(data);
  }, [data]);

  return (
    <>
      <StyleTable
        title="매장 수주사 거래내역 수주사 목록"
        hasDetail={true}
        openDetailInfoRowId={openDetailInfoRowId}
        handleOpenRowDetailInfo={handleOpenRowDetailInfo}
      >
        <Table.Head>
          {vendorAndSellerTwoDpethTableColumns.map((column) => (
            <Table.HeadColumn key={column.key} column={column} />
          ))}
        </Table.Head>
        <Table.Body>
          {isClickRow ? (
            <>
              <TableSearchRow>
                <td>
                  <form onSubmit={handleSearchVendor}>
                    <input
                      type="text"
                      placeholder="수주사명으로 검색"
                      onChange={handleChangeSearchValue}
                    />
                    <button>검색</button>
                  </form>
                </td>
              </TableSearchRow>
              {searchData.map((rowData) => (
                <Table.Row key={rowData.sellerId} id={rowData.sellerId}>
                  {vendorAndSellerTwoDpethTableColumns.map((columnData) => (
                    <Table.Cell key={columnData.key}>
                      <span>{rowData[columnData.key]}</span>
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </>
          ) : (
            <NoDataWrapper>
              <td>
                <img src={image.icNoData} alt="no data" />
              </td>
            </NoDataWrapper>
          )}
        </Table.Body>
      </StyleTable>

      {isOpen && (
        <OrderHistoryModal
          type="vendors"
          query={qs}
          openDetailInfoRowId={openDetailInfoRowId}
          modalRef={modalRef}
          handleClose={handleClose}
          handleDimClose={handleDimClose}
        />
      )}
    </>
  );
};

export default VendorsTransactionTwoDepthTable;

const StyleTable = styled(Table)`
  height: 860px;

  & > tbody {
    height: 817px;
  }

  && tr {
    grid-template-columns:
      minmax(130px, 34.2105%) minmax(70px, 18.421%) minmax(70px, 18.421%)
      minmax(110px, 28.9473%);
    min-height: 41px;
  }

  && > thead > tr {
    height: 41px;
  }
`;

const TableSearchRow = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e0e5e8;
  padding: 0 19px;
  background-color: #f6f8f8;

  & > td {
    width: 100%;
    & > form {
      display: grid;
      grid-template-columns: 1fr 60px;
      column-gap: 8px;
      width: 100%;

      & input {
        ${typo.medium13};
        width: 100%;
        height: 30px;
        border: 1px solid #c9c9c9;
        border-radius: 2px;
        padding: 0 12px;
        background-color: #fff;

        &::placeholder {
          ${typo.regaulr13};
          color: #b1b1b1;
        }
      }

      & button {
        ${typo.regular13};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border: 1px solid #e0e5e8;
        border-radius: 2px;
        background-color: #e0e5e8;
      }
    }
  }
`;

const NoDataWrapper = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
