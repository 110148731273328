import React from "react";
import { useSelector } from "react-redux";
import Header from "container/header/index";
import Navbar from "component/navbar/index";
import Styled from "./style";
import { useLocation } from "react-router";

export default function AuthLayout({ children }) {
  // 교육자료 페이지 background color 조정용 path
  const path = useLocation().pathname;
  const globalStoreValue = useSelector((state) => ({
    navbarSize: state.common.navbarSize,
  }));
  return (
    <Styled.Root>
      <Navbar />
      <Styled.Wrapper path={path} marginLeft={globalStoreValue.navbarSize}>
        <Header />
        <Styled.Body path={path} marginLeft={globalStoreValue.navbarSize}>
          {children}
        </Styled.Body>
      </Styled.Wrapper>
    </Styled.Root>
  );
}
