import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import Styled from "./style";
import { API } from "store/reducer/apiSlice";
import { deletePubsPubId, getPubs } from "api/pub/index";
import PubsSearchBar from "container/searchBar/pubSearchBar/index";
import PaginationComplex from "container/paginationComplex/index";
import Table from "component/table/table/index";
import { pubsColumn } from "asset/data/column";
import useTableData from "hook/useTableData";

export default function Pub() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(query.name || "");
  const config = {
    name: query.name || "",
    franchise: query.franchise || "",
    sort: query.sort || "",
    page: query.page || "",
    perPage: query.perPage || "",
  };
  const apiHandler = async (config) => {
    const res = await dispatch(API(getPubs(config)));
    res.payload.data.pubs.forEach((item) => (item.id = item.pubId));

    return {
      tableData: res.payload.data.pubs,
      pageInfo: res.payload.data.pageInfo,
      status: res.payload.status,
    };
  };

  const { tableData, pageInfo, getTableData, handlePagination } = useTableData(
    apiHandler,
    config
  );

  const handleChangeSearchInput = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      getTableData({ name: searchValue });
    },
    [getTableData, searchValue]
  );
  const handleFilter = useCallback(
    (franchise) => {
      getTableData({ franchise });
    },
    [getTableData]
  );

  const handleReload = useCallback(() => {
    setSearchValue("");
    for (let option in config) {
      config[option] = "";
    }
    getTableData(config);
  }, [getTableData, setSearchValue]);

  const handleSort = useCallback(() => {
    const sortOption = query.sort === "desc" ? "asc" : "desc";
    getTableData({ sort: sortOption });
  }, [getTableData, query.sort]);

  const handleDeletePub = useCallback((pubsId) => {
    dispatch(API(deletePubsPubId(pubsId))).then(() => getTableData());
  }, []);

  useEffect(() => {
    getTableData();
    if (location.search === "") {
      setSearchValue("");
    }
  }, [location.search]);

  return (
    <Styled.Root>
      <PubsSearchBar
        filterValue={config.franchise}
        searchValue={searchValue}
        handleChangeSearchInput={handleChangeSearchInput}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        handleReload={handleReload}
      />
      <Table
        columns={pubsColumn}
        datas={tableData}
        modalKey="deletePubModal"
        handleDelete={handleDeletePub}
        updateHref="/pub/update"
        sort={query.sort}
        handleSort={handleSort}
      />
      <PaginationComplex
        pageInfo={pageInfo}
        handlePagination={handlePagination}
      />
    </Styled.Root>
  );
}
