import Styled from "./style";
import React from "react";
import PortalModal from "../portalModal/index";
import { image } from "style/globalStyle";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import LinkChoiceList from "component/linkChoiceList/index";
import { useDispatch } from "react-redux";
import { API } from "store/reducer/apiSlice";
import { getVendorsNames } from "api/vendor/index";
import {
  getBrewerysAndPubsNames,
  getBrewerysAndVendorsNames,
  getVendorsAndPubsNames,
  postLinksBreweriesPubs,
  postLinksBreweriesVendors,
  postLinksVendorsPubs,
} from "api/link/index";
import { getBreweriesNames } from "api/brewery/index";

const subHeader = [
  {
    leftSubHeader: { label: "도매사", value: "vendor" },
    rightSubHeader: { label: "매장", value: "pub" },
  },
  {
    leftSubHeader: { label: "제조사", value: "brewery" },
    rightSubHeader: { label: "매장", value: "pub" },
  },
  {
    leftSubHeader: { label: "제조사", value: "vendor" },
    rightSubHeader: { label: "도매사", value: "brewery" },
  },
];

const initState = {
  leftState: { original: [], modalSearch: [], select: [], view: "original" },
  rightState: { original: [], modalSearch: [], select: [], view: "original" },
  modal: {
    //링크 생성모달에서 +버튼 누르면 생성되는 choiceList on/off 상태
    leftChoiceList: false,
    rightChoiceList: false,
  },
  submit: false,
  searchValue: "",
};

export default function CreateLinkModal(props) {
  const {
    handleModalClose,
    modalSetState,
    menu /* tabUI 메뉴 선택 번호 0 = "매장:도매사" 1 = "매장:제조사" 2 = "도매사:제조사" */,
    getTableData,
  } = props;

  const [state, setState] = useImmer(initState);
  const dispatch = useDispatch();

  const handleDeleteChoiceItem = (e, position, index) => {
    if (position === "left") {
      e.stopPropagation();
      setState((draft) => {
        draft.leftState.select = [];
        draft.rightState.select = [];
        draft.rightState.original = [];
      });
    }

    if (position === "right") {
      setState((draft) => {
        // original 배열에 checked === false로 만드는 로직
        draft.rightState.original = state.rightState.original.map(
          (item, index) => {
            if (index === +e.target.getAttribute("index")) {
              return { ...item, checked: false };
            } else {
              return item;
            }
          }
        );

        // search 배열에 checked === false로 만드는 로직
        draft.rightState.modalSearch = state.rightState.modalSearch.map(
          (item) => {
            if (
              item.label ===
              state.rightState.original[+e.target.getAttribute("index")].label
            ) {
              return { ...item, checked: false };
            } else {
              return item;
            }
          }
        );

        // select 배열에서 삭제하는 로직
        draft.rightState.select = state.rightState.select.filter((item) => {
          return (
            item.label !==
            state.rightState.original[+e.target.getAttribute("index")].label
          );
        });
      });
    }
  };

  const handleClearAll = () => {
    // 모두 지우기버튼 클릭시 실행 함수
    setState((draft) => {
      draft.rightState.original = state.rightState.original.map((item) => {
        return { ...item, checked: false };
      });
      draft.rightState.select = [];
      draft.modal.rightChoiceList = false;
    });
  };

  const handleIsOpenChoiceList = (position) => {
    //position === leftChoiceList or rightChoiceList
    setState((draft) => {
      draft.modal[`${position}ChoiceList`] =
        !state.modal[`${position}ChoiceList`];
      draft.searchValue = "";
    });
  };

  const handleSubmit = async () => {
    if (menu === 0) {
      const req = {
        vendorId: state.leftState.select[0].vendorId,
        pubIds: state.rightState.select.map((item) => {
          return item.pubId;
        }),
      };
      const res = await dispatch(API(postLinksVendorsPubs(req)));

      if (res.payload.status === 200) {
        getTableData();
        handleModalClose();
      }
    } else if (menu === 1) {
      const req = {
        breweryId: state.leftState.select[0].breweryId,
        pubIds: state.rightState.select.map((item) => {
          return item.pubId;
        }),
      };
      const res = await dispatch(API(postLinksBreweriesPubs(req)));

      if (res.payload.status === 200) {
        getTableData();
        handleModalClose();
      }
    } else if (menu === 2) {
      const req = {
        breweryId: state.leftState.select[0].breweryId,
        vendorIds: state.rightState.select.map((item) => {
          return item.vendorId;
        }),
      };
      const res = await dispatch(API(postLinksBreweriesVendors(req)));

      if (res.payload.status === 200) {
        getTableData();
        handleModalClose();
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setState((draft) => {
        draft.modal.leftChoiceList = false;
        draft.modal.rightChoiceList = false;
      });
    }
  };

  const init = async () => {
    if (menu === 0) {
      //매장:도매사 탭
      const res = await dispatch(API(getVendorsNames()));
      const vendors = res.payload.data.vendors.map((item) => {
        return {
          ...item,
          checked: false,
          label: item.vendorName,
          value: item.vendorId,
        };
      });

      setState((draft) => {
        draft.leftState.original = vendors;
      });
    } else if (menu === 1 || menu === 2) {
      //매장:제조사 탭, 도매사:제조사 탭
      const res = await dispatch(API(getBreweriesNames()));
      const breweries = res.payload.data.breweries.map((item) => {
        return {
          ...item,
          checked: false,
          label: item.breweryName,
          value: item.breweryId,
        };
      });

      setState((draft) => {
        draft.leftState.original = breweries;
      });
    }
  };

  useEffect(() => {
    init();
    window.addEventListener("keydown", escFunction);

    return () => {
      window.removeEventListener("keydown", escFunction);
    };
  }, []);

  useEffect(() => {
    if (state.rightState.select.length !== 0) {
      setState((draft) => {
        draft.submit = true;
      });
    } else {
      setState((draft) => {
        draft.submit = false;
      });
    }
  }, [state.rightState]);

  useEffect(() => {
    // leftState에서 선택하면 rightState의 original에 사용할 데이터들을 넣어주는 로직
    if (state.leftState.select.length === 0) return;
    if (menu === 0) {
      const getVendorAndPub = async () => {
        let temp = [];

        const res = await dispatch(
          API(getVendorsAndPubsNames(state.leftState.select[0].vendorId))
        );

        temp = res.payload.data.pubs.filter((item) => {
          return item.isLinked === 0;
        });
        temp = temp.map((item) => {
          return { ...item, checked: false, label: item.pubName };
        });
        setState((draft) => {
          draft.rightState.original = temp;
        });
      };
      getVendorAndPub();
    } else if (menu === 1) {
      const getBreweryAndPub = async () => {
        let temp = [];

        const res = await dispatch(
          API(getBrewerysAndPubsNames(state.leftState.select[0].breweryId))
        );

        temp = res.payload.data.pubs.filter((item) => {
          return item.isLinked === 0;
        });
        temp = temp.map((item) => {
          return { ...item, checked: false, label: item.pubName };
        });
        setState((draft) => {
          draft.rightState.original = temp;
        });
      };
      getBreweryAndPub();
    } else if (menu === 2) {
      const getBreweryAndVendors = async () => {
        let temp = [];

        const res = await dispatch(
          API(getBrewerysAndVendorsNames(state.leftState.select[0].breweryId))
        );

        temp = res.payload.data.vendors.filter((item) => {
          return item.isLinked === 0;
        });
        temp = temp.map((item) => {
          return { ...item, checked: false, label: item.vendorName };
        });
        setState((draft) => {
          draft.rightState.original = temp;
        });
      };
      getBreweryAndVendors();
    }
  }, [state.leftState.select]);

  useEffect(() => {
    // leftChoiceList가 닫히면 modalSearch에 빈 배열을 넣어줘서 다시 켰을 때 original 배열로 보여줄수 있도록 하는 로직
    setState((draft) => {
      draft.leftState.modalSearch = [];
    });
  }, [state.modal.leftChoiceList]);

  return (
    <PortalModal setState={modalSetState} type={false}>
      <Styled.Dialog>
        <Styled.Header>링크 생성</Styled.Header>
        <Styled.CloseButton onClick={handleModalClose}>X</Styled.CloseButton>
        <Styled.Main>
          <Styled.SubHeader>
            {subHeader[menu].leftSubHeader.label}에서 관리할 매장을 선택해
            주세요
          </Styled.SubHeader>
          <div className="content-box-wrapper">
            <Styled.ContentBox header={subHeader[menu].leftSubHeader.label}>
              <div className="flex-box">
                {subHeader[menu].leftSubHeader.label}
                {state.leftState.select.length === 0 && (
                  // leftChoiceList에서 선택을 안했을 때만 + 버튼이 보임
                  <button
                    className="button"
                    onClick={() => handleIsOpenChoiceList("left")}
                  >
                    +
                  </button>
                )}
              </div>
              {state.modal.leftChoiceList && (
                // leftChoiceList에서 + 버튼을 클릭 했을 때
                <LinkChoiceList
                  header={subHeader[menu].leftSubHeader.label}
                  handleIsOpenChoiceList={handleIsOpenChoiceList}
                  position="left" //choiceList 위치 left or right
                  state={state}
                  setState={setState}
                  className="choice-list"
                />
              )}
              {state.leftState.select.map((item, index) => {
                return (
                  <Styled.ChoiceItem
                    key={index}
                    onClick={(e) => handleIsOpenChoiceList(e, "left")}
                  >
                    {item.label}
                    <button
                      className="button"
                      onClick={(e) => handleDeleteChoiceItem(e, "left")}
                    >
                      <Styled.DeleteIMG
                        src={image.icCloseCircle}
                        alt="삭제버튼"
                      />
                    </button>
                  </Styled.ChoiceItem>
                );
              })}
            </Styled.ContentBox>

            <Styled.ChainIMG
              className="chanin-img"
              src={image.icLinkGray}
              alt="링크이미지"
            />

            <Styled.ContentBox header={subHeader[menu].rightSubHeader.label}>
              <div className="flex-box">
                {subHeader[menu].rightSubHeader.label}
                {
                  // left에서 선택하면 right +버튼 생성
                  state.leftState.select.length === 1 &&
                    state.rightState.select.length === 0 && (
                      <button
                        className="button"
                        onClick={() => handleIsOpenChoiceList("right")}
                      >
                        +
                      </button>
                    )
                }
                {
                  // right에서 1개 이상 선택시 +버튼 -> 모두 지우기 버튼으로 변경
                  state.rightState.select.length > 0 && (
                    <Styled.ClearAllButton onClick={handleClearAll}>
                      모두 지우기
                    </Styled.ClearAllButton>
                  )
                }
              </div>
              {state.modal.rightChoiceList && (
                <LinkChoiceList
                  header={subHeader[menu].rightSubHeader.label}
                  handleIsOpenChoiceList={handleIsOpenChoiceList}
                  position="right" //choiceList 위치 left or right
                  state={state}
                  setState={setState}
                  className="choice-list"
                />
              )}
              {state.rightState.selectCount > 0 && (
                <Styled.AddSelectButton
                  onClick={() => handleIsOpenChoiceList("right")}
                >
                  그 외 선택하기
                </Styled.AddSelectButton>
              )}
              <Styled.ChoiceItemList>
                {state.rightState.original.map((item, index) => {
                  if (item.checked === true) {
                    return (
                      <Styled.ChoiceItem key={index}>
                        {item.label}
                        <button
                          className="button"
                          onClick={(e) =>
                            handleDeleteChoiceItem(e, "right", index)
                          }
                          index={index}
                        >
                          <Styled.DeleteIMG
                            src={image.icCloseCircle}
                            alt="삭제버튼"
                          />
                        </button>
                      </Styled.ChoiceItem>
                    );
                  }
                })}
              </Styled.ChoiceItemList>
            </Styled.ContentBox>
          </div>
          <Styled.ButtonWarpper state={state}>
            <button className="cancel-button" onClick={handleModalClose}>
              취소
            </button>
            <button className="add-button" onClick={handleSubmit}>
              연결하기
            </button>
          </Styled.ButtonWarpper>
        </Styled.Main>
      </Styled.Dialog>
    </PortalModal>
  );
}
