// banner/update
import React, { useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { modalCont } from "asset/data/modalCont";
import { color } from "style/globalStyle";
import Styled from "../create/style";
import PortalModal from "container/modal/portalModal/index";
import SubmitButton from "component/button/submitButton/index";
import RadioButton from "component/radioButton/index";
import CancelButton from "component/button/cancelButton/index";
import CustomPrompt from "component/prompt/index";
import SelectBox from "component/selectBox/index";
import TextInput from "component/input/textInput/TextInput";
import { setModalKey } from "hook/modal";
import usePrompt from "hook/usePrompt";
import { makeCheckObject } from "util/makeCheckList";
import { API, handleErrorState } from "store/reducer/apiSlice";
import { getBannersBannerId, putBannersBannerId } from "api/banner/index";
import { checkAwsApiStaus } from "util/presigend";
import { bannerSelectList } from "asset/data/selectList";
import ProductListModal from "../productListModal/index";
import { getBeverageNames } from "api/beverage/index";
import { getLessonsTitles } from "api/education/index";
import { getNoticesTitles } from "api/notice/index";

const initModalState = {
  modal: {
    modalVisible: false,
    modalKey: "",
    nextPath: "",
    completeContent: "",
  },
};

const initSubmitState = {
  file: "",
  filePreview: "",
  activated: "",
  idx: 0,
  linkType: "",
  beverageId: "",
  beverageName: "",
  noticeId: "",
  lessonId: "",
  url: "",
};

export default function SettingBannerUpdate() {
  const [modalState, setModalState] = useImmer(initModalState);
  const [submitState, setSubmitState] = useImmer(initSubmitState);
  const [educationList, setEducationList] = useImmer();
  const [noticeList, setNoticeList] = useImmer();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const bannerIdx = useSelector((state) => ({
    activatedLastIdx: state.banner.activatedLastIdx,
    unActivatedLastIdx: state.banner.unActivatedLastIdx,
  }));

  const checkList = makeCheckObject({ ...submitState }, ["filePreview", "idx"]);
  const { isValueChanged, setIsValueChanged, setInitList } = usePrompt({
    checkList,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) return;
    reader.onload = () => {
      setSubmitState((draft) => {
        draft.file = event.target.files[0];
        draft.filePreview = reader.result;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSelectProductId = useCallback((product) => {
    setSubmitState((draft) => {
      draft.beverageId = product.id;
      draft.beverageName = product.name;
    });
  }, []);

  const handleChangeExternalUrl = useCallback((event) => {
    const value = event?.target?.value ?? event;

    setSubmitState((draft) => {
      draft.url = value;
    });
  }, []);

  const handleSubmit = async () => {
    const activated = submitState.activated === "1" ? true : false;
    const body = {};
    if (submitState.activated === 1 || submitState.activated === true) {
      body.idx = bannerIdx.activatedLastIdx + 1;
    } else {
      body.idx = bannerIdx.unActivatedLastIdx + 1;
    }
    body.activated = activated;
    body.contentType = submitState.file.type;

    if (submitState.linkType) {
      body.linkType = submitState.linkType;
      body.link =
        submitState.linkType === "beverage"
          ? submitState.beverageId
          : submitState.linkType === "notice"
          ? submitState.noticeId
          : submitState.linkType === "lesson"
          ? submitState.lessonId
          : submitState.url;
    }

    const res = await dispatch(API(putBannersBannerId(id, body)));

    const { status } = res.payload;
    if (status === 200) {
      const awsStatus = await checkAwsApiStaus(res, submitState.file, dispatch);
      if (awsStatus === 204 || awsStatus === 200) {
        setIsValueChanged(false);
        setModalKey(
          setModalState,
          "submitComplete",
          "/banner",
          "변경사항이 저장되었습니다."
        );
        setTimeout(() => {
          setModalState((draft) => {
            draft.modal.modalVisible = false;
          });
        }, [1000]);
        return;
      }
    }
    dispatch(handleErrorState(false)); //  errorOccur state 초기화
  };

  const handleClickCancel = () => history.push("/banner");

  const handleSelect = (name, value) => {
    setSubmitState((draft) => {
      draft[name] = value;
    });
  };

  const getDetailInfo = async () => {
    const {
      payload: { data, status },
    } = await dispatch(API(getBannersBannerId(id)));

    const {
      payload: {
        data: { beverages },
      },
    } = await dispatch(API(getBeverageNames()));

    if (status === 200) {
      const activated = data.activated === 0 ? "0" : "1";
      setInitList({
        file: "",
        activated: activated,
      }); //  initList 의 제일 처음 값 입력 checkList 의 값과 비교하기 위함
      setSubmitState((draft) => {
        draft.filePreview = data.signedUrl;
        draft.activated = activated;
        draft.idx = data.idx;

        if (data.linkType) {
          draft.linkType = data.linkType;
        }

        if (data.link) {
          if (data.linkType === "beverage") {
            draft.beverageId = data.link;

            const beverage = beverages.find(
              ({ beverageId }) => beverageId === data.link
            );
            if (beverage) {
              draft.beverageName = beverage.beverageName;
            }
          }
          if (data.linkType === "lesson") {
            draft.lessonId = data.link;
          }
          if (data.linkType === "notice") {
            draft.noticeId = data.link;
          }
          if (data.linkType === "url") {
            draft.url = data.link;
          }
        }
      });
    } else {
      dispatch(handleErrorState(false)); //  errorOccur state 초기화
    }
  };

  const getEducationList = async () => {
    const {
      payload: {
        data: { lessons },
      },
    } = await dispatch(API(getLessonsTitles()));

    setEducationList(
      lessons.map((education) => ({
        label: education.title,
        value: education.lessonId,
      }))
    );
  };

  const getNoticeList = async () => {
    const {
      payload: {
        data: { notices },
      },
    } = await dispatch(API(getNoticesTitles()));

    setNoticeList(
      notices.map((notice) => ({
        label: notice.title,
        value: notice.noticeId,
      }))
    );
  };

  useEffect(() => {
    getDetailInfo();
    getEducationList();
    getNoticeList();
  }, []);

  return (
    <Styled.Root>
      <CustomPrompt
        modalState={modalState}
        setModalState={setModalState}
        isValueChanged={isValueChanged}
      />
      {modalState.modal.modalVisible && (
        <PortalModal setState={setModalState}>
          {modalCont({
            modalKey: modalState.modal.modalKey,
            nextPath: modalState.modal.nextPath,
            completeContent: modalState.modal.completeContent,
            setState: setModalState,
          })}
        </PortalModal>
      )}
      <Styled.Header>배너등록 &nbsp; | &nbsp; 모바일</Styled.Header>
      <Styled.Body>
        <div className="tr">
          <div className="title">배너 유형</div>
          <div className="content">모바일 배너</div>
        </div>
        <div className="tr">
          <div className="title">배너 크기</div>
          <div className="content">1200*600</div>
        </div>
        <div className="tr">
          <div className="title">연결 페이지</div>
          <div className="content">
            <Styled.SelectBoxWrapper>
              <SelectBox
                placeholder="- 선택 -"
                width="140px"
                innerWidth="140px"
                name="linkType"
                value={submitState.linkType}
                selectList={bannerSelectList}
                handleSelect={handleSelect}
              />
              {submitState.linkType === "beverage" && (
                <ProductListModal
                  value={submitState.beverageName}
                  selectedId={submitState.beverageId}
                  submitState={submitState}
                  handleSelectId={handleSelectProductId}
                />
              )}
              {submitState.linkType === "lesson" && educationList && (
                <SelectBox
                  placeholder="- 선택 -"
                  className="type"
                  width="300px"
                  innerWidth="300px"
                  name="lessonId"
                  value={submitState.lessonId}
                  selectList={educationList}
                  handleSelect={handleSelect}
                />
              )}
              {submitState.linkType === "notice" && noticeList && (
                <SelectBox
                  placeholder="- 선택 -"
                  className="type"
                  width="300px"
                  innerWidth="300px"
                  name="noticeId"
                  value={submitState.noticeId}
                  selectList={noticeList}
                  handleSelect={handleSelect}
                />
              )}
              {submitState.linkType === "url" && (
                <Styled.TextWrapper>
                  <label htmlFor="externalUrl">링크URL</label>
                  <TextInput
                    id="externalUrl"
                    name="url"
                    value={submitState.url}
                    placeholder="Url 입력"
                    onChange={handleChangeExternalUrl}
                  />
                </Styled.TextWrapper>
              )}
            </Styled.SelectBoxWrapper>
          </div>
        </div>
        <div className="tr">
          <div className="title">
            활성여부<Styled.Essential>*</Styled.Essential>
          </div>
          <div className="content">
            <RadioButton
              height="100%"
              contents={["활성", "비활성"]}
              name="activated"
              state={submitState}
              setState={setSubmitState}
            />
          </div>
        </div>
        <div className="tr gallery-tr">
          <div className="title">
            이미지 등록<Styled.Essential>*</Styled.Essential>
          </div>
          <div className="content">
            <Styled.ImgLabel
              checkFile={submitState.filePreview ? true : false}
              htmlFor="banner-upload-img-file"
            >
              {submitState.filePreview && (
                <img src={submitState.filePreview} alt="preview-img" />
              )}
            </Styled.ImgLabel>
            <input
              type="file"
              id="banner-upload-img-file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </Styled.Body>
      <Styled.ButtonWrapper>
        <CancelButton handleClick={handleClickCancel} />
        <SubmitButton
          content="수정완료"
          backgroundColor={color.Color5ab1f0}
          handleClick={handleSubmit}
          width="120px"
          height="40px"
        />
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
}
